import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TablePagination
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSettingsAsync,
  selectPaginator,
  selectSettingData,
  selectStatus
} from 'src/slices/settingSlice';
import SettingTableActionCell from './SettingTableActionCell';
const SettingTable = ({ selectedEntity }) => {
  const dispatch = useDispatch();
  const settingsData = useSelector(selectSettingData);
  const status = useSelector(selectStatus);
  const paginator = useSelector(selectPaginator);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const settingFilterdData = selectedEntity
    ? settingsData.filter(
        (settingData) => settingData.entity === selectedEntity
      )
    : settingsData;
  useEffect(() => {
    const query = selectedEntity
      ? { entity: { $regex: selectedEntity, $options: 'i' }, isDefault: false }
      : { isDefault: true };
    dispatch(
      getSettingsAsync({
        endPoint: 'setting',
        select: ['entity', 'setting_type', 'data'],
        pagination: true,
        page,
        query,
        limit
      })
    );
  }, [page, limit, selectedEntity, dispatch]);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };
  return (
    <>
      <Card variant="outlined">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Entity</TableCell>
                <TableCell>Setting Type</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status.getSetting ? (
                <TableRow>
                  <TableCell colSpan={settingsData.length + 1}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                settingFilterdData &&
                settingFilterdData.length > 0 &&
                settingFilterdData.map((settingData) => (
                  <TableRow key={settingData?.id}>
                    <TableCell>{settingData?.entity}</TableCell>
                    <TableCell>{settingData?.setting_type}</TableCell>
                    <SettingTableActionCell
                      selectedEntity={selectedEntity}
                      settingData={settingData}
                    />
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paginator && (
          <Box p={2}>
            <TablePagination
              component="div"
              count={paginator?.itemCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[1, 3, 5, 10, 20]}
            />
          </Box>
        )}
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 5
        }}
      ></Box>
    </>
  );
};

export default SettingTable;
