import { MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteDataAsync,
  selectDynamicPageConfig,
  selectStatus
} from 'src/slices/dynamicSlice';

const StandardAction = ({ row }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const { entity, entitySetting } = useSelector(selectDynamicPageConfig);
  const [confirmationModal, setConfirmationModal] = useState(false);

  // Determine the base route
  const baseRoute = `/${location.pathname.split('/')[1]}`;

  // Check if an action should be shown based on the `overrideAct` value
  const shouldShowAction = (actionType) => {
    // Show the action if it's not found in the hidden list (overrideAct)
    return !(entitySetting?.actions || []).some(
      (action) => action.overrideAct === actionType
    );
  };

  // Handle the delete action
  const handleDelete = async () => {
    await dispatch(deleteDataAsync({ endPoint: entity, id: row.id }));
    setConfirmationModal(false);
  };

  return (
    <>
      {shouldShowAction('edit') && (
        <MenuItem
          component={RouterLink}
          to={`${baseRoute}/edit/${row.id}`}
          disableRipple
        >
          <EditIcon />
          Edit
        </MenuItem>
      )}
      {shouldShowAction('view') && (
        <MenuItem
          component={RouterLink}
          to={`${baseRoute}/view/${row.id}`}
          disableRipple
        >
          <VisibilityIcon />
          View
        </MenuItem>
      )}
      {shouldShowAction('delete') && (
        <MenuItem onClick={() => setConfirmationModal(true)} disableRipple>
          <DeleteForeverIcon />
          Delete
        </MenuItem>
      )}
      <ConfirmationModal
        title={`Permanently Delete ${row.name}?`}
        description={
          'This will permanently remove the item. Are you sure you want to continue?'
        }
        confirmBtnConfig={{
          name: 'Delete',
          variant: 'contained',
          color: 'error'
        }}
        wayOutBtnConfig={{
          name: 'Cancel',
          variant: 'outlined',
          color: 'primary'
        }}
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        isLoading={status.deleteData}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default StandardAction;
