import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';

const RootWrapper = styled(Box)(
  () => `
            flex: 1;
        `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

const PageHeader = ({ currentItem }) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [isSending, setSending] = useState(false);
  const isMounted = useRefMounted();
  const { approval_status, status } = currentItem;
  const handleConfirmStockEntry = async () => {
    setSubmitting(true);
    try {
      const payload = {
        endPoint: 'stock_audit',
        id: currentItem.id,
        data: {
          status: 'confirmed'
        }
      };
      await requestApi.updateData(payload);
    } finally {
      if (isMounted.current) setSubmitting(false);
    }
  };

  const handleSendApproval = async () => {
    setSending(true);
    try {
      const payload = {
        endPoint: 'approval',
        data: {
          entity: 'stock_audit',
          document_id: currentItem.id
        }
      };
      await requestApi.createData(payload);
    } finally {
      if (isMounted.current) setSending(false);
    }
  };
  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3" component="h3" gutterBottom>
              Initial Stock
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={0.5}>
            {status === 'draft' && (
              <Button
                variant="outlined"
                size="small"
                type="submit"
                disabled={submitting}
                startIcon={
                  submitting ? (
                    <CircularProgress size={'1rem'} />
                  ) : (
                    <InventoryIcon size={'1rem'} />
                  )
                }
                onClick={handleConfirmStockEntry}
              >
                Confirm Stock Entry
              </Button>
            )}
            {approval_status === 'NOT SUBMITTED' && (
              <Button
                variant="outlined"
                size="small"
                type="submit"
                disabled={isSending}
                startIcon={
                  isSending ? (
                    <CircularProgress size={'1rem'} />
                  ) : (
                    <SendIcon size={'1rem'} />
                  )
                }
                onClick={handleSendApproval}
              >
                Send For Approval
              </Button>
            )}
            <Tooltip title={`Go back`}>
              <IconButtonWrapper
                disabled={submitting}
                onClick={() => navigate(-1)}
              >
                <ArrowBackTwoToneIcon />
              </IconButtonWrapper>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </RootWrapper>
  );
};

export default PageHeader;
