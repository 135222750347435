/* eslint-disable no-constant-binary-expression */
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip
} from '@mui/material';
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import BackDropLoader from 'src/components/BackDropLoader';
import ImportTable from 'src/components/ImportTable';
import { requestApi } from 'src/mocks/request';
import { checkCellValue } from 'src/services/exportTableDataService';
import { selectDynamicPageConfig, selectQuery } from 'src/slices/dynamicSlice';
import * as XLSX from 'xlsx';

const ExportData = () => {
  const { endPoint } = useParams();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { fieldNames, populateString, entitySetting, schema, entity } =
    useSelector(selectDynamicPageConfig);
  const query = useSelector(selectQuery);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const filterData = (data) => {
    return data?.map((row) => {
      return fieldNames?.map((fieldName) =>
        checkCellValue(row[fieldName], fieldName, schema, entitySetting)
      );
    });
  };

  const getDataAsync = async () => {
    try {
      setLoading(true);
      const requestPayload = {
        select: fieldNames,
        endPoint: entity,
        populate: populateString,
        query: query,
        pagination: false
      };
      const response = await requestApi.getData(requestPayload);
      if (response?.status === 'SUCCESS') {
        return filterData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = async () => {
    setAnchorEl(null);
    const data = await getDataAsync();
    if (!data) return;

    const headers = fieldNames;
    const transformedData = data?.map((row) => {
      const rowData = {};
      row.forEach((cell, index) => {
        rowData[headers[index]] = cell;
      });
      return rowData;
    });

    // Convert array of objects to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Write the workbook to a file
    XLSX.writeFile(workbook, `${endPoint}.csv` || 'data.csv');
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'export-popover' : undefined;

  return (
    <>
      <Tooltip title={'Import/Export'}>
        <IconButton color="primary" onClick={handleClick}>
          <UploadFileIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItem>
            <ListItemText
              primary="Export as CSV"
              sx={{ cursor: 'pointer' }}
              onClick={exportToExcel}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              onClick={handleModal}
              primary="Import CSV"
              sx={{ cursor: 'pointer' }}
            />
          </ListItem>
        </List>
      </Popover>

      {/* BULK IMPORT MODAL */}
      {openModal && (
        <ImportTable
          open={openModal}
          handleModal={handleModal}
          endPoint={entity}
        />
      )}

      <BackDropLoader isShow={loading}>
        <CircularProgress size="3rem" />
      </BackDropLoader>
    </>
  );
};

export default ExportData;
