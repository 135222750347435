import { CloseRounded, Info } from '@mui/icons-material';
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Scrollbar from 'src/components/Scrollbar';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

export default function ImportPreviewTable({
  uploadFileData,
  endPoint,
  setUploadFileData,
  tableHeaders,
  setIsFieldDirty,
  fieldToShow,
  setFieldToShow,
  fieldErrors
}) {
  // console.log('Table Headers::S3', tableHeaders);
  // console.log('Filtered Data::S3', uploadFileData);

  const theme = useTheme();
  // const { schema } = useSelector(selectDynamicPageConfig);
  const { schema } = useEntityPageConfig(endPoint);
  const [paginatedData, setPaginatedData] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(uploadFileData?.length || 0);
  const [fileLoading, setFileLoading] = useState(true);

  const filteredTableHeaders = useMemo(() => {
    return tableHeaders?.filter((header) => {
      const field = schema[header];
      return field && field.instance !== 'Array' && field.instance !== 'Object';
    });
  }, [schema, tableHeaders]);

  const enumTypeFields = useMemo(() => {
    return Object.values(schema)?.filter(
      (item) => item?.enumValues?.length > 0 || item.instance === 'Boolean'
    );
  }, [schema]);

  const getEnumValues = (header) => {
    const field = enumTypeFields?.find(({ path }) => path === header);
    if (field?.instance === 'Boolean') {
      return ['Yes', 'No'];
    } else {
      return field?.enumValues || null;
    }
  };

  const handleChange = (e, rowIndex, header) => {
    setIsFieldDirty(true);
    const newData = [...uploadFileData];
    newData[rowIndex][header] = e.target.value;
    setUploadFileData(newData);
  };

  const handleDeleteRow = (rowIndex) => {
    const newData = uploadFileData?.filter((_, index) => index !== rowIndex);
    setUploadFileData(newData);
  };

  useEffect(() => {
    let delay;
    if (uploadFileData) {
      setPageCount(uploadFileData.length);
      const startIndex = (page - 0) * limit;
      const endIndex = startIndex + limit;
      const currentPageData = uploadFileData?.slice(startIndex, endIndex);
      setPaginatedData(currentPageData);
      delay = setTimeout(() => {
        setFileLoading(false);
      }, 1000);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [uploadFileData, page, limit]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const renderInputCell = (item, rowIndex, header) => {
    const enumValues = getEnumValues(header);

    const errors = fieldErrors?.find(
      (error) => error.fieldName === header
    )?.errors;
    const error = errors?.find((error) => error.rowIndex === rowIndex);

    return (
      <Box sx={{ minWidth: 'max-content', width: '100%' }}>
        <TextField
          key={header}
          fullWidth
          select={!!enumValues}
          variant="standard"
          size="small"
          sx={{
            minWidth: 'fit-content',
            width: '100%'
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              borderRadius: '0 !important'
            },
            endAdornment: error && (
              <InputAdornment position="end">
                <Tooltip title="Item not found!">
                  <IconButton size="small" color="error" edge="end">
                    <Info fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
          value={
            (['Yes', 'yes', 'Y', 'y'].includes(item[header])
              ? 'Yes'
              : ['No', 'no', 'N', 'n'].includes(item[header])
              ? 'No'
              : item[header]) || ''
          }
          onChange={(e) => handleChange(e, rowIndex, header)}
          error={!!error}
        >
          {enumValues &&
            enumValues.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
        </TextField>
      </Box>
    );
  };

  const handleFieldToShowChange = (e, header) => {
    setIsFieldDirty(true);
    const selectedField = e.target.value;
    setFieldToShow((prev) => {
      const updatedFields = prev.map((item) =>
        item.fieldName === header ? { ...item, selectedField } : item
      );
      return updatedFields;
    });
  };

  return (
    <>
      {fileLoading && (
        <Box sx={{ py: 2 }}>
          <Typography variant="body1" gutterBottom>
            Loading file. Please wait...
          </Typography>
          <LinearProgress />
        </Box>
      )}
      <Card
        sx={{
          boxShadow: 'none',
          border: `1px solid ${theme.palette.divider}`
        }}
      >
        <TableContainer sx={{ width: '100%' }}>
          <Scrollbar horizontal={true} maxHeight={340}>
            <Table
              stickyHeader
              aria-label="preview table"
              sx={{ borderCollapse: 'collapse', tableLayout: 'auto' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      py: 0.5,
                      border: `1px solid ${theme.palette.divider}`,
                      borderLeft: 0,
                      borderTop: 0
                    }}
                  ></TableCell>
                  {filteredTableHeaders?.map((header) => {
                    const field = fieldToShow?.find(
                      (item) => item.fieldName === header
                    );
                    return (
                      <TableCell
                        key={header}
                        sx={{
                          py: 0.5,
                          border: `1px solid ${theme.palette.divider}`,
                          borderTop: 0
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          <span>{header}</span>
                          {field && (
                            <Select
                              size="small"
                              fullWidth
                              MenuProps={{
                                PaperProps: { sx: { padding: '0' } },
                                MenuListProps: { sx: { padding: '2px 4px' } }
                              }}
                              sx={{
                                ml: 1,
                                '& .MuiSelect-select': {
                                  padding: '2px 6px',
                                  fontSize: '12px',
                                  fontWeight: '600'
                                }
                              }}
                              value={field.selectedField || ''}
                              onChange={(e) =>
                                handleFieldToShowChange(e, header)
                              }
                            >
                              {field.fields.map((fieldOption) => (
                                <MenuItem key={fieldOption} value={fieldOption}>
                                  {fieldOption}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </Stack>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData?.map((item, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell
                      sx={{
                        padding: 0,
                        border: `1px solid ${theme.palette.divider}`,
                        borderLeft: 0
                      }}
                      align="center"
                    >
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteRow(rowIndex)}
                      >
                        <CloseRounded />
                      </IconButton>
                    </TableCell>
                    {filteredTableHeaders?.map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          paddingY: 0.4,
                          whiteSpace: 'nowrap',
                          border: `1px solid ${theme.palette.divider}`
                        }}
                      >
                        {renderInputCell(item, rowIndex, header)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        {pageCount > 0 && (
          <Box
            sx={{
              boxShadow: '0px -9px 16px rgba(159, 162, 191, .18)'
            }}
          >
            <TablePagination
              component="div"
              count={pageCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 50]}
            />
          </Box>
        )}
      </Card>
    </>
  );
}
