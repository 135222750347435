import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import StockViewTable from '../StockViewTable';

const ReviewStock = () => {
  const { currentItem, isFetching } = useDyFetchDataById();
  if (isFetching) return null;
  return (
    <>
      <Helmet>
        <title>Review Stock Audit</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader currentItem={currentItem} />
      </PageTitleWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StockViewTable currentItem={currentItem} />
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewStock;
