import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useRefMounted from './useRefMounted';
import { requestApi } from 'src/mocks/request';
import {
  createDataAsync,
  deleteDataAsync,
  selectDynamicPageConfig,
  setData
} from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { capitalizeAndRemoveChar } from 'src/services/commonService';
import useLinkGenerator from './useLinkGenerator';
import useEntitySchemaAndSetting from './useEntitySchemaAndSetting';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';

const useCustomActFuc = (rowData, action, setConfirmationModal) => {
  const dispatch = useDispatch();
  const isMounted = useRefMounted();
  const generateLink = useLinkGenerator();
  const { getEntityConfig } = useEntitySchemaAndSetting();
  const { entity, schema } = useSelector(selectDynamicPageConfig);
  const [loading, setLoading] = useState(false);
  const { id: rowId, name, title, number } = rowData || {};
  const { customFun, apiEndpoint, targetEntity } = action || {};

  // Function to handle conversion
  const handleApiAction = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        path: `/${targetEntity}/${apiEndpoint}`,
        data: {
          from_entity: entity,
          from_id: rowId
        }
      };
      const response = await requestApi.actionApi(payload);
      if (response.status === 'SUCCESS') {
        setConfirmationModal({
          isOpen: true,
          title: `The ${capitalizeAndRemoveChar(
            targetEntity
          )} has been successfully created.`,
          description: `The ${capitalizeAndRemoveChar(
            targetEntity
          )} for ${capitalizeAndRemoveChar(
            entity
          )} has been created. You can now complete this task or view the details.`,
          link: generateLink(targetEntity, 'view', response.data.id) // Link for navigation
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteDataAsync({ endPoint: entity, id: rowId }));
  }, []);

  const handleConfirmationModal = useCallback(() => {
    setConfirmationModal({
      isOpen: true,
      title: `Permanently Delete ${name || title || number || rowId}?`,
      description:
        'This will permanently remove the item. Are you sure you want to continue?',
      onConfirm: handleDelete
    });
    // handleCloseMenu();
  }, [handleDelete, rowData]);

  // Function to handle deep copy if needed
  const deepCopy = async (key, value) => {
    // Check for the reference endpoint
    const refEndpoint =
      schema[key]?.options?.ref ||
      schema[key]?.$embeddedSchemaType?.options?.ref;

    // If no ref is found, return the original value
    if (!refEndpoint) {
      return value;
    }

    // Fetch schema and setting for the reference endpoint
    const refSchema = await getEntityConfig(refEndpoint);

    const keys = Object.keys(value).filter(
      (key) =>
        !refSchema?.[key]?.options?.no_copy &&
        !refSchema[key]?.$embeddedSchemaType?.options?.no_copy &&
        !REMOVE_FIELDS.includes(key) &&
        key !== 'id'
    );
    const filteredData = keys.reduce((obj, key) => {
      obj[key] = value[key];
      return obj;
    }, {});

    try {
      const response = await requestApi.createData({
        endPoint: refEndpoint,
        data: filteredData
      });

      // Return the data of the created deep copy document
      return response?.data;
    } catch (error) {
      console.error(`Error during deep copy for field ${key}:`, error);
      return value; // Return original value on error
    }
  };

  const duplicateDoc = async () => {
    setLoading(true);
    const keys = Object.keys(rowData).filter(
      (key) =>
        !schema?.[key]?.options?.no_copy &&
        !schema[key]?.$embeddedSchemaType?.options?.no_copy &&
        !REMOVE_FIELDS.includes(key) &&
        key !== 'id'
    );

    // Separate keys that need deep copying from those that don't
    const deepCopyPromises = keys.map(async (key) => {
      if (schema[key]?.options?.deep_copy) {
        const response = await deepCopy(key, rowData[key]);
        return { [key]: response };
      }
      // Directly return the value if no deep copy is needed
      return { [key]: rowData[key] };
    });

    try {
      // Resolve all deep copy promises
      const deepCopyResults = await Promise.all(deepCopyPromises);
      // Combine all results into a single object
      const filteredData = deepCopyResults.reduce(
        (acc, cur) => ({ ...acc, ...cur }),
        {}
      );

      // Sanitize the data before creating a new document
      const { createData: sanitizedData } = sanitizeFormData(
        filteredData,
        schema,
        keys
      );

      // Prepare payload for creating the duplicated document
      const payload = {
        endPoint: entity,
        data: sanitizedData,
        localUpdate: filteredData
      };

      // Dispatch the action to create the duplicated document
      await dispatch(createDataAsync(payload));
    } catch (error) {
      console.error('Error duplicating document:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  // Function to handle sending approval
  const sendApproval = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        endPoint: 'approval',
        data: {
          entity,
          document_id: rowId
        }
      };
      const response = await requestApi.createData(payload);
      if (response?.data)
        dispatch(
          setData({
            ...rowData,
            approval_id: response.data,
            approval_status: 'PENDING'
          })
        );
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  }, [dispatch, entity, rowData]);

  const availableActions = ['delete', 'sendApproval', 'duplicateDoc'];

  const actionMap = {
    delete: handleConfirmationModal,
    sendApproval: sendApproval,
    duplicateDoc: duplicateDoc
  };

  const handleInvokeFun = () => {
    if (actionMap[customFun]) {
      return actionMap[customFun](); // Executes the corresponding function
    } else {
      throw new Error(`Unknown operation type: ${customFun}`);
    }
  };

  return {
    handleInvokeFun,
    availableActions,
    handleApiAction,
    loading
  };
};

export default useCustomActFuc;
