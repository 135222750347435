import {
  CloseRounded,
  DoneTwoTone,
  DownloadRounded,
  InfoTwoTone,
  UploadFileRounded
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';

import FieldErrorPreview from './FieldErrorPreview';
import ImportPreviewTable from './ImportPreviewTable';
import InsertionSummaryModal from './InsertionSummary';
import { useImportTable } from './useImportTable';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = [
  {
    label: 'Upload file',
    description: `Upload a CSV file with the data you want to import. The file must
              contain a header row that specifies the field names. Make sure the file aligns with the table`
  },
  {
    label: 'Validate fields',
    description: `Click 'Validate' to check for validation errors in the table. You can also choose which
              field to import for some columns.`
  },
  {
    label: 'Import data',
    description: `Once validated, click 'Import' to add the data into the site.`
  }
];

export default function ImportTable({
  open,
  handleModal,
  endPoint,
  customActions,
  onValidated
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    tableHeaders,
    uploadFileData,
    setUploadFileData,
    fieldToShow,
    setFieldToShow,
    isImporting,
    importData,
    selectedFileName,
    setSelectedFileName,
    fieldErrors,
    setFieldErrors,
    setIsFieldDirty,
    errorMessage,
    errorPreviewOpen,
    isSummaryDialogOpen,
    setIsSummaryDialogOpen,
    // functions
    handleSampleFile,
    handleFileChange,
    handleValidateField,
    handleImport,
    handleUploadButtonClick,
    handleClose,
    // other
    isImportDisabled,
    isValidateDisabled,
    fileInputRef
  } = useImportTable({ endPoint });

  const handleImportAndNotify = async () => {
    if (onValidated && importData) {
      onValidated(importData);
    } else {
      await handleImport(); // default import action
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleModal}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', px: { xs: 2, md: 3 }, py: 0.5 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleModal}>
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 1, flex: 1 }} variant="h5" color={'white'}>
              Upload CSV File
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            width: '100%',
            minHeight: 150,
            marginX: 'auto',
            padding: { xs: 2, md: 3 }
          }}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={1}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: { sm: 'center' },
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' }
              }}
            >
              <Button
                disabled={isValidateDisabled && uploadFileData?.length > 0}
                size="small"
                variant="outlined"
                startIcon={<DownloadRounded />}
                onClick={handleSampleFile}
                sx={{ textWrap: 'nowrap' }}
              >
                Download Sample
              </Button>
              <TextField
                fullWidth
                id="csv-file"
                type="file"
                inputProps={{ accept: '.csv' }}
                onChange={handleFileChange}
                sx={{ flex: 1, display: 'none' }}
                inputRef={fileInputRef}
              />
              <Button
                disabled={isValidateDisabled && uploadFileData}
                size="small"
                variant="outlined"
                startIcon={<UploadFileRounded />}
                onClick={handleUploadButtonClick}
              >
                Upload
              </Button>
              <LoadingButton
                size="small"
                variant="contained"
                color={isValidateDisabled ? 'primary' : 'success'}
                disabled={!uploadFileData?.length || !uploadFileData}
                startIcon={
                  isValidateDisabled ? <InfoTwoTone /> : <DoneTwoTone />
                }
                onClick={handleValidateField}
              >
                {isValidateDisabled ? 'Validate' : 'Validated'}
              </LoadingButton>
              <LoadingButton
                fullWidth
                loading={isImporting}
                variant="contained"
                size="small"
                disabled={isImportDisabled}
                onClick={handleImportAndNotify}
                sx={{ width: 1 / 4 }}
              >
                Import
              </LoadingButton>
              {/* =-=-=-=-=-=-=-=-= Custom Actions | Buttons =-=-=-=-=-=-=-=-= */}
              {customActions}
              {/* =-=-=-=-=-=-=-=-= Custom Actions | Buttons =-=-=-=-=-=-=-=-= */}
            </Box>
            <Stack direction="row" gap={1}>
              {selectedFileName && (
                <Chip
                  label={selectedFileName}
                  onDelete={() => {
                    setUploadFileData(null);
                    setSelectedFileName(null);
                    setFieldErrors(null);
                    setFieldToShow(null);
                    if (fileInputRef.current) {
                      fileInputRef.current.value = null;
                    }
                  }}
                />
              )}
            </Stack>
          </Stack>

          {/* Instructions | Stepper */}
          {!uploadFileData && (
            <Stepper
              orientation={isSmallScreen ? 'vertical' : 'horizontal'}
              sx={{ borderRadius: '8px', p: 4 }}
            >
              {steps.map((step) => (
                <Step key={step.label} active={true}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Typography variant="subtitle2" fontSize={12}>
                      {step.description}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
              {!isSmallScreen && <Step></Step>}
            </Stepper>
          )}

          {/* Import Preview Table */}
          {uploadFileData && uploadFileData?.length > 0 && (
            <ImportPreviewTable
              endPoint={endPoint}
              uploadFileData={uploadFileData}
              setUploadFileData={setUploadFileData}
              tableHeaders={tableHeaders}
              setIsFieldDirty={setIsFieldDirty}
              fieldToShow={fieldToShow}
              setFieldToShow={setFieldToShow}
              fieldErrors={fieldErrors}
            />
          )}
        </Box>
      </Dialog>

      {/* Create Non-Existing Documents | Modal */}
      {fieldErrors && fieldErrors?.length > 0 && (
        <FieldErrorPreview
          errorPreviewOpen={errorPreviewOpen}
          handleClose={handleClose}
          fieldToShow={fieldToShow}
          fieldErrors={fieldErrors}
          uploadFileData={uploadFileData}
          isValidateDisabled={isValidateDisabled}
          handleValidateField={handleValidateField}
          setIsFieldDirty={setIsFieldDirty}
          setFieldToShow={setFieldToShow}
        />
      )}

      {isSummaryDialogOpen && (
        <InsertionSummaryModal
          isSummaryDialogOpen={isSummaryDialogOpen}
          setIsSummaryDialogOpen={setIsSummaryDialogOpen}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
}
