import { Checkbox, Chip, TableCell, TableRow } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import useFetchMultiple from 'src/hooks/useFetchMultiple';
import ChooseSupplier from './ChooseSupplier';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
import ItemList from './ItemLinkList';
import ProReqMoreAction from './ProcessTableActions/ProReqMoreAction';
import SupplierActions from './SupplierActions';
const ProcessReqTableRow = ({ item, handleSelectOneRow, selectedRows }) => {
  const { product, qty, uom, PO: purchaseOrders, RFQ: reqForQuotations } = item;
  const { allItemSuppliers } = useContext(ProcessReqContext);
  const [openModal, setOpenModal] = useState(false);
  const { data: suppliers } = useFetchMultiple({
    endPoint: 'supplier_quote',
    populate: 'supplier uom',
    query: { product: item.product.id }
  });
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  // Find selected suppliers for the current item
  const selectedSuppliers = useMemo(() => {
    return allItemSuppliers
      ?.filter((data) => data.itemID === item.id) // Use itemID for filtering
      .map((data) => ({
        ...data.supplier,
        qty: data.qty
      }));
  }, [allItemSuppliers, item]);

  // Filter out suppliers that are already in the purchaseOrders
  const filteredSuppliers = useMemo(() => {
    return suppliers?.filter(
      (supplier) =>
        !purchaseOrders.some((po) => po.supplier === supplier.supplier.id)
    );
  }, [suppliers, purchaseOrders]);

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            size="small"
            checked={selectedRows.some(
              (selectedRow) => selectedRow.id === item.id
            )}
            onChange={(event) => handleSelectOneRow(event, item)}
          />
        </TableCell>
        <TableCell>{product?.name}</TableCell>
        <TableCell>{`${qty || ''} ${uom?.name || ''}`}</TableCell>
        <TableCell>
          <SupplierActions
            selectedSuppliers={selectedSuppliers}
            filteredSuppliers={filteredSuppliers}
            handleModal={handleModal}
          />
        </TableCell>
        <TableCell>
          <ItemList
            items={purchaseOrders}
            routePrefix="purchases"
            itemLabelKey="number"
          />
        </TableCell>
        <TableCell>
          <ItemList
            items={reqForQuotations}
            routePrefix="request_for_quotations"
            itemLabelKey="number"
          />
        </TableCell>
        <TableCell>
          <ProReqMoreAction item={item} selectedSuppliers={selectedSuppliers} />
        </TableCell>
      </TableRow>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        title={'Choose Supplier'}
        maxWidth={'sm'}
      >
        <ChooseSupplier
          suppliers={filteredSuppliers}
          selectedSuppliers={selectedSuppliers}
          item={item}
        />
      </CommonModal>
    </>
  );
};

export default ProcessReqTableRow;
