import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LabelSuccess,
  LabelWarning,
  TableHeadWrapper,
  TableRowDivider,
  TableWrapper
} from 'src/components/Styled';
import RequestMaterial from './RequestMaterial';

const RawMaterials = ({ currentItem }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isRequestMaterialOpen, setIsRequestMaterialOpen] = useState(false);

  const handleRequestMaterialModal = () => {
    setIsRequestMaterialOpen((prev) => !prev);
  };

  const firstTableHeaders = [
    'Product',
    'Product Type',
    'Req Quantity',
    'Status'
  ];

  return (
    <>
      <Card>
        <Box
          px={3}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              gutterBottom
              sx={{
                fontSize: `${theme.typography.pxToRem(16)}`
              }}
              variant="h4"
            >
              {t('Raw Materials')}
            </Typography>
          </Box>
          <Button
            size="small"
            variant="outlined"
            disabled={!!currentItem?.material_request}
            onClick={handleRequestMaterialModal}
          >
            {currentItem?.material_request
              ? currentItem?.material_request?.status
              : 'Request All Materials'}
          </Button>
        </Box>
        <Divider />
        <Box px={3} pb={3}>
          <TableContainer>
            <TableWrapper>
              <TableHeadWrapper>
                <TableRow>
                  {firstTableHeaders.map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHeadWrapper>
              <TableBody>
                {currentItem?.materials?.map((item, index) => (
                  <>
                    <TableRow hover>
                      <TableCell>
                        <Box>
                          <Link href="#" variant="h5" noWrap>
                            {item?.product?.name}
                          </Link>
                          <Typography variant="subtitle1" fontSize={12} noWrap>
                            {item?.product?.product_code}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography variant="h5" noWrap>
                            {item?.product?.product_type}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography variant="h5" noWrap>
                            {currentItem?.quantity * item?.quantity || '-'}{' '}
                            {item?.uom?.name || ''}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography variant="h5" noWrap>
                            {item?.stock?.in_hand -
                            item?.stock?.outward_reserve ? (
                              <LabelSuccess>
                                {item?.stock?.in_hand -
                                  item?.stock?.outward_reserve}
                                {t(' Available')}
                              </LabelSuccess>
                            ) : (
                              <LabelWarning>{t('Not Available')}</LabelWarning>
                            )}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRowDivider />
                  </>
                ))}
              </TableBody>
            </TableWrapper>
          </TableContainer>
        </Box>
      </Card>
      {isRequestMaterialOpen && (
        <RequestMaterial
          open={isRequestMaterialOpen}
          handleModal={handleRequestMaterialModal}
          currentItem={currentItem}
        />
      )}
    </>
  );
};

export default RawMaterials;
