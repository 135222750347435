import ProcessRequest from 'src/content/ProcessRequest';
import GrnForm from 'src/components/DynamicEntityForm/GrnForm';
import WorkflowForm from 'src/components/DynamicEntityForm/WorkflowForm';
import PerformTest from 'src/content/AnalysisPages/PerfomTest';
import ViewTestReport from 'src/content/AnalysisPages/ViewTestReport';
import InitialStock from 'src/content/WarehousePages/InitialStock';
import StockAudit from 'src/content/WarehousePages/StockAudit';
import WorkOrderForm from 'src/components/DynamicEntityForm/WorkOrderForm';
import InitialStockView from 'src/content/StockAuditPages/InitialStockView';
import DeviationReportView from 'src/content/StockAuditPages/DeviationReportView';
import StockAuditEdit from 'src/content/StockAuditPages/StockAuditEdit';
import PerformAudit from 'src/content/StockAuditPages/PerformAudit';
import ReviewStock from 'src/content/StockAuditPages/ReviewStock';
import AddStock from 'src/content/StockAuditPages/AddStock';
import InitialStockEdit from 'src/content/StockAuditPages/InitialStockEdit';

export const customRoutesConfig = [
  {
    path: '/qc/view/:id',
    endPoint: 'qc',
    type: 'view',
    component: ViewTestReport
  },
  {
    path: '/qc/perform-test/:id',
    endPoint: 'qc',
    type: 'perform-test',
    component: PerformTest
  },
  {
    path: '/prs/process-request/:id',
    endPoint: 'prs',
    type: 'process-request',
    component: ProcessRequest
  },
  {
    path: '/work_orders/process/:id',
    endPoint: 'work_orders',
    type: 'process',
    component: WorkOrderForm
  },
  {
    path: '/stock_audit/view/:id',
    endPoint: 'stock_audit',
    type: 'view',
    component: InitialStockView
  },
  {
    path: '/stock_audit/deviation-report/:id',
    endPoint: 'stock_audit',
    type: 'deviation-report',
    component: DeviationReportView
  },
  {
    path: '/stock_audit/stock-audit-edit/:id',
    endPoint: 'stock_audit',
    type: 'stock-audit-edit',
    component: StockAuditEdit
  },
  {
    path: '/stock_audit/initial-stock-edit/:id',
    endPoint: 'stock_audit',
    type: 'initial-stock-edit',
    component: InitialStockEdit
    // component: StockAuditEdit
  },
  {
    path: '/stock_audit/perform-audit/:id',
    endPoint: 'stock_audit',
    type: 'perform-audit',
    component: PerformAudit
  },
  {
    path: '/stock_audit/review-stock/:id',
    endPoint: 'stock_audit',
    type: 'review-stock',
    component: ReviewStock
  },
  {
    path: '/stock_audit/add-stock/:id',
    endPoint: 'stock_audit',
    type: 'add-stock',
    component: AddStock
  },
  {
    path: '/stock_audit/initial-stock/:id',
    endPoint: 'warehouse',
    type: 'initial-stock',
    component: InitialStock
  },
  {
    path: '/stock_audit/stock-audit/:id',
    endPoint: 'warehouse',
    type: 'stock-audit',
    component: StockAudit
  },
  { path: '/grn/edit/:id', endPoint: 'grn', type: 'edit', component: GrnForm },
  { path: '/grn/create', endPoint: 'grn', type: 'create', component: GrnForm },
  {
    path: '/workflows/edit/:id',
    endPoint: 'workflows',
    type: 'edit',
    component: WorkflowForm
  },
  {
    path: '/workflows/create',
    endPoint: 'workflows',
    type: 'create',
    component: WorkflowForm
  }
];
