import { Button, CircularProgress, Grid, Link, Stack } from '@mui/material';
import { Formik } from 'formik';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import useDynamicFormLogic from 'src/hooks/useDynamicFormLogic';
import { useSelector } from 'react-redux';
import { selectStatus, selectTitle } from 'src/slices/dynamicSlice';
import { Link as RouterLink, useLocation } from 'react-router-dom';
const ModalForm = ({ handleModal }) => {
  const status = useSelector(selectStatus);
  const title = useSelector(selectTitle);
  const location = useLocation();
  const {
    fieldNames,
    initialValues,
    validationSchema,
    schema,
    entitySetting,
    handleFormSubmit
  } = useDynamicFormLogic({
    isModal: true,
    handleModal
  });
  if (!initialValues || !validationSchema) return <CircularProgress />;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, handleSubmit, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              {fieldNames.map((fieldName, index) => (
                <Grid item xs={12} key={`${index}_${fieldName}_checkfield`}>
                  <GetFieldBasedOnSchema
                    name={fieldName}
                    fieldName={fieldName}
                    entitySetting={entitySetting}
                    schema={schema}
                    value={values[fieldName]}
                    error={errors[fieldName]}
                    touched={touched[fieldName]}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Button
                    type="submit"
                    startIcon={
                      status.createData ? (
                        <CircularProgress size="1rem" />
                      ) : null
                    }
                    disabled={status.createData}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Link
                    variant="h5"
                    component={RouterLink}
                    to={`/${location.pathname.split('/')[1]}/create`}
                  >
                    Add all {title} details
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ModalForm;
