import { useEffect, useState } from 'react';
import { requestApi } from 'src/mocks/request';
import useRefMounted from './useRefMounted';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectData, selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const useDyFetchDataById = () => {
  const { id: dataId } = useParams();
  const allData = useSelector(selectData);
  const { populateString, entity } = useSelector(selectDynamicPageConfig);
  const [currentItem, setCurrentItem] = useState(null);
  const [isFetching, setFetching] = useState(() => !!dataId);
  const isMounted = useRefMounted();

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      try {
        const payload = {
          endPoint: entity,
          populate: populateString,
          query: { _id: dataId }
        };

        const response = await requestApi.getData(payload);
        if (response?.data?.data?.length) {
          if (isMounted.current) setCurrentItem(response.data.data[0]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        if (isMounted.current) setFetching(false);
      }
    };

    if (dataId) {
      const foundData = allData.find((data) => data.id === dataId);

      if (foundData) {
        setCurrentItem(foundData);
        setFetching(false);
      } else {
        fetchData();
      }
    }
  }, [dataId, allData, entity, populateString, isMounted]);
  return {
    currentItem,
    isFetching
  };
};

export default useDyFetchDataById;
