import React from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { VALUE_TYPES } from 'src/constant/constant';
import EntityDropdown from '../EntityDropdown';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import CustomSelectFIeld from 'src/components/CustomFields/CustomSelectField';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const ActionPayload = ({ actionIndex, payload, entity = null }) => {
  const { setFieldValue, values, handleChange } = useFormikContext();
  const { schema, entitySetting } = useEntityPageConfig(entity);

  const handlePreviousActionChange = (event, payloadIndex) => {
    const actionID = event.target.value;
    const selectedAction = values.actions.find(
      (action) => action.action_id === actionID
    );
    const selectedEntity = selectedAction?.entity || '';
    setFieldValue(
      `actions.${actionIndex}.payload.${payloadIndex}.field_value.previous_action`,
      actionID
    );
    setFieldValue(
      `actions.${actionIndex}.payload.${payloadIndex}.field_value.previous_action_entity`,
      selectedEntity
    );
  };
  const handleValuTypeChange = (event, payloadIndex) => {
    const { value } = event.target;
    const defaultFieldValue = getDefaultFieldValue(value);
    const updatedPayload = {
      ...payload[payloadIndex],
      value_type: value,
      ...defaultFieldValue
    };
    setFieldValue(
      `actions.${actionIndex}.payload.${payloadIndex}`,
      updatedPayload
    );
  };
  const PreviousActionArray = values.actions
    .slice(0, actionIndex)
    .map((act) => ({ value: act.action_id, label: act.name }));
  const getDefaultFieldValue = (valueType) => {
    switch (valueType) {
      case 'Constants':
        return { field_value: '' };
      case 'Custom input':
        return { field_value: '' };
      case 'values from trigger document':
        return { trigger_field_name: '' };
      case 'values from previous actions':
        return {
          previous_action: '',
          previous_action_entity: '',
          previous_action_field_name: ''
        };
      default:
        return {};
    }
  };
  const handlFieldNameChange = (event, payloadIndex) => {
    const value = event.target.value;
    console.log(schema, 'actionpayloadschema');
    const payload = {
      field_name: value,
      value_type: '',
      schema
    };
    setFieldValue(`actions.${actionIndex}.payload.${payloadIndex}`, payload);
  };

  const fieldNames = schema ? Object.keys(schema) : [];

  return (
    <FieldArray name={`actions.${actionIndex}.payload`}>
      {({ push, remove }) => (
        <>
          {fieldNames.length > 0 && (
            <>
              {payload?.map((payloadItem, payloadIndex) => (
                <Grid item xs={12} key={payloadIndex}>
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={3}>
                      <CustomSelectFIeld
                        name={`actions.${actionIndex}.payload.${payloadIndex}.field_name`}
                        label="Field Name"
                        value={payloadItem?.field_name || ''}
                        options={fieldNames}
                        onChange={(e) => handlFieldNameChange(e, payloadIndex)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomSelectFIeld
                        name={`actions.${actionIndex}.payload.${payloadIndex}.value_type`}
                        label="Value Type"
                        value={payloadItem.value_type || ''}
                        options={VALUE_TYPES}
                        onChange={(event) =>
                          handleValuTypeChange(event, payloadIndex)
                        }
                      />
                    </Grid>
                    {payloadItem.value_type === 'Constants' && (
                      <Grid item xs={3}>
                        <CustomTextField
                          name={`actions.${actionIndex}.payload.${payloadIndex}.field_value`}
                          label={'Field Value'}
                          fullWidth={true}
                          value={payloadItem?.field_value || ''}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                    {payloadItem.value_type === 'Custom input' && (
                      <Grid item xs={5}>
                        <GetFieldBasedOnSchema
                          name={`actions.${actionIndex}.payload.${payloadIndex}.field_value`}
                          label="Field Value"
                          entitySetting={entitySetting}
                          fieldName={payloadItem?.field_name}
                          entity={entity}
                          schema={schema}
                          value={payloadItem?.field_value}
                        />
                      </Grid>
                    )}
                    {payloadItem.value_type ===
                      'values from trigger document' && (
                      <Grid item xs={3}>
                        <EntityDropdown
                          entity={values?.entity || ''}
                          name={`actions.${actionIndex}.payload.${payloadIndex}.trigger_field_name`}
                          label="Trigger Field Name"
                        />
                      </Grid>
                    )}
                    {payloadItem.value_type ===
                      'values from previous actions' && (
                      <>
                        <Grid item xs={3}>
                          <CustomSelectFieldWithLabel
                            name={`actions.${actionIndex}.payload.${payloadIndex}.previous_action`}
                            label="Previous Action"
                            value={
                              payloadItem?.field_value?.previous_action || ''
                            }
                            options={PreviousActionArray}
                            onChange={(event) =>
                              handlePreviousActionChange(event, payloadIndex)
                            }
                          />
                        </Grid>
                        {payloadItem?.field_value?.previous_action_entity && (
                          <Grid item xs={2}>
                            {/* todo make seperate component */}
                            <EntityDropdown
                              entity={
                                payloadItem?.field_value?.previous_action_entity
                              }
                              name={`actions.${actionIndex}.payload.${payloadIndex}.previous_action_field_name`}
                              label="Previous Action Field Name"
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid item xs={1}>
                      <IconButton onClick={() => remove(payloadIndex)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          <Grid item xs={12}>
            <Tooltip title={'Add Payload'}>
              <IconButton
                onClick={() => push({ field_name: '', value_type: '' })}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default ActionPayload;
