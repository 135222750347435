import { Box, Stack, Tab, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { TabsWrapper } from 'src/components/Styled';
import ManufacturingDetailsCard from './ManufacturingDetailsCard';
import RawMaterials from './RawMaterials';
import Operations from './Operations';
import useDynamicFormLogic from 'src/hooks/useDynamicFormLogic';
import OperationsNew from './OperationsNew';

const FormBody = ({ currentItem }) => {
  const { t } = useTranslation();
  const { initialValues, validationSchema, handleFormSubmit } =
    useDynamicFormLogic({
      currentItem,
      isCustomForm: true
    });

  const [currentTab, setCurrentTab] = useState(1);

  const tabs = [
    { value: 1, label: t('Required Materials') },
    { value: 2, label: t('Operations / Job Cards') }
  ];

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  if (!initialValues || !validationSchema) return <CircularProgress />;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Stack gap={1}>
                <ManufacturingDetailsCard />

                <Box sx={{ my: 2 }}>
                  <TabsWrapper
                    onChange={handleTabsChange}
                    value={currentTab}
                    textColor="primary"
                    indicatorColor="primary"
                  >
                    {tabs?.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </TabsWrapper>
                </Box>

                {currentTab === 1 && <RawMaterials currentItem={currentItem} />}
                {/* {currentTab === 2 && <Operations currentItem={currentItem} />} */}
                {currentTab === 2 && (
                  <OperationsNew currentItem={currentItem} />
                )}
              </Stack>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default FormBody;
