import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import InspectionCard from './InspectionCard';
import TestQualityCard from './TestQualityCard';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import PerformTestLoader from '../PerformTestLoader';
import { Form, Formik } from 'formik';
import useRefMounted from 'src/hooks/useRefMounted';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import { requestApi } from 'src/mocks/request';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal.js';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';

const entity = 'analysis';

const PerformTest = () => {
  const navigate = useNavigate();
  const { schema } = useEntityPageConfig(entity);
  const [modalOpen, setModalOpen] = useState(false);
  const { currentItem, isFetching } = useDyFetchDataById();
  const [submitting, setSubmitting] = useState(false);
  const isMounted = useRefMounted();

  const handleConfirmSubmit = async (formValues) => {
    setSubmitting(true);
    try {
      const { createData } = sanitizeFormData(
        formValues,
        schema,
        Object.keys(formValues)
      );
      const payload = {
        id: currentItem?.id,
        endPoint: entity,
        data: createData
      };
      const response = await requestApi.updateData(payload);
      if (response.data) {
        navigate(-1);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      if (isMounted.current) setSubmitting(false);
      setModalOpen(false);
    }
  };

  if (isFetching) return <PerformTestLoader />;

  return (
    <>
      <Helmet>
        <title>Perform Quality Test</title>
      </Helmet>
      <Formik
        initialValues={{
          summary: currentItem?.summary || '',
          status: 'completed',
          qc_pass: currentItem?.qc_pass || '',
          tests: currentItem?.tests || []
        }}
        onSubmit={() => setModalOpen(true)}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <PageTitleWrapper>
              <PageHeader currentItem={currentItem} />
            </PageTitleWrapper>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InspectionCard currentItem={currentItem} />
              </Grid>
              <Grid item xs={12}>
                <TestQualityCard currentItem={currentItem} />
              </Grid>
            </Grid>
            <CommonModal
              maxWidth="xs"
              open={modalOpen}
              handleModal={() => setModalOpen(false)}
              title={'Analytical Summary'}
            >
              <QuillEditorField
                placeholder={'Any notes about inspection'}
                onChange={(value) => setFieldValue(`summary`, value)}
                value={values?.summary || ''}
              />
              <Stack mt={1} direction={'row'} alignItems={'center'} spacing={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  disabled={submitting}
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  disabled={submitting}
                  startIcon={submitting && <CircularProgress size={'1rem'} />}
                  onClick={() => handleConfirmSubmit(values)}
                >
                  Confirm
                </Button>
              </Stack>
            </CommonModal>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PerformTest;
