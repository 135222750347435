import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

const CustomSelectFieldWithLabel = ({
  label,
  value,
  options,
  error,
  touched,
  placeholder = '',
  inputProps = {},
  readOnly = false,
  disabled = false,
  onChange,
  onBlur = () => {},
  name = '',
  size = 'medium',
  fullWidth = true,
  required = false,
  sx = {}
}) => {
  // Check if touched and error are present
  const hasError = touched && error;
  const modifiedInputProps = { ...inputProps, readOnly };
  return (
    <FormControl
      size={size}
      fullWidth={fullWidth}
      error={hasError}
      required={required}
    >
      <InputLabel id={`${name}_Label`}>{label}</InputLabel>
      <Select
        labelId={`${name}_Label`}
        value={value !== undefined ? value : ''}
        name={name}
        label={label}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        MenuProps={MenuProps}
        inputProps={modifiedInputProps}
        sx={sx}
      >
        {options.map((option, index) => (
          <MenuItem value={option.value} key={`${name}_${index}`}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {/* Display error message if both touched and error are present */}
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelectFieldWithLabel;
