import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { getCookie } from 'src/utils/cookie';
import './App.css';
import AppInit from './components/AppInit';
import useDomainInfo from './hooks/useDomainDetector';
import { routes } from './router';
import {
  getLoggedUserDataAsync,
  selectIsInitialized,
  selectToken,
  selectWorkspaceName,
  setIsInitialized
} from './slices/authSlice';
import { getWorkspaceAsync } from './slices/publicSlice';
import ThemeProvider from './theme/ThemeProvider';
import useScript from './hooks/useScript';

function App() {
  const dispatch = useDispatch();
  const content = useRoutes(routes);
  const isInitialized = useSelector(selectIsInitialized);
  const isTokenAvailable = useSelector(selectToken);
  const { subdomain } = useDomainInfo();
  const workspaceName = useSelector(selectWorkspaceName);

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places&callback=initMap`;
  useScript(mapUrl);

  useEffect(() => {
    function initialize() {
      if (process.env.REACT_APP_PLATFORM === 'workspace') {
        if (workspaceName || subdomain) {
          // get logo or workspace details (NoAuth Request)
          dispatch(getWorkspaceAsync(workspaceName || subdomain));
        }
      }

      if (isTokenAvailable) {
        console.log('calling get user with token', isTokenAvailable);
        // make sure we have localstorage token as well else transfer from cookies

        dispatch(getLoggedUserDataAsync(isTokenAvailable)); // get user data
      } else {
        dispatch(setIsInitialized());
      }
    }
    initialize();
  }, []);

  useEffect(() => {
    if (workspaceName === subdomain) {
      const tokenFromCookie = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
      const workspaceFromCookie = getCookie('workspace');
      if (tokenFromCookie) {
        console.log('copy token from cookie', tokenFromCookie);
        localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          tokenFromCookie
        );
      }
      if (workspaceFromCookie) {
        console.log('copy workspace from cookie', tokenFromCookie);
        localStorage.setItem('workspace', workspaceFromCookie);
      }
    }
  }, [workspaceName, subdomain]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {isInitialized ? content : <AppInit />}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
