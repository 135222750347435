import { useEffect, useState } from 'react';
import { requestApi } from 'src/mocks/request';
import useRefMounted from './useRefMounted';

const useGetItemById = (itemId, payload, allItems = []) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [getData, setGetData] = useState(null);
  const [isFetching, setFetching] = useState(() => (itemId ? true : false));
  const isMounted = useRefMounted();

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);

      try {
        const response = await requestApi.getData(payload);

        if (Boolean(response?.data?.data?.length)) {
          setCurrentItem(response?.data?.data[0]);
          setGetData(response?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        if (isMounted.current) setFetching(false);
      }
    };

    if (itemId) {
      const foundItem = allItems.find((item) => item.id === itemId);
      if (foundItem) {
        setFetching(false);
        setCurrentItem(foundItem);
        const formattedItem = { data: [foundItem] };
        setGetData(formattedItem);
      } else {
        fetchData();
      }
    }
  }, []);

  return {
    currentItem,
    getData,
    isFetching
  };
};

export default useGetItemById;
