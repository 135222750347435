import { REMOVE_FIELDS } from 'src/constant/constant';

// filter imported data to preview
export const getFilteredData = (formattedData, schema, entitySetting) => {
  if (!schema || !formattedData) {
    console.warn('Schema/Data is not available');
    return { headers: [], filteredData: null };
  }

  console.log('[getFilteredData]-[entitySetting]::', entitySetting);
  console.log('[getFilteredData]-[schema]::', schema);

  const createFields = entitySetting?.create_fields || [];
  const headers = new Set();
  const includeHeaders =
    Array.isArray(createFields) && createFields.length > 0
      ? createFields
      : null;

  const filteredData = formattedData
    ?.map((item) => {
      const filteredItem = {};
      // Assumption: table headers are same as schema
      for (const key of Object.keys(item)) {
        const field = schema[key];

        if (field?.instance === 'Array' || field?.instance === 'Object') {
          // Exclude this key if its instance is 'Array' or 'Object'
          return;
        }

        if (!includeHeaders) {
          console.warn('Missing setting data');
          if (!REMOVE_FIELDS?.includes(key)) {
            headers.add(key);
            filteredItem[key] = item[key];
          }
        } else {
          // Only include specified fields from create_fields settings
          if (includeHeaders?.includes(key)) {
            headers.add(key);
            filteredItem[key] = item[key];
          }
        }
        // console.log('filteredItem', filteredItem);
      }

      // console.log('filteredItem', filteredItem);
      return Object.keys(filteredItem).length > 0 ? filteredItem : null;
    })
    .filter(Boolean); // Filter out empty objects

  // console.log('filteredData', filteredData);

  return { headers: Array.from(headers), filteredData };
};
