import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useFormikContext } from 'formik';
import useSearch from 'src/hooks/useSearch';
import CustomCardWithSearch from 'src/components/CustomCardWithSearch';
import AddSTableRows from './AddSTableRows';

const AddStockTable = () => {
  const { values } = useFormikContext();
  const { products } = values;
  const { searchTerm, handleSearchChange, filteredItems } = useSearch(
    products,
    'product.name'
  );
  return (
    <>
      <CustomCardWithSearch
        title={'Stock Details'}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      >
        <Table
          sx={{
            minWidth: 1000,
            tableLayout: 'fixed'
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Current</TableCell>
              <TableCell>Actual</TableCell>
              <TableCell width={'10%'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <AddSTableRows products={filteredItems} />
          </TableBody>
        </Table>
      </CustomCardWithSearch>
    </>
  );
};

export default AddStockTable;
