import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

let initialState = {
  data: [],
  paginator: null,
  columnFilterFieldname: [],
  dynamicPageConfig: null,
  title: null,
  currentTab: null,
  page: 0,
  query: {},
  defaultQuery: {},
  searchQuery: {},
  filterQuery: [],
  craetedEnitiyID: null,
  status: {
    getData: false,
    createData: false,
    deleteData: false,
    deleteMany: false,
    updateData: false,
    updateBulk: false,
    getSetting: false
  },
  message: { success: null, error: null, for: null },
  apiSuccess: false
};

export const getDataAsync = createAsyncThunk(
  'dynamicSlice/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createDataAsync = createAsyncThunk(
  'dynamicSlice/createData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.createData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDataAsync = createAsyncThunk(
  'dynamicSlice/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status === 'SUCCESS') {
        return { localUpdate: data.localUpdate || {}, repData: response.data };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBulkDataAsync = createAsyncThunk(
  'dynamicSlice/createBulkDataAsync',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.createBulkData(data);
      if (response?.status === 'SUCCESS') {
        const repData = response?.data;
        return { data, repData };
        // return { response: response?.data, title: data.title };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      console.log(error, 'isError');
      return error;
    }
  }
);

export const updateBulkDataAsync = createAsyncThunk(
  'dynamicSlice/updateBulkDataAsync',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateBulk(data);
      console.log(response, 'wait');
      if (response?.status === 'SUCCESS') {
        return data.localUpdate;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      console.log(error, 'isError');
      return error;
    }
  }
);

export const deleteDataAsync = createAsyncThunk(
  'dynamicSlice/deleteData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteData(data);
      if (response?.status === 'SUCCESS') {
        return data.id;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteManyDataAsync = createAsyncThunk(
  'dynamicSlice/deleteMany',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteMany(data);
      if (response?.status === 'SUCCESS') {
        return data.ids.ids;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadFileAsync = createAsyncThunk(
  'dynamicSlice/uploadFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.uploadFile(data);
      console.log(response);
      if (response?.status === 'SUCCESS') {
        return response.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'dynamicSlice',
  initialState,
  reducers: {
    cleanMessageLog: (state) => {
      state.message.success = null;
      state.message.error = null;
      state.message.for = null;
      state.message.isOptionCreate = false;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setTableData: (state) => {
      state.data = [];
    },
    setColumnFilterFieldname: (state, { payload }) => {
      state.columnFilterFieldname = payload;
    },
    setQuery: (state, action) => {
      // Helper function to check if a query object is not empty
      const isNotEmpty = (query) => query && Object.keys(query).length > 0;

      // Helper function to construct the query with non-null conditions
      const constructQuery = (conditions) => {
        // Filter out null conditions
        const filteredConditions = conditions.filter((query) => query !== null);
        // Return an object with $and if there are conditions, otherwise an empty object
        return filteredConditions.length > 0
          ? { $and: filteredConditions }
          : {};
      };

      // Handle different types of filter actions
      switch (action.payload.filterType) {
        case 'filterQuery':
          // Update filterQuery state
          state.filterQuery = [...action.payload.allFilters];
          // Construct the query with the updated filterQuery, searchQuery, and objectIdQuery
          state.query = constructQuery([
            ...action.payload.allFilters,
            isNotEmpty(state.searchQuery) ? state.searchQuery : null,
            isNotEmpty(state.defaultQuery) ? state.defaultQuery : null
          ]);
          state.page = 0;
          break;

        case 'searchQuery':
          state.searchQuery = action.payload.queryData;
          state.query = constructQuery([
            action.payload.queryData,
            ...state.filterQuery,
            isNotEmpty(state.defaultQuery) ? state.defaultQuery : null
          ]);
          // Reset pagination
          state.page = 0;
          break;

        default:
          // If the action type does not match, do nothing
          break;
      }
    },
    setDefaultQuery: (state, { payload }) => {
      state.defaultQuery = payload;
    },

    refreshDynamicSliceState: () => initialState,
    setDynamicPageConfig: (state, action) => {
      state.dynamicPageConfig = action.payload;
    },
    setStatus: (state, { payload }) => {
      state.status.getData = payload;
    },
    setData: (state, { payload }) => {
      state.data = state.data.map((item) =>
        item.id === payload.id ? payload : item
      );
    },
    setApiSuccess: (state) => {
      state.apiSuccess = false;
    },
    setCurrentTab: (state, { payload }) => {
      state.currentTab = payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(createDataAsync.pending, (state) => {
        state.status.createData = true;
      })
      .addCase(createDataAsync.fulfilled, (state, { payload }) => {
        state.status.createData = false;
        state.apiSuccess = true;
      })
      .addCase(createDataAsync.rejected, (state) => {
        state.status.createData = false;
      })
      .addCase(getDataAsync.pending, (state) => {
        state.status.getData = true;
      })

      .addCase(getDataAsync.fulfilled, (state, { payload }) => {
        state.status.getData = false;
        state.data = payload?.data || [];
        state.paginator = payload?.paginator || null;
      })
      .addCase(getDataAsync.rejected, (state) => {
        state.data = [];
        state.status.getData = false;
      })
      .addCase(updateDataAsync.pending, (state) => {
        state.status.updateData = true;
      })
      .addCase(updateDataAsync.fulfilled, (state, { payload }) => {
        state.status.updateData = false;
        if (Object.keys(payload.localUpdate).length) {
          let updateData = { ...payload.repData, ...payload.localUpdate };
          state.data = state.data.map((item) =>
            item.id === updateData.id ? updateData : item
          );
        }
        state.apiSuccess = true;
      })
      .addCase(updateDataAsync.rejected, (state, action) => {
        state.status.updateData = false;
      })
      .addCase(deleteDataAsync.pending, (state) => {
        state.status.deleteData = true;
      })
      .addCase(deleteDataAsync.fulfilled, (state) => {
        state.status.deleteData = false;
        state.apiSuccess = true;
      })
      .addCase(deleteDataAsync.rejected, (state) => {
        state.status.deleteData = false;
      })
      .addCase(deleteManyDataAsync.pending, (state) => {
        state.status.deleteMany = true;
      })
      .addCase(deleteManyDataAsync.fulfilled, (state) => {
        state.status.deleteMany = false;
        state.apiSuccess = true;
      })
      .addCase(deleteManyDataAsync.rejected, (state) => {
        state.status.deleteMany = false;
      })
      .addCase(updateBulkDataAsync.pending, (state) => {
        state.status.updateBulk = true;
      })
      .addCase(updateBulkDataAsync.fulfilled, (state, { payload }) => {
        state.status.updateBulk = false;
        state.data = state.data.map((dataItem) =>
          payload.ids.includes(dataItem.id)
            ? { ...dataItem, ...payload.data }
            : dataItem
        );
      })
      .addCase(updateBulkDataAsync.rejected, (state) => {
        state.status.updateBulk = false;
      });
  }
});

export const {
  cleanMessageLog,
  refreshDynamicSliceState,
  setQuery,
  setDynamicPageConfig,
  setTitle,
  setColumnFilterFieldname,
  setTableData,
  setPage,
  setStatus,
  setDefaultQuery,
  setData,
  setApiSuccess,
  setCurrentTab
} = slice.actions;

// selectors
export const selectData = (state) => state.dynamicState.data;
export const selectQuery = (state) => state.dynamicState.query;
export const selectDefaultQuery = (state) => state.dynamicState.defaultQuery;
export const selectDynamicPageConfig = (state) =>
  state.dynamicState.dynamicPageConfig;
export const selectApiSuccess = (state) => state.dynamicState.apiSuccess;
export const selectMessage = (state) => state.dynamicState.message;
export const selectStatus = (state) => state.dynamicState.status;
export const selectPaginator = (state) => state.dynamicState.paginator;
export const selectTitle = (state) => state.dynamicState.title;
export const selectPage = (state) => state.dynamicState.page;
export const selectCurrentTab = (state) => state.dynamicState.currentTab;
export const seletedcraetedEnitiyID = (state) =>
  state.dynamicState.craetedEnitiyID;
export const selectColumnFilterFieldname = (state) =>
  state.dynamicState.columnFilterFieldname;

export default slice.reducer;
