import { Avatar } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import PropTypes from 'prop-types';
import React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

const PhoneTextField = React.memo(
  ({
    value,
    setFieldValue,
    onBlur,
    label,
    name,
    placeholder,
    required,
    size,
    readOnly,
    error,
    isArray,
    fieldName,
    fieldValue
  }) => {
    const handlePhoneChange = (newPhone, info) => {
      if (isArray) {
        const updatedValues = [...fieldValue];
        updatedValues[name] = newPhone;
        setFieldValue(fieldName, updatedValues);
        return;
      }
      setFieldValue(name, newPhone);
    };

    return (
      <MuiTelInput
        disableFormatting
        focusOnSelectCountry
        forceCallingCode
        preferredCountries={['IN']}
        defaultCountry="IN"
        fullWidth
        label={label}
        name={name}
        placeholder={placeholder}
        required={required}
        size={size}
        readOnly={readOnly}
        error={error}
        onBlur={onBlur}
        value={value}
        onChange={handlePhoneChange}
        getFlagElement={(isoCode, { imgProps }) => (
          <Avatar
            {...imgProps}
            variant="square"
            sx={{ width: 24, height: 20 }}
          />
        )}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: '1rem' }
        }}
        InputProps={{ style: { fontSize: '1rem', paddingLeft: 0 } }}
        MenuProps={MenuProps}
      />
    );
  }
);

PhoneTextField.displayName = 'PhoneTextField';

PhoneTextField.propTypes = {
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func
};

export default PhoneTextField;
