import { AddRounded, RemoveRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import TestJobCard from './TestJobCard';

const data = {
  operations: [
    {
      operation: {
        name: 'Design and Specification',
        id: '66cd920a6629c76b1341976c'
      },
      job_cards: [
        {
          id: '66dabeb2e573f57ea39fc943',
          number: 'JB-001',
          location: 'Floor 1',
          start_time: '2024-01-10T09:15:00.000Z',
          units: 100,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Loader'
          },
          scheduled_start: '2024-01-10T09:15:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc943',
          number: 'JB-003',
          location: 'Floor 2',
          start_time: '2024-02-15T11:45:00.000Z',
          units: 80,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 1'
          },
          scheduled_start: '2024-02-15T11:45:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc944',
          number: 'JB-002',
          location: 'Floor 2',
          start_time: '2024-04-05T14:00:00.000Z',
          units: 150,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 2'
          },
          scheduled_start: '2024-04-05T14:00:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc945',
          number: 'JB-004',
          location: 'Floor 3',
          start_time: '2024-06-20T10:30:00.000Z',
          units: 120,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 3'
          },
          scheduled_start: '2024-06-20T10:30:00.000Z'
        }
      ],
      id: '66dabeb2e573f57ea39fc943'
    },
    {
      operation: {
        name: 'Cutting',
        id: '66cd94b56629c76b13419a2a'
      },
      job_cards: [
        {
          id: '66dabeb2e573f57ea39fc946',
          number: 'JB-005',
          location: 'Floor 8',
          start_time: '2024-01-15T12:30:00.000Z',
          units: 200,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 4'
          },
          scheduled_start: '2024-01-15T12:30:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc947',
          number: 'JB-006',
          location: 'Floor 8',
          start_time: '2024-03-25T14:00:00.000Z',
          units: 180,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 5'
          },
          scheduled_start: '2024-03-25T14:00:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc948',
          number: 'JB-007',
          location: 'Floor 7',
          start_time: '2024-05-10T16:00:00.000Z',
          units: 150,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 6'
          },
          scheduled_start: '2024-05-10T16:00:00.000Z'
        }
      ],
      id: '66dabeb2e573f57ea39fc944'
    },
    {
      operation: {
        name: 'Assembly',
        id: '66cd94f36629c76b13419d35'
      },
      job_cards: [
        {
          id: '66dabeb2e573f57ea39fc949',
          number: 'JB-008',
          location: 'Floor 4',
          start_time: '2024-02-05T11:20:00.000Z',
          units: 50,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 7'
          },
          scheduled_start: '2024-02-05T09:45:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc949',
          number: 'JB-101',
          location: 'Floor 5',
          start_time: '2024-02-05T10:25:00.000Z',
          units: 50,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 7'
          },
          scheduled_start: '2024-02-05T10:25:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc949',
          number: 'JB-102',
          location: 'Floor 6',
          start_time: '2024-02-05T05:10:00.000Z',
          units: 50,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 7'
          },
          scheduled_start: '2024-02-05T10:25:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc950',
          number: 'JB-009',
          location: 'Floor 4',
          start_time: '2024-07-15T13:30:00.000Z',
          units: 75,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 8'
          },
          scheduled_start: '2024-07-15T13:30:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc951',
          number: 'JB-010',
          location: 'Floor 4',
          start_time: '2024-09-10T15:00:00.000Z',
          units: 100,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 9'
          },
          scheduled_start: '2024-09-10T15:00:00.000Z'
        }
      ],
      id: '66dabeb2e573f57ea39fc945'
    },
    {
      operation: {
        name: 'Make Chassis',
        id: '68cd94f36689c76b45679d35'
      },
      job_cards: [
        {
          id: '66dabeb2e573f57ea39fc952',
          number: 'JB-011',
          location: 'Floor 2',
          start_time: '2024-03-20T14:55:00.000Z',
          units: 210,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 10'
          },
          scheduled_start: '2024-03-20T14:55:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc953',
          number: 'JB-012',
          location: 'Floor 2',
          start_time: '2024-08-05T16:15:00.000Z',
          units: 180,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 11'
          },
          scheduled_start: '2024-08-05T16:15:00.000Z'
        },
        {
          id: '66dabeb2e573f57ea39fc954',
          number: 'JB-013',
          location: 'Floor 1',
          start_time: '2024-09-01T09:45:00.000Z',
          units: 120,
          equipment: {
            id: '66cd94f36629c76b13419d35',
            name: 'Wheel Station 12'
          },
          scheduled_start: '2024-09-01T09:45:00.000Z'
        }
      ],
      id: '66dabeb2e573f57ea39fc946'
    }
  ]
};

const timelineZoomOptions = [
  { label: 'Hour', value: 0 },
  { label: 'Days', value: 1 },
  { label: 'Weeks', value: 2 },
  { label: 'Months', value: 3 }
];

const getWeeksInMonth = () => {
  const startOfMonth = moment().startOf('month');
  const endOfMonth = moment().endOf('month');

  // Calculate the total number of weeks in the month
  const totalWeeks = endOfMonth.week() - startOfMonth.week() + 1;

  return totalWeeks;
};

const getHeaderFormat = (zoom) => {
  switch (zoom) {
    case 0:
      return 'h A'; // Hour format
    case 1:
      return 'D'; // Day format
    case 2:
      return 'W'; // Week format
    case 3:
      return 'MMMM'; // Month format
    default:
      return '';
  }
};

const getHeaderText = (zoom) => {
  switch (zoom) {
    case 0: // Hours
      return moment().format('dddd, MMMM D, YYYY'); // Day, Month Daynumber, Year
    case 1: // Days
      return moment().format('MMMM YYYY'); // Month, Year
    case 2: // Weeks
      return moment().format('MMMM YYYY'); // Month, Year
    case 3: // Months
      return moment().format('YYYY'); // Year
    default:
      return '';
  }
};

const calculateColSpan = (timelineZoom) => {
  switch (timelineZoom) {
    case 0: // Hour view
      return 24;
    case 1: // Day view
      return moment().daysInMonth(); // Number of days in the current month
    case 2: {
      // Week view
      return getWeeksInMonth();
    }
    case 3: // Month view
      return 12; // Number of months
    default:
      return getColumnHeaders(timelineZoom).length;
  }
};

const getColumnHeaders = (timelineZoom) => {
  switch (timelineZoom) {
    case 0:
      return [...Array(24)].map((_, i) =>
        moment().startOf('day').add(i, 'hours').format('h A')
      );
    case 1: {
      // // todo: days of month can also different like 28, 29, 30, 31
      // return [...Array(31)].map((_, i) => (i + 1).toString());
      const daysInMonth = moment().daysInMonth();
      return [...Array(daysInMonth)].map((_, i) => (i + 1).toString());
    }
    case 2: {
      // // todo: fix this (make it dynamic, one month might have 4 weeks or 4.5 weeks)
      return [...Array(52)].map((_, i) => `Week ${i + 1}`);
      // const weeksInMonth = getWeeksInMonth();
      // return [...Array(Math.ceil(weeksInMonth))].map((_, i) => `Week ${i + 1}`);

      // const currentMonth = moment().month(); // Get current month (0-based index)
      // const currentYear = moment().year(); // Get current year
      // const weeksInMonth = getWeeksInMonth(currentMonth, currentYear);

      // // Get the first ISO week number of the month
      // const startWeek = moment().startOf('month').isoWeek();

      // return [...Array(weeksInMonth)].map((_, i) => `Week ${startWeek + i}`);
    }
    case 3:
      return [...Array(12)].map((_, i) => moment().month(i).format('MMMM'));
    default:
      return [];
  }
};

const getJobCardInPeriod = (job, colIndex, timelineZoom) => {
  const startTime = moment(job.scheduled_start);

  switch (timelineZoom) {
    case 0:
      return startTime.hour() === colIndex; // Hour-level comparison
    case 1:
      return startTime.date() === colIndex + 1; // Day-level comparison
    case 2:
      return startTime.week() === colIndex + 1; // Week-level comparison
    case 3:
      return startTime.month() === colIndex; // Month-level comparison
    default:
      return false;
  }
};

// ================================= JobCards Timeline ==============================

const JobCardsTimeLine = () => {
  const theme = useTheme();

  // !
  const [openPopover, setOpenPopover] = useState(null);
  const [selectedJobCard, setSelectedJobCard] = useState(null);
  console.log('selectedJobCard', selectedJobCard);

  const handlePopoverOpen = (event, job) => {
    event.stopPropagation();
    setSelectedJobCard(job);
    setOpenPopover(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    event.stopPropagation();
    setOpenPopover(null);
  };
  //

  const [timelineZoom, setTimelineZoom] = useState(0);
  const [todayColumnIndex, setTodayColumnIndex] = useState(null);

  const todayIndex = useMemo(() => {
    return getColumnHeaders(timelineZoom).indexOf(
      moment().format(getHeaderFormat(timelineZoom))
    );
  }, [timelineZoom]);

  useEffect(() => {
    setTodayColumnIndex(todayIndex);
  }, [todayIndex]);

  useEffect(() => {
    if (todayColumnIndex !== null) {
      // Scroll to the today column
      const element = document.querySelector(
        `.timeline-column-${todayColumnIndex}`
      );
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }
  }, [todayColumnIndex]);

  const handleTimelineZoom = (inc) => {
    setTimelineZoom((prev) => {
      const newZoom = Math.min(
        Math.max(prev + inc, 0),
        timelineZoomOptions.length - 1
      );
      if (todayColumnIndex !== null && newZoom !== prev) {
        // Update todayColumnIndex if zoom level changes
        const headers = getColumnHeaders(newZoom);
        const todayIndex = headers.indexOf(
          moment().format(getHeaderFormat(newZoom))
        );
        setTodayColumnIndex(todayIndex);
      }
      return newZoom;
    });
  };

  const handleTodayClick = () => {
    setTodayColumnIndex(todayIndex);
  };
  return (
    <Card sx={{ p: 2, height: '100%' }}>
      {/* Header */}
      <Stack spacing={1} direction="row" mb={2}>
        <Button
          size="small"
          variant="outlined"
          onClick={handleTodayClick}
          sx={{ borderRadius: '6px' }}
        >
          Today
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '6px',
            px: 0.5,
            py: 0.2
          }}
        >
          <Typography variant="h6" sx={{ minWidth: 60 }} textAlign="center">
            {timelineZoomOptions[timelineZoom]?.label}
          </Typography>
          <IconButton
            size="small"
            onClick={() => handleTimelineZoom(-1)}
            disabled={timelineZoom === 0}
          >
            <RemoveRounded />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleTimelineZoom(1)}
            disabled={timelineZoom === timelineZoomOptions.length - 1}
          >
            <AddRounded />
          </IconButton>
        </Box>
      </Stack>

      {/* Timeline Body */}
      <TableContainer
        sx={{
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ border: `1px solid ${theme.palette.divider}` }}>
              <TableCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  backgroundColor: theme.palette.background.default,
                  border: `1px solid ${theme.palette.divider}`,
                  borderBottom: `none`
                }}
              ></TableCell>
              <TableCell
                colSpan={calculateColSpan(timelineZoom)}
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  py: '5px',
                  borderLeft: 'none'
                }}
              >
                {getHeaderText(timelineZoom)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  textWrap: 'nowrap',
                  borderLeft: `1px solid ${theme.palette.divider}`,
                  borderRight: `1px solid ${theme.palette.divider}`,
                  py: '5px',
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  backgroundColor: theme.palette.background.default
                }}
              >
                {/* Operation */}
              </TableCell>
              {getColumnHeaders(timelineZoom).map((header, index) => (
                <TableCell
                  key={index}
                  className={`timeline-column-${index}`}
                  sx={{
                    fontWeight: 'bold',
                    textWrap: 'nowrap',
                    borderRight: `1px solid ${theme.palette.divider}`,
                    py: '5px',
                    textAlign: 'center',
                    backgroundColor:
                      index === todayColumnIndex
                        ? theme.palette.secondary.dark
                        : theme.palette.background.default,
                    color: index === todayColumnIndex ? 'white' : 'inherit'
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.operations.map((operation) => (
              <TableRow key={operation.operation.id}>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    textWrap: 'nowrap',
                    borderRight: `1px solid ${theme.palette.divider}`,
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    backgroundColor: theme.palette.background.default
                  }}
                >
                  {operation.operation.name}
                </TableCell>
                {getColumnHeaders(timelineZoom).map((_, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{ borderRight: `1px solid ${theme.palette.divider}` }}
                  >
                    {operation.job_cards
                      .filter((job) =>
                        getJobCardInPeriod(job, colIndex, timelineZoom)
                      )
                      .map((job) => (
                        <Box
                          key={job.number}
                          sx={{
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            p: 0.5,
                            borderRadius: '4px',
                            textAlign: 'center',
                            textWrap: 'nowrap',
                            mb: 1,
                            cursor: 'pointer'
                          }}
                          onClick={(event) => handlePopoverOpen(event, job)}
                        >
                          {job.number}
                        </Box>
                      ))}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Popover | Job Cards */}
      <TestJobCard
        jobCard={selectedJobCard}
        openPopover={openPopover}
        handlePopoverClose={handlePopoverClose}
      />
    </Card>
  );
};

export default JobCardsTimeLine;
