import moment from 'moment';

export const getChipLabel = ({ item, schema, fieldName, entitySetting }) => {
  const isArrayWithObjectID =
    schema[fieldName]?.$embeddedSchemaType?.instance === 'ObjectID';

  const isArrayWithMultipleProperties =
    !isArrayWithObjectID &&
    schema[fieldName]?.$embeddedSchemaType?.schema &&
    Object.keys(schema[fieldName]?.$embeddedSchemaType?.schema?.paths)?.length >
      0;

  if (isArrayWithObjectID) {
    const foundData = entitySetting?.populate_fields?.find(
      (populateFieldItem) => populateFieldItem?.populate_field === fieldName
    );

    if (foundData) return item[foundData.field_show];
  }

  if (isArrayWithMultipleProperties) {
    const arrayObjKeys = Object.keys(
      schema[fieldName]?.$embeddedSchemaType?.schema?.paths
    );
    // const foundData = entitySetting?.populate_fields?.find(
    //   (populateFieldItem) =>
    //     arrayObjKeys.find(
    //       (arrayObjKey) =>
    //         populateFieldItem?.populate_field === `${fieldName}.${arrayObjKey}`
    //     )
    // );
    let foundData = null;

    for (const populateFieldItem of entitySetting?.populate_fields || []) {
      const foundKey = arrayObjKeys.find(
        (arrayObjKey) =>
          populateFieldItem?.populate_field === `${fieldName}.${arrayObjKey}`
      );

      if (foundKey) {
        foundData = {
          fieldShow: populateFieldItem.field_show,
          keyName: foundKey
        };
        break;
      }
    }

    if (foundData) {
      const { fieldShow, keyName } = foundData;
      return item[keyName][fieldShow];
    }
  }

  return item['id'];
};

export const checkCellValue = (value, fieldName, schema, entitySetting) => {
  if (fieldName === 'userType') {
    let enumValues = [
      { label: 'Admin', value: 1 },
      { label: 'Staff', value: 2 }
    ];
    const match = enumValues?.find((enumValue) => enumValue?.value === value);
    return match ? match.label : 'N/A';
  }

  if (value === undefined || value === '') return '';

  if (schema[fieldName]?.instance === 'Date')
    return moment(value).format('DD-MM-YYYY');

  if (typeof value === 'boolean') return value ? 'Yes' : 'No';

  if (Array.isArray(value)) {
    if (value?.length === 0) {
      return '';
    } else if (typeof value[0] === 'object' && value[0] !== null) {
      return value
        ?.map((item) => {
          let label = getChipLabel({
            item,
            schema,
            fieldName,
            entitySetting
          });
          return label;
        })
        .join();
    } else {
      return value?.join();
    }
  }

  if (typeof value === 'object' && value !== null) {
    const propertyName = entitySetting?.populate_fields?.find(
      (populate_field) => populate_field?.populate_field === fieldName
    );
    return value[
      propertyName
        ? propertyName?.field_show ||
          propertyName?.primary_text?.show_field ||
          'id'
        : 'id'
    ];
  }

  return value;
};
