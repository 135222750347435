import React from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
import MuiIconSelector from './MuiIconSelector';

const actionLocation = [
  { label: 'Bulk', value: 'bulk' },
  { label: 'More', value: 'more' },
  { label: 'Pin', value: 'pin' }
];

const buttonOrIconOptions = [
  { label: 'Button', value: 'button' },
  { label: 'Icon', value: 'icon' }
];

const CommonActionFields = ({ dataItem, index }) => {
  const { setFieldValue, handleChange, handleBlur } = useFormikContext();

  const handleActionLocationChange = (event) => {
    const value = event.target.value;
    setFieldValue(`data[${index}].actionLocation`, value);

    if (!['pin', 'bulk'].includes(value.toLowerCase())) {
      setFieldValue(`data[${index}].buttonOrIcon`, '');
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <CustomSelectFieldWithLabel
          label={'Action Location'}
          name={`data[${index}].actionLocation`}
          value={dataItem.actionLocation || ''}
          options={actionLocation}
          onChange={handleActionLocationChange}
          onBlur={handleBlur}
        />
      </Grid>

      {dataItem.actionLocation && (
        <>
          {['pin', 'bulk'].includes(dataItem.actionLocation.toLowerCase()) && (
            <Grid item xs={12} sm={6} md={3}>
              <CustomSelectFieldWithLabel
                label={'Show As'}
                name={`data[${index}].buttonOrIcon`}
                value={dataItem?.buttonOrIcon || ''}
                options={buttonOrIconOptions}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <MuiIconSelector
              label={'Select Icon'}
              name={`data[${index}].icon`}
              value={dataItem?.icon || ''}
              onChange={(value) => setFieldValue(`data[${index}].icon`, value)}
              onBlur={handleBlur}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default CommonActionFields;

// import React from 'react';
// import { Grid, Switch, FormControlLabel, Stack } from '@mui/material';
// import { useFormikContext } from 'formik';
// import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
// import CustomTextField from 'src/components/CustomFields/CustomTextField';
// import MuiIconSelector from './MuiIconSelector';

// const actionLocation = [
//   { label: 'Bulk', value: 'bulk' },
//   { label: 'More', value: 'more' },
//   { label: 'Pin', value: 'pin' }
// ];

// const buttonOrIconOptions = [
//   { label: 'Button', value: 'button' },
//   { label: 'Icon', value: 'icon' }
// ];

// const CommonActionFields = ({ dataItem, index }) => {
//   const { setFieldValue, handleChange, handleBlur } = useFormikContext();

//   const handleActionLocationChange = (event) => {
//     const value = event.target.value;
//     setFieldValue(`data[${index}].actionLocation`, value);

//     if (!['pin', 'bulk'].includes(value.toLowerCase())) {
//       // Reset fields if location is not 'Pin' or 'bulk'
//       setFieldValue(`data[${index}].buttonOrIcon`, '');
//       setFieldValue(`data[${index}].icon`, '');
//       setFieldValue(`data[${index}].actionName`, '');
//     }
//   };

//   return (
//     <>
//       <Grid item xs={12} sm={6} md={3}>
//         <CustomSelectFieldWithLabel
//           label={'Action Location'}
//           name={`data[${index}].actionLocation`}
//           value={dataItem.actionLocation || ''}
//           options={actionLocation}
//           onChange={handleActionLocationChange}
//           onBlur={handleBlur}
//         />
//       </Grid>

//       {dataItem.actionLocation && (
//         <>
//           {['pin', 'bulk'].includes(dataItem.actionLocation.toLowerCase()) && (
//             <Grid item xs={12} sm={6} md={3}>
//               <CustomSelectFieldWithLabel
//                 label={'Show As'}
//                 name={`data[${index}].buttonOrIcon`}
//                 value={dataItem?.buttonOrIcon || ''}
//                 options={buttonOrIconOptions}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//               />
//             </Grid>
//           )}
//           <Grid item xs={12} sm={6} md={3}>
//             <MuiIconSelector
//               label={'Select Icon'}
//               name={`data[${index}].icon`}
//               value={dataItem?.icon || ''}
//               onChange={(value) => setFieldValue(`data[${index}].icon`, value)}
//               onBlur={handleBlur}
//             />
//           </Grid>
//           {/* {dataItem?.buttonOrIcon !== 'icon' && (
//             <Grid item my={'auto'}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={dataItem.showIcon || false}
//                     onChange={(event) => {
//                       const isChecked = event.target.checked;
//                       setFieldValue(`data[${index}].showIcon`, isChecked);
//                       if (!isChecked) {
//                         setFieldValue(`data[${index}].icon`, '');
//                       }
//                     }}
//                     color="primary"
//                   />
//                 }
//                 sx={{ margin: 0 }}
//               />
//             </Grid>
//           )} */}
//         </>
//       )}
//     </>
//   );
// };

// export default CommonActionFields;
