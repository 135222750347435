import { Box, Button, IconButton, MenuItem, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CustomMenu from 'src/components/CustomMenu';
import { useFormikContext } from 'formik';

const buttonConfigs = [
  {
    btnName: 'On Hold',
    status: 'on-hold',
    icon: <PauseCircleOutlineIcon />,
    color: 'warning'
  },
  {
    btnName: 'Passed',
    status: 'passed',
    icon: <CheckCircleIcon />,
    color: 'success'
  },
  {
    btnName: 'Failed',
    status: 'failed',
    icon: <CancelIcon />,
    color: 'error'
  }
];

const StatusButtons = () => {
  const { setFieldValue, values } = useFormikContext();
  const { qc_pass } = values;
  const [moreMenu, setMoreMenu] = useState(null);

  const handleQcPass = (status) => {
    if (status === qc_pass) {
      setFieldValue('qc_pass', '');
    } else {
      setFieldValue('qc_pass', status);
    }
  };

  const handleMoreMenu = (event) => {
    setMoreMenu((open) => (!open ? event.currentTarget : null));
  };

  return (
    <>
      {/* MoreVertIcon for mobile view */}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleMoreMenu}
        sx={{ display: { xs: 'block', sm: 'none' } }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      {/* Buttons for larger screens */}
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {buttonConfigs.map(({ btnName, status, icon, color }) => (
          <Button
            key={status}
            size="small"
            color={qc_pass === status ? color : 'secondary'}
            startIcon={icon}
            onClick={() => handleQcPass(status)}
            variant={qc_pass === status ? 'outlined' : 'text'}
            sx={{ ml: 1 }}
          >
            {btnName}
          </Button>
        ))}
      </Box>

      {/* CustomMenu for mobile view */}
      <CustomMenu anchorEl={moreMenu} handleClose={handleMoreMenu}>
        {buttonConfigs.map(({ btnName, status, icon, color }) => (
          <MenuItem
            key={status}
            selected={qc_pass === status}
            onClick={() => handleQcPass(status)}
            color={qc_pass === status ? color : 'secondary'}
          >
            {icon}
            {btnName}
          </MenuItem>
        ))}
      </CustomMenu>
    </>
  );
};

export default StatusButtons;
