import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};
const CustomMultiSelectField = ({
  name = '',
  size = 'medium',
  value,
  onChange,
  options,
  label,
  placeholder = '',
  onBlur = () => {},
  disabled = false,
  error = null,
  touched = null,
  required = false,
  inputProps = {},
  readOnly = false
}) => {
  // Check if touched and error are present
  const hasError = touched && error;
  const modifiedInputProps = { ...inputProps, readOnly };
  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={hasError}
      required={required}
      size={size}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        name={name}
        multiple
        disabled={disabled}
        value={value || []}
        onChange={onChange}
        onBlur={onBlur}
        labelId={`${name}-label`}
        label={label}
        placeholder={placeholder}
        inputProps={modifiedInputProps}
        MenuProps={MenuProps}
      >
        {options?.map((option, index) => (
          <MenuItem key={`option_${index}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {/* Display error message if both touched and error are present */}
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomMultiSelectField;
