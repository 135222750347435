import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { getFieldType } from 'src/services/commonService';
const getMdSize = (fieldname, schema, size) => {
  const { isStringHasMaxNull, isObject, isArrayWithMultipleProperties } =
    getFieldType(fieldname, schema);
  if (isArrayWithMultipleProperties || isObject || isStringHasMaxNull)
    return 12;
  return size;
};

const BasicInfo = ({ config }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { dependentKey, depKeySchema } = config || {};
  let fields = [];

  switch (dependentKey) {
    case 'point_of_contact':
      fields = [
        'salutation',
        'firstname',
        'lastname',
        'bio',
        'birthday',
        'gender',
        'avatar'
      ];
      break;
    case 'company':
      fields = [
        'country',
        'registrationType',
        'legalName',
        'industryType',
        'logo'
      ];
      break;
    default:
      fields = [];
  }

  return (
    <Grid container spacing={2}>
      {fields.map((field, index) => (
        <Grid
          item
          xs={12}
          md={getMdSize(field, depKeySchema, 4)}
          sm={getMdSize(field, depKeySchema, 6)}
          key={`${field}_${index}`}
        >
          <GetFieldBasedOnSchema
            name={`${dependentKey}.${field}`}
            fieldName={field}
            //   entitySetting={entitySetting}
            schema={depKeySchema}
            value={values?.[dependentKey]?.[field] || ''}
            error={errors?.[dependentKey]?.[field]}
            touched={touched?.[dependentKey]?.[field]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BasicInfo;
