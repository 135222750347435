import { selectData, selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { useCallback } from 'react';
import { requestApi } from 'src/mocks/request';
import { useEffect } from 'react';
import TemplateViewPage from './TemplateViewPage';
import ComingSoonPage from 'src/components/ComingSoonPage';
import CustomViewPage from './CustomViewPage';
import ViewPageLayout from './ViewPageLayout';
import ViewPageLoader from 'src/components/SkeltonLoaders/ViewPageLoader';
import useGetItemById from 'src/hooks/useGetItemById';
import { useParams } from 'react-router';

const customViewPageEntities = [
  // 'purchase_request',
  'material_request'
  // 'request_for_quotation',
  // 'grn',
  // 'purchase_order'
];
function ViewPage() {
  const { id: itemId } = useParams();
  const data = useSelector(selectData);
  const isMounted = useRefMounted();
  const [isLoading, setLoading] = useState(true);
  const [template, setTemplate] = useState(null);
  const { entity, entitySetting, populateString } = useSelector(
    selectDynamicPageConfig
  );
  const { currentItem, isFetching, getData } = useGetItemById(
    itemId,
    { endPoint: entity, populate: populateString, query: { _id: itemId } },
    data
  );
  const isCustomPage = customViewPageEntities.includes(entity);

  const fetchTemplate = useCallback(async () => {
    const endPoint = 'view_template';
    if (isCustomPage) {
      setLoading(false);
      return;
    }

    console.log('fetchTemplate');

    try {
      setLoading(true);
      const viewTemplateId =
        entitySetting?.view_template_settings?.[0]?.default || null;

      // Fetch template based on viewTemplateId or entity
      const query = viewTemplateId
        ? { _id: viewTemplateId }
        : { entity, isDefault: true };

      const res = await requestApi.getData({ endPoint, query });
      const templateData = res?.data?.data?.[0] || null;

      if (!templateData) {
        const fallbackRes = await requestApi.getData({
          endPoint,
          query: { entity }
        });
        setTemplate(fallbackRes?.data?.data?.[0] || null);
      } else {
        setTemplate(templateData);
      }
    } catch (error) {
      console.error('Failed to fetch templates:', error);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  }, [entity, entitySetting, isCustomPage, isMounted]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  if (isLoading || isFetching) return <ViewPageLoader />;

  // CUSTOM PAGE
  if (isCustomPage && currentItem) {
    return (
      <ViewPageLayout data={currentItem}>
        <CustomViewPage data={currentItem} />
      </ViewPageLayout>
    );
  }

  // template page
  if (template && currentItem && getData) {
    return (
      <ViewPageLayout data={currentItem}>
        <TemplateViewPage getData={getData} template={template} />
      </ViewPageLayout>
    );
  }

  return <ComingSoonPage pageName={entity} />;
}

export default ViewPage;
