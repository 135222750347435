import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import InitialStockViewTable from './InitialStockViewTable';
import StockAuditViewLoader from '../StockAuditViewLoader';
const StockAuditViewPage = () => {
  const { currentItem, isFetching } = useDyFetchDataById();
  if (isFetching) return <StockAuditViewLoader title={'Confirm Stock'} />;
  return (
    <>
      <Helmet>
        <title>Confirm Stock</title>
      </Helmet>

      <PageTitleWrapper>
        <PageHeader currentItem={currentItem} />
      </PageTitleWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InitialStockViewTable currentItem={currentItem} />
        </Grid>
      </Grid>
    </>
  );
};

export default StockAuditViewPage;
