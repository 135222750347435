import { Avatar, Grid, Typography } from '@mui/material';
import React from 'react';
import CustomPopover from 'src/components/CustomPopover';

const UserBox = ({ openPopover, user, handlePopoverClose }) => {
  return (
    <CustomPopover
      anchorEl={openPopover}
      open={Boolean(openPopover)}
      onClose={handlePopoverClose}
    >
      <Grid container spacing={2} width={300} alignItems={'center'}>
        <Grid item xs={12} sm={5}>
          <Avatar
            variant="square"
            src={user?.avatar}
            sx={{ width: '100%', height: 100 }}
          ></Avatar>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant="h4" textTransform={'capitalize'} gutterBottom>
            {user?.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {user?.email}
          </Typography>
        </Grid>
      </Grid>
    </CustomPopover>
  );
};

export default UserBox;
