import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

const RootWrapper = styled(Box)(
  () => `
            flex: 1;
        `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader({ currentItem }) {
  const navigate = useNavigate();

  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h3" component="h3" gutterBottom>
                Stock Audit & Reconcilliation
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={0.5}>
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                navigate(`/stock_audit/review-stock/${currentItem.id}`)
              }
              startIcon={<Inventory2OutlinedIcon size={'1rem'} />}
            >
              Review Stocks
            </Button>
            <Tooltip title={`Go back`}>
              <IconButtonWrapper onClick={() => navigate(-1)}>
                <ArrowBackTwoToneIcon />
              </IconButtonWrapper>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
