import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack
} from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { getFieldType } from 'src/services/commonService';
import { structureSchema } from 'src/utils/structureSchema';
import AddIcon from '@mui/icons-material/Add';
const getMdSize = (fieldname, schema, size) => {
  const { isStringHasMaxNull, isObject, isArrayWithMultipleProperties } =
    getFieldType(fieldname, schema);
  if (isArrayWithMultipleProperties || isObject || isStringHasMaxNull)
    return 12;
  return size;
};

const PeopleTab = ({ schema, fieldName, entitySetting }) => {
  const { values } = useFormikContext();
  const companySchema = schema ? schema?.[fieldName]?.schema?.paths : null;
  let ownerSchema =
    structureSchema(companySchema?.['owner']?.schema?.paths) || {};
  let ownerFields = Object.keys(ownerSchema)?.filter(
    (key) => !REMOVE_FIELDS.includes(key)
  );
  let otherContactSchema =
    structureSchema(companySchema?.['other_contacts']?.schema?.paths) || {};
  let otherContactFields = Object.keys(otherContactSchema)?.filter(
    (key) => !REMOVE_FIELDS.includes(key)
  );
  return (
    <>
      <Stack spacing={2}>
        {ownerFields?.length > 0 && (
          <Card variant="outlined">
            <CardHeader title="Owner" />
            <Divider />
            <CardContent>
              <Grid container spacing={1}>
                {ownerFields.map((field, index) => (
                  <Grid
                    item
                    xs={12}
                    md={getMdSize(field, ownerSchema, 4)}
                    sm={getMdSize(field, ownerSchema, 6)}
                    key={`${field}_${index}`}
                  >
                    <GetFieldBasedOnSchema
                      name={`${fieldName}.owner.${field}`}
                      fieldName={field}
                      entitySetting={entitySetting}
                      schema={ownerSchema}
                      value={values?.[fieldName]?.owner?.[field] || ''}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        )}
        {otherContactFields?.length > 0 && (
          <FieldArray
            name={`${fieldName}.other_contacts`}
            render={({ remove, push }) => (
              <Card variant="outlined">
                <CardHeader title="Other Contacts" />
                <Divider />
                <CardContent>
                  <Stack spacing={1}>
                    {(values?.[fieldName]?.other_contacts || [])?.map(
                      (otherContact, index) => (
                        <Grid
                          key={index}
                          container
                          spacing={1}
                          border={1}
                          p={1}
                          borderColor="divider"
                          borderRadius={1}
                        >
                          {otherContactFields.map((field) => (
                            <Grid
                              item
                              xs={12}
                              md={getMdSize(field, otherContactSchema, 4)}
                              sm={getMdSize(field, otherContactSchema, 6)}
                              key={field}
                            >
                              <GetFieldBasedOnSchema
                                name={`${fieldName}.other_contacts[${index}].${field}`}
                                fieldName={field}
                                schema={otherContactSchema}
                                value={otherContact?.[field] || ''}
                              />
                            </Grid>
                          ))}
                          <Grid
                            item
                            xs={12}
                            display={'flex'}
                            justifyContent={'flex-end'}
                          >
                            <Button
                              size="small"
                              color="error"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      )
                    )}
                    <Button
                      onClick={() => push({})}
                      size="small"
                      sx={{ alignSelf: 'flex-start' }}
                      startIcon={<AddIcon />}
                    >
                      {values?.[fieldName]?.other_contacts?.length > 0
                        ? 'Add more contact'
                        : 'Add contact'}
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            )}
          />
        )}
      </Stack>
    </>
  );
};

export default PeopleTab;
