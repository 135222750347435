import React from 'react';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import WorkflowFormBody from './WorkflowFormBody';
import CreateEditPageLoader from 'src/components/SkeltonLoaders/CreateEditPageLoader';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';

const WorkflowForm = () => {
  const { currentItem, isFetching } = useDyFetchDataById();
  if (isFetching) return <CreateEditPageLoader />;
  return (
    <>
      <PageTitleWrapper>
        <PageHeader currentItem={currentItem} />
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <WorkflowFormBody currentItem={currentItem} />
        </Grid>
      </Grid>
    </>
  );
};

export default WorkflowForm;
