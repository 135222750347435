import { CircularProgress, Stack } from '@mui/material';
import { Formik } from 'formik';

import ProductsCard from './ProductsCard';
import useDynamicFormLogic from 'src/hooks/useDynamicFormLogic';

const FormBody = ({ currentItem }) => {
  const { initialValues, validationSchema, handleFormSubmit } =
    useDynamicFormLogic({
      currentItem,
      isCustomForm: true
    });

  if (!initialValues || !validationSchema) return <CircularProgress />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Stack gap={1}>
              <ProductsCard currentItem={currentItem} />

              {/* <Stack direction="row" justifyContent="center">
                <Button type="submit" variant="contained" color="primary">
                  Save Material Request
                </Button>
              </Stack> */}
            </Stack>
          </form>
        </>
      )}
    </Formik>
  );
};

export default FormBody;
