import { Button, DialogActions, Grid, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CommonModal from 'src/components/CommonModal.js';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const AddProduct = ({ open, handleModal }) => {
  const { t } = useTranslation();
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const { values, errors, touched } = useFormikContext();

  const fieldNames = ['items'];

  return (
    <>
      <CommonModal
        maxWidth="md"
        open={open}
        handleModal={handleModal}
        title={t(`Add Product`)}
      >
        <Stack spacing={1} sx={{ mb: 1 }}>
          <Grid container spacing={1}>
            {fieldNames.map((fieldName) => (
              <Grid item xs={12} key={fieldName}>
                <GetFieldBasedOnSchema
                  name={fieldName}
                  fieldName={fieldName}
                  entitySetting={entitySetting}
                  schema={schema}
                  value={values[fieldName]}
                  error={errors[fieldName]}
                  touched={touched[fieldName]}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleModal}
          >
            {t('Done')}
          </Button>
        </DialogActions>
      </CommonModal>
    </>
  );
};

export default AddProduct;
