import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getFieldOptions } from 'src/utils/getFieldOptions';
import BasicInfo from './BasicInfo';
import { useFormikContext } from 'formik';
import { structureSchema } from 'src/utils/structureSchema';
import { useMemo } from 'react';
import SocialMedia from './SocialMedia';
import NotesAndFiles from './NotesAndFiles';
import Identity from './Identity';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { getInitialValues } from 'src/utils/formikInitialization';
import { useEffect } from 'react';
import PeopleTab from './PeopleTab';
import BankAccTab from './BankAccTab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const DyTabForm = ({ fieldName }) => {
  const [value, setValue] = useState(0);
  const { values, setFieldValue } = useFormikContext();
  const { schema } = useSelector(selectDynamicPageConfig);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Memoize config computation
  const config = useMemo(() => {
    let dependents = getFieldOptions(fieldName, schema)?.dependents || [];
    const selectedDependent = dependents.find(
      (dependent) => values?.[fieldName] === dependent?.value
    );

    if (selectedDependent) {
      const dependentKey = selectedDependent?.fields_to_show?.[0];
      const hideDependentKey = selectedDependent?.fields_to_hide?.[0];
      const depKeySchema = structureSchema(
        schema?.[dependentKey]?.schema?.paths
      );
      if (depKeySchema) {
        return { hideDependentKey, dependentKey, depKeySchema };
      }
    }
    return null;
  }, [fieldName, schema, values?.[fieldName], setFieldValue]);

  useEffect(() => {
    if (config) {
      const { hideDependentKey, dependentKey, depKeySchema } = config;
      const initialValues = getInitialValues(
        depKeySchema,
        Object.keys(depKeySchema)?.filter(
          (key) => !REMOVE_FIELDS.includes(key)
        ),
        values?.[dependentKey]
      );
      setFieldValue(dependentKey, initialValues);
      setFieldValue(hideDependentKey, null);
    }
  }, [config, fieldName, setFieldValue]);
  if (!config) return null;
  return (
    <Box sx={{ border: 1, borderRadius: 1, borderColor: 'divider' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Dynamic scrollable auto tabs"
        >
          <Tab label="Basic info" {...a11yProps(0)} />
          <Tab label="Identity" {...a11yProps(1)} />
          <Tab label="Bank Accounts" {...a11yProps(2)} />
          <Tab label="Social" {...a11yProps(3)} />
          <Tab label="People" {...a11yProps(4)} />
          <Tab label="Notes & Files" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <BasicInfo config={config} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Identity config={config} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <BankAccTab config={config} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <SocialMedia config={config} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <PeopleTab config={config} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <NotesAndFiles config={config} />
      </CustomTabPanel>
    </Box>
  );
};

export default DyTabForm;
