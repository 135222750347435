import {
  Checkbox,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import ProcessReqTableRow from './ProcessReqTableRow';
import { useState } from 'react';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import MulCreateRFQ from './ProcessTableActions/MulCreateRFQ';
import MulCreatePo from './ProcessTableActions/MulCreatePo';

const PurchaseReqTable = ({ selectedRowData }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const { updateCurrentPurchaseReq, currentPurchaseReq } =
    useContext(ProcessReqContext);
  const { items = [] } = currentPurchaseReq || {};
  const handleSelectAllRows = (event) => {
    setSelectedRows(event.target.checked ? items : []);
  };

  const handleSelectOneRow = (_event, item) => {
    if (selectedRows.some((selectedRow) => selectedRow.id === item.id)) {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((prevItem) => prevItem.id !== item.id)
      );
    } else {
      setSelectedRows((prevSelected) => [...prevSelected, item]);
    }
  };

  useEffect(() => {
    if (selectedRowData) {
      updateCurrentPurchaseReq(selectedRowData);
    }
  }, [selectedRowData, updateCurrentPurchaseReq]);

  const isAllRowsSelected = selectedRows.length === items.length;
  const selectedSomeRows =
    selectedRows.length > 0 && selectedRows.length < items.length;
  return (
    <Paper variant="outlined">
      {Boolean(selectedRows.length) && (
        <Stack gap={1} direction={'row'} alignItems={'center'} p={1}>
          <MulCreateRFQ selectedRows={selectedRows} />
          <MulCreatePo selectedRows={selectedRows} />
        </Stack>
      )}
      <Divider />
      <TableContainer>
        <Table aria-label="department table" size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  size="small"
                  checked={isAllRowsSelected}
                  indeterminate={selectedSomeRows}
                  onChange={handleSelectAllRows}
                />
              </TableCell>
              <TableCell>Items</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>PO</TableCell>
              <TableCell>RFQ's</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <ProcessReqTableRow
                key={item.id}
                item={item}
                selectedRows={selectedRows}
                handleSelectOneRow={handleSelectOneRow}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PurchaseReqTable;
