import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useFormikContext } from 'formik';
import useSearch from 'src/hooks/useSearch';
import SDTableRows from './StockAdjustmentItems';
import { useState } from 'react';
import SDCardActions from './SDCardActions';

const StockDetailsCard = () => {
  const theme = useTheme();
  const { values } = useFormikContext();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [showSearchField, setShowSearchField] = useState(false);
  const { searchTerm, handleSearchChange, filteredItems } = useSearch(
    values?.products,
    'product.name'
  );
  const handleShowSearchField = () => {
    setShowSearchField(!showSearchField);
  };
  return (
    <Card variant="outlined">
      {(!showSearchField || !isXs) && (
        <CardHeader
          action={
            <>
              <SDCardActions
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                handleShowSearchField={handleShowSearchField}
                showSearchField={showSearchField}
                showSearchIcon={true}
              />
            </>
          }
          title="Stock Details"
        />
      )}
      <Box
        p={1}
        sx={{
          display: { xs: showSearchField ? 'block' : 'none', sm: 'none' }
        }}
      >
        <SDCardActions
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          handleShowSearchField={handleShowSearchField}
          showSearchField={showSearchField}
          showSearchIcon={false}
        />
      </Box>
      <Divider />
      <CardContent>
        <SDTableRows products={filteredItems} />
      </CardContent>
    </Card>
  );
};

export default StockDetailsCard;
