import { Chip } from '@mui/material';
import CardComponent from 'src/components/DynamicComponents/CardComponent';
import ProgressComponent from 'src/components/DynamicComponents/ProgressComponent';
import SwitchComponent from 'src/components/DynamicComponents/SwitchComponent';
import TypographyComponent from 'src/components/DynamicComponents/TypographyComponent';
import { Link as RouterLink } from 'react-router-dom';

const applyTextModeration = (value, moderation) => {
  if (!moderation) return value;

  const { moderation_type, moderation_value } = moderation;

  if (moderation_type === 'prefix') {
    return `${moderation_value}${value}`;
  } else if (moderation_type === 'suffix') {
    return `${value}${moderation_value}`;
  }

  return value;
};

const handleTypography = (props, value) => {
  let sx = {};

  if (props?.color?.hex) {
    sx = { color: props.color.hex };
    // Remove the color property from props
    const { color, ...rest } = props;
    props = rest;
  }

  return <TypographyComponent {...props} sx={sx} value={value} />;
};

const getComponentProps = (propsArray, value) => {
  return (
    propsArray?.reduce(
      (acc, item) => {
        if (item.specificRange) {
          const filteredData = item.ranges?.find(
            (range) =>
              range.startValue !== undefined &&
              range.endValue !== undefined &&
              value >= range.startValue &&
              value <= range.endValue
          );
          if (filteredData) {
            const barColor = filteredData?.barColor?.rgb;
            const rootColor = item?.rootColor?.rgb;
            acc.sx = {
              ...acc.sx,
              ...(barColor && {
                '& .MuiLinearProgress-bar': {
                  backgroundColor: `rgba(${barColor.r},${barColor.g},${barColor.b},${barColor.a})`
                }
              }),
              ...(rootColor && {
                backgroundColor: `rgba(${rootColor.r},${rootColor.g},${rootColor.b},${rootColor.a})`
              })
            };
          }
        }
        if (item.value !== undefined) {
          acc[item.name] = item.value;
        }
        const bgColor = item?.backgroundColor?.rgb;
        const txtColor = item?.textColor?.rgb;
        const barColor = item?.barColor?.rgb;
        const rootColor = item?.rootColor?.rgb;
        acc.sx = {
          ...acc.sx,
          ...(bgColor && {
            backgroundColor: `rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`
          }),
          ...(txtColor && {
            color: `rgba(${txtColor.r},${txtColor.g},${txtColor.b},${txtColor.a})`
          }),
          ...(barColor && {
            '& .MuiLinearProgress-bar': {
              backgroundColor: `rgba(${barColor.r},${barColor.g},${barColor.b},${barColor.a})`
            }
          }),
          ...(rootColor && {
            backgroundColor: `rgba(${rootColor.r},${rootColor.g},${rootColor.b},${rootColor.a})`
          })
        };

        return acc;
      },
      { sx: {} }
    ) || {}
  );
};

const renderComponent = (
  component,
  cardData,
  value,
  props,
  id = null,
  ref = null
) => {
  if (id) {
    props.to = `/view/${ref}/${id}`;
    props.component = RouterLink;
    props.clickable = true;
  }
  switch (component) {
    case 'chip':
      return <Chip label={value} {...props} />;
    case 'switch':
      return <SwitchComponent value={value} {...props} />;
    case 'progress':
      return <ProgressComponent value={value} {...props} />;
    case 'card':
      return <CardComponent {...props} cardData={cardData} id={id} />;
    default:
      return value;
  }
};

const getEnumSetting = (enumSetting, value) => {
  return enumSetting?.find((enumItem) => enumItem.enum_name === value);
};

const DesignFormat = ({
  designSetting,
  value,
  fieldName,
  schema,
  row,
  id = null
}) => {
  const checkVal = typeof value === 'object' ? value.primary_text : value;
  let moderatedValue = applyTextModeration(
    checkVal,
    designSetting?.text_moderation
  );

  if (
    schema[fieldName]?.enumValues?.length > 0 &&
    designSetting?.enum_setting?.length > 0
  ) {
    const foundSetting = getEnumSetting(designSetting.enum_setting, checkVal);
    if (foundSetting) {
      if (foundSetting.design_type) {
        // console.log(foundSetting.props, 'foundSetting.props');
        const props = getComponentProps(foundSetting.props);
        return renderComponent(
          foundSetting.component,
          null,
          moderatedValue,
          props
        );
      } else {
        return handleTypography(foundSetting.format, moderatedValue);
      }
    }
  }

  if (designSetting.design_type) {
    let cardData = null;
    if (designSetting.componentConfig?.cardDesign?.length > 0) {
      cardData = handleCardData(
        designSetting.componentConfig?.cardDesign,
        row,
        schema
      );
    }
    const props = getComponentProps(
      designSetting.componentConfig?.props,
      checkVal
    );
    return renderComponent(
      designSetting.componentConfig?.component,
      cardData,
      moderatedValue,
      props,
      id,
      schema[fieldName].options.ref
    );
  } else {
    return handleTypography(designSetting.format, moderatedValue);
  }
};
const handleCardData = (cardDesigns, row, schema) => {
  return cardDesigns?.reduce((acc, cardDesign) => {
    let field = cardDesign?.fieldName || null;
    let nestedField = cardDesign?.nestedField || null;
    let value = null;
    let id = null;
    let ref = null;
    if (field && !nestedField) {
      value = row?.[field];
    }
    if (nestedField) {
      value = row?.[field]?.[nestedField];
      id = row?.[field]?.id;
      ref = schema[field]?.options?.ref;
    }
    switch (cardDesign.element_name) {
      case 'avatar':
        acc['avatarProps'] = {
          logo: cardDesign?.isLogo
            ? value || 'N'
            : value
            ? value.charAt(0)
            : 'N',
          isLogo: cardDesign?.isLogo
        };
        break;
      case 'title':
        acc['titleProps'] = {
          ...acc['titleProps'],
          value: value || 'No Title',
          id,
          ref
        };
        break;
      case 'description':
        acc['descriptionProps'] = {
          ...acc['descriptionProps'],
          value: value || 'No Description',
          id,
          ref
        };
        break;
      default:
        break;
    }
    return acc;
  }, {});
};

export default DesignFormat;
