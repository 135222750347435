import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import TableChartIcon from '@mui/icons-material/TableChart';

const getFileTypeIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();

  switch (extension) {
    case 'pdf':
      return <PictureAsPdfIcon fontSize="large" />;
    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsx':
      return <InsertDriveFileIcon fontSize="large" />;
    case 'csv':
      return <TableChartIcon fontSize="large" />;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'svg':
      return <ImageIcon fontSize="large" />;
    default:
      return <AttachFileIcon fontSize="large" />;
  }
};
const FileGridView = ({ files }) => {
  return (
    <>
      <ImageList cols={3} gap={10}>
        {files.map((file, index) => (
          <ImageListItem key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {getFileTypeIcon(file.name)}
              <Typography variant="caption" noWrap>
                {file.name}
              </Typography>
            </Box>
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default FileGridView;
