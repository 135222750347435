import { Button, Grid, Skeleton, Stack } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import DTAutocomplete from 'src/components/GetFieldBasedOnSchema/DTAutocomplete';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
const StockAdjustmentItems = ({ products }) => {
  const { schema, entitySetting } = useEntityPageConfig('audit_record');
  const { setFieldValue, handleChange } = useFormikContext();
  if (!schema) return <Skeleton variant="rounded" height={'10vh'} />;
  return (
    <>
      <FieldArray name="products">
        {({ push, remove }) => (
          <Stack spacing={1}>
            {products.map((product, proIdx) => (
              <Grid
                key={`product_${proIdx}`}
                container
                spacing={1}
                border={1}
                p={1}
                borderColor="divider"
                borderRadius={1}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <DTAutocomplete
                    label="Product"
                    size="small"
                    fieldName="product"
                    value={product?.product || ''}
                    schema={schema}
                    onChange={(value) =>
                      setFieldValue(`products[${proIdx}].product`, value)
                    }
                    entitySetting={entitySetting}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <DTAutocomplete
                    label="Location"
                    size="small"
                    fieldName="location"
                    value={product?.location || ''}
                    schema={schema}
                    onChange={(value) =>
                      setFieldValue(`products[${proIdx}].location`, value)
                    }
                    entitySetting={entitySetting}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomTextField
                    label={'Quantity'}
                    size="small"
                    name={`products[${proIdx}].actual_quantity`}
                    value={product?.actual_quantity || ''}
                    onChange={handleChange}
                    inputProps={{ type: 'number' }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button
                    color="error"
                    size="small"
                    onClick={() => remove(proIdx)}
                  >
                    Remove Product
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button
              startIcon={<AddIcon fontSize="small" />}
              size="small"
              sx={{ alignSelf: 'flex-start' }}
              onClick={() =>
                push({
                  product: '',
                  actual_quantity: '',
                  location: ''
                })
              }
            >
              {products.length ? 'Add More Products' : 'Add Products'}
            </Button>
          </Stack>
        )}
      </FieldArray>
    </>
  );
};

export default StockAdjustmentItems;
