import { Button, CircularProgress, Stack } from '@mui/material';
import { Formik } from 'formik';

import OrderDetailsCard from './OrderDetailsCard';
import ProductsCard from './ProductsCard';
import useDynamicFormLogic from 'src/hooks/useDynamicFormLogic';

const FormBody = ({ currentItem }) => {
  const { initialValues, validationSchema, handleFormSubmit } =
    useDynamicFormLogic({
      currentItem,
      isCustomForm: true
    });

  if (!initialValues || !validationSchema) return <CircularProgress />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Stack gap={1}>
              <OrderDetailsCard currentItem={currentItem} />
              <ProductsCard currentItem={currentItem} />
            </Stack>
          </form>
        </>
      )}
    </Formik>
  );
};

export default FormBody;
