import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { Form, Formik } from 'formik';
import AuditSplit from './AuditSplit';
import AuditDetails from './AuditDetails';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
import { Navigate, useNavigate } from 'react-router';
import StockAuditEditLoader from './StockAuditEditLoader';

const StockAuditEdit = () => {
  const navigate = useNavigate();
  const { schema } = useEntityPageConfig('stock_audit');
  const { currentItem, isFetching } = useDyFetchDataById();
  const [submitting, setSubmitting] = useState(false);
  const isMounted = useRefMounted();
  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const { set, unset } = sanitizeFormData(
        values,
        schema,
        Object.keys(values)
      );
      const payload = {
        endPoint: 'stock_audit',
        data: {
          $set: set,
          $unset: unset
        },
        id: currentItem.id
      };
      const response = await requestApi.updateData(payload);
      if (response.data) {
        navigate('/stock_audit');
      }
    } finally {
      if (isMounted.current) setSubmitting(true);
    }
  };
  if (isFetching) return <StockAuditEditLoader />;
  if (currentItem?.status === 'in-progress') {
    return (
      <Navigate to={`/stock_audit/perform-audit/${currentItem.id}`} replace />
    );
  }
  return (
    <>
      <Helmet>
        <title>Stock Audit</title>
      </Helmet>

      <Formik
        initialValues={{
          warehouse: currentItem.warehouse || '',
          splits: currentItem.splits || [],
          split_by: currentItem.split_by || '',
          scheduled_on: currentItem.scheduled_on || '',
          audit_type: 'stock_audit',
          scope: currentItem.scope || '',
          instructions:
            currentItem.instructions ||
            `
    <p>Verify that the physical count of stock items matches the recorded quantities in the inventory system.</p>
    <p>Ensure that stock items are stored in their designated locations according to the inventory management system.</p>
    <p>Evaluate the condition of stock items to identify any damage, obsolescence, or spoilage.</p>
    <p>For perishable items, check the expiration dates and ensure that they are within the acceptable range.</p>
    <p>Assess the measures in place to prevent stock theft and unauthorized access.</p>
  `
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <PageTitleWrapper>
              <PageHeader currentItem={currentItem} submitting={submitting} />
            </PageTitleWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AuditDetails />
              </Grid>
              <Grid item xs={12}>
                <AuditSplit currentItem={currentItem} />
              </Grid>
              <Grid item xs={12}>
                <QuillEditorField
                  label={'Audit Criteria &  Instructions'}
                  value={values.instructions || ''}
                  onChange={(value) => setFieldValue('instructions', value)}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default StockAuditEdit;
