import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Button, CircularProgress, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationModal = ({
  title,
  description,
  navigateLink,
  onConfirm,
  open,
  onClose,
  isLoading,
  confirmBtnConfig = { name: 'Confirm', variant: 'contained', color: 'error' },
  wayOutBtnConfig = { name: 'Cancel', variant: 'outlined', color: 'primary' }
}) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    } else if (navigateLink) {
      navigate(navigateLink);
    }
  };

  return (
    <Dialog
      open={Boolean(open)}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            sx={{ minWidth: 100 }}
            variant={wayOutBtnConfig.variant}
            color={wayOutBtnConfig.color}
            onClick={onClose}
            disabled={isLoading}
          >
            {wayOutBtnConfig.name}
          </Button>
          <Button
            sx={{ ml: 1, minWidth: 100 }}
            variant={confirmBtnConfig.variant}
            disabled={isLoading}
            color={confirmBtnConfig.color}
            onClick={handleConfirm}
            startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
          >
            {confirmBtnConfig.name}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
