import { styled, Tabs, Tab, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import {
  selectCurrentTab,
  selectDynamicPageConfig,
  setCurrentTab,
  setDefaultQuery
} from 'src/slices/dynamicSlice';

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
        box-shadow: none;
      }
    }
  `
);

const CustomTabs = () => {
  const dispatch = useDispatch();
  const { menuItems } = useSelector(selectAppConfigData);
  const { entity } = useSelector(selectDynamicPageConfig);
  const currentTab = useSelector(selectCurrentTab);

  // Memoize tabs to avoid unnecessary recalculations
  const tabs = useMemo(() => {
    if (!menuItems.length || !entity) return [];

    const foundMenuItem = menuItems
      .flatMap((menuItem) => menuItem.items)
      .find((item) => item.entity === entity);

    return foundMenuItem ? foundMenuItem.tabs : [];
  }, [menuItems, entity]);

  // Handle tab change
  const handleTabsChange = (event, newValue) => {
    dispatch(setCurrentTab(newValue));

    // Find the selected tab
    const selectedTab = tabs.find((tab) => tab.name === newValue);

    // Dispatch default query if available for the selected tab
    if (selectedTab?.query) {
      dispatch(setDefaultQuery(selectedTab.query));
    }
  };
  if (!tabs?.length) return null;
  return (
    <Grid item xs={12}>
      <TabsWrapper
        value={currentTab}
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
      >
        {tabs.map((tab) => (
          <Tab key={tab.name} value={tab.name} label={tab.name} />
        ))}
      </TabsWrapper>
    </Grid>
  );
};

export default CustomTabs;

// import { styled, Tabs, Tab, Grid } from '@mui/material';
// import React, { useState, useEffect, useMemo } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { selectAppConfigData } from 'src/slices/appConfigSlice';
// import {
//   selectDynamicPageConfig,
//   setDefaultQuery
// } from 'src/slices/dynamicSlice';

// const TabsWrapper = styled(Tabs)(
//   ({ theme }) => `
//     @media (max-width: ${theme.breakpoints.values.md}px) {
//       .MuiTabs-scrollableX {
//         overflow-x: auto !important;
//       }

//       .MuiTabs-indicator {
//         box-shadow: none;
//       }
//     }
//   `
// );

// const CustomTabs = () => {
//   const dispatch = useDispatch();
//   const { menuItems } = useSelector(selectAppConfigData);
//   const { entity } = useSelector(selectDynamicPageConfig);
//   const [currentTab, setCurrentTab] = useState('');

//   // Memoize tabs to avoid unnecessary recalculations
//   const tabs = useMemo(() => {
//     if (!menuItems.length || !entity) return [];

//     const foundMenuItem = menuItems
//       .flatMap((menuItem) => menuItem.items)
//       .find((item) => item.entity === entity);

//     return foundMenuItem ? foundMenuItem.tabs : [];
//   }, [menuItems, entity]);

//   // Set the first tab as the selected tab when tabs are available
//   useEffect(() => {
//     if (tabs?.length > 0) {
//       setCurrentTab(tabs[0].name);

//       // Set default query for the first tab on load
//       if (tabs[0].query) {
//         dispatch(setDefaultQuery(tabs[0].query));
//       }
//     }
//   }, [tabs, dispatch]);

//   // Handle tab change
//   const handleTabsChange = (event, newValue) => {
//     setCurrentTab(newValue);

//     // Find the selected tab
//     const selectedTab = tabs.find((tab) => tab.name === newValue);

//     // Dispatch default query if available for the selected tab
//     if (selectedTab?.query) {
//       dispatch(setDefaultQuery(selectedTab.query));
//     }
//   };
//   if (!tabs?.length) return null;
//   return (
//     <Grid item xs={12}>
//       <TabsWrapper
//         value={currentTab}
//         onChange={handleTabsChange}
//         scrollButtons="auto"
//         textColor="secondary"
//       >
//         {tabs.map((tab) => (
//           <Tab key={tab.name} value={tab.name} label={tab.name} />
//         ))}
//       </TabsWrapper>
//     </Grid>
//   );
// };

// export default CustomTabs;
