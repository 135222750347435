import { useState, useEffect, useCallback } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';

const useFetchData = (payload, isSingle = false) => {
  const isMounted = useRefMounted();
  const [data, setData] = useState(isSingle ? '' : []);
  const [isFetching, setFetching] = useState(true);

  const fetchData = useCallback(async () => {
    setFetching(true);
    try {
      const response = await requestApi.getData(payload);
      const resData = response?.data?.data;

      if (resData?.length && isMounted.current) {
        setData(isSingle ? resData[0] : resData);
      }
    } finally {
      if (isMounted.current) setFetching(false);
    }
  }, [isMounted]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isFetching
  };
};

export default useFetchData;
