import { IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import ImportTable from 'src/components/ImportTable';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import UserTableCell from './UserTableCell';
import CommonModal from 'src/components/CommonModal.js';
import AddStock from './AddStock';
const ASTableRow = ({ currentItem, split, path }) => {
  const navigate = useNavigate();
  const { setFieldValue } = useFormikContext();
  const [addStockModal, setAddStockModal] = useState(false);
  const { location = '', performed_by = '', verified_by = '' } = split;
  const [openImportModal, setImportModal] = useState(false);
  const handleUploadModal = () => {
    setImportModal(!openImportModal);
  };
  const handleImportData = (importData) => {
    if (importData && importData?.length)
      setFieldValue(`${path}.data`, importData);
  };
  return (
    <>
      <TableRow>
        <TableCell> {location?.full_code || location?.code || ''}</TableCell>
        <TableCell>
          <UserTableCell user={performed_by} defaultText="No assignee" />
        </TableCell>
        <TableCell>
          <UserTableCell user={verified_by} defaultText="No verifier" />
        </TableCell>
        <TableCell>
          <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
            <Tooltip title={'Upload'}>
              <IconButton
                size="small"
                color="primary"
                onClick={handleUploadModal}
              >
                <UploadFileOutlinedIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Add Stock'}>
              <IconButton
                size="small"
                color="primary"
                onClick={
                  () => setAddStockModal(true)
                  // navigate(`/stock_audit/add-stock/${currentItem.id}`)
                }
              >
                <Inventory2OutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <CommonModal
        open={addStockModal}
        title={`Add Stock (${location?.full_code || location?.code || ''})`}
        handleModal={() => setAddStockModal(false)}
        maxWidth="md"
      >
        <AddStock
          handleModal={() => setAddStockModal(false)}
          location={location}
          currentItem={currentItem}
        />
      </CommonModal>
      {openImportModal && (
        <ImportTable
          open={openImportModal}
          handleModal={handleUploadModal}
          endPoint={'audit_record'}
          onValidated={handleImportData}
        />
      )}
    </>
  );
};

export default ASTableRow;
