import {
  Card,
  Stack,
  Typography,
  useTheme,
  Tooltip,
  Avatar,
  Box
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconButtonWrapper } from 'src/components/Styled';
import { InfoOutlined } from '@mui/icons-material';
import CustomPopover from '../CustomPopover';

const TestJobCard = ({
  jobCard,
  options,
  onClick,
  openPopover,
  handlePopoverClose
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <CustomPopover
      anchorEl={openPopover}
      open={Boolean(openPopover)}
      onClose={handlePopoverClose}
    >
      <Card
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          p: 1,
          width: '100%',
          cursor: 'pointer'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2" fontWeight="bold">
            {jobCard?.number}
          </Typography>
          {options?.isEditable && (
            <Tooltip
              arrow
              title={t(
                `We've drafted a schedule. Feel free to edit by clicking on any field.`
              )}
              placement="right"
            >
              <IconButtonWrapper sx={{ ml: 0.5 }}>
                <InfoOutlined fontSize="small" />
              </IconButtonWrapper>
            </Tooltip>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" sx={{ mr: 3 }}>
            Production Floor: {jobCard?.location}
          </Typography>
          <Typography variant="subtitle2">
            {moment(jobCard?.start_time).isValid()
              ? moment(jobCard?.start_time).format('h:mm a')
              : ''}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Avatar
              src={jobCard?.operator?.user?.avatar}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="subtitle1">
              {jobCard?.equipment?.name}
            </Typography>
          </Box>
          <Typography variant="subtitle2">{jobCard?.units} units</Typography>
        </Stack>
      </Card>
    </CustomPopover>
  );
};

export default TestJobCard;
