import React from 'react';
import { Box, Button, Grid, Paper, Stack } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import FieldToShow from './FieldToShow';
const PopulateFields = ({ fieldNames, schema }) => {
  const { handleBlur, values, touched, errors, setFieldValue } =
    useFormikContext();
  return (
    <>
      <Grid item xs={12}>
        {fieldNames.length > 0 && (
          <Stack rowGap={1}>
            <FieldArray
              name="data"
              render={({ remove, push }) => (
                <>
                  {values.data.map((dataItem, index) => (
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        width: '100%',
                        border: 1,
                        color: '#ccc'
                      }}
                      key={`dataItem_${index}`}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CustomSelectField
                            value={dataItem.populate_field}
                            name={`data[${index}].populate_field`}
                            label="Populate Field"
                            onBlur={handleBlur}
                            options={fieldNames.filter((fieldName) => {
                              // Check if fieldName is not selected in any row other than the current one
                              const isFieldNameSelected = values.data.some(
                                (rowData, rowIndex) =>
                                  rowData.populate_field === fieldName &&
                                  rowIndex !== index
                              );
                              return !isFieldNameSelected;
                            })}
                            onChange={(e) => {
                              setFieldValue(
                                `data[${index}].populate_field`,
                                e.target.value
                              );
                            }}
                            touched={touched?.data?.[index]?.populate_field}
                            error={errors?.data?.[index]?.populate_field}
                          />
                        </Grid>
                        {dataItem.populate_field && (
                          <Grid item xs={12}>
                            <FieldToShow
                              populateFieldName={dataItem.populate_field}
                              dataItem={dataItem}
                              index={index}
                              schema={schema}
                              showType={'primary_text'}
                              name={`data[${index}].primary_text`}
                            />
                          </Grid>
                        )}
                        {dataItem.populate_field && (
                          <Grid item xs={12}>
                            <FieldToShow
                              populateFieldName={dataItem.populate_field}
                              dataItem={dataItem}
                              index={index}
                              schema={schema}
                              showType={'secondary_text'}
                              name={`data[${index}].secondary_text`}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Box textAlign={'end'}>
                            <Button
                              size="small"
                              onClick={() => remove(index)}
                              color="error"
                            >
                              Remove populate setting
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                  <Button
                    variant="text"
                    size="small"
                    sx={{ alignSelf: 'flex-start' }}
                    color="primary"
                    onClick={() =>
                      push({
                        populate_field: '',
                        primary_text: { show_field: '', deep: '' },
                        secondary_text: { show_field: '', deep: '' }
                      })
                    }
                  >
                    {values.data.length
                      ? 'Add more populate setting'
                      : 'Add populate setting'}
                  </Button>
                </>
              )}
            />
          </Stack>
        )}
      </Grid>
    </>
  );
};

export default PopulateFields;
