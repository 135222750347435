import {
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { useState } from 'react';
import ImportTable from 'src/components/ImportTable';
const SDCardActions = ({
  searchTerm,
  handleSearchChange,
  handleShowSearchField,
  showSearchField,
  showSearchIcon
}) => {
  const theme = useTheme();
  const [openImportModal, setImportModal] = useState(false);
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleImportModal = () => {
    setImportModal(!openImportModal);
  };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <CustomTextField
          size="small"
          sx={{
            display: showSearchIcon
              ? { xs: 'none', sm: 'block' }
              : { xs: 'flex', sm: 'none' }
          }}
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search Test..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="secondary" />
              </InputAdornment>
            )
          }}
        />
        <IconButton
          color="primary"
          size="small"
          onClick={handleShowSearchField}
          sx={{
            display: { xs: 'block', sm: 'none' }
          }}
        >
          {showSearchField ? (
            <CloseIcon fontSize="small" />
          ) : (
            <SearchIcon fontSize="small" />
          )}
        </IconButton>
        {(!showSearchField || !isXs) && (
          <Tooltip title={`Import File`}>
            <IconButton
              color="primary"
              size="small"
              onClick={handleImportModal}
            >
              <UploadFileOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {openImportModal && (
        <ImportTable
          open={openImportModal}
          handleModal={handleImportModal}
          endPoint={'audit_record'}
          onValidated={(importData) => {
            console.log('importData', importData);
          }}
        />
      )}
    </>
  );
};

export default SDCardActions;
