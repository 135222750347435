import { alpha, createTheme, darken } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { DarkSpacesTheme } from './schemes/DarkSpacesTheme';
import { GreenFieldsTheme } from './schemes/GreenFieldsTheme';
import { GreyGooseTheme } from './schemes/GreyGooseTheme';
import { NebulaFighterTheme } from './schemes/NebulaFighterTheme';
import { PureLightTheme } from './schemes/PureLightTheme';
import { PurpleFlowTheme } from './schemes/PurpleFlowTheme';

const themeMap = {
  PureLightTheme,
  GreyGooseTheme,
  PurpleFlowTheme,
  NebulaFighterTheme,
  DarkSpacesTheme,
  GreenFieldsTheme
};

export function themeCreator(themeName, selectCustomColor) {
  let baseTheme = themeMap[themeName];
  let theme = createTheme(baseTheme);

  // Merge custom color settings
  if (selectCustomColor) {
    theme = createTheme(
      deepmerge(theme, {
        palette: {
          primary: {
            lighter: alpha(selectCustomColor, 0.85),
            light: alpha(selectCustomColor, 0.3),
            main: selectCustomColor,
            dark: darken(selectCustomColor, 0.2)
          }
        }
      })
    );
  }

  return theme;
}
