import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { requestApi } from 'src/mocks/request';
import useRefMounted from 'src/hooks/useRefMounted';

const RootWrapper = styled(Box)(
  () => `
            flex: 1;
        `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader({ currentItem }) {
  const navigate = useNavigate();
  const isMounted = useRefMounted();
  const [isLoading, setLoading] = useState(false);
  const handleCompleteStockAudit = async () => {
    setLoading(true);
    try {
      const payload = {
        endPoint: 'stock_audit',
        data: {
          status: 'completed'
        }
      };
      const response = await requestApi.createData(payload);
      if (response.data) {
        navigate(-1);
      }
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };
  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h3" component="h3" gutterBottom>
                Review Stock Audit
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={0.5}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCompleteStockAudit}
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={'1rem'} />}
            >
              Complete Stock Audit
            </Button>
            <Tooltip title={`Go back`}>
              <IconButtonWrapper onClick={() => navigate(-1)}>
                <ArrowBackTwoToneIcon />
              </IconButtonWrapper>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
