import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import CustomCardWithSearch from 'src/components/CustomCardWithSearch';
import useFetchData from 'src/hooks/useFetchData';
import useSearch from 'src/hooks/useSearch';

const InitialStockViewTable = ({ currentItem }) => {
  const { data, isFetching } = useFetchData({
    endPoint: 'audit_record',
    populate: 'product location',
    query: { audit: currentItem?.id }
  });
  const { searchTerm, handleSearchChange, filteredItems } = useSearch(
    data,
    'product.name'
  );
  if (isFetching) return <Skeleton variant="rounded" height={'30vh'} />;
  return (
    <CustomCardWithSearch
      title={'Stock Details'}
      searchTerm={searchTerm}
      handleSearchChange={handleSearchChange}
    >
      <Table sx={{ minWidth: 700, tableLayout: 'fixed' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{item?.product?.name || '-'}</TableCell>
              <TableCell>{item?.location?.name || '-'}</TableCell>
              <TableCell>{item?.actual_quantity || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CustomCardWithSearch>
  );
};

export default InitialStockViewTable;
