import {
  Badge,
  Box,
  Button,
  Card,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  LabelWarning,
  TableHeadWrapper,
  TableRowDivider,
  TableWrapper
} from 'src/components/Styled';
import { createDataAsync, updateDataAsync } from 'src/slices/dynamicSlice';
import PurchaseRequest from './PurchaseRequest';

const ProductsCard = ({ currentItem }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [PRCart, setPRCart] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentItem?.items) {
      setItems(currentItem.items);
    }
  }, [currentItem]);

  const hasLowStock = useMemo(
    () =>
      items.some((item) => {
        const stockTotal =
          item?.process_status?.stock?.in_hand -
          item?.process_status?.stock?.outward_reserve;
        return stockTotal < item?.process_status?.qty;
      }),
    [items]
  );

  const toggleRequestModal = () => {
    setOpen((prev) => !prev);
  };

  const handleTogglePR = useCallback((item) => {
    setPRCart((prev) => {
      const isAlreadyInCart = prev.some((cartItem) => cartItem.id === item.id);
      return isAlreadyInCart
        ? prev.filter((cartItem) => cartItem.id !== item.id)
        : [...prev, item];
    });
  }, []);

  const handleGenerateWorkOrder = useCallback(async (item) => {
    const data = {
      product: item?.product?.id,
      uom: item?.uom?.id,
      quantity: item?.qty
    };

    const res = await dispatch(
      createDataAsync({ endPoint: 'work_order', data })
    );

    const workOrderId = res?.payload?.id;
    if (!workOrderId) {
      console.error('Invalid response from work order creation');
      return;
    }

    await dispatch(
      updateDataAsync({
        id: item?.process_status?.id,
        endPoint: 'material_processing',
        data: { work_order: workOrderId, status: 'processing' }
      })
    );
  }, []);

  const handleReserveStock = useCallback(async (selectedItem) => {
    const stockTotal =
      selectedItem?.process_status?.stock?.in_hand -
      selectedItem?.process_status?.stock?.outward_reserve;

    if (stockTotal >= selectedItem?.process_status?.qty) {
      await dispatch(
        updateDataAsync({
          id: selectedItem.process_status.id,
          endPoint: 'material_processing',
          data: { status: 'reserved' }
        })
      );
    } else {
      console.error('Not enough stock');
    }
  }, []);

  const renderAction = useCallback(
    (item) => {
      const stockTotal =
        item?.process_status?.stock?.in_hand -
        item?.process_status?.stock?.outward_reserve;

      const isInCart = PRCart.some((cartItem) => cartItem?.id === item?.id);

      if (stockTotal >= item?.process_status?.qty) {
        return (
          <Button
            variant="outlined"
            size="small"
            disabled={['reserved', 'issued'].includes(
              item?.process_status?.status
            )}
            onClick={() => handleReserveStock(item)}
          >
            {['reserved', 'issued'].includes(item?.process_status?.status)
              ? 'Stocks Reserved'
              : 'Reserve'}
          </Button>
        );
      } else {
        if (item?.product?.is_purchased) {
          return (
            <Button
              variant="outlined"
              size="small"
              disabled={
                ['reserved', 'issued'].includes(item?.process_status?.status) ||
                item?.process_status?.purchase_request
              }
              onClick={() => handleTogglePR(item)}
            >
              {item?.process_status?.purchase_request
                ? 'Purchase Requested'
                : isInCart
                ? 'Remove from PR'
                : 'Add to PR'}
            </Button>
          );
        }
        if (item?.product?.is_manufactured) {
          return (
            <Button
              variant="outlined"
              size="small"
              disabled={
                ['reserved', 'issued'].includes(item?.process_status?.status) ||
                item?.process_status?.work_order
              }
              onClick={() => handleGenerateWorkOrder(item)}
            >
              {item?.process_status?.work_order
                ? 'Work Order Generated'
                : 'Generate WO'}
            </Button>
          );
        }
      }

      return (
        <Button variant="outlined" size="small" disabled>
          Not enough stock
        </Button>
      );
    },
    [PRCart, handleTogglePR, handleGenerateWorkOrder, handleReserveStock]
  );

  const tableHeaders = useMemo(
    () => [
      t('Product'),
      t('Quantity'),
      t('Available Stocks'),
      t('Status'),
      t('Action')
    ],
    []
  );

  return (
    <>
      <Card>
        <Box
          px={3}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              gutterBottom
              sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
              variant="h4"
            >
              {t('Products')}
            </Typography>
          </Box>
          {hasLowStock && (
            <Badge badgeContent={PRCart?.length} color="primary">
              <Button
                variant="outlined"
                size="small"
                disabled={PRCart?.length === 0}
                onClick={toggleRequestModal}
              >
                Generate PR
              </Button>
            </Badge>
          )}
        </Box>
        <Box px={3} pb={3}>
          <TableContainer>
            <TableWrapper>
              <TableHeadWrapper>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell key={`${header}_${index}`}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHeadWrapper>
              <TableBody>
                {!items || items?.length === 0 ? (
                  <>No Data</>
                ) : (
                  items?.map((item, index) => (
                    <Fragment key={`${item?.id}_${index}`}>
                      <TableRow hover>
                        <TableCell>
                          <Box>
                            <Link
                              href={`/product/view/${item?.id}`}
                              variant="h5"
                              noWrap
                            >
                              {item?.product?.name}
                            </Link>
                            <Typography
                              variant="subtitle1"
                              fontSize={12}
                              noWrap
                            >
                              {item?.product?.product_code}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <div>
                            <Typography variant="h5" noWrap>
                              {item?.qty} {item?.uom?.name}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <Typography variant="h5" noWrap>
                              {item?.process_status?.stock?.in_hand -
                                item?.process_status?.stock?.outward_reserve ||
                                '-'}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <LabelWarning>
                              {item?.process_status?.status}
                            </LabelWarning>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>{renderAction(item)}</div>
                        </TableCell>
                      </TableRow>
                      <TableRowDivider />
                    </Fragment>
                  ))
                )}
              </TableBody>
            </TableWrapper>
          </TableContainer>
        </Box>
      </Card>

      <PurchaseRequest
        open={open}
        handleModal={toggleRequestModal}
        PRCart={PRCart}
        setPRCart={setPRCart}
      />
    </>
  );
};

export default ProductsCard;
