import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CustomSwitchField from 'src/components/CustomFields/CustomSwitchField';
import LaunchIcon from '@mui/icons-material/Launch';
import DOMPurify from 'dompurify';
import CommonModal from 'src/components/CommonModal.js';
import FileGridView from 'src/components/FileGridView.js';
const sanitizeHtml = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return cleanHtml.replace(/<[^>]*>?/gm, ''); // Remove remaining HTML tags
};
const TQTableRow = ({ item, currentItem }) => {
  console.log('');
  const [procedureDrawer, setProcedureDrawer] = useState(false);
  const [viewAttachModal, setViewAttachModal] = useState(false);
  const { result, note, attachments = [], parameter, is_pass = false } = item;
  const { spec } = currentItem;

  const specifications = useMemo(() => {
    const foundSpec = spec?.specifications?.find(
      (specItem) => specItem?.parameter === parameter
    );
    return foundSpec || {};
  }, [spec, parameter]);

  const { test, exact_value, max_value, min_value, uom } = specifications;
  const specificationsText = () => {
    if (exact_value) {
      return `${exact_value} ${uom?.symbol || ''}`;
    }
    if (min_value && max_value) {
      return `${min_value}-${max_value} ${uom?.symbol || ''}`;
    }
    return '-';
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{parameter || '-'}</TableCell>
        <TableCell>
          {test?.name || '-'}
          {test?.name && (
            <Tooltip title="View Procedures" arrow>
              <IconButton
                color="primary"
                onClick={() => setProcedureDrawer(true)}
              >
                <LaunchIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Typography>{specificationsText()}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{result || '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{sanitizeHtml(note) || '-'}</Typography>
        </TableCell>
        <TableCell>
          <Stack direction={'row'} alignItems={'center'}>
            <Tooltip title={'View Attachments'} arrow>
              <IconButton
                size="small"
                color="primary"
                onClick={() => setViewAttachModal(true)}
              >
                <AttachFileIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {attachments.length > 0 && (
              <Typography variant="body2">{`+${attachments.length}`}</Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <CustomSwitchField checked={is_pass} />
        </TableCell>
      </TableRow>
      <Drawer
        transitionDuration={300}
        anchor="right"
        open={procedureDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '95%', sm: 350 }
          }
        }}
        onClose={() => setProcedureDrawer(false)}
      >
        <Box padding={2}>
          <Typography variant="h6" gutterBottom>
            Test Procedures
          </Typography>
          <Divider />
          <Box marginTop={2}>
            <Typography variant="h5" gutterBottom>
              Title : {test?.name}
            </Typography>
            {test?.procedure ? (
              <Typography variant="body2">
                {sanitizeHtml(test?.procedure)}
              </Typography>
            ) : (
              <Typography variant="body2">No procedure</Typography>
            )}
          </Box>
        </Box>
      </Drawer>
      <CommonModal
        open={viewAttachModal}
        handleModal={() => setViewAttachModal(false)}
        btnName={'Close'}
        maxWidth="xs"
        title={'Attachments'}
      >
        <FileGridView files={attachments} />
      </CommonModal>
    </>
  );
};

export default TQTableRow;
