import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
const ActionSearchField = ({
  searchTerm,
  handleSearchChange,
  handleShowSearchField,
  showSearchField,
  showSearchIcon
}) => {
  return (
    <>
      <CustomTextField
        size="small"
        sx={{
          display: showSearchIcon
            ? { xs: 'none', sm: 'block' }
            : { xs: 'flex', sm: 'none' }
        }}
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search Test..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="secondary" />
            </InputAdornment>
          )
        }}
      />
      <IconButton
        aria-label="search"
        aria-haspopup="true"
        size="small"
        onClick={handleShowSearchField}
        sx={{
          display: { xs: 'block', sm: 'none' }
        }}
      >
        {showSearchField ? (
          <CloseIcon fontSize="small" />
        ) : (
          <SearchIcon fontSize="small" />
        )}
      </IconButton>
    </>
  );
};

export default ActionSearchField;
