import {
  LocationOnRounded,
  MapRounded,
  MyLocationRounded
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  transition: `${theme.transitions.create(['transform', 'background'])}`,
  transform: `scale(1)`,
  transformOrigin: 'center',
  backgroundColor: `${theme.colors.primary.lighter}`,
  color: `${theme.colors.primary.main}`,

  '&:hover': {
    transform: `scale(1.1)`,
    backgroundColor: `${theme.colors.primary.main}`,
    color: `${theme.palette.getContrastText(theme.colors.primary.main)}`
  }
}));

const CustomAddressField = ({ value, setFieldValue, label, name }) => {
  const { t } = useTranslation();
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [openMap, setOpenMap] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);

  const mapRef = useRef();
  const markerRef = useRef(null);

  // Initialize the autocomplete service
  useEffect(() => {
    if (window.google) {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
    }
  }, []);

  // Initialize the map
  useEffect(() => {
    const initializeMap = async () => {
      if (openMap && !mapLoaded) {
        let center = { lat: 20.5937, lng: 78.9629 }; // Default center (India)

        try {
          const response = await axios.get('https://ipapi.co/json/');
          const { latitude, longitude } = response.data;
          center = { lat: latitude, lng: longitude };
        } catch (error) {
          console.error('Error fetching location from IP:', error);
        }

        mapRef.current = new window.google.maps.Map(mapRef.current, {
          center,
          zoom: 12
        });

        mapRef.current.addListener('click', (e) => {
          updateMarkerPosition(e.latLng);
        });

        setMapLoaded(true);
      }
    };

    initializeMap();
  }, [openMap, mapLoaded, mapRef.current]);

  const updateMarkerPosition = (position) => {
    if (!mapLoaded) {
      console.error('Map is not initialized yet.');
      return;
    }

    // Check if the marker exists; if not, create it
    if (markerRef.current) {
      markerRef.current.setPosition(position);
    } else {
      markerRef.current = new window.google.maps.Marker({
        position: position,
        map: mapRef.current
      });
    }

    // Pan the map to the new position
    mapRef.current.panTo(position);
    updateAddressFromPosition(position);
  };

  const updateAddressFromPosition = (position) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const selectedAddress = results[0];

        const address = {
          street_address:
            selectedAddress.address_components.find((ac) =>
              ac.types.includes('street_number')
            )?.long_name || '',
          locality:
            selectedAddress.address_components.find((ac) =>
              ac.types.includes('locality')
            )?.long_name || '',
          city:
            selectedAddress.address_components.find((ac) =>
              ac.types.includes('administrative_area_level_2')
            )?.long_name || '',
          state:
            selectedAddress.address_components.find((ac) =>
              ac.types.includes('administrative_area_level_1')
            )?.long_name || '',
          postal_code:
            selectedAddress.address_components.find((ac) =>
              ac.types.includes('postal_code')
            )?.long_name || '',
          country:
            selectedAddress.address_components.find((ac) =>
              ac.types.includes('country')
            )?.long_name || '',
          formatted_address: selectedAddress.formatted_address || '',
          place_id: selectedAddress.place_id || '',
          plus_code: selectedAddress.plus_code?.global_code || '',
          geocode_status: 'OK'
        };

        // setPlaceDetails(address);
        // setSearchInput(selectedAddress.formatted_address);
        setFieldValue(name, address);
      }
    });
  };

  const handleInputChange = (event, value) => {
    // setSearchInput(value);

    if (autocompleteService && value) {
      autocompleteService.getPlacePredictions(
        { input: value },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(
              predictions.map((prediction) => ({
                formatted_address: prediction.description,
                place_id: prediction.place_id
              }))
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (event, value) => {
    if (value) {
      // setSearchInput(value.formatted_address);

      const div = document.createElement('div');
      const placesService = new window.google.maps.places.PlacesService(div);
      placesService.getDetails({ placeId: value.place_id }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          console.log('place::', place);

          const address = {
            street_address:
              place.address_components.find((ac) =>
                ac.types.includes('street_number')
              )?.long_name || '',
            locality:
              place.address_components.find((ac) =>
                ac.types.includes('locality')
              )?.long_name || '',
            city:
              place.address_components.find((ac) =>
                ac.types.includes('administrative_area_level_2')
              )?.long_name || '',
            state:
              place.address_components.find((ac) =>
                ac.types.includes('administrative_area_level_1')
              )?.long_name || '',
            postal_code:
              place.address_components.find((ac) =>
                ac.types.includes('postal_code')
              )?.long_name || '',
            country:
              place.address_components.find((ac) =>
                ac.types.includes('country')
              )?.long_name || '',
            formatted_address: place.formatted_address || '',
            place_id: place.place_id || '',
            plus_code: place.plus_code?.global_code || '',
            geocode_status: place.geometry?.location ? 'OK' : 'ZERO_RESULTS'
          };

          // setPlaceDetails(address);
          setFieldValue(name, address);

          // Update marker position on map
          const location = place.geometry?.location;
          if (location) {
            updateMarkerPosition(location);
          }
        }
      });
    }
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentPosition = { lat: latitude, lng: longitude };
          updateMarkerPosition(currentPosition);
          setMapLoaded(true);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert(
                'Location access denied. Please enable location services in your browser settings and try again.'
              );
              break;
            case error.POSITION_UNAVAILABLE:
              alert('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              alert('The request to get user location timed out.');
              break;
            default:
              alert(
                'An unknown error occurred while retrieving your location.'
              );
              break;
          }
          console.error('Error fetching current location:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000, // 10 seconds
          maximumAge: 0
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <AddressAutocomplete
          value={value}
          suggestions={suggestions}
          handleInputChange={handleInputChange}
          handleSuggestionSelect={handleSuggestionSelect}
          label={label}
          name={name}
        />

        <Tooltip title={t('Map')} arrow>
          <IconButtonWrapper onClick={() => setOpenMap(true)}>
            <MapRounded />
          </IconButtonWrapper>
        </Tooltip>
      </Stack>
      <Dialog
        keepMounted
        open={openMap}
        onClose={() => setOpenMap(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h4" gutterBottom>
            Pin Location on Map
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 0 }}>
          {!mapLoaded && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="400px"
              animation="wave"
            />
          )}
          <Box
            ref={mapRef}
            sx={{ height: mapLoaded ? '400px' : 'auto', width: '100%' }}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 1 }}>
          <Tooltip title={t('Get current location')} arrow>
            <IconButton onClick={handleGetCurrentLocation}>
              <MyLocationRounded />
            </IconButton>
          </Tooltip>
          <AddressAutocomplete
            value={value}
            suggestions={suggestions}
            handleInputChange={handleInputChange}
            handleSuggestionSelect={handleSuggestionSelect}
            label={label}
            name={name}
          />
          <Button onClick={() => setOpenMap(false)} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomAddressField;

const AddressAutocomplete = ({
  value,
  suggestions,
  handleInputChange,
  handleSuggestionSelect,
  label,
  name,
  size
}) => {
  return (
    <Autocomplete
      fullWidth
      value={value}
      options={suggestions}
      getOptionLabel={(option) => {
        if (typeof option === 'object' && option.formatted_address) {
          return option?.formatted_address;
        }
        return '';
      }}
      onInputChange={handleInputChange}
      onChange={handleSuggestionSelect}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionKey={(option) => option?.id}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          size="small"
          label={label}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <LocationOnRounded fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.primary">
            {option?.formatted_address}
          </Typography>
        </Box>
      )}
    />
  );
};
