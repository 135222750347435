// todo chips overflow

import { Chip } from '@mui/material';
import React, { useState } from 'react';
import CustomTextField from './CustomTextField';

const FlatArrayField = ({
  error,
  touched,
  value,
  onChange,
  name,
  label,
  onBlur = () => {},
  disabled = false,
  required = false,
  size = 'medium',
  placeholder = ''
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      onChange([...value, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleChipDelete = (chipIndex) => {
    const allChips = [...value];
    allChips.splice(chipIndex, 1);
    onChange(allChips);
  };
  const InputProps = {
    startAdornment:
      value.length > 0 &&
      value.map((chip, chipIndex) => (
        <Chip
          key={chipIndex}
          label={chip}
          onDelete={() => handleChipDelete(chipIndex)}
          sx={{ margin: '4px' }}
          size="small"
        />
      ))
  };

  return (
    <CustomTextField
      name={name}
      label={label}
      value={inputValue}
      size={size}
      placeholder={placeholder}
      fullWidth={true}
      onChange={handleInputChange}
      onBlur={onBlur}
      InputProps={InputProps}
      disabled={disabled}
      touched={touched}
      error={error}
      required={required}
      onKeyDown={handleInputKeyDown}
    />
  );
};

export default FlatArrayField;
