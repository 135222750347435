import Scheduler from './components/DynamicEntityForm/WorkOrderForm/FormBody/Scheduler';
import JobCardsTimeLine from './components/JobCardsTimeLine';

// ================================= Schedule Timeline End ==============================

const Test = () => {
  return (
    <>
      <JobCardsTimeLine />
      {/* <Scheduler /> */}
    </>
  );
};

export default Test;
