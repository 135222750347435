import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { getFieldType } from 'src/services/commonService';

const getEndPoint = (schema, key) => {
  if (!schema || !key) return null;

  const keyName = key.includes('.') ? key.split('.')[0] : key;
  const { isArrayWithMultipleProperties, arrayWithMulProSchema } = getFieldType(
    keyName,
    schema
  );

  if (isArrayWithMultipleProperties) {
    const subKey = key.split('.')[1];
    return (
      arrayWithMulProSchema?.[subKey]?.options?.ref ||
      arrayWithMulProSchema?.[subKey]?.$embeddedSchemaType?.options?.ref
    );
  }

  return (
    schema?.[keyName]?.options?.ref ||
    schema?.[keyName]?.$embeddedSchemaType?.options?.ref ||
    null
  );
};

const FieldToShow = ({
  schema,
  populateFieldName,
  dataItem,
  index,
  name,
  showType
}) => {
  const { setFieldValue, handleBlur, touched, errors } = useFormikContext();
  const { schema: refSchema } = useEntityPageConfig(
    getEndPoint(schema, populateFieldName)
  );
  const [deepEntity, setDeepEntity] = useState(
    getEndPoint(refSchema, dataItem[showType]?.show_field)
  );

  const { schema: deepSchema } = useEntityPageConfig(deepEntity);

  useEffect(() => {
    const updatedDeepEntity = getEndPoint(
      refSchema,
      dataItem[showType]?.show_field
    );
    setFieldValue(`${name}.deep`, '');
    setDeepEntity(updatedDeepEntity);
  }, [dataItem[showType]?.show_field, refSchema]);

  const allFieldShow = Object.keys(refSchema || {});
  if (!allFieldShow.length) return null;

  return (
    <Card variant="outlined">
      <CardHeader title={showType} />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomSelectField
              value={dataItem[showType]?.show_field || ''}
              name={`${name}.show_field`}
              label="Show Field"
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue(`${name}.show_field`, e.target.value)
              }
              options={allFieldShow}
              touched={touched?.data?.[index]?.[showType]?.show_field}
              error={errors?.data?.[index]?.[showType]?.show_field}
            />
          </Grid>

          {dataItem[showType]?.show_field && deepSchema && (
            <Grid item xs={12} md={6}>
              <CustomSelectField
                value={dataItem[showType]?.deep || ''}
                name={`${name}.deep`}
                label="Deep Field"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue(`${name}.deep`, e.target.value)}
                options={Object.keys(deepSchema)}
                touched={touched?.data?.[index]?.[showType]?.deep}
                error={errors?.data?.[index]?.[showType]?.deep}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FieldToShow;
