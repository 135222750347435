import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useState, useCallback } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import ActionSearchField from './ActionSearchField';

const CustomCardWithSearch = ({
  title,
  searchTerm,
  handleSearchChange,
  children
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const [showSearchField, setShowSearchField] = useState(false);

  const handleShowSearchField = useCallback(() => {
    setShowSearchField((prevState) => !prevState);
  }, []);

  return (
    <Card variant="outlined">
      {(!showSearchField || !isXs) && (
        <CardHeader
          action={
            <ActionSearchField
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              handleShowSearchField={handleShowSearchField}
              showSearchField={showSearchField}
              showSearchIcon={true}
            />
          }
          title={title}
        />
      )}

      {isXs && showSearchField && (
        <Stack
          p={1}
          direction="row"
          spacing={0.5}
          alignItems="center"
          sx={{ display: 'flex' }}
        >
          <ActionSearchField
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            handleShowSearchField={handleShowSearchField}
            showSearchField={showSearchField}
            showSearchIcon={false}
          />
        </Stack>
      )}

      <Divider />

      <CardContent>
        <Scrollbar maxHeight="auto">{children}</Scrollbar>
      </CardContent>
    </Card>
  );
};

export default CustomCardWithSearch;
