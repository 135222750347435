import { Grid, Button } from '@mui/material';
import DTAutocomplete from 'src/components/GetFieldBasedOnSchema/DTAutocomplete';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import FieldWithMsg from './FieldWithMsg';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { requestApi } from 'src/mocks/request';
import useRefMounted from 'src/hooks/useRefMounted';

const ProductFields = ({
  currentItem,
  product: productObj,
  proIdx,
  remove
}) => {
  const { product = '', location = '', system_quantity = '' } = productObj;
  const isMounted = useRefMounted();
  const { handleChange, setFieldValue } = useFormikContext();
  const { schema, entitySetting } = useEntityPageConfig('audit_record');
  const fetchData = useCallback(async () => {
    if (!location || !product) return;
    const payload = {
      endPoint: 'stock_location',
      query: {
        warehouse: currentItem?.warehouse?.id,
        warehouse_location: location?.id,
        product: product?.id
      }
    };
    const response = await requestApi.getData(payload);
    const resData = response?.data?.data;

    if (resData?.length && isMounted.current) {
      setFieldValue(
        `products[${proIdx}].system_quantity`,
        resData[0]?.quantity
      );
    } else {
      setFieldValue(`products[${proIdx}].system_quantity`, '');
    }
  }, [isMounted, product, location]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <Grid
      container
      spacing={1}
      border={1}
      p={1}
      borderColor="divider"
      borderRadius={1}
    >
      <Grid item xs={12} sm={6} md={3}>
        <DTAutocomplete
          label="Product"
          size="small"
          fieldName="product"
          value={product}
          schema={schema}
          onChange={(value) =>
            setFieldValue(`products[${proIdx}].product`, value)
          }
          entitySetting={entitySetting}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DTAutocomplete
          label="Location"
          size="small"
          fieldName="location"
          value={location}
          schema={schema}
          onChange={(value) =>
            setFieldValue(`products[${proIdx}].location`, value)
          }
          entitySetting={entitySetting}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CustomTextField
          label="Current"
          size="small"
          readOnly
          name={`products[${proIdx}].system_quantity`}
          value={system_quantity}
          onChange={handleChange}
          inputProps={{ type: 'number' }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FieldWithMsg product={productObj} path={`products[${proIdx}]`} />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button color="error" size="small" onClick={() => remove(proIdx)}>
          Remove Product
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductFields;
