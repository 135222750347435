import { Box, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import CustomPopover from 'src/components/CustomPopover';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import { useFormikContext } from 'formik';
import CustomTextField from 'src/components/CustomFields/CustomTextField';

const isContentEmpty = (content) => {
  if (!content) return true;
  const strippedContent = content.replace(/<[^>]*>/g, '').trim();
  return strippedContent === '';
};
const FieldWithMsg = ({ product, path }) => {
  const { reason = '', actual_quantity = '' } = product;
  const { setFieldValue, handleChange } = useFormikContext();
  const [messageBox, setMessageBox] = useState(null);
  const handleMessageBox = (event) => {
    setMessageBox((prev) => (!prev ? event.currentTarget : null));
  };
  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <CustomTextField
          label={'Actual'}
          size="small"
          name={`${path}.actual_quantity`}
          value={actual_quantity}
          onChange={handleChange}
          inputProps={{ type: 'number' }}
        />
        <IconButton
          onClick={handleMessageBox}
          size="small"
          color={isContentEmpty(reason) ? 'secondary' : 'primary'}
          sx={{ p: 0 }}
        >
          {isContentEmpty(reason) ? (
            <ChatBubbleOutlineOutlinedIcon fontSize="small" />
          ) : (
            <ChatIcon fontSize="small" />
          )}
        </IconButton>
      </Stack>
      <CustomPopover
        anchorEl={messageBox}
        open={Boolean(messageBox)}
        onClose={handleMessageBox}
      >
        <Box p={2}>
          <QuillEditorField
            label={'Reason'}
            placeholder={'Any reason about the deviation in stock'}
            onChange={(value) => setFieldValue(`${path}.reason`, value)}
            value={reason}
          />
        </Box>
      </CustomPopover>
    </>
  );
};

export default FieldWithMsg;
