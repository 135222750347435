import React, { useState } from 'react';
import { List, ListItem, ListItemText, Button, Box, Chip } from '@mui/material';
import CustomPopover from 'src/components/CustomPopover';
import Scrollbar from 'src/components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import useLinkGenerator from 'src/hooks/useLinkGenerator';

const getChipLabel = ({ item, fieldName, parentFieldName, entitySetting }) => {
  const foundData = entitySetting?.populate_fields?.find(
    (populateFieldItem) =>
      populateFieldItem?.populate_field ===
      (parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName)
  );

  if (foundData) {
    const showField = foundData?.primary_text?.show_field;
    const deepField = foundData?.primary_text?.deep;

    // If there's a deep field, retrieve the label from the nested object
    if (deepField) {
      return item?.[showField]?.[deepField] || item['id'];
    }
    return item?.[showField] || item['id'];
  }

  // Fallback to 'id' if no matching field is found
  return item['id'];
};

const ArrayWithObjectIDCell = ({
  value,
  fieldName,
  entitySetting,
  schema,
  props,
  parentFieldName
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const generateLink = useLinkGenerator();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {value.slice(0, 2).map((item, index) => {
        let label = getChipLabel({
          item,
          fieldName,
          parentFieldName,
          entitySetting
        });
        if (parentFieldName) {
          return <Chip label={label} key={index} {...props} />;
        }
        return (
          <Chip
            component={RouterLink}
            to={generateLink(
              schema[fieldName]?.$embeddedSchemaType?.options?.ref,
              'view',
              item.id
            )}
            label={label}
            clickable
            key={index}
            {...props}
          />
        );
      })}
      {value.length > 3 && (
        <>
          <Button
            color="primary"
            size="small"
            style={{ minWidth: 'auto', padding: '4px' }}
            onClick={handlePopoverOpen}
          >
            +{value.length - 2}
          </Button>
          <CustomPopover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handlePopoverClose}
          >
            <Box sx={{ maxWidth: 300 }}>
              <Scrollbar maxHeight={300}>
                <List>
                  {value.map((item, index) => {
                    let label = getChipLabel({
                      item,
                      fieldName,
                      entitySetting
                    });
                    return (
                      <ListItem key={index}>
                        <ListItemText primary={label} />
                      </ListItem>
                    );
                  })}
                </List>
              </Scrollbar>
            </Box>
          </CustomPopover>
        </>
      )}
    </>
  );
};

export default ArrayWithObjectIDCell;
