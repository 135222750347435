import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  useTheme
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Icon for Word and Excel
import TableChartIcon from '@mui/icons-material/TableChart';
import ImageIcon from '@mui/icons-material/Image';

// Function to determine the icon based on file extension
const getFileTypeIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();

  switch (extension) {
    case 'pdf':
      return PictureAsPdfIcon; // Icon for PDF files
    case 'doc':
    case 'docx':
      return InsertDriveFileIcon; // Icon for Word documents
    case 'xls':
    case 'xlsx':
      return InsertDriveFileIcon; // Icon for Excel files
    case 'csv':
      return TableChartIcon; // Icon for CSV files
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'svg':
      return ImageIcon; // Icon for image files
    default:
      return AttachFileIcon; // Default icon for other file types
  }
};

const FileGrid = ({ file, handleFileDelete, loading, index }) => {
  const theme = useTheme();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const FileIcon = getFileTypeIcon(file?.file?.name || file?.name); // Determine icon based on file type

  return (
    <Stack
      border={1}
      p={1}
      width={60}
      height={60}
      position={'relative'}
      alignItems={'center'}
      justifyContent={'center'}
      borderColor={theme.palette.divider}
      borderRadius={0.5}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <FileIcon sx={{ fontSize: 40 }} />
      {/* <Typography variant="caption" align="center">
          {file?.file?.name || file?.name}
        </Typography> */}
      {/* {file?.upload && (
        <Backdrop
          sx={{
            position: 'absolute',
            borderRadius: 0.5,
            top: 0,
            left: 0,
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={file.upload}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
      )} */}
      <IconButton
        size="small"
        aria-label={`delete file ${index + 1}`}
        onClick={() => handleFileDelete(file, index)}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10000,
          display: hoveredIndex === index && !loading ? 'block' : 'none'
        }}
      >
        <DeleteIcon color="error" fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default FileGrid;
