import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import SettingDynamicField from '../settingDynamicField';
import {
  cleanMessageLog,
  selectMessage,
  selectStatus
} from 'src/slices/settingSlice';
import { useDispatch } from 'react-redux';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
import { useSelector } from 'react-redux';
import settingsValidationSchema from 'src/services/validationService';
import { toast } from 'react-toastify';
import DialogActionBody from 'src/components/DialogActionBody';
import { handleSubmitForm } from 'src/services/settingService';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const company = ['company_entity_settings', 'company_general_settings'];
const SettingForm = ({
  handleDialog,
  selectedRowData = null,
  selectedEntity
}) => {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const [settingSchema, setSettingSchema] = useState(null);
  const { success, error, ref } = useSelector(selectMessage);
  const isMountedRef = useRefMounted();
  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const { data, status, message } = await requestApi.getSchema({
          endPoint: 'setting'
        });
        if (status === 200) {
          const { entity, setting_type } = data;
          console.log(data, 'data');
          if (isMountedRef.current) {
            setSettingSchema({
              entityName: entity.enumValues,
              setting_type: setting_type.enumValues
            });
          }
        } else {
          console.error(message);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchSchema();
  }, []);
  useEffect(() => {
    if (success) {
      handleDialog();
      dispatch(cleanMessageLog());
    }
    if (error) {
      dispatch(cleanMessageLog());
    }
  }, [success, error, ref, dispatch]);
  return (
    <>
      {settingSchema && (
        <>
          <DialogTitle
            sx={{
              p: 3,
              mt: 3,
              textAlign: 'center'
            }}
          >
            <Typography variant="h3" gutterBottom>
              {selectedRowData ? 'Update' : 'Add New'} Setting
            </Typography>
            <Typography variant="subtitle2">
              Fill in the fields below to{' '}
              {selectedRowData ? 'update a' : 'create a new'} setting to the
              site
            </Typography>
          </DialogTitle>
          <Formik
            initialValues={{
              entity: selectedRowData
                ? selectedRowData.entity
                : selectedEntity
                ? selectedEntity
                : '',
              setting_type: selectedRowData ? selectedRowData.setting_type : '',
              companyGeneralSettings:
                selectedRowData && !Array.isArray(selectedRowData.data)
                  ? selectedRowData.data
                  : {},
              data:
                selectedRowData && Array.isArray(selectedRowData.data)
                  ? selectedRowData.data
                  : []
            }}
            validationSchema={settingsValidationSchema}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              console.log(values, 'values');
              await handleSubmitForm({
                dispatch,
                selectedRowData,
                values,
                resetForm,
                setStatus,
                setSubmitting,
                setErrors,
                isMountedRef,
                toast,
                selectedEntity
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldError,
              setFieldTouched,
              setFieldValue,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container justifyContent={'center'}>
                    <Grid item xs={12} lg={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <CustomSelectField
                            label="Entity"
                            value={values.entity}
                            options={settingSchema?.entityName}
                            error={errors.entity}
                            touched={touched.entity}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue('setting_type', ''); // Clear setting type when entity changes
                            }}
                            onBlur={handleBlur}
                            name="entity"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomSelectField
                            label="Setting Type"
                            value={values.setting_type}
                            options={
                              values.entity === 'COMPANY'
                                ? settingSchema?.setting_type.filter(
                                    (settingName) =>
                                      company.includes(settingName)
                                  )
                                : values.entity === ''
                                ? settingSchema?.setting_type
                                : settingSchema?.setting_type.filter(
                                    (settingName) =>
                                      !company.includes(settingName)
                                  )
                            }
                            error={errors.setting_type}
                            touched={touched.setting_type}
                            onChange={(e) => {
                              if (values.data.length > 0) {
                                setFieldValue('data', []);
                              }
                              setFieldError('data', undefined);
                              setFieldTouched('data', false);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            name="setting_type"
                          />
                        </Grid>
                        {values.entity !== '' && values.setting_type !== '' && (
                          <SettingDynamicField
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            endPoint={values.entity}
                            setting_type={values.setting_type}
                            handleChange={handleChange}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActionBody
                  handleDialog={handleDialog}
                  isUpdatingOrCreating={
                    selectedRowData
                      ? status.updateSetting
                      : status.createSetting
                  }
                  selectedRowData={selectedRowData}
                />
              </form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default SettingForm;
