import * as React from 'react';
import { IconButton, Stack, TableCell } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { useTheme } from '@mui/material';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import CustomMenu from 'src/components/CustomMenu';
import DynamicButton from './DynamicButton';
import StandardAction from './StandardAction';

const ActionTableCell = ({ row }) => {
  const theme = useTheme();
  const { entitySetting } = useSelector(selectDynamicPageConfig);
  const [menuOpen, setMenuOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setMenuOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuOpen(null);
  };

  const rowActions =
    entitySetting?.actions?.filter(
      (action) => action.actionLocation === 'more'
    ) || [];

  const pinActions =
    entitySetting?.actions?.filter(
      (action) => action.actionLocation === 'pin'
    ) || [];

  console.log(rowActions, 'rowActions');

  return (
    <>
      <TableCell
        sx={{
          position: 'sticky',
          right: 0,
          zIndex: 10,
          bgcolor:
            theme.palette.mode === 'light'
              ? 'white'
              : theme.palette.background.default
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          {pinActions.map((action, actIndex) => (
            <DynamicButton
              key={`action_${actIndex}`}
              action={action}
              row={row}
            />
          ))}
          <IconButton
            size="small"
            color="primary"
            id="action-option-manu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Stack>
        <CustomMenu anchorEl={menuOpen} handleClose={handleCloseMenu}>
          <StandardAction row={row} />
          {rowActions.map((action, actIndex) => (
            <DynamicButton
              key={`action_${actIndex}`}
              action={action}
              row={row}
            />
          ))}
        </CustomMenu>
      </TableCell>
    </>
  );
};
export default ActionTableCell;
