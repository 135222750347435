import React from 'react';
import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

export default function InsertionSummary({
  isSummaryDialogOpen,
  setIsSummaryDialogOpen,
  errorMessage
}) {
  const resultObj = errorMessage?.response?.data?.data?.result || null;
  const theme = useTheme();

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    border: 'none'
  };

  const errorStyle = {
    color: theme.palette.error.main
  };
  return (
    <Modal
      disableAutoFocus
      open={isSummaryDialogOpen}
      onClose={() => setIsSummaryDialogOpen(!isSummaryDialogOpen)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Paper variant="outlined" sx={{ p: 4 }}>
          <Typography variant="h4" mb={3} textAlign="center">
            Insertion Summary
          </Typography>
          <Stack spacing={2}>
            <Typography variant="body1">
              Successful Insertions: {resultObj?.nInserted || 12}
            </Typography>
            <Typography variant="body1">
              Errors Encountered: {resultObj?.writeErrors?.length || 0}
            </Typography>
            {resultObj?.writeErrors?.length > 0 && (
              <>
                <Typography variant="body1" sx={errorStyle}>
                  Errors:
                </Typography>
                {resultObj?.writeErrors.map((errorMessage, index) => (
                  <Typography key={index} variant="body2" sx={errorStyle}>
                    - {errorMessage.errmsg}
                  </Typography>
                ))}
              </>
            )}
            <Divider />
            <Button
              onClick={() => setIsSummaryDialogOpen(!isSummaryDialogOpen)}
              variant="contained"
              // sx={{ alignSelf: 'center' }}
            >
              Ok
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Modal>
  );
}
