import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InfoOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';

import { IconButtonWrapper } from 'src/components/Styled';
import JobCard from './JobCard';
import JobCardSuggestions from './JobCardSuggestions';
import EditJobCard from './EditJobCard';
import Scrollbar from 'src/components/Scrollbar';
import CreateJobCards from './CreateJobCards';
import { updateBulkDataAsync } from 'src/slices/dynamicSlice';

const OperationsNew = ({ currentItem: initialCurrentItem }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [currentItem, setCurrentItem] = useState(null);
  const [schedule, setSchedule] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [createJobOpen, setCreateJobOpen] = useState(false);
  const [editJobCard, setEditJobCard] = useState(null);
  const [selectedJobCard, setSelectedJobCard] = useState(null);
  const [selectedOperationJobCards, setSelectedOperationJobCards] = useState(
    []
  );

  useEffect(() => {
    setCurrentItem(initialCurrentItem);
  }, []);

  const allJobCards = useMemo(
    () => currentItem?.operations?.flatMap((op) => op.job_cards),
    [currentItem?.operations]
  );

  // Extract all job cards and group them by their date (formatted as "D MMM")
  const jobCardsByDate = useMemo(() => {
    const jobCardsMap = {};
    currentItem?.operations?.forEach((item) => {
      item?.job_cards?.forEach((jobCard) => {
        const scheduledDate =
          jobCard?.scheduled_start && moment(jobCard?.scheduled_start);
        const jobCardDate = scheduledDate?.isValid()
          ? scheduledDate.format('D MMM')
          : 'Invalid Date';

        if (!jobCardsMap[jobCardDate]) jobCardsMap[jobCardDate] = {};

        const operationId = item?.operation?.id || 'unknown_operation';
        if (!jobCardsMap[jobCardDate][operationId])
          jobCardsMap[jobCardDate][operationId] = [];

        jobCardsMap[jobCardDate][operationId].push(jobCard);
      });
    });
    return jobCardsMap;
  }, [currentItem, editJobCard]);

  // Get a sorted list of dates
  const sortedDates = useMemo(
    () =>
      Object?.keys(jobCardsByDate)?.sort((a, b) => {
        const dateA = moment(a, 'D MMM').isValid()
          ? moment(a, 'D MMM')
          : moment.invalid();
        const dateB = moment(b, 'D MMM').isValid()
          ? moment(b, 'D MMM')
          : moment.invalid();
        return dateA.isValid() && dateB.isValid() ? dateA - dateB : 0;
      }),
    [jobCardsByDate]
  );

  const handleScheduleModal = () => {
    setSchedule((prev) => !prev);
  };

  const handleEditJobCardModal = () => {
    setEditJobCard((prev) => !prev);
  };

  const handleEditJobCard = (jobCard) => {
    setSelectedJobCard(jobCard);
    handleEditJobCardModal();
  };

  const handleScheduleJobCard = (item) => {
    setSelectedOperationJobCards(item?.job_cards);
    setSelectedOperation(item);
    handleScheduleModal();
  };

  const handleCreateJobOpen = () => {
    setCreateJobOpen((prev) => !prev);
  };

  const handleCreateJobCard = () => {
    setCreateJobOpen(true);
  };

  const handleConfirmAll = () => {
    setCurrentItem((prev) => {
      const updatedItem = prev;
      updatedItem?.operations?.forEach((op) => {
        op?.job_cards?.forEach((jobCard) => {
          jobCard.isActive = true;
        });
      });

      return updatedItem;
    });
    const payload = {
      data: { isActive: true },
      filter: {
        _id: { $in: [...allJobCards.map((jobCard) => jobCard.id)] }
      }
    };
    if (!currentItem?.start_date) return;
    dispatch(updateBulkDataAsync({ endPoint: 'job_card', data: payload }));
  };

  console.log('currentItem', currentItem);
  console.log('jobCardsByDate', jobCardsByDate);
  console.log('allJobCards', allJobCards);

  return (
    <Card sx={{ p: 2, height: '100%' }}>
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Box>
          <Typography
            gutterBottom
            sx={{
              fontSize: `${theme.typography.pxToRem(16)}`,
              display: 'inline-block'
            }}
            variant="h4"
          >
            {t('Operations')}
          </Typography>
          <Tooltip
            arrow
            title={t(
              'operations can be sequential as well as parallel, that will depend on method of manufacturing'
            )}
            placement="right"
          >
            <IconButtonWrapper
              sx={{
                ml: 0.5
              }}
            >
              <InfoOutlined fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
        </Box>
        {(!allJobCards || allJobCards?.length <= 0) && (
          <Button variant="outlined" size="small" onClick={handleCreateJobCard}>
            Create Job Cards
          </Button>
        )}
        {allJobCards?.some((jobCard) => !jobCard.isActive) && (
          <Button variant="outlined" size="small" onClick={handleConfirmAll}>
            Confirm All Job Cards
          </Button>
        )}
      </Box>
      <TableContainer
        sx={{
          background: theme.colors.alpha.black[10],
          borderRadius: theme.general.borderRadius,
          width: '100%',
          height: 500,
          maxHeight: '70vh'
        }}
      >
        <Scrollbar>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {/* Date column */}
                <TableCell></TableCell>
                {currentItem?.operations?.length > 0 ? (
                  currentItem?.operations?.map((item, idx) => (
                    <TableCell
                      key={`${item?.id}_${idx}_O`}
                      sx={{ minWidth: 300 }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Typography variant="h5" noWrap>
                          {item?.operation?.name || 'unknown operation'}
                        </Typography>
                        {item?.job_cards?.some(
                          (jobCard) =>
                            // currently operation is id and not populated
                            (jobCard?.operation || jobCard?.operation?.id) &&
                            item?.operation?.id &&
                            (jobCard?.operation || jobCard?.operation?.id) ===
                              item?.operation?.id &&
                            !jobCard.isActive
                        ) && (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ ml: 1 }}
                            onClick={() => handleScheduleJobCard(item)}
                          >
                            confirm
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                  ))
                ) : (
                  <TableCell colSpan={12}>No Operations</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Step 2: Render rows based on the sorted dates */}
              {sortedDates?.map((date) => (
                <TableRow key={date}>
                  {/* Date column */}
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      borderRight: `1px solid ${theme.palette.divider}`
                    }}
                  >
                    <Typography variant="subtitle1" noWrap>
                      {date || '-'}
                    </Typography>
                  </TableCell>

                  {/* Render job cards in their respective operation columns */}
                  {currentItem?.operations?.map((item) => (
                    <TableCell
                      key={item?.operation?.id}
                      sx={{ verticalAlign: 'top' }}
                    >
                      <Stack spacing={1}>
                        {jobCardsByDate[date] &&
                        jobCardsByDate[date][item?.operation?.id] ? (
                          jobCardsByDate[date][item?.operation?.id]?.map(
                            (jobCard, index) => (
                              <JobCard
                                key={index}
                                jobCard={jobCard}
                                onClick={() => handleEditJobCard(jobCard)}
                              />
                            )
                          )
                        ) : (
                          <></> // Empty cell if no job cards for this operation on this date
                        )}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      {createJobOpen && (
        <CreateJobCards
          open={createJobOpen}
          handleModal={handleCreateJobOpen}
          currentItem={currentItem}
        />
      )}

      {schedule && (
        <JobCardSuggestions
          open={schedule}
          selectedOperation={selectedOperation}
          selectedOperationJobCards={selectedOperationJobCards}
          handleModal={handleScheduleModal}
          currentItem={currentItem}
        />
      )}

      {editJobCard && (
        <EditJobCard
          open={editJobCard}
          handleModal={handleEditJobCardModal}
          selectedJobCard={selectedJobCard}
          setSelectedJobCard={setSelectedJobCard}
          setCurrentItem={setCurrentItem}
          currentItem={currentItem}
        />
      )}
    </Card>
  );
};

export default OperationsNew;
