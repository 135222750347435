import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import CustomPopover from 'src/components/CustomPopover';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import { useFormikContext } from 'formik';
import FieldByValueType from './FieldByValueType';
import FileUpload from 'src/components/FileUpload';

const isContentEmpty = (content) => {
  if (!content) return true;
  // Strip common HTML tags and check if the remaining text is empty
  const strippedContent = content.replace(/<[^>]*>/g, '').trim();
  return strippedContent === '';
};
const ReasultBox = ({ item, path, specifications }) => {
  const { result = '', note = '', attachments = [] } = item;
  console.log(attachments, 'attachBox');
  const { setFieldValue } = useFormikContext();
  const [messageBox, setMessageBox] = useState(null);
  const [attachBox, setAttachBox] = useState(null);
  const handleMessageBox = (event) => {
    setMessageBox((prev) => (!prev ? event.currentTarget : null));
  };
  const handleAttachBox = (event) => {
    setAttachBox((prev) => (!prev ? event.currentTarget : null));
  };
  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <FieldByValueType
          specifications={specifications}
          result={result}
          path={`${path}.result`}
        />
        <IconButton
          onClick={handleMessageBox}
          size="small"
          color={isContentEmpty(note) ? 'secondary' : 'primary'}
          sx={{ p: 0 }}
        >
          {isContentEmpty(note) ? (
            <ChatBubbleOutlineOutlinedIcon fontSize="small" />
          ) : (
            <ChatIcon fontSize="small" />
          )}
        </IconButton>
        <IconButton
          onClick={handleAttachBox}
          size="small"
          color={attachments?.length ? 'primary' : 'secondary'}
          sx={{ p: 0 }}
        >
          <AttachFileIcon fontSize="small" />
        </IconButton>
      </Stack>
      <CustomPopover
        anchorEl={messageBox}
        open={Boolean(messageBox)}
        onClose={handleMessageBox}
      >
        <Box p={2}>
          <QuillEditorField
            label={'Test Insights'}
            placeholder={'Any Insights about the test'}
            onChange={(value) => setFieldValue(`${path}.note`, value)}
            value={note || ''}
          />
        </Box>
      </CustomPopover>
      <CustomPopover
        anchorEl={attachBox}
        open={Boolean(attachBox)}
        onClose={handleAttachBox}
      >
        <Stack spacing={1} p={2} maxWidth={400}>
          <Typography>Attachments</Typography>
          <FileUpload
            value={attachments}
            path={`${path}.attachments`}
            isImage={false}
          />
        </Stack>
      </CustomPopover>
    </>
  );
};

export default ReasultBox;
