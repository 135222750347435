import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { getFieldType } from 'src/services/commonService';
import { structureSchema } from 'src/utils/structureSchema';
const getMdSize = (fieldname, schema, size) => {
  const { isStringHasMaxNull, isObject, isArrayWithMultipleProperties } =
    getFieldType(fieldname, schema);
  if (isArrayWithMultipleProperties || isObject || isStringHasMaxNull)
    return 12;
  return size;
};

const SocialMedia = ({ schema, entitySetting, fieldName }) => {
  const { values, handleChange } = useFormikContext();
  const companySchema = schema
    ? structureSchema(schema?.[fieldName]?.schema?.paths)
    : null;
  const socialMediaSchema = companySchema?.['socialMedia']?.schema || {};
  let socialMediaKeys = Object.keys(socialMediaSchema) || [];

  return (
    <>
      <Grid container spacing={2}>
        {socialMediaKeys?.length > 0 &&
          socialMediaKeys.map((field, index) => (
            <Grid
              item
              xs={12}
              md={getMdSize(field, socialMediaSchema, 4)}
              sm={getMdSize(field, socialMediaSchema, 6)}
              key={`${field}_${index}`}
            >
              <GetFieldBasedOnSchema
                name={`${fieldName}.socialMedia.${field}`}
                fieldName={field}
                schema={socialMediaSchema}
                value={values?.[fieldName]?.socialMedia?.[field] || ''}
                // error={errors?.[dependentKey]?.socialMedia?.[field]}
                // touched={touched?.[dependentKey]?.socialMedia?.[field]}
              />
            </Grid>
          ))}
        <Grid item xs={12} sm={6} md={4}>
          <CustomTextField
            label={'Website'}
            size="small"
            name={`${fieldName}.website`}
            value={values?.[fieldName]?.website || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SocialMedia;
