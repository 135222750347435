import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import * as Icons from '@mui/icons-material';
import { selectStatus } from 'src/slices/dynamicSlice';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useSelector } from 'react-redux';
import useCustomActFuc from 'src/hooks/useCustomActFuc';
import useLinkGenerator from 'src/hooks/useLinkGenerator';

const DynamicButton = ({ action, row }) => {
  const navigate = useNavigate();
  const generateLink = useLinkGenerator();
  const status = useSelector(selectStatus);
  const [confirmationModal, setConfirmationModal] = useState({});
  const { id: rowId } = row;
  const {
    actionType,
    routeOrCustomFun,
    routeType,
    targetEntity,
    actionLocation,
    actionName,
    icon,
    buttonOrIcon,
    buttonState,
    conditions
  } = action;

  const { handleApiAction, handleInvokeFun, loading } = useCustomActFuc(
    row,
    action,
    setConfirmationModal
  );

  const getCustomActFuc = () => {
    if (actionType === 'api') {
      return handleApiAction(action);
    } else if (actionType === 'custom') {
      if (routeOrCustomFun === 'route') {
        const link = generateLink(targetEntity, routeType, rowId);
        if (link) navigate(link);
      } else {
        handleInvokeFun();
      }
    }
  };

  const renderIcon = () => {
    if (loading) return <CircularProgress size="1rem" />;
    if (icon) {
      return React.createElement(Icons[action.icon], { fontSize: 'small' });
    }
    return null;
  };

  // Function to evaluate a single condition
  const evaluateCondition = (condition) => {
    const { field_name, field_operator, field_value } = condition;

    if (!row.hasOwnProperty(field_name)) return false;

    const fieldValue = row[field_name];

    switch (field_operator) {
      case 'equal':
        return fieldValue === field_value;
      case 'is not equal':
        return fieldValue !== field_value;
      case 'greater than':
        return fieldValue > field_value;
      case 'less than':
        return fieldValue < field_value;
      case 'in between':
        return fieldValue >= field_value && fieldValue <= field_value;
      case 'contains':
        return Array.isArray(fieldValue)
          ? fieldValue.includes(field_value)
          : String(fieldValue).includes(field_value);
      case 'starts with':
        return (
          typeof fieldValue === 'string' && fieldValue.startsWith(field_value)
        );
      case 'ends with':
        return (
          typeof fieldValue === 'string' && fieldValue.endsWith(field_value)
        );
      case 'is empty':
        return (
          fieldValue === '' || fieldValue == null || fieldValue.length === 0
        );
      case 'is not empty':
        return fieldValue !== '' && fieldValue != null && fieldValue.length > 0;
      case 'is any of':
        return Array.isArray(field_value) && field_value.includes(fieldValue);
      default:
        return false;
    }
  };

  const checkConditions = (conditions) => {
    if (!conditions || conditions.length === 0) return true;

    let result = evaluateCondition(conditions[0]); // Start with the first condition's result

    for (let i = 1; i < conditions.length; i++) {
      const condition = conditions[i];
      const { field_join } = condition;
      const conditionResult = evaluateCondition(condition);

      if (field_join === 'OR') {
        result = result || conditionResult;
      } else {
        // Default to 'AND'
        result = result && conditionResult;
        // If 'AND' and result is already false, no need to check further
        if (!result) return false;
      }
    }

    return result;
  };

  const isHideBtn = buttonState === 'hide' && checkConditions(conditions);
  const isDisableBtn =
    buttonState === 'disabled' && checkConditions(conditions);
  let size = actionLocation !== 'bulk' ? 'small' : 'medium';
  if (isHideBtn) return null;
  return (
    <>
      {actionLocation === 'more' ? (
        <MenuItem
          onClick={() => getCustomActFuc()}
          disableRipple
          disabled={loading || isDisableBtn}
        >
          {renderIcon()}
          {actionName}
        </MenuItem>
      ) : buttonOrIcon === 'icon' ? (
        <Tooltip title={actionName}>
          <IconButton
            size={size}
            disabled={loading || isDisableBtn}
            onClick={() => getCustomActFuc()}
          >
            {renderIcon()}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          size={size}
          variant="outlined"
          startIcon={renderIcon(action)}
          sx={{ whiteSpace: 'nowrap' }}
          disabled={loading || isDisableBtn}
          onClick={() => getCustomActFuc()}
        >
          {actionName}
        </Button>
      )}
      <ConfirmationModal
        title={confirmationModal?.title}
        description={confirmationModal?.description}
        navigateLink={confirmationModal?.link}
        onConfirm={confirmationModal?.onConfirm}
        open={confirmationModal?.isOpen}
        onClose={() => setConfirmationModal({})}
        isLoading={status.deleteData}
        confirmBtnConfig={{
          name: confirmationModal?.link ? 'View' : 'Delete',
          variant: 'contained',
          color: confirmationModal?.link ? 'success' : 'error'
        }}
        wayOutBtnConfig={{
          name: 'Cancel',
          variant: 'outlined',
          color: 'primary'
        }}
      />
    </>
  );
};

export default DynamicButton;
