import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { requestApi } from 'src/mocks/request';
import useRefMounted from './useRefMounted';

const useMultiFileUpload = (value, handleFileChange, isImage = false) => {
  const [loading, setLoading] = useState(false);
  const isMounted = useRefMounted();
  const [files, setFiles] = useState(value || []);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.warning(
          `Only ${isImage ? 'image' : 'document'} files are allowed!`
        );
        return;
      }

      const newFiles = acceptedFiles.map((file) => ({
        file,
        path: URL.createObjectURL(file),
        upload: true,
        type: file.type
      }));

      // setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      handleFileUpload([...files, ...newFiles]);
    },
    [isImage, files]
  );

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: isImage
  //     ? {
  //         'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.svg']
  //       }
  //     : {
  //         'application/pdf': ['.pdf'],
  //         'application/msword': ['.doc'],
  //         'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
  //           ['.docx'],
  //         'application/vnd.ms-excel': ['.xls'],
  //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
  //           '.xlsx'
  //         ],
  //         'text/csv': ['.csv']
  //       }
  //   // : {
  //   //     // Allow any type of application file including images and CSV
  //   //     // 'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.svg'],
  //   //     'application/*': ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv']
  //   //   }
  // });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: isImage
      ? {
          'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.svg'] // Only images
        }
      : undefined // Allow all file types when isImage is false
  });

  const handleFileDelete = async (file, index) => {
    setLoading(true);
    try {
      if (!file?.upload) {
        await requestApi.deleteFile({ url: file.path });
      }

      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
      handleFileChange(updatedFiles);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  const handleFileUpload = async (files) => {
    const filesToUpload = files
      .filter((file) => file.upload)
      .map((file) => file.file);

    if (filesToUpload.length === 0) {
      toast.info('All files have been uploaded');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append('files', file, file.name);
    });
    try {
      const response = await requestApi.uploadFile(formData);
      if (response?.data?.uploadSuccess?.length > 0) {
        const uploadedFiles = response.data.uploadSuccess.map((data) => ({
          name: data.name,
          path: encodeURI(data.path)
        }));

        const updatedFiles = [
          ...files.filter((file) => !file.upload),
          ...uploadedFiles
        ];

        setFiles(updatedFiles);
        handleFileChange(updatedFiles);
      }
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return {
    files,
    getRootProps,
    getInputProps,
    isDragActive,
    handleFileUpload,
    handleFileDelete,
    loading
  };
};

export default useMultiFileUpload;
