import {
  AddCircleRounded,
  CloseRounded,
  DoneTwoTone,
  InfoTwoTone
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Chip,
  Dialog,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddOptions from 'src/components/GetFieldBasedOnSchema/DTAutocomplete/AddOptions';
import Scrollbar from 'src/components/Scrollbar';
import {
  createBulkDataAsync,
  selectDynamicPageConfig
} from 'src/slices/dynamicSlice';

export default function FieldErrorPreview({
  errorPreviewOpen,
  handleClose,
  fieldErrors,
  fieldToShow,
  uploadFileData,
  isValidateDisabled,
  handleValidateField,
  setIsFieldDirty,
  setFieldToShow
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [uniqueErrorValues, setUniqueErrorValues] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);

  const handleDrawer = (event = null) => {
    if (event) {
      event.stopPropagation();
    }
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const uniqueValues = {};
    fieldErrors?.forEach((error) => {
      const uniqueValuesForField = new Set(
        error?.errors?.map((err) => err.value)
      );
      const filteredValues = [...uniqueValuesForField].filter(Boolean); // Remove empty values
      uniqueValues[error?.fieldName] = filteredValues;
    });
    setUniqueErrorValues(uniqueValues);
  }, [fieldErrors]);

  // const handleDeleteValue = (fieldName, index) => {
  //   const updatedUniqueErrorValues = { ...uniqueErrorValues };
  //   updatedUniqueErrorValues[fieldName].splice(index, 1);
  //   setUniqueErrorValues(updatedUniqueErrorValues);
  // };

  const handleBulkCreate = useCallback(async () => {
    try {
      setLoading(true);

      for (const fieldName in uniqueErrorValues) {
        const values = uniqueErrorValues[fieldName];
        const endPoint = schema[fieldName]?.options?.ref;
        const data = [];

        const propertyName = entitySetting?.populate_fields?.find(
          (item) => item?.populate_field === fieldName
        );

        const field = fieldToShow?.find(
          (item) => item?.fieldName === fieldName
        );

        values?.forEach((value) => {
          data.push({
            [field?.selectedField || propertyName?.field_show]: value
          });
        });

        console.log('data', data);
        await dispatch(createBulkDataAsync({ endPoint, data: { data } }));
      }

      setUniqueErrorValues({});
    } catch (error) {
      console.error('Error bulk creating data:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  }, [dispatch, schema, uniqueErrorValues]);

  const handleFieldToShowChange = (e, header) => {
    setIsFieldDirty(true);
    const selectedField = e.target.value;
    setFieldToShow((prev) => {
      const updatedFields = prev.map((item) =>
        item.fieldName === header ? { ...item, selectedField } : item
      );
      return updatedFields;
    });
  };

  const handleCreate = (value, field) => {
    setSelectedItem({ value, field });
    handleDrawer();
  };

  const hasMissingSelectedField = (fieldToShow || [])?.some(
    (item) => !item.selectedField || item.selectedField === ''
  );

  return (
    <Dialog
      open={errorPreviewOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Stack gap={2} padding={4}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            Field Error Preview
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Stack>
        <Alert severity="info">{`Choose Field and Click 'Validate' to Recheck.`}</Alert>
        <Alert severity="error">
          Non Existing field values found. Create them before importing or use
          existing one.
        </Alert>

        <TableContainer sx={{ width: '100%' }}>
          <Scrollbar horizontal={true} maxHeight={340}>
            <Table
              stickyHeader
              aria-label="preview table"
              sx={{ borderCollapse: 'collapse' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      py: 0.5,
                      border: `1px solid ${theme.palette.divider}`
                    }}
                  >
                    Header
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 0.5,
                      border: `1px solid ${theme.palette.divider}`
                    }}
                  >
                    Field
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 0.5,
                      border: `1px solid ${theme.palette.divider}`
                    }}
                  >
                    Values
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(uniqueErrorValues).map(
                  ([fieldName, values]) => {
                    const field = fieldToShow?.find(
                      (item) => item.fieldName === fieldName
                    );
                    return (
                      <TableRow key={fieldName}>
                        <TableCell
                          sx={{
                            paddingY: 0.4,
                            whiteSpace: 'nowrap',
                            border: `1px solid ${theme.palette.divider}`
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{ textTransform: 'uppercase' }}
                          >
                            {fieldName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            paddingY: 0.4,
                            whiteSpace: 'nowrap',
                            border: `1px solid ${theme.palette.divider}`
                          }}
                        >
                          <Select
                            size="small"
                            fullWidth
                            variant="standard"
                            MenuProps={{
                              PaperProps: {
                                sx: { padding: '0' }
                              },
                              MenuListProps: {
                                sx: { padding: '2px 4px' }
                              }
                            }}
                            sx={{
                              '& .MuiSelect-select': {
                                padding: '4px'
                              }
                            }}
                            disableUnderline
                            value={field.selectedField || ''}
                            onChange={(e) =>
                              handleFieldToShowChange(e, fieldName)
                            }
                          >
                            {field.fields.map((fieldOption) => (
                              <MenuItem key={fieldOption} value={fieldOption}>
                                {fieldOption}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>

                        <TableCell
                          sx={{
                            paddingY: 0.4,
                            whiteSpace: 'nowrap',
                            border: `1px solid ${theme.palette.divider}`
                          }}
                        >
                          {values?.map((uniqueValue, index) => (
                            <Chip
                              sx={{ margin: 0.2 }}
                              key={index}
                              label={uniqueValue}
                              onClick={() => handleCreate(uniqueValue, field)}
                              deleteIcon={<AddCircleRounded />}
                              onDelete={() => handleCreate(uniqueValue, field)}
                            />
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        {drawerOpen && (
          <Drawer
            sx={{ zIndex: 10000 }}
            anchor="right"
            open={drawerOpen}
            onClose={() => handleDrawer()}
          >
            <AddOptions
              entity={selectedItem?.field?.endPoint}
              handleDrawer={() => handleDrawer()}
              inputValue={selectedItem?.value}
              fieldShow={selectedItem?.field?.selectedField}
            />
          </Drawer>
        )}
        <Stack gap={2} direction="row" justifyContent="flex-end">
          {!hasMissingSelectedField && (
            <LoadingButton
              disabled={hasMissingSelectedField}
              loading={loading}
              sx={{
                backgroundColor: `${theme.colors.primary.lighter}`,
                '&:hover': {
                  backgroundColor: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`
                }
              }}
              onClick={handleBulkCreate}
              size="small"
            >
              Bulk Create
            </LoadingButton>
          )}
          <LoadingButton
            size="small"
            color={isValidateDisabled ? 'primary' : 'success'}
            disabled={!uploadFileData?.length || !uploadFileData}
            startIcon={isValidateDisabled ? <InfoTwoTone /> : <DoneTwoTone />}
            onClick={handleValidateField}
            variant="contained"
          >
            {isValidateDisabled ? 'Validate' : 'Validated'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
