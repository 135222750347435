import { onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getAppConfigAsync,
  getCompanyEntitySettingAsync
  // getCompanyGeneralSettingAsync
} from 'src/slices/appConfigSlice';
import {
  getPushNotification,
  selectLoggedUserData
} from 'src/slices/authSlice';
import { getAllProjects } from 'src/slices/projectSlice';
import { generateToken, messaging } from 'src/utils/firebase';

const WorkspaceInitialization = ({ children }) => {
  const dispatch = useDispatch();
  const loggedUserData = useSelector(selectLoggedUserData);
  const [fcmToken, setFcmToken] = useState(null);
  console.log(
    loggedUserData,
    'loggedUserData in WorkspaceInitialization , called only after Workspace checks are passed'
  );
  // this will be used in protected route
  useEffect(() => {
    const fetchTokenAndSetupListener = async () => {
      try {
        const FCM_TOKEN = await generateToken();
        setFcmToken(FCM_TOKEN);

        // Handle foreground messages
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          toast(payload.notification.body);
        });
      } catch (error) {
        console.error('Failed to get FCM token:', error);
      }
    };

    fetchTokenAndSetupListener();
  }, []);

  useEffect(() => {
    // Execute all actions in parallel
    Promise.all([
      dispatch(getAppConfigAsync()),
      // dispatch(getAllProjects({ endPoint: 'project' })),
      dispatch(
        getCompanyEntitySettingAsync({
          endPoint: 'setting',
          query: { setting_type: 'company_entity_settings' }
        })
      )
      // dispatch(
      //   getCompanyGeneralSettingAsync({
      //     endPoint: 'setting',
      //     query: { setting_type: 'company_general_settings' }
      //   })
      // )
    ]);
  }, [dispatch]);

  useEffect(() => {
    const handleGetPushNotification = async () => {
      console.log(loggedUserData);

      try {
        if (fcmToken && loggedUserData)
          await dispatch(
            getPushNotification({
              userId: loggedUserData?.id || loggedUserData?._id,
              playerId: fcmToken,
              deviceId: localStorage.getItem('deviceId')
            })
          );
      } catch (error) {
        console.error('Failed to get push notification:', error);
      }
    };
    handleGetPushNotification();
  }, [fcmToken, dispatch]);

  return children;
};
export default WorkspaceInitialization;
