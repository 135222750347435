import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { Form, Formik } from 'formik';
import { requestApi } from 'src/mocks/request';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import useRefMounted from 'src/hooks/useRefMounted';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import StockAuditLoader from '../StockAuditLoader';
import StockDetailsCard from './StockDetailsCard';

const InitialStock = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const isMounted = useRefMounted();
  const { schema } = useEntityPageConfig('audit_record');
  const { currentItem, isFetching } = useDyFetchDataById();
  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const auditPayload = {
        endPoint: 'stock_audit',
        data: {
          audit_type: 'initial_stock',
          warehouse: currentItem?.id
        }
      };

      const auditResponse = await requestApi.createData(auditPayload);
      if (auditResponse?.data) {
        const sanitizedData = values.products.map((product) => {
          const { createData } = sanitizeFormData(
            product,
            schema,
            Object.keys(product)
          );

          return createData;
        });

        const adjPayload = {
          endPoint: 'audit_record',
          data: {
            data: sanitizedData.map((product) => ({
              ...product,
              audit: auditResponse?.data.id,
              warehouse: currentItem?.id
            }))
          }
        };

        const adjResponse = await requestApi.createBulkData(adjPayload);
        if (adjResponse?.data) {
          navigate('/stock_audit');
        }
        console.log(adjResponse);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted.current) setSubmitting(false);
    }
  };

  if (isFetching) return <StockAuditLoader />;

  return (
    <>
      <Helmet>
        <title>Initial Stock</title>
      </Helmet>

      <Formik initialValues={{ products: [] }} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <PageTitleWrapper>
              <PageHeader currentItem={currentItem} submitting={submitting} />
            </PageTitleWrapper>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <StockDetailsCard />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InitialStock;
