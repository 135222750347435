import { useEffect, useMemo, useState } from 'react';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import moment from 'moment';
import { DragIndicatorRounded } from '@mui/icons-material';
import { Box, Card, Tooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateDataAsync } from 'src/slices/dynamicSlice';

// todo: make shifts dynamic
const shifts = [
  { name: 'Morning', start: '08:00', end: '16:00' },
  { name: 'Evening', start: '17:00', end: '24:00' }
];

const pixelPerMinute = 1.5;

// Utils =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
const isInShift = (scheduledStart, shift) => {
  const startTime = moment(scheduledStart).format('HH:mm');

  const shiftStartTime = moment(shift.start, 'HH:mm');
  const shiftEndTime = moment(shift.end, 'HH:mm');

  const scheduledTime = moment(startTime, 'HH:mm');

  return scheduledTime.isBetween(shiftStartTime, shiftEndTime, null, '[)');
};

const getStartOffset = (selectedDate, scheduledStart, shift) => {
  const shiftStart = moment(`${selectedDate}T${shift.start}:00`);
  const jobStart = moment(scheduledStart);
  const offset = jobStart.diff(shiftStart, 'minutes');
  return offset * pixelPerMinute;
};

const getMinutesFromOffset = (offset) => {
  return offset / pixelPerMinute;
};
// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- END

// const getExistingJobCards = (shifts, selectedDate) => {}

// DRAGGABLE JOB CARD
const DraggableJobCard = ({ job, shift, selectedDate }) => {
  const { setNodeRef, attributes, listeners, transform, transition } =
    useDraggable({
      id: job?.id.toString(),
      data: { ...job, shift }
    });

  const startOffset = useMemo(
    () => getStartOffset(selectedDate, job?.scheduled_start, shift),
    [selectedDate, job, shift]
  );

  if (!job?.isDraggable) {
    return (
      <Tooltip arrow title={job?.number} placement="bottom">
        <Card
          sx={{
            position: 'absolute',
            width: `${job?.total_time_mins * pixelPerMinute}px`,
            left: `${startOffset}px`,
            backgroundColor: 'secondary.light',
            color: 'secondary.contrastText',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            borderRadius: '4px',
            pl: 0.5,
            cursor: 'move'
          }}
        >
          <Typography
            noWrap
            sx={{
              color: 'secondary.contrastText'
            }}
          >
            {job?.number}
          </Typography>
        </Card>
      </Tooltip>
    );
  }

  return (
    <Tooltip arrow title={job?.number} placement="bottom">
      <Card
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        sx={{
          transform: transform ? `translateX(${transform?.x}px)` : undefined,
          transition: transition,
          position: 'absolute',
          width: `${job?.total_time_mins * pixelPerMinute}px`,
          left: `${startOffset}px`,
          zIndex: 10,
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          borderRadius: '4px',
          pl: 0.5,
          cursor: 'move'
        }}
      >
        <Typography noWrap sx={{ color: 'primary.contrastText' }}>
          {job?.number}
        </Typography>
        <DragIndicatorRounded fontSize="small" />
      </Card>
    </Tooltip>
  );
};

// DROPPABLE TIMELINE CONTAINER
const DroppableTimeline = ({ shift, children }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `droppable-${shift?.name}`,
    data: { shift }
  });

  // console.log('chidren',children)

  return (
    <Box
      ref={setNodeRef}
      sx={{
        position: 'relative',
        height: '40px',
        backgroundColor: isOver ? 'primary.light' : 'background.default',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '4px'
      }}
    >
      {children}
    </Box>
  );
};

// JOB SCHEDULER | MAIN COMPONENT
const JobScheduler = ({ jobCards, selectedJobCard, setCurrentItem }) => {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const selectedDate = selectedJobCard
    ? moment(selectedJobCard?.scheduled_start)?.format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  // console.log('selectedJobCard::', selectedJobCard);

  useEffect(() => {
    if (!selectedJobCard) return;

    const updatedJobs = jobCards?.map((job) => {
      const startDate = job.scheduled_start && moment(job.scheduled_start);
      return {
        ...job,
        scheduled_start: startDate?.format('YYYY-MM-DD HH:mm:ss'),
        isDraggable: job.id === selectedJobCard?.id
      };
    });
    const selectedJobExists = updatedJobs?.some(
      (job) => job.id === selectedJobCard?.id
    );

    if (!selectedJobExists && selectedJobCard) {
      setJobs([...updatedJobs, { ...selectedJobCard, isDraggable: true }]);
    } else {
      setJobs(updatedJobs);
    }
  }, [jobCards, selectedJobCard]);

  // console.log('jobs::', jobs);

  const handleDragEnd = (event) => {
    const { active, over, delta } = event;

    if (!over) return;

    const dropShift = over.data.current.shift;
    const draggedJob = active.data.current;

    if (!dropShift || !draggedJob) return;

    const pixelMovement = delta.x;
    const minuteMovement = getMinutesFromOffset(pixelMovement);

    const jobStartMoment = moment(draggedJob.scheduled_start);
    const newStartTime = jobStartMoment
      .clone()
      .add(minuteMovement, 'm')
      .format('HH:mm');

    console.log('selectedDate', selectedDate);

    const newStart = moment(`${selectedDate}T${newStartTime}`);
    const newEnd = newStart.clone().add(draggedJob.total_time_mins, 'm');

    const shiftStart = moment(`${selectedDate}T${dropShift.start}:00`);
    const shiftEnd = moment(`${selectedDate}T${dropShift.end}:00`);

    if (!newStart.isBetween(shiftStart, shiftEnd)) return;

    const doesOverlap = jobs.some((job) => {
      if (job.id === draggedJob.id) return false; // skip the dragged job itself

      const jobStart = moment(job.scheduled_start);
      const jobEnd = jobStart.clone().add(job.total_time_mins, 'minutes');

      // Check if the new job's schedule overlaps with any existing job's schedule
      return (
        (newStart.isBefore(jobEnd) && newEnd.isAfter(jobStart)) ||
        (newEnd.isAfter(jobStart) && newStart.isBefore(jobEnd)) ||
        (newStart.isSameOrBefore(jobEnd) && newEnd.isSameOrAfter(jobStart))
      );
    });

    if (!doesOverlap) {
      const updatedJobs = jobs.map((j) =>
        j.id === draggedJob.id
          ? { ...j, scheduled_start: newStart.toLocaleString() }
          : j
      );

      setJobs(updatedJobs);

      dispatch(
        updateDataAsync({
          endPoint: 'job_card',
          id: draggedJob.id,
          data: { scheduled_start: newStart.toLocaleString() }
        })
      );

      setCurrentItem((prev) => {
        const updatedItem = { ...prev };
        updatedItem?.operations?.forEach((op) => {
          op?.job_cards?.forEach((jobCard) => {
            if (jobCard?.id === draggedJob?.id) {
              jobCard.scheduled_start = newStart.toLocaleString();
            }
          });
        });
        return updatedItem;
      });
    }
  };

  const renderJobCards = (shift) => {
    return (
      jobs
        // filter jobs based on shift
        ?.filter((job) => isInShift(job?.scheduled_start, shift))
        ?.map((job) => (
          <DraggableJobCard
            key={job?.id}
            job={job}
            shift={shift}
            selectedDate={selectedDate}
          />
        ))
    );
  };

  const renderTimeRuler = (shift) => {
    const shiftStart = moment(`${selectedDate}T${shift.start}:00`);
    const shiftEnd = moment(`${selectedDate}T${shift.end}:00`);
    const durationMins = shiftEnd.diff(shiftStart, 'minutes');

    // based on duration, create a marker for each minute
    const markers = [];
    for (let m = 0; m <= durationMins; m += 60) {
      const markerTime = shiftStart.clone().add(m, 'minutes').format('h A'); // 12-hour format with AM/PM
      markers.push(
        <Box
          key={m}
          sx={{
            position: 'absolute',
            left: `${m * pixelPerMinute}px`,
            height: '20px'
          }}
        >
          <Typography variant="body2">{markerTime}</Typography>
        </Box>
      );
    }
    return markers;
  };

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <Box>
        {shifts.map((shift) => (
          <Card
            key={shift?.name}
            sx={{
              marginBottom: '20px',
              position: 'relative',
              p: 2,
              userSelect: 'none',
              msUserSelect: 'none',
              webkitUserSelect: 'none',
              MozUserSelect: 'none'
            }}
          >
            <Typography variant="h6" mb={1}>
              {shift?.name} Shift
            </Typography>

            {/* Time Ruler */}
            <Box
              sx={{
                position: 'relative',
                height: '30px',
                borderBottom: '1px solid divider',
                backgroundColor: 'background.paper',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              {renderTimeRuler(shift)}
            </Box>

            {/* Droppable Timeline */}
            <DroppableTimeline shift={shift}>
              {renderJobCards(shift)}
            </DroppableTimeline>
          </Card>
        ))}
      </Box>
    </DndContext>
  );
};

export default JobScheduler;
