import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { createDataAsync, updateBulkDataAsync } from 'src/slices/dynamicSlice';

const MaterialItem = ({ item, isLastItem }) => (
  <>
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Typography variant="h5">{item?.product?.name}</Typography>
        <Typography variant="subtitle1">
          {item?.process_status?.processed_qty}{' '}
          {item?.process_status?.uom?.name}
        </Typography>
      </Box>
      <Typography variant="subtitle1">
        {item?.qty} {item?.uom?.name}
      </Typography>
    </Stack>
    {!isLastItem && <Divider />}
  </>
);

const PurchaseRequest = ({ open, handleModal, PRCart, setPRCart }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleGeneratePR = async () => {
    const items = PRCart?.map((item) => ({
      product: item?.product?.id,
      uom: item?.uom?.id,
      qty: item?.qty
    }));
    const res = await dispatch(
      createDataAsync({
        endPoint: 'purchase_request',
        data: { items }
      })
    );

    setPRCart([]);

    const purchaseRequestId = res?.payload?.id;
    const data = {
      purchase_request: purchaseRequestId,
      status: 'processing'
    };

    const payload = {
      data,
      filter: {
        _id: { $in: [...PRCart?.map((item) => item?.process_status?.id)] }
      }
    };

    await dispatch(
      updateBulkDataAsync({ endPoint: 'material_processing', data: payload })
    );

    handleModal();
  };

  return (
    <Dialog fullWidth open={open} onClose={handleModal}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle>
            <Typography variant="h4">Generate Purchase Request</Typography>
          </DialogTitle>
        </Stack>
        <Fragment>
          <DialogContent>
            <>
              {PRCart?.length > 0 && (
                <>
                  <Stack
                    spacing={1}
                    sx={{
                      background: theme.colors.alpha.black[10],
                      borderRadius: theme.general.borderRadius,
                      p: 1,
                      mb: 2
                    }}
                  >
                    {PRCart?.map((item, index) => (
                      <MaterialItem
                        key={index}
                        item={item}
                        isLastItem={index === PRCart?.length - 1}
                      />
                    ))}
                  </Stack>
                </>
              )}
            </>
            <Typography variant="body1" gutterBottom sx={{ fontSize: 13 }}>
              Purchase requests will be generated for the items, and once
              available, they will be reserved for production.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button size="small" variant="contained" onClick={handleModal}>
              Close
            </Button>
            <Button size="small" variant="outlined" onClick={handleGeneratePR}>
              Generate PR
            </Button>
          </DialogActions>
        </Fragment>
      </Box>
    </Dialog>
  );
};

export default PurchaseRequest;
