import { useFormikContext } from 'formik';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
const BOOLEAN_OPTIONS = ['Acceptable', 'Not Acceptable'];
const FieldByValueType = ({ specifications = {}, result, path }) => {
  const { setFieldValue } = useFormikContext();
  const { value_type: fieldType = '', options = [] } = specifications;

  if (fieldType === 'ENUM' || fieldType === 'BOOLEAN') {
    return (
      <CustomSelectField
        size="small"
        label={'Result'}
        value={result}
        sx={{ maxWidth: 200 }}
        options={fieldType === 'BOOLEAN' ? BOOLEAN_OPTIONS : options}
        onChange={(event) => setFieldValue(path, event.target.value)}
      />
    );
  }
  return (
    <CustomTextField
      value={result}
      size="small"
      sx={{ maxWidth: 200 }}
      onChange={(event) => setFieldValue(path, event.target.value)}
      label={'Result'}
      inputProps={{ type: fieldType === 'NUMBER' ? 'number' : 'text' }}
    />
  );
};

export default FieldByValueType;
