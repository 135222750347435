import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import StatusButtons from './StatusButtons';
import { useFormikContext } from 'formik';

const InspectionCard = ({ currentItem }) => {
  const { product, grn } = currentItem;
  const { values } = useFormikContext();
  const { tests } = values;
  // Calculate the number of completed tests
  const completedTests = tests?.filter((test) => test.result).length || 0;
  const totalTests = tests?.length || 0;
  return (
    <Card variant="outlined">
      <CardHeader action={<StatusButtons />} title="Inspection Overview" />
      <Divider />
      <CardContent>
        <Stack
          direction={{ md: 'row' }}
          flexWrap={'wrap'}
          gap={2}
          justifyContent="space-between"
        >
          <Stack sx={{ flex: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Product
            </Typography>
            <Typography variant="body2">{product?.name}</Typography>
          </Stack>

          <Stack sx={{ flex: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Source Document
            </Typography>
            <Typography>{grn?.number}</Typography>
          </Stack>

          <Stack>
            <Typography variant="subtitle1" fontWeight="bold">
              Assessment
            </Typography>
            <Typography>
              {completedTests}/{totalTests}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default InspectionCard;
