import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import InspectionCard from './InspectionCard';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import PerformTestLoader from '../PerformTestLoader';
import TestQualityCard from './TestQualityCard';
const ViewTestReport = () => {
  const { currentItem, isFetching } = useDyFetchDataById();

  if (isFetching) return <PerformTestLoader />;
  return (
    <>
      <Helmet>
        <title>View Test Report</title>
      </Helmet>

      <PageTitleWrapper>
        <PageHeader currentItem={currentItem} />
      </PageTitleWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InspectionCard currentItem={currentItem} />
        </Grid>
        <Grid item xs={12}>
          <TestQualityCard currentItem={currentItem} />
        </Grid>
      </Grid>
    </>
  );
};

export default ViewTestReport;
