import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton
} from '@mui/material';
import { useFormikContext } from 'formik';
import CustomDateField from 'src/components/CustomFields/CustomDateField';
import DTAutocomplete from 'src/components/GetFieldBasedOnSchema/DTAutocomplete';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const AuditDetails = () => {
  const { values, setFieldValue } = useFormikContext();
  console.log(values, 'values');
  const { schema, entitySetting } = useEntityPageConfig('stock_audit');
  const handleWarehouseChange = (value) => {
    setFieldValue('warehouse', value);
    setFieldValue('scope', '');
  };
  if (!schema) return <Skeleton variant="rounded" height={'20vh'} />;
  return (
    <Card variant="outlined">
      <CardHeader title="Audit Details" />

      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sm={6}>
            <DTAutocomplete
              label="Warehouse"
              size={'small'}
              fieldName={'warehouse'}
              value={values?.warehouse || ''}
              schema={schema}
              onChange={handleWarehouseChange}
              entitySetting={entitySetting}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <DTAutocomplete
              label="Audit Coverage"
              size="small"
              fieldQuery={
                values?.warehouse?.id ? { warehouse: values.warehouse.id } : {}
              }
              fieldName="scope"
              value={values?.scope || ''}
              schema={schema}
              onChange={(value) => setFieldValue('scope', value)}
              entitySetting={entitySetting}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <CustomDateField
              label="Schedule Date"
              size={'small'}
              value={values?.scheduled_on || ''}
              onChange={(date) => setFieldValue('scheduled_on', date)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AuditDetails;
