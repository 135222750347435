import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { requestApi } from 'src/mocks/request';
import { getFieldType } from 'src/services/commonService';
const getMdSize = (fieldname, schema, size) => {
  const { isStringHasMaxNull, isObject, isArrayWithMultipleProperties } =
    getFieldType(fieldname, schema);
  if (isArrayWithMultipleProperties || isObject || isStringHasMaxNull)
    return 12;
  return size;
};

const Identity = ({ config }) => {
  const { values, setFieldValue } = useFormikContext();
  const { dependentKey, depKeySchema } = config || {};
  const country = values?.[dependentKey]?.country;
  const identities = values?.[dependentKey]?.['identities'] || [];
  const identitiesSchema = depKeySchema?.['identities']?.schema?.paths;
  const fetchIdentityType = useCallback(async () => {
    if (!country || identities?.length > 0) return;
    try {
      const payload = {
        endPoint: 'identity_type',
        query: {
          country
        }
      };
      const response = await requestApi.getData(payload);
      const resData = response?.data?.data || [];
      if (resData?.length) {
        const identities = resData.map((item) => {
          return {
            name: item.name,
            number: '',
            valid_from: '',
            validity_till: '',
            attachment: ''
          };
        });
        setFieldValue(`${dependentKey}.identities`, identities);
      }
    } catch (error) {}
  }, [country]);
  useEffect(() => fetchIdentityType(), []);

  return (
    <Grid container spacing={2}>
      {identities.map((identity, index) => (
        <Grid item xs={12} key={index}>
          <Card variant="outlined">
            <CardHeader title={identity?.name} />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                {['number', 'valid_from', 'validity_till', 'attachment'].map(
                  (field) => (
                    <Grid
                      item
                      xs={12}
                      md={getMdSize(field, depKeySchema, 4)}
                      sm={getMdSize(field, depKeySchema, 6)}
                      key={`${field}_${index}`}
                    >
                      <GetFieldBasedOnSchema
                        name={`${dependentKey}.identities[${index}].${field}`}
                        fieldName={field}
                        schema={identitiesSchema}
                        value={identity?.[field] || ''}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Identity;
