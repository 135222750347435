import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';

import { createBulkDataAsync } from 'src/slices/dynamicSlice';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { getFilteredData } from 'src/services/bulkImportService';
import { toast } from 'react-toastify';
import { requestApi } from 'src/mocks/request';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const isEmpty = (value) =>
  value === '' || value === null || value === undefined;

const handleFilterFields = (fieldSchema) => {
  return Object.keys(fieldSchema).filter((key) => {
    const field = fieldSchema[key];
    return (
      ['String', 'Number'].includes(field.instance) &&
      !field.path.includes('.') &&
      field.options.is_unique &&
      ![...REMOVE_FIELDS, 'avatar', 'images', 'files'].includes(key)
    );
  });
};

export const useImportTable = ({ endPoint }) => {
  const dispatch = useDispatch();

  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploadFileData, setUploadFileData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [fieldToShow, setFieldToShow] = useState(null);
  const [isFieldDirty, setIsFieldDirty] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorPreviewOpen, setErrorPreviewOpen] = useState(false);

  const [importData, setImportData] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [isSummaryDialogOpen, setIsSummaryDialogOpen] = useState(false);

  const fieldToShowRef = useRef(fieldToShow);
  const fileInputRef = useRef(null);

  const entity = endPoint;
  const { schema, entitySetting } = useEntityPageConfig(entity);
  const fieldNames = schema ? Object?.keys(schema) : [];

  console.log('fieldToShow', fieldToShow);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // Sample file generation
  const handleSampleFile = useCallback(() => {
    // Example headers for the sample file
    const filteredHeaders = fieldNames?.filter(
      (item) => !REMOVE_FIELDS.includes(item)
    );

    // Create a new worksheet
    const ws = XLSX.utils.aoa_to_sheet([filteredHeaders]);

    const startDate = new Date('2000-01-01');
    const endDate = new Date();
    const timeDiff = endDate.getTime() - startDate.getTime();

    const getRandomDate = () => {
      const randomTime = startDate.getTime() + Math.random() * timeDiff;
      return new Date(randomTime).toISOString().split('T')[0];
    };

    // Generate sample data
    const sampleData = Array.from({ length: 5 }, () =>
      filteredHeaders?.map((header) => {
        const type = schema[header]?.instance;
        switch (type) {
          case 'String':
            return 'String Value';
          case 'Number':
            return Math.floor(Math.random() * 100) + 1;
          case 'ObjectID':
            return '663dd3f8ec554f707085b8ea';
          case 'Array':
            return ['Item 1', 'Item 2', 'Item 3'];
          case 'Boolean':
            return Math.random() < 0.5 ? 'Yes' : 'No';
          case 'Date':
            return getRandomDate();
          default:
            return '';
        }
      })
    );

    // Add sample data to the worksheet
    // -1 means append after the existing data
    XLSX.utils.sheet_add_aoa(ws, sampleData, { origin: -1 });

    // Create a new workbook
    // Add the worksheet to the workbook
    // Write the workbook to a file
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SampleData');
    XLSX.writeFile(wb, 'sample.csv');
  }, []);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // File upload
  const handleFileChange = useCallback(
    (event) => {
      // RESET STATES
      setUploadFileData(null);
      setSelectedFileName(null);
      setTableHeaders(null);
      setFieldErrors(null);

      const file = event.target.files[0];
      if (file) {
        setSelectedFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const tableHeaders = jsonData[0];
          const formattedData = jsonData?.slice(1)?.map((row) => {
            const obj = {};
            tableHeaders?.forEach((key, index) => {
              const cellValue = row[index];
              if (cellValue !== undefined && cellValue !== '') {
                obj[key] = cellValue;
              }
            });
            return obj;
          });

          // console.log('JSON Data::S1', jsonData);
          // console.log('formattedData::S1', formattedData);

          const { headers, filteredData } = getFilteredData(
            formattedData,
            schema,
            entitySetting
          );

          console.log('Table Headers::S2', headers);
          console.log('Filtered Data::S2', filteredData);

          setTableHeaders(headers);
          setUploadFileData(filteredData);
        };

        reader.readAsArrayBuffer(file);
      } else {
        console.warn('No file selected');
        setUploadFileData(null);
      }
    },
    [getFilteredData]
  );

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // Get ObjectId
  const getObjectId = async ({ header, endPoint }) => {
    try {
      const propertyName = entitySetting?.populate_fields?.find(
        (item) => item?.populate_field === header
      );
      const uniqueItems = [
        ...new Set(uploadFileData?.map((item) => item[header]))
      ];

      if (!propertyName) {
        console.warn(`populate_field not found for [${header}]-[${endPoint}]`);
        const { data: fieldSchema } = await requestApi.getSchema({
          endPoint
        });
        const filteredFields = handleFilterFields(fieldSchema);

        setFieldToShow((prev) => {
          const fields = prev ? [...prev] : [];
          const index = fields?.findIndex((item) => item.fieldName === header);

          if (index > -1) {
            fields[index] = {
              ...fields[index],
              fields: filteredFields
            };
          } else {
            fields.push({
              fieldName: header,
              fields: filteredFields,
              endPoint
            });
          }
          console.log('fields', fields);
          return fields;
        });

        console.log('fieldToShow', fieldToShow);

        // Find the updated field after setting state
        const field = fieldToShowRef.current?.find(
          (item) => item?.fieldName === header
        );

        let payload = { endPoint, select: ['id', '_id'], query: {} };
        if (field.selectedField) {
          payload = {
            endPoint,
            select: [field?.selectedField, 'id', '_id'],
            query: { [field?.selectedField]: { $in: uniqueItems } }
          };
        } else {
          console.warn('Please select a field to validate document', header);
          return;
        }

        const response = await requestApi.getData(payload);
        return response?.data?.data || response?.data;
      } else {
        const { data: fieldSchema } = await requestApi.getSchema({
          endPoint
        });
        const filteredFields = handleFilterFields(fieldSchema);

        const field = fieldToShowRef.current?.find(
          (item) => item?.fieldName === header
        );

        setFieldToShow((prev) => {
          const fields = prev ? [...prev] : [];
          const index = fields?.findIndex((item) => item.fieldName === header);

          if (index > -1) {
            fields[index] = {
              ...fields[index],
              fields: filteredFields
            };
          } else {
            fields.push({
              fieldName: header,
              fields: filteredFields,
              selectedField:
                propertyName?.field_show ||
                propertyName?.primary_text?.show_field,
              endPoint
            });
          }
          return fields;
        });

        let payload = {
          endPoint,
          select: [field?.selectedField, 'id', '_id'],
          query: {
            [field?.selectedField]: { $in: uniqueItems }
          }
        };

        const response = await requestApi.getData(payload);
        return response?.data?.data || response?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // Process data
  const processUploadData = useCallback(async () => {
    const updatedFieldToShow = fieldToShowRef.current;

    const objectIdFields = Object.values(schema)
      ?.filter((item) => item?.instance === 'ObjectID')
      ?.map((item) => item?.path);

    // console.log('objectIdFields::', objectIdFields);

    const objectIdHeaderEndPoints = tableHeaders
      ?.filter((header) => objectIdFields.includes(header))
      ?.map((header) => ({
        header,
        endPoint: schema[header]?.options?.ref
      }));

    // console.log('objectIdHeaderEndPoints::', objectIdHeaderEndPoints);

    const objectIds = await Promise.all(
      objectIdHeaderEndPoints?.map(async ({ header, endPoint }) => {
        let data = await getObjectId({ header, endPoint });
        return { header, data };
      })
    ).then((res) =>
      res?.reduce((acc, { header, data }) => {
        acc[header] = data;
        return acc;
      }, {})
    );
    console.log('objectIds', objectIds);

    let errors = [];

    // console.log('uploadFileData', uploadFileData);
    // console.log('entitySetting', entitySetting);
    console.log('fieldToShow', fieldToShow);

    const processedData = uploadFileData
      ?.map((item, rowIndex) => {
        const processedItem = { ...item };
        const booleanValues = { Yes: true, No: false };

        for (const key of Object.keys(processedItem)) {
          // console.log('key', key);

          // Handle boolean values
          if (booleanValues.hasOwnProperty(processedItem[key])) {
            processedItem[key] = booleanValues[processedItem[key]];
          }

          const propertyName = entitySetting?.populate_fields?.find(
            (populate_field) => populate_field?.populate_field === key
          );

          const field = updatedFieldToShow?.find(
            (item) => item?.fieldName === key
          );

          // Handle ObjectId fields
          if (objectIdFields?.includes(key) && objectIds.hasOwnProperty(key)) {
            // console.log(processedItem, 'processedItem');

            if (processedItem[key] === '') {
              delete processedItem[key];
            }

            const matchedObject = objectIds[key]?.find((obj) => {
              const processedValue = processedItem[key]?.trim?.();
              const fieldValue =
                obj[
                  propertyName?.field_show ||
                    propertyName?.primary_text?.show_field
                ]?.trim?.();
              const selectedFieldValue = obj[field?.selectedField]?.trim?.();

              console.log('selectedFieldValue', selectedFieldValue);

              return (
                processedValue === fieldValue ||
                processedValue === selectedFieldValue ||
                processedItem[key] === obj.id ||
                processedItem[key] === obj._id ||
                processedItem[key] === ''
              );
            });

            if (matchedObject) {
              processedItem[key] = matchedObject.id;
            } else {
              const errorObj = { rowIndex, value: processedItem[key] };

              // Find existing error object for this field or create a new one
              let fieldError = errors?.find((error) => error.fieldName === key);
              if (!fieldError) {
                fieldError = {
                  fieldName: key,
                  endPoint: schema[key]?.options?.ref,
                  errors: []
                };
                errors.push(fieldError);
              }

              // Add error to the field error object
              fieldError.errors.push(errorObj);
            }
          }
        }

        return Object.keys(processedItem).length ? processedItem : null;
      })
      .filter((item) => item);

    const filterErrors = (data) => {
      return data.filter((item) => {
        // Check if the `errors` array is not empty
        if (!item.errors || item.errors.length === 0) {
          return false;
        }

        // Check if all `errors` items have valid `value`
        return item.errors.every((error) => {
          return (
            error.value !== '' &&
            error.value !== undefined &&
            error.value !== null
          );
        });
      });
    };

    const filteredErrors = filterErrors(errors);
    console.log(processedData, 'processedData');

    return { processedData, errors: filteredErrors };
  }, [uploadFileData, schema, tableHeaders]);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // Validate fields
  const handleValidateField = useCallback(async () => {
    try {
      const { processedData, errors } = await processUploadData();
      setFieldErrors(errors);

      console.log('Errors::', errors);

      if (errors?.length > 0) {
        toast.warn('Mismatch in the upload data');
        handleOpenCreate();
        return;
      }
      setIsFieldDirty(false);
      setImportData(processedData);
    } catch (error) {
      console.error('Error processing data:', error);
    }
  }, [uploadFileData, schema, tableHeaders]);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // Import
  const handleImport = useCallback(async () => {
    try {
      setIsImporting(true);

      await dispatch(
        createBulkDataAsync({ endPoint: entity, data: { data: importData } })
      );

      setUploadFileData(null);
      setImportData(null);
      setSelectedFileName(null);
      setFieldErrors(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    } catch (error) {
      setIsSummaryDialogOpen(true);
      setErrorMessage(error);
      console.error('Error isImporting data:', error);
    } finally {
      setIsImporting(false);
    }
  }, [dispatch, entity, importData]);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleOpenCreate = () => {
    setErrorPreviewOpen(true);
  };

  const handleClose = () => setErrorPreviewOpen(false);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  useEffect(() => {
    if (errorMessage) {
      setIsSummaryDialogOpen(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    fieldToShowRef.current = fieldToShow;
  }, [fieldToShow]);

  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  const isImportDisabled = !(
    uploadFileData &&
    fieldErrors?.length === 0 &&
    !isFieldDirty
  );

  const isValidateDisabled =
    !fieldErrors || fieldErrors?.length > 0 || isFieldDirty;

  return {
    tableHeaders,
    setTableHeaders,
    uploadFileData,
    setUploadFileData,
    fieldToShow,
    setFieldToShow,
    importData,
    isImporting,
    selectedFileName,
    setSelectedFileName,
    fieldErrors,
    setFieldErrors,
    setIsFieldDirty,
    errorMessage,
    errorPreviewOpen,
    isSummaryDialogOpen,
    setIsSummaryDialogOpen,
    // functions
    handleSampleFile,
    handleFileChange,
    handleValidateField,
    handleImport,
    handleUploadButtonClick,
    handleClose,
    // other
    isImportDisabled,
    isValidateDisabled,
    fileInputRef
  };
};
