import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Stack, useTheme } from '@mui/material';
import { FieldArray } from 'formik';
import React from 'react';
import NestedGridItem from './NestedGridItem';

const NestedTableForm = ({
  itemTableHeads,
  schema,
  entitySetting,
  fieldName,
  value,
  touched,
  error
}) => {
  const theme = useTheme();
  return (
    <>
      <FieldArray
        name={fieldName}
        render={({ remove, push }) => (
          <Stack spacing={1}>
            {(value || []).map((dataItem, valueIndex) => (
              <Box
                border={1}
                borderRadius={0.5}
                p={1}
                borderColor={theme.palette.divider}
                key={`${fieldName}_row_${valueIndex}`}
              >
                <Grid container spacing={1}>
                  {itemTableHeads.map((itemTableHead, itemIndex) => (
                    <NestedGridItem
                      key={`${fieldName}_field_${itemIndex}`}
                      name={`${fieldName}.${[valueIndex]}.${itemTableHead}`}
                      value={dataItem[itemTableHead]}
                      itemTableHead={itemTableHead}
                      index={valueIndex}
                      schema={schema}
                      entitySetting={entitySetting}
                      fieldName={fieldName}
                      touched={touched?.[valueIndex]?.[itemTableHead]}
                      error={error?.[valueIndex]?.[itemTableHead]}
                    />
                  ))}
                  <Grid item xs={12} textAlign={'end'}>
                    <Button
                      color="error"
                      size="small"
                      onClick={() => remove(valueIndex)}
                    >
                      Remove Row
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Box>
              <Button
                size="small"
                startIcon={<AddIcon />}
                onClick={() => {
                  const newItem = itemTableHeads.reduce(
                    (accumulator, itemTableHead) => {
                      accumulator[itemTableHead] = '';
                      return accumulator;
                    },
                    {}
                  );

                  push(newItem);
                }}
              >
                {!value?.length ? 'Add Row' : ' Add More'}
              </Button>
            </Box>
          </Stack>
        )}
      />
    </>
  );
};

export default NestedTableForm;
