import { useFormikContext } from 'formik';
import { useState, useEffect, useRef } from 'react';
import { processAutoFillFields } from 'src/utils/processAutoFillFields';

const useAutoFill = ({
  autoFillByFieldName,
  allAutoFillFieldName,
  value,
  index,
  fieldName,
  itemTableHead
}) => {
  const { setFieldValue, values: allValues } = useFormikContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isInitialRender = useRef(true);

  // Function to validate the field value based on its type
  const isValidFieldValue = (fieldValue, item) => {
    // Check if the fieldValue is an array
    if (Array.isArray(fieldValue)) {
      // Return false if array is empty
      if (!fieldValue.length) return false;

      // Check each item in the array
      return fieldValue.some((arrayItem) => {
        if (typeof arrayItem === 'object' && arrayItem !== null) {
          return item.aff.some((affItem) => {
            const nestedFieldValue = arrayItem?.[affItem.subKey];
            // Recursively validate the nested field value
            return isValidFieldValue(nestedFieldValue);
          });
        }
        // For non-object array items, just check if they are truthy
        return true;
      });
    }

    // Check for booleans: both true and false are considered valid
    if (typeof fieldValue === 'boolean') {
      return fieldValue !== null && fieldValue !== undefined;
    }

    // For other types, return true if the fieldValue is truthy
    return Boolean(fieldValue);
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip the effect on initial render
    }
    const savedFieldName = localStorage.getItem('autoFillBy');
    localStorage.removeItem('autoFillBy');
    if (
      autoFillByFieldName &&
      value &&
      allAutoFillFieldName.length &&
      (!savedFieldName || savedFieldName === autoFillByFieldName)
    ) {
      const hasExistingValue = allAutoFillFieldName.some((item) => {
        const fieldValue = itemTableHead
          ? allValues?.[fieldName]?.[index]?.[item.fieldName]
          : allValues?.[item.fieldName];

        // Use the isValidFieldValue function to determine if the fieldValue is valid
        return isValidFieldValue(fieldValue, item);
      });

      if (hasExistingValue) {
        // Show the modal if values already exist
        setModalOpen(true);
      } else {
        // Directly proceed with auto-fill if no values exist
        handleProcessAutoFill();
      }
    }
  }, [autoFillByFieldName, value, allAutoFillFieldName]);

  const handleProcessAutoFill = async () => {
    localStorage.setItem('autoFillBy', autoFillByFieldName);
    try {
      setLoading(true);
      await processAutoFillFields(
        value,
        allAutoFillFieldName,
        index,
        fieldName,
        itemTableHead,
        setFieldValue,
        allValues
      );
      setModalOpen(false); // Close the modal after processing
    } catch (error) {
      console.error('Error processing auto-fill fields:', error);
      // Optionally, handle the error by showing a notification or setting an error state
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  return {
    isModalOpen,
    isLoading,
    setModalOpen,
    handleProcessAutoFill
  };
};

export default useAutoFill;
