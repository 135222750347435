import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import { FIELD_TO_OVERRIDE } from 'src/constant/constant';
import {
  capitalizeAndRemoveChar,
  getFieldType
} from 'src/services/commonService';
import CustomMultiSelectField from 'src/components/CustomFields/CustomMultiSelectField';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import FlatArrayField from 'src/components/CustomFields/FlatArrayField';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import PhoneTextField from '../PhoneTextField';
import NestedForm from './NestedForm';
import DTAutocomplete from './DTAutocomplete';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import { getFieldOptions } from 'src/utils/getFieldOptions';
import InfoAdornment from './InfoAdornment';
import { getAllAutoFillFieldName } from 'src/utils/getAllAutoFillFieldName';
import FieldOverrideHandler from './FieldOverrideHandler';
import ConfirmationModal from 'src/components/ConfirmationModal';
import CustomDateField from 'src/components/CustomFields/CustomDateField';
import useAutoFill from './useAutoFill';
import StringArrayField from './StringArrayField';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomAddressField from 'src/components/CustomFields/CustomAddressField';

const GetFieldBasedOnSchema = ({
  fieldName,
  schema,
  name,
  value,
  itemTableHead = null,
  label = null,
  touched,
  error,
  entitySetting,
  index
}) => {
  const { setFieldValue, handleChange, handleBlur, values } =
    useFormikContext();

  const {
    fieldType,
    isRequired,
    fieldLabel,
    fieldPlaceholder,
    isFieldDisabled,
    fieldInfo,
    autoFillByFieldName,
    allAutoFillFieldName
  } = useMemo(() => {
    let fieldType = getFieldType(itemTableHead || fieldName, schema);
    let fieldLabel = capitalizeAndRemoveChar(
      label || itemTableHead || fieldName
    );
    let options = getFieldOptions(itemTableHead || fieldName, schema);
    let isRequired = Boolean(options?.required);
    let fieldPlaceholder = options?.placeholder ?? null;
    let fieldInfo = options?.info ?? null;
    let isFieldDisabled = Boolean(options?.readOnly);
    let autoFillByFieldName = options?.afb ?? null;
    let allAutoFillFieldName = autoFillByFieldName
      ? getAllAutoFillFieldName(schema, autoFillByFieldName)
      : [];
    return {
      fieldType,
      isRequired,
      fieldLabel,
      fieldPlaceholder,
      isFieldDisabled,
      fieldInfo,
      autoFillByFieldName,
      allAutoFillFieldName
    };
  }, [schema, label, itemTableHead, fieldName]);

  // Use the custom hook for auto fill field
  const { isModalOpen, isLoading, setModalOpen, handleProcessAutoFill } =
    useAutoFill({
      autoFillByFieldName,
      allAutoFillFieldName,
      value,
      index,
      fieldName,
      itemTableHead
    });

  const {
    isPhoneNumber,
    isPhoneNumberArray,
    isAddress,
    isBoolean,
    isNumber,
    isString,
    isStringHasMaxNull,
    isDate,
    isStringEnum,
    isNumberEnum,
    isObjectID,
    isObject,
    isArray,
    isArrayEnum,
    isArrayWithObjectID,
    isArrayWithMultipleProperties,
    enumValues
  } = fieldType;

  // Common props for form fields
  const commonProps = {
    name,
    ...(!itemTableHead && { label: fieldLabel }),
    placeholder: fieldPlaceholder || (itemTableHead ? `Add ${fieldLabel}` : ''),
    // size: itemTableHead ? 'small' : 'medium',
    size: 'small',
    required: isRequired,
    readOnly: isFieldDisabled,
    touched: touched,
    error: error,
    ...(fieldInfo && {
      InputProps: {
        endAdornment: <InfoAdornment info={fieldInfo} />
      }
    })
  };

  /* Handle field overrides */
  if (FIELD_TO_OVERRIDE.includes(itemTableHead || fieldName)) {
    return (
      <FieldOverrideHandler
        fieldName={itemTableHead || fieldName}
        name={name}
        value={value}
      />
    );
  }
  return (
    <>
      {isBoolean && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(value)}
              name={name}
              indeterminate={value === null}
              onChange={(event) => setFieldValue(name, event.target.checked)}
              onBlur={handleBlur}
              disabled={isFieldDisabled}
            />
          }
          label={fieldLabel}
        />
      )}

      {isString && isPhoneNumber && (
        <PhoneTextField
          {...commonProps}
          value={value || ''}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
        />
      )}

      {isArray && isPhoneNumberArray && (
        <StringArrayField
          entitySetting={entitySetting}
          fieldName={fieldName}
          schema={schema}
          name={name}
          value={value || []}
          touched={touched}
          error={error}
          setFieldValue={setFieldValue}
        />
      )}

      {isAddress && (
        <CustomAddressField
          {...commonProps}
          value={value}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
        />
      )}

      {((isString && !isStringEnum && !isStringHasMaxNull && !isPhoneNumber) ||
        (isNumber && !isNumberEnum)) && (
        <CustomTextField
          {...commonProps}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ type: isNumber ? 'number' : 'text' }}
          InputLabelProps={isDate ? { shrink: true } : {}}
        />
      )}
      {isDate && (
        <CustomDateField
          {...commonProps}
          value={value}
          onBlur={handleBlur}
          onChange={(date) => setFieldValue(name, date)}
        />
      )}
      {(isStringEnum || isNumberEnum) && (
        <CustomSelectField
          {...commonProps}
          value={value}
          options={enumValues}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}
      {isArray &&
        !isPhoneNumberArray &&
        !isArrayEnum &&
        !isArrayWithObjectID &&
        !isArrayWithMultipleProperties && (
          <FlatArrayField
            {...commonProps}
            value={value || []}
            onBlur={handleBlur}
            onChange={(value) => setFieldValue(name, value)}
          />
        )}
      {isArrayEnum &&
        !isPhoneNumberArray &&
        !isArrayWithObjectID &&
        !isArrayWithMultipleProperties && (
          <CustomMultiSelectField
            {...commonProps}
            value={value}
            options={enumValues}
            onChange={(e) => {
              setFieldValue(name, e.target.value);
            }}
            onBlur={handleBlur}
          />
        )}
      {(isObjectID || isArrayWithObjectID) && !isAddress && (
        <DTAutocomplete
          {...commonProps}
          fieldName={fieldName}
          itemTableHead={itemTableHead}
          isMultiple={isArrayWithObjectID}
          value={value}
          schema={schema}
          onChange={(value) => setFieldValue(name, value || '')}
          entitySetting={entitySetting}
          onBlur={handleBlur}
        />
      )}
      {isStringHasMaxNull && (
        <QuillEditorField
          {...commonProps}
          onChange={(value) => setFieldValue(name, value)}
          value={value}
          onBlur={handleBlur}
        />
      )}
      {(isArrayWithMultipleProperties || isObject) && (
        <NestedForm
          entitySetting={entitySetting}
          fieldName={fieldName}
          schema={schema}
          name={name}
          value={value}
          touched={touched}
          error={error}
        />
      )}

      <ConfirmationModal
        title="Confirm Auto-Fill"
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        isLoading={isLoading}
        description="Are you sure you want to proceed with auto-filling this field? This action will override the current value."
        confirmBtnConfig={{
          name: 'Yes, Proceed',
          variant: 'contained',
          color: 'primary'
        }}
        wayOutBtnConfig={{
          name: 'Cancel',
          variant: 'outlined',
          color: 'secondary'
        }}
        onConfirm={handleProcessAutoFill}
      />
    </>
  );
};

export default GetFieldBasedOnSchema;
