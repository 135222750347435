import { getFieldType } from 'src/services/commonService';

export const sanitizeFormData = (values, schema, fieldNames) => {
  const set = {};
  const unset = {};
  const createData = {};
  const filteredFieldNames = fieldNames.filter((field) => field !== 'number');
  filteredFieldNames.forEach((field) => {
    const sanitizedValue = processData(field, values[field], schema);
    if (sanitizedValue !== null) {
      set[field] = sanitizedValue;
      createData[field] = sanitizedValue;
    } else {
      unset[field] = ''; // Use an empty string to indicate removal
    }
  });
  return { set, unset, createData };
};

export const processData = (field, value, schema) => {
  const {
    isObjectID,
    isEmbedded,
    isArrayWithObjectID,
    isArrayWithMultipleProperties,
    isObject
  } = getFieldType(field, schema);
  if (isObjectID) {
    return value?.id || null;
  }

  if (isArrayWithObjectID) {
    return value?.length > 0 ? value.map((val) => val.id) : null;
  }

  if (isArrayWithMultipleProperties) {
    let nestedSchema = schema[field]?.$embeddedSchemaType?.schema?.paths;
    const nestedSchemaKeys = Object.keys(nestedSchema).filter(
      (key) => key !== '_id'
    );

    const sanitizedArray = value
      ?.map((nestedItem) =>
        nestedSchemaKeys.reduce((sanitizedItem, nestedSchemaKey) => {
          if (nestedItem?.[nestedSchemaKey]) {
            let nestedValue = processData(
              nestedSchemaKey,
              nestedItem[nestedSchemaKey],
              nestedSchema
            );
            if (nestedValue) {
              sanitizedItem[nestedSchemaKey] = nestedValue;
            }
          }
          return sanitizedItem;
        }, {})
      )
      .filter((nestedItem) => Object.keys(nestedItem).length > 0);
    return sanitizedArray?.length > 0 ? sanitizedArray : null;
  }

  if (isObject) {
    let objectSchema = schema[field]?.schema;
    const objectSchemaKeys = Object.keys(objectSchema).filter(
      (key) => key !== '_id'
    );

    let result = objectSchemaKeys.reduce((acc, objectSchemaKey) => {
      if (value?.[objectSchemaKey]) {
        let objectValue = processData(
          objectSchemaKey,
          value[objectSchemaKey],
          objectSchema
        );
        if (objectValue) {
          acc[objectSchemaKey] = objectValue;
        }
      }
      return acc;
    }, {});

    return Object.keys(result).length > 0 ? result : null;
  }
  if (isEmbedded) {
    let embeddedSchema = schema[field]?.schema?.paths;
    const embeddedSchemaKeys = Object.keys(embeddedSchema).filter(
      (key) => key !== '_id'
    );

    let embeddedData = embeddedSchemaKeys.reduce((acc, embeddedKey) => {
      if (value?.[embeddedKey]) {
        let embeddedValue = processData(
          embeddedKey,
          value[embeddedKey],
          embeddedSchema
        );
        if (embeddedValue) {
          acc[embeddedKey] = embeddedValue;
        }
      }
      return acc;
    }, {});

    return Object.keys(embeddedData).length > 0 ? embeddedData : null;
  }

  if (
    value !== '' &&
    value !== undefined &&
    value !== null &&
    (!Array.isArray(value) || value.length > 0)
  ) {
    return value;
  }

  return null;
};

// import { getFieldType } from 'src/services/commonService';

// export const sanitizeFormData = (values, schema, fieldNames) => {
//   const set = {};
//   const unset = {};
//   const createData = {};
//   const filteredFieldNames = fieldNames.filter((field) => field !== 'number');
//   filteredFieldNames.forEach((field) => {
//     const sanitizedValue = processData(field, values[field], schema);
//     if (sanitizedValue !== null) {
//       set[field] = sanitizedValue;
//       createData[field] = sanitizedValue;
//     } else {
//       unset[field] = ''; // Use an empty string to indicate removal
//     }
//   });

//   return { set, unset, createData };
// };

// export const processData = (field, value, schema) => {
//   const {
//     isObjectID,
//     isArrayWithObjectID,
//     isArrayWithMultipleProperties,
//     isObject
//   } = getFieldType(field, schema);
//   if (isObjectID) {
//     return value?.id || null;
//   }

//   if (isArrayWithObjectID) {
//     return Boolean(value.length) ? value.map((val) => val.id) : null;
//   }

//   if (isArrayWithMultipleProperties) {
//     let nestedData = value;
//     const nestedSchema = schema[field]?.$embeddedSchemaType?.schema?.paths;
//     const nestedSchemaKeys = Object.keys(nestedSchema).filter(
//       (key) => key !== '_id'
//     );
//     nestedSchemaKeys.forEach((nestedSchemaKey) => {
//       const {
//         isObjectID: isNestedObjectID,
//         isArrayWithObjectID: isNestedArrayWithObjectID
//       } = getFieldType(nestedSchemaKey, nestedSchema);

//       if (isNestedObjectID) {
//         nestedData = nestedData?.map((nestedItem) => ({
//           ...nestedItem,
//           [nestedSchemaKey]: nestedItem[nestedSchemaKey]?.id || ''
//         }));
//       }

//       if (isNestedArrayWithObjectID) {
//         nestedData = nestedData?.map((nestedItem) => ({
//           ...nestedItem,
//           [nestedSchemaKey]: (nestedItem[nestedSchemaKey] || [])?.map(
//             (val) => val.id
//           )
//         }));
//       }
//     });

//     nestedData = nestedData
//       ?.map((nestedItem) =>
//         nestedSchemaKeys.reduce((sanitizedItem, key) => {
//           const value = nestedItem[key];
//           if (Array.isArray(value) && Boolean(value.length)) {
//             sanitizedItem[key] = value;
//           } else if (
//             !Array.isArray(value) &&
//             value !== '' &&
//             value !== undefined &&
//             value !== null
//           ) {
//             sanitizedItem[key] = value;
//           }
//           return sanitizedItem;
//         }, {})
//       )
//       .filter((nestedItem) => Object.keys(nestedItem).length > 0);
//     return nestedData.length > 0 ? nestedData : null;
//   }

//   if (isObject) {
//     let objectData = value;
//     const objectSchema = schema[field]?.schema;
//     const objectSchemaKeys = Object.keys(objectSchema).filter(
//       (key) => key !== '_id'
//     );
//     objectSchemaKeys.forEach((objectSchemaKey) => {
//       const {
//         isObjectID: isObjectFieldID,
//         isArrayWithObjectID: isArrayWithObjectIDField
//       } = getFieldType(objectSchemaKey, objectSchema);

//       if (isObjectFieldID) {
//         objectData = {
//           ...objectData,
//           [objectSchemaKey]: objectData[objectSchemaKey].id
//         };
//       }

//       if (isArrayWithObjectIDField) {
//         objectData = {
//           ...objectData,
//           [objectSchemaKey]: objectData?.[objectSchemaKey]?.map((val) => val.id)
//         };
//       }
//     });

//     // Remove empty values
//     objectData = objectSchemaKeys.reduce((acc, key) => {
//       const value = objectData[key];
//       if (Array.isArray(value) && value.length > 0) {
//         acc[key] = value;
//       } else if (
//         !Array.isArray(value) &&
//         value !== '' &&
//         value !== undefined &&
//         value !== null
//       ) {
//         acc[key] = value;
//       }
//       return acc;
//     }, {});
//     return Object.keys(objectData).length > 0 ? objectData : null;
//   }

//   // For other types of fields (like strings, numbers, etc.)
//   if (
//     value !== '' &&
//     value !== undefined &&
//     value !== null &&
//     (!Array.isArray(value) || value.length > 0)
//   ) {
//     return value;
//   }

//   return null;
// };
