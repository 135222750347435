import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack
} from '@mui/material';
import { Formik } from 'formik';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import useDynamicFormLogic from 'src/hooks/useDynamicFormLogic';
import { useSelector } from 'react-redux';
import { selectStatus } from 'src/slices/dynamicSlice';
import DyTabForm from './DyTabForm';
import { getFieldOptions } from 'src/utils/getFieldOptions';
import { useMemo } from 'react';
import { requestApi } from 'src/mocks/request';
const DyFormBody = ({ currentItem = null }) => {
  const status = useSelector(selectStatus);
  const {
    fieldNames,
    initialValues,
    validationSchema,
    schema,
    entitySetting,
    handleFormSubmit
  } = useDynamicFormLogic({
    currentItem
  });
  // Memoized computation of dependent fields
  const dependentFields = useMemo(() => {
    return fieldNames.filter((fieldName) => {
      const fieldOptions = getFieldOptions(fieldName, schema);
      return fieldOptions?.dependents?.length > 0;
    });
  }, [fieldNames, schema]);

  if (!initialValues || !validationSchema) return <CircularProgress />;
  return (
    <Card variant="outlined">
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ errors, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {fieldNames.map((fieldName, index) => (
                  <Grid
                    item
                    xs={12}
                    md={
                      schema[fieldName]?.instance === 'Array' &&
                      schema[fieldName]?.$embeddedSchemaType?.schema &&
                      Object.keys(
                        schema[fieldName]?.$embeddedSchemaType?.schema?.paths
                      )?.length > 0
                        ? 12
                        : schema[fieldName]?.instance === 'String' &&
                          schema[fieldName]?.options?.max === null
                        ? 12
                        : schema[fieldName]?.instance === 'Object'
                        ? 12
                        : 6
                    }
                    key={`${index}_${fieldName}_checkfield`}
                  >
                    <GetFieldBasedOnSchema
                      name={fieldName}
                      fieldName={fieldName}
                      entitySetting={entitySetting}
                      schema={schema}
                      value={values[fieldName]}
                      error={errors[fieldName]}
                      touched={touched[fieldName]}
                    />
                  </Grid>
                ))}
                {dependentFields.map((fieldName, index) => (
                  <Grid item xs={12} key={index}>
                    <DyTabForm fieldName={fieldName} index />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={1}
                  mt={4}
                >
                  <Button
                    type="submit"
                    startIcon={
                      currentItem ? (
                        status.updateData ? (
                          <CircularProgress size="1rem" />
                        ) : null
                      ) : status.createData ? (
                        <CircularProgress size="1rem" />
                      ) : null
                    }
                    disabled={
                      currentItem ? status.updateData : status.createData
                    }
                    variant="contained"
                  >
                    Save
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default DyFormBody;
