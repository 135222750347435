import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import FormBody from './FormBody';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import CreateEditPageLoader from 'src/components/SkeltonLoaders/CreateEditPageLoader';

const WorkOrderForm = () => {
  const { currentItem, isFetching } = useDyFetchDataById();
  if (isFetching) return <CreateEditPageLoader />;
  return (
    <>
      <PageTitleWrapper>
        <PageHeader currentItem={currentItem} />
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <FormBody currentItem={currentItem} />
        </Grid>
      </Grid>
    </>
  );
};

WorkOrderForm.propTypes = {
  currentItem: PropTypes.object
};

export default WorkOrderForm;
