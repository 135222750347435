import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom';

const RootWrapper = styled(Box)(
  () => `
            flex: 1;
        `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader({ currentItem, submitting }) {
  const navigate = useNavigate();

  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h3" component="h3" gutterBottom>
                Initial Stock
              </Typography>
              <Typography variant="body2" gutterBottom>
                {currentItem?.name}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={0.5}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="submit"
              startIcon={submitting && <CircularProgress size={'1rem'} />}
              disabled={submitting}
            >
              Update Stock Entry
            </Button>
            <Tooltip title={`Go back`}>
              <IconButtonWrapper
                disabled={submitting}
                onClick={() => navigate(-1)}
              >
                <ArrowBackTwoToneIcon />
              </IconButtonWrapper>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;

// import {
//   Box,
//   Button,
//   CircularProgress,
//   Grid,
//   IconButton,
//   Stack,
//   Tooltip,
//   Typography,
//   alpha,
//   styled
// } from '@mui/material';
// import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
// import { useNavigate } from 'react-router-dom';

// const RootWrapper = styled(Box)(
//   () => `
//             flex: 1;
//         `
// );

// const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.primary.contrastText,
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.primary.main, 0.8)
//   }
// }));

// function PageHeader({ submitting }) {
//   const navigate = useNavigate();

//   return (
//     <RootWrapper>
//       <Grid
//         container
//         justifyContent="space-between"
//         alignItems="center"
//         spacing={1}
//       >
//         <Grid item>
//           <Box
//             display="flex"
//             alignItems="center"
//             justifyContent="space-between"
//           >
//             <Stack direction={'row'} alignItems={'center'} spacing={1}>
//               <Typography variant="h3" component="h3" gutterBottom>
//                 Initial Stock
//               </Typography>
//             </Stack>
//           </Box>
//         </Grid>
//         <Grid item>
//           <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
//             <Button
//               variant="outlined"
//               color="primary"
//               type="submit"
//               startIcon={submitting && <CircularProgress size={'1rem'} />}
//               disabled={submitting}
//             >
//               Make Stock Entry
//             </Button>
//             <Tooltip title={`Go back`}>
//               <IconButtonWrapper onClick={() => navigate(-1)}>
//                 <ArrowBackTwoToneIcon />
//               </IconButtonWrapper>
//             </Tooltip>
//           </Stack>
//         </Grid>
//       </Grid>
//     </RootWrapper>
//   );
// }

// export default PageHeader;
