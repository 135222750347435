import { TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const CustomTextField = ({
  label,
  sx = {},
  value,
  size = 'medium',
  onChange,
  name = '',
  variant = 'outlined',
  inputProps = {},
  touched,
  disabled = false,
  error,
  fullWidth = true,
  placeholder = '',
  readOnly = false,
  required = false,
  InputLabelProps = {},
  InputProps = {},
  margin = 'none',
  onBlur = () => {},
  onKeyDown = () => {}
}) => {
  const inputRef = useRef(null);

  // Prevent number input from changing value on scroll, but allow window scroll
  const preventScrollOnNumberInput = (e) => {
    if (
      inputRef.current === document.activeElement &&
      inputProps?.type === 'number'
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement && inputProps?.type === 'number') {
      inputElement.addEventListener('wheel', preventScrollOnNumberInput, {
        passive: false
      });
    }
    return () => {
      if (inputElement && inputProps?.type === 'number') {
        inputElement.removeEventListener('wheel', preventScrollOnNumberInput);
      }
    };
  }, [inputProps?.type]);

  return (
    <TextField
      inputRef={inputRef}
      sx={sx}
      onBlur={onBlur}
      size={size}
      label={label}
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      onChange={onChange}
      name={name}
      margin={margin}
      placeholder={placeholder}
      fullWidth={fullWidth}
      variant={variant}
      inputProps={{ ...inputProps, readOnly }}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      error={Boolean(touched && error)}
      helperText={(touched && error) || ''}
      onKeyDown={onKeyDown}
    />
  );
};

export default CustomTextField;
