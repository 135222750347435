import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useFormikContext } from 'formik';
import useSearch from 'src/hooks/useSearch';
import TQTableRow from './TQTableRow';
import { useState } from 'react';
import TQSearchField from './TQSearchField';
import Scrollbar from 'src/components/Scrollbar';

const TestQualityCard = ({ currentItem }) => {
  const theme = useTheme();
  const { values } = useFormikContext();
  const { tests } = values;
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [showSearchField, setShowSearchField] = useState(false);
  const { searchTerm, handleSearchChange, filteredItems } = useSearch(
    tests,
    'parameter'
  );
  const handleShowSearchField = () => {
    setShowSearchField(!showSearchField);
  };
  return (
    <Card variant="outlined">
      {(!showSearchField || !isXs) && (
        <CardHeader
          action={
            <TQSearchField
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              handleShowSearchField={handleShowSearchField}
              showSearchField={showSearchField}
              showSearchIcon={true}
            />
          }
          title="Test"
        />
      )}
      <Stack
        p={1}
        sx={{
          display: { xs: showSearchField ? 'flex' : 'none', sm: 'none' }
        }}
        direction={'row'}
        spacing={0.5}
        alignItems={'center'}
      >
        <TQSearchField
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          handleShowSearchField={handleShowSearchField}
          showSearchField={showSearchField}
          showSearchIcon={false}
        />
      </Stack>
      <Divider />
      <CardContent>
        <Scrollbar maxHeight={'auto'}>
          <Table
            sx={{
              minWidth: 1000,
              tableLayout: 'fixed'
            }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell width={'25%'}>Test Procedure</TableCell>
                <TableCell>Specification</TableCell>
                <TableCell width={'30%'}>Test Result</TableCell>
                <TableCell>Pass</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item, index) => (
                <TQTableRow
                  key={`item_${index}`}
                  item={item}
                  path={`tests[${index}]`}
                  currentItem={currentItem}
                />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </CardContent>
    </Card>
  );
};

export default TestQualityCard;
