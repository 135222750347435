import { Chip, IconButton, Stack, Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CustomAvatar from 'src/components/CustomAvatar';

const UserTableCell = ({ user, defaultText = 'No assignee' }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.2}>
      {user ? (
        <Chip
          avatar={
            <CustomAvatar
              name={user.name || user.username}
              src={user?.avatar}
            />
          }
          label={user?.name || user?.username}
          variant="outlined"
          sx={{ textTransform: 'capitalize' }}
        />
      ) : (
        <>
          <IconButton disableRipple color="primary">
            <AccountCircleOutlinedIcon />
          </IconButton>
          <Typography variant="body1" textTransform={'capitalize'}>
            {defaultText}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default UserTableCell;

// import { IconButton, Stack, Typography } from '@mui/material';
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import CustomAvatar from 'src/components/CustomAvatar';

// const UserTableCell = ({ user, defaultText = 'No assignee' }) => {
//   return (
//     <Stack direction={'row'} alignItems={'center'} spacing={0.2}>
//       {user ? (
//         <CustomAvatar
//           name={user.name || user.username}
//           src={user?.avatar}
//           sx={{ cursor: 'pointer', width: 25, height: 25 }}
//         />
//       ) : (
//         <IconButton disableRipple color="primary">
//           <AccountCircleOutlinedIcon />
//         </IconButton>
//       )}
//       <Typography variant="body1" textTransform={'capitalize'}>
//         {user?.name || user?.username || defaultText}
//       </Typography>
//     </Stack>
//   );
// };

// export default UserTableCell;
