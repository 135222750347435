import { REMOVE_FIELDS } from 'src/constant/constant';

export const getFieldType = (fieldName, schema) => {
  const isPhoneNumber =
    schema?.[fieldName]?.instance === 'String' &&
    schema?.[fieldName]?.path === 'phone';

  const isPhoneNumberArray =
    schema?.[fieldName]?.path === 'other_phones' &&
    schema?.[fieldName]?.instance === 'Array' &&
    schema?.[fieldName]?.$embeddedSchemaType?.instance === 'String';

  const isAddress = schema?.[fieldName]?.path === 'address';
  const isEmbedded = schema?.[fieldName]?.instance === 'Embedded';
  const isBoolean = schema?.[fieldName]?.instance === 'Boolean';
  const isObject = schema?.[fieldName]?.instance === 'Object';
  const isNumber = schema?.[fieldName]?.instance === 'Number';
  const isString = schema?.[fieldName]?.instance === 'String';
  const isStringHasMaxNull =
    isString && schema?.[fieldName]?.options?.max === null;
  const isDate = schema?.[fieldName]?.instance === 'Date';
  const isStringEnum = isString && schema?.[fieldName]?.enumValues?.length > 0;
  const isNumberEnum =
    isNumber && schema?.[fieldName]?.options?.enum?.length > 0;
  const isObjectID = schema?.[fieldName]?.instance === 'ObjectID';
  const isArray = schema?.[fieldName]?.instance === 'Array';
  const isArrayEnum =
    isArray && schema[fieldName]?.$embeddedSchemaType?.enumValues?.length > 0;
  const isArrayWithObjectID =
    isArray &&
    schema?.[fieldName]?.$embeddedSchemaType?.instance === 'ObjectID';
  const isArrayWithMultipleProperties =
    !isArrayWithObjectID &&
    schema?.[fieldName]?.$embeddedSchemaType?.schema &&
    Object.keys(schema[fieldName]?.$embeddedSchemaType?.schema?.paths)?.length >
      0;
  let arrayWithMulProKeys = null;
  let arrayWithMulProSchema = null;
  if (isArrayWithMultipleProperties) {
    arrayWithMulProKeys = Object.keys(
      schema?.[fieldName]?.$embeddedSchemaType?.schema?.paths
    );
    arrayWithMulProSchema =
      schema?.[fieldName]?.$embeddedSchemaType?.schema?.paths;
  }
  let enumValues;

  if (isStringEnum) {
    enumValues = schema?.[fieldName]?.enumValues;
  } else if (isNumberEnum) {
    enumValues = schema?.[fieldName]?.options?.enum;
  } else if (isArrayEnum) {
    enumValues = schema[fieldName]?.$embeddedSchemaType?.enumValues;
  } else {
    enumValues = [];
  }

  return {
    isPhoneNumber,
    isPhoneNumberArray,
    isAddress,
    isBoolean,
    isNumber,
    isString,
    isEmbedded,
    isStringHasMaxNull,
    isDate,
    isStringEnum,
    isNumberEnum,
    isObjectID,
    isObject,
    isArray,
    isArrayWithObjectID,
    isArrayWithMultipleProperties,
    enumValues,
    isArrayEnum,
    arrayWithMulProKeys,
    arrayWithMulProSchema
  };
};

export const getPopulateString = (schema, fieldNames) => {
  if (!fieldNames || fieldNames.length === 0) {
    return '';
  }

  const filteredFieldNames = fieldNames.flatMap((fieldName) => {
    // console.log(fieldName, 'fieldNamesfieldNamesfieldNames');
    const {
      isObjectID,
      isArrayWithObjectID,
      isArrayWithMultipleProperties,
      arrayWithMulProKeys,
      arrayWithMulProSchema
    } = getFieldType(fieldName, schema);

    if (isObjectID || isArrayWithObjectID) {
      return [fieldName];
    }

    if (isArrayWithMultipleProperties) {
      const objectFieldNames = arrayWithMulProKeys
        .filter((key) => {
          const { isObjectID, isArrayWithObjectID } = getFieldType(
            key,
            arrayWithMulProSchema
          );
          return isObjectID || isArrayWithObjectID;
        })
        .map((key) => `${fieldName}.${key}`);

      return objectFieldNames;
    }

    return [];
  });
  return filteredFieldNames.join(' ');
};

export const transformSchema = (schema) => {
  const result = {};

  Object.keys(schema).forEach((key) => {
    const keyParts = key.split('.');

    if (keyParts.length === 1) {
      // No dot in the key, add it directly to the result
      result[key] = schema[key];
    } else {
      // Dot in the key, transform it into nested objects
      let current = result;
      keyParts.forEach((part, index) => {
        if (index === 0) {
          // First part of the key, set up the instance and schema
          if (!current[part]) {
            current[part] = { instance: 'Object', schema: {} };
          }
          current = current[part].schema;
        } else {
          // Last part of the key, add the value
          current[part] = schema[key];
        }
      });
    }
  });
  return result;
};

export const getSanitizedValue = (field, value, schema) => {
  const {
    isObjectID,
    isArrayWithObjectID,
    isArrayWithMultipleProperties,
    isObject
  } = getFieldType(field, schema);
  if (isObjectID) {
    return value?.id || null;
  }

  if (isArrayWithObjectID) {
    return Boolean(value.length) ? value.map((val) => val.id) : null;
  }

  if (isArrayWithMultipleProperties) {
    let nestedData = value;
    const nestedSchema = schema[field]?.$embeddedSchemaType?.schema?.paths;
    const nestedSchemaKeys = Object.keys(nestedSchema).filter(
      (key) => key !== '_id'
    );
    nestedSchemaKeys.forEach((nestedSchemaKey) => {
      const {
        isObjectID: isNestedObjectID,
        isArrayWithObjectID: isNestedArrayWithObjectID
      } = getFieldType(nestedSchemaKey, nestedSchema);

      if (isNestedObjectID) {
        nestedData = nestedData.map((nestedItem) => ({
          ...nestedItem,
          [nestedSchemaKey]: nestedItem[nestedSchemaKey]?.id || ''
        }));
      }

      if (isNestedArrayWithObjectID) {
        nestedData = nestedData.map((nestedItem) => ({
          ...nestedItem,
          [nestedSchemaKey]: nestedItem[nestedSchemaKey]?.map((val) => val.id)
        }));
      }
    });

    nestedData = nestedData
      ?.map((nestedItem) =>
        nestedSchemaKeys.reduce((sanitizedItem, key) => {
          const value = nestedItem[key];
          if (Array.isArray(value) && Boolean(value.length)) {
            sanitizedItem[key] = value;
          } else if (
            !Array.isArray(value) &&
            value !== '' &&
            value !== undefined &&
            value !== null
          ) {
            sanitizedItem[key] = value;
          }
          return sanitizedItem;
        }, {})
      )
      .filter((nestedItem) => Object.keys(nestedItem).length > 0);
    return nestedData.length > 0 ? nestedData : null;
  }

  if (isObject) {
    let objectData = value;
    const objectSchema = schema[field]?.schema;
    const objectSchemaKeys = Object.keys(objectSchema);

    objectSchemaKeys.forEach((objectSchemaKey) => {
      if (objectSchemaKey !== '_id') {
        const {
          isObjectID: isObjectFieldID,
          isArrayWithObjectID: isArrayWithObjectIDField
        } = getFieldType(objectSchemaKey, objectSchema);

        if (isObjectFieldID) {
          objectData = {
            ...objectData,
            [objectSchemaKey]: objectData[objectSchemaKey].id
          };
        }

        if (isArrayWithObjectIDField) {
          objectData = {
            ...objectData,
            [objectSchemaKey]: objectData[objectSchemaKey].map((val) => val.id)
          };
        }
      }
    });

    // Remove empty values
    objectData = Object.keys(objectData).reduce((acc, key) => {
      const value = objectData[key];
      if (field === 'address')
        if (Array.isArray(value) && value.length > 0) {
          if (field === 'address') console.log(value, 'inaddress');
          acc[key] = value;
        } else if (
          !Array.isArray(value) &&
          value !== '' &&
          value !== undefined &&
          value !== null
        ) {
          acc[key] = value;
        }
      return acc;
    }, {});
    return Object.keys(objectData).length > 0 ? objectData : null;
  }

  // For other types of fields (like strings, numbers, etc.)
  if (
    value !== '' &&
    value !== undefined &&
    value !== null &&
    (!Array.isArray(value) || value.length > 0)
  ) {
    return value;
  }

  return null;
};

export const transformSubmitData = async (values, schema) => {
  console.log(values, 'beforeSanitizeData');

  const allFieldNames = Object.keys(schema);
  const FilteredFieldNames = allFieldNames.filter(
    (fieldName) => !REMOVE_FIELDS.includes(fieldName)
  );

  let set = {};
  let unset = {};
  let createData = {};

  FilteredFieldNames?.forEach((field) => {
    const sanitizedValue = getSanitizedValue(field, values[field], schema);
    if (sanitizedValue !== null) {
      set[field] = sanitizedValue;
      createData[field] = sanitizedValue; // For creation, we include valid fields
    } else {
      unset[field] = '';
    }
  });

  // For update: use $set and $unset
  const sanitizedUpdateData = {
    $set: { ...set },
    $unset: { ...unset }
  };

  // For create: use plain object
  const sanitizedCreateData = { ...createData };

  return { sanitizedUpdateData, sanitizedCreateData };
};

export const getAllObjectIdFields = (schema, fieldNames) => {
  if (!schema || !fieldNames) return;

  const objectIdFieldNames = [];

  fieldNames?.filter((fieldName) => {
    if (schema?.[fieldName]?.instance === 'ObjectID') {
      objectIdFieldNames.push(fieldName);
    }

    if (
      schema?.[fieldName]?.instance === 'Array' &&
      Array.isArray(schema?.[fieldName]?.options?.type)
    ) {
      if (schema?.[fieldName]?.$embeddedSchemaType?.instance === 'ObjectID') {
        objectIdFieldNames.push(fieldName);
      }

      const embeddedFields =
        schema?.[fieldName]?.$embeddedSchemaType?.schema?.paths;

      if (typeof embeddedFields === 'object') {
        Object?.keys(embeddedFields)?.map((field) => {
          if (embeddedFields?.[field]?.instance === 'ObjectID') {
            objectIdFieldNames.push(embeddedFields?.[field]?.path);
          }
        });
      }
    }
  });

  return objectIdFieldNames;
};

export const transformData = (data) => {
  const transform = (obj) => {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        // If the object is an array, transform each item in the array
        return obj.map((item) => transform(item));
      } else {
        const transformed = {};
        for (const key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (obj[key].id) {
              // If the object's value has an 'id', replace the object with its 'id'
              transformed[key] = obj[key].id;
            } else {
              // Recursively transform nested objects
              transformed[key] = transform(obj[key]);
            }
          } else {
            // For primitive values, just assign them directly
            transformed[key] = obj[key];
          }
        }
        return transformed;
      }
    }
    // Return primitive values as they are
    return obj;
  };
  return transform(data);
};

export const filterObjectIdFields = (data, objectIdFields) => {
  if (Array.isArray(data)) {
    // Apply the filterObjectIdFields function recursively if data is array
    return data?.map((item) => filterObjectIdFields(item, objectIdFields));
  } else if (data !== null && typeof data === 'object') {
    const filteredData = {};
    for (const key in data) {
      if (objectIdFields.includes(key)) {
        // If the value of the key is not an empty string, null, or undefined
        if (data[key] !== '' && data[key] !== null && data[key] !== undefined) {
          filteredData[key] = filterObjectIdFields(data[key], objectIdFields);
        }
        // do not add this key to filteredData (thus removing it)
      } else {
        filteredData[key] = filterObjectIdFields(data[key], objectIdFields);
      }
    }
    return filteredData;
  } else {
    return data;
  }
};

/**
 * Checks if a given field name in the schema is required.
 *
 * @param {string} fieldName - The name of the field to check.
 * @param {object} schema - The schema object containing field definitions.
 * @returns {boolean} - Returns true if the field is required, otherwise false.
 */
export const isFieldRequired = (fieldName, schema) => {
  // Retrieve the schema definition for the given field name
  const fieldSchema = schema[fieldName];

  // Return false if the field schema is not defined
  if (!fieldSchema) {
    return false;
  }

  // Determine if the field is required based on its type
  switch (fieldSchema.instance) {
    case 'String':
    case 'Number':
    case 'Boolean':
    case 'Date':
    case 'ObjectID':
      // Return true if the field is required
      return !!fieldSchema?.options?.required;

    case 'Array': {
      // Get details about the array type
      const {
        isArrayWithObjectID,
        isArray
        // isArrayWithMultipleProperties,
        // arrayWithMulProKeys,
        // arrayWithMulProSchema
      } = getFieldType(fieldName, schema);

      if (isArrayWithObjectID) {
        // Return true if the embedded schema type is required
        return !!fieldSchema?.$embeddedSchemaType?.options?.required;
      }
      // else if (isArrayWithMultipleProperties) {
      //   // Return true if any of the multiple properties in the array are required
      //   return arrayWithMulProKeys.some(
      //     (key) => !!arrayWithMulProSchema?.[key]?.options?.required
      //   );
      // }
      else if (isArray) {
        // Return true if the embedded schema type is required
        return !!fieldSchema?.$embeddedSchemaType?.options?.required;
      }
      break;
    }

    default:
      // Return false for unhandled field types
      return false;
  }

  // Return false if no condition matched
  return false;
};

/**
 * Retrieves a list of required field names from the schema.
 *
 * @param {object} schema - The schema object containing field definitions.
 * @param {string[]} fieldNames - An array of field names to check.
 * @returns {string[]} - An array of required field names.
 */
export const getAllRequiredFields = (fieldNames, schema) => {
  // Return an empty array if schema is not defined or fieldNames is empty
  if (!schema || fieldNames?.length === 0) {
    return [];
  }

  // Filter the field names to include only those that are required
  return fieldNames.filter((fieldName) => isFieldRequired(fieldName, schema));
};

/**
 * Capitalizes each word in a string after splitting it by a specified character.
 * Removes the specified character and replaces it with spaces.
 *
 * @param {string} str - The input string to be processed.
 * @param {string} charToRemove - The character to remove from the input string (default is '_').
 * @returns {string} - The processed string with words capitalized and spaces replacing the removed character.
 */
export const capitalizeAndRemoveChar = (str, charToRemove = '_') => {
  return str
    ?.split(charToRemove)
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Escapes special characters in a string for use in a regular expression.
 *
 * @param {string} string - The input string containing potential regex special characters.
 * @returns {string} - The input string with regex special characters escaped.
 */
export const escapeRegexSpecialChars = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape regex special characters
};

/**
 * Truncates the given text to a specified word limit,
 * appending an ellipsis (...) if the text exceeds the limit.
 *
 * @param {string} text - The text to truncate.
 * @param {number} wordLimit - The maximum number of words to display.
 * @returns {string} - Truncated text with ellipsis if necessary.
 */
export const truncateTextToWordLimit = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};
