import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import DOMPurify from 'dompurify';
const sanitizeHtml = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return cleanHtml.replace(/<[^>]*>?/gm, ''); // Remove remaining HTML tags
};
// Button configuration based on status
const buttonConfigs = [
  {
    btnName: 'On Hold',
    status: 'on-hold',
    icon: <PauseCircleOutlineIcon />,
    color: 'secondary'
  },
  {
    btnName: 'Passed',
    status: 'passed',
    icon: <CheckCircleIcon />,
    color: 'success'
  },
  {
    btnName: 'Failed',
    status: 'failed',
    icon: <CancelIcon />,
    color: 'error'
  }
];

const InspectionCard = ({ currentItem }) => {
  const { product, grn, tests, qc_pass, summary } = currentItem;

  // Match the button based on the qc_pass status
  const matchBtn = buttonConfigs.find(
    (buttonConfig) => buttonConfig.status === qc_pass
  );

  // Calculate the number of completed tests
  const completedTests = tests?.filter((test) => test.result).length || 0;
  const totalTests = tests?.length || 0;

  return (
    <Card variant="outlined">
      <CardHeader
        title="Inspection Overview"
        action={
          matchBtn && (
            <Button
              size="small"
              color={
                qc_pass === matchBtn?.status ? matchBtn?.color : 'secondary'
              }
              startIcon={matchBtn?.icon}
              variant={qc_pass === matchBtn?.status ? 'outlined' : 'text'}
              sx={{ ml: 1 }}
            >
              {matchBtn?.btnName}
            </Button>
          )
        }
      />
      <Divider />
      <CardContent>
        <Stack
          direction={{ md: 'row' }}
          flexWrap="wrap"
          gap={2}
          justifyContent="space-between"
        >
          {/* Product Details */}
          <Stack sx={{ flex: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Product
            </Typography>
            <Typography variant="body2">{product?.name}</Typography>
          </Stack>

          {/* Source Document */}
          <Stack sx={{ flex: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Source Document
            </Typography>
            <Typography>{grn?.number}</Typography>
          </Stack>

          {/* Assessment */}
          <Stack>
            <Typography variant="subtitle1" fontWeight="bold">
              Assessment
            </Typography>
            <Typography>
              {completedTests}/{totalTests}
            </Typography>
          </Stack>
        </Stack>

        {/* summary Section */}
        {summary && (
          <Stack>
            <Typography variant="subtitle1" fontWeight="bold">
              Analysis Summary
            </Typography>
            <Typography variant="body2">{sanitizeHtml(summary)}</Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default InspectionCard;
