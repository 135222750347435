import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import DTAutocomplete from 'src/components/GetFieldBasedOnSchema/DTAutocomplete';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import FieldWithMsg from './FieldWithMsg';
const AddSTableRow = ({ products }) => {
  const { setFieldValue, handleChange } = useFormikContext();
  const { schema, entitySetting } = useEntityPageConfig('audit_record');
  if (!schema) return null;
  return (
    <>
      <FieldArray name="products">
        {({ push, remove }) => (
          <>
            {products.map((product, proIdx) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={`product_${proIdx}`}
              >
                <TableCell>
                  <DTAutocomplete
                    label="Product"
                    size={'small'}
                    fieldName={'product'}
                    value={product?.product || ''}
                    schema={schema}
                    onChange={(value) =>
                      setFieldValue(`products[${proIdx}].product`, value)
                    }
                    entitySetting={entitySetting}
                  />
                </TableCell>

                <TableCell>
                  <DTAutocomplete
                    label="location"
                    size={'small'}
                    fieldName={'location'}
                    value={product?.location || ''}
                    schema={schema}
                    onChange={(value) =>
                      setFieldValue(`products[${proIdx}].location`, value)
                    }
                    entitySetting={entitySetting}
                  />
                </TableCell>
                <TableCell>
                  <CustomTextField
                    label={'Current'}
                    size="small"
                    name={`products[${proIdx}].system_quantity`}
                    value={product?.system_quantity || ''}
                    onChange={handleChange}
                    inputProps={{ type: 'number' }}
                  />
                </TableCell>
                <TableCell>
                  <FieldWithMsg
                    product={product}
                    path={`products[${proIdx}]`}
                  />
                </TableCell>

                <TableCell>
                  <Tooltip title={`Delete`}>
                    <IconButton color="primary" onClick={() => remove(proIdx)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4}>
                <Button
                  startIcon={<AddIcon fontSize="small" />}
                  size="small"
                  onClick={() =>
                    push({
                      product: '',
                      actual_quantity: '',
                      system_quantity: '',
                      reason: '',
                      location: ''
                    })
                  }
                >
                  {products.length ? 'Add More Products' : 'Add Products'}
                </Button>
              </TableCell>
            </TableRow>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default AddSTableRow;
