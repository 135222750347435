import {
  Box,
  Button,
  Card,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  TableHeadWrapper,
  TableRowDivider,
  TableWrapper
} from 'src/components/Styled';
import GenerateWorkOrder from './GenerateWorkOrder';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { AddRounded } from '@mui/icons-material';
import AddProduct from './AddProduct';
import { useFormikContext } from 'formik';

const ProductsCard = ({ currentItem }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const [open, setOpen] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({
    quantity: '',
    dead_line: ''
  });

  const handleModal = (item) => {
    setSelectedItem(item);
    setFormData({
      quantity: item?.quantity || '',
      dead_line: ''
    });
    setOpen((prev) => !prev);
  };

  const handleAddProduct = () => {
    setAddProduct((prev) => !prev);
  };

  useEffect(() => {
    setItems(values?.items || []);
  }, [values]);

  const tableHeaders = [
    t('Product'),
    t('Quantity'),
    t('Planned Date'),
    t('All Work Orders'),
    t('Work Order')
  ];

  return (
    <>
      <Card>
        <Box
          px={3}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              gutterBottom
              sx={{
                fontSize: `${theme.typography.pxToRem(16)}`
              }}
              variant="h4"
            >
              {t('Products')}
            </Typography>
          </Box>
          <Button
            size="small"
            variant="outlined"
            startIcon={<AddRounded />}
            onClick={handleAddProduct}
          >
            Add Product
          </Button>
        </Box>
        <Box px={3} pb={3}>
          <TableContainer>
            <TableWrapper>
              <TableHeadWrapper>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHeadWrapper>
              <TableBody>
                {!items || items?.length === 0 ? (
                  <>No Data</>
                ) : (
                  items?.map((item, index) => (
                    <>
                      <TableRow hover key={item?.id}>
                        <TableCell>
                          <Box>
                            <Link
                              href={`/product/view/${item?.id}`}
                              variant="h5"
                              noWrap
                            >
                              {item?.product?.name}
                            </Link>
                            <Typography
                              variant="subtitle1"
                              fontSize={12}
                              noWrap
                            >
                              {item?.product?.product_code}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <div>
                            <Typography variant="h5" noWrap>
                              {item?.quantity} {item?.uom?.name}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <Typography variant="h5" noWrap>
                              {item?.dead_line
                                ? moment(item.dead_line)?.format('DD MMM YY')
                                : 'not scheduled'}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            {item?.work_orders?.length > 0 ? (
                              item?.work_orders?.map((workOrder) => (
                                <Link
                                  key={workOrder?.id}
                                  href={`/work_orders/process/${workOrder?.id}`}
                                >
                                  <Typography variant="h5" noWrap>
                                    {workOrder?.number || workOrder?.id}
                                  </Typography>
                                </Link>
                              ))
                            ) : (
                              <Typography variant="h5" noWrap>
                                no orders yet
                              </Typography>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <Button
                              size="small"
                              variant="contained"
                              color="inherit"
                              onClick={() => handleModal(item)}
                            >
                              Generate Work Order
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRowDivider />
                    </>
                  ))
                )}
              </TableBody>
            </TableWrapper>
          </TableContainer>
        </Box>
      </Card>

      <AddProduct open={addProduct} handleModal={handleAddProduct} />

      <GenerateWorkOrder
        open={open}
        setOpen={setOpen}
        handleModal={handleModal}
        selectedItem={selectedItem}
        currentItem={currentItem}
        formData={formData}
        setFormData={setFormData}
        setItems={setItems}
      />
    </>
  );
};

export default ProductsCard;
