import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
// import { useSelector } from 'react-redux';
import { useState } from 'react';
// import { getFieldOptions } from 'src/utils/getFieldOptions';
import BasicInfo from './BasicInfo';
// import { useFormikContext } from 'formik';
// import { structureSchema } from 'src/utils/structureSchema';
// import { useMemo } from 'react';
// import SocialMedia from './SocialMedia';
// import NotesAndFiles from './NotesAndFiles';
// import Identity from './Identity';
// import { REMOVE_FIELDS } from 'src/constant/constant';
// import { getInitialValues } from 'src/utils/formikInitialization';
// import { useEffect } from 'react';
// import PeopleTab from './PeopleTab';
// import BankAccTab from './BankAccTab';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { Card, Skeleton } from '@mui/material';
import NotesAndFiles from './NotesAndFiles';
import PeopleTab from './PeopleTab';
import SocialMedia from './SocialMedia';
import BankAccTab from './BankAccTab';
import Identity from './Identity';
import { useEffect } from 'react';
import { getInitialValues } from 'src/utils/formikInitialization';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { useFormikContext } from 'formik';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const CompanyTabs = () => {
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState(0);
  const { schema, entitySetting, loading } = useEntityPageConfig('workspace');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (schema) {
      const companySchema = schema ? schema?.['company']?.schema?.paths : null;
      const initialValues = getInitialValues(
        companySchema,
        Object.keys(companySchema)?.filter(
          (key) => !REMOVE_FIELDS.includes(key)
        ),
        values?.['company'] || null
      );
      setFieldValue('company', initialValues);
    }
  }, [schema, setFieldValue]);
  if (!schema) return <Skeleton height={100} width={'100%'} />;
  return (
    <Card variant={'outlined'}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Dynamic scrollable auto tabs"
        >
          <Tab label="Basic info" {...a11yProps(0)} />
          <Tab label="Identity" {...a11yProps(1)} />
          <Tab label="Bank Accounts" {...a11yProps(2)} />
          <Tab label="Social" {...a11yProps(3)} />
          <Tab label="People" {...a11yProps(4)} />
          <Tab label="Notes & Files" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <BasicInfo
          schema={schema}
          fieldName={'company'}
          entitySetting={entitySetting}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Identity
          schema={schema}
          fieldName={'company'}
          entitySetting={entitySetting}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <BankAccTab schema={schema} fieldName={'company'} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <SocialMedia
          schema={schema}
          fieldName={'company'}
          entitySetting={entitySetting}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <PeopleTab
          schema={schema}
          fieldName={'company'}
          entitySetting={entitySetting}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <NotesAndFiles
          schema={schema}
          fieldName={'company'}
          entitySetting={entitySetting}
        />
      </CustomTabPanel>
    </Card>
  );
};

export default CompanyTabs;
