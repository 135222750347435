import { Grid } from '@mui/material';
import GetFieldBasedOnSchema from '..';
import React from 'react';

const NestedGridItem = (props) => {
  const { schema, itemTableHead } = props;
  const gridSize = schema[itemTableHead]?.options?.gridSize ||
    schema[itemTableHead]?.$embeddedSchemaType?.options?.gridSize || {
      xs: 12,
      md: 3
    };
  return (
    <Grid item {...gridSize}>
      <GetFieldBasedOnSchema {...props} />
    </Grid>
  );
};

export default NestedGridItem;
