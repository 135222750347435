import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';

const RootWrapper = styled(Box)(
  () => `
          flex: 1;
      `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader({ currentItem }) {
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const isMounted = useRefMounted();
  const { approval_status } = currentItem;
  const handleSendApproval = async () => {
    setSending(true);
    try {
      const payload = {
        endPoint: 'approval',
        data: {
          entity: 'analysis',
          document_id: currentItem.id
        }
      };
      await requestApi.createData(payload);
    } finally {
      if (isMounted.current) setSending(false);
    }
  };
  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h3" component="h3" gutterBottom>
                {currentItem?.number}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={0.5}>
            {approval_status === 'NOT SUBMITTED' && (
              <Button
                variant="outlined"
                size="small"
                type="submit"
                disabled={sending}
                startIcon={
                  sending ? (
                    <CircularProgress size={'1rem'} />
                  ) : (
                    <SendIcon size={'1rem'} />
                  )
                }
                onClick={handleSendApproval}
              >
                Send For Approval
              </Button>
            )}
            <Tooltip title={`Go back`}>
              <IconButtonWrapper
                disabled={sending}
                onClick={() => navigate(-1)}
              >
                <ArrowBackTwoToneIcon />
              </IconButtonWrapper>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
