import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Stack,
  Button,
  CardActions,
  Typography
} from '@mui/material';
import { useFormikContext, FieldArray } from 'formik';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import { useSelector } from 'react-redux';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import CommonActionFields from './CommonActFields';
import CustomRadioField from 'src/components/CustomFields/CustomRadioField';
import ActionCondition from './ActionConditions';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import useCustomActFuc from 'src/hooks/useCustomActFuc';

const actionTypes = ['custom', 'api'];
const api_endpoint = ['by_convert'];

const routeOrCustomFun = ['route', 'customFun'];
const standardActions = ['edit', 'view', 'delete'];

const ActionSetting = () => {
  const { entities } = useSelector(selectAppConfigData);
  const { availableActions } = useCustomActFuc();
  const { setFieldValue, values, handleChange, handleBlur } =
    useFormikContext();
  const handleActionTypeChange = (event, index) => {
    const value = event.target.value;
    setFieldValue(`data[${index}].actionType`, value);

    if (value === 'custom') {
      setFieldValue(`data[${index}].apiEndpoint`, '');
    } else {
      setFieldValue(`data[${index}].customAction`, '');
    }
  };

  return (
    <FieldArray name="data">
      {({ remove, push }) => (
        <Grid item xs={12}>
          <Stack spacing={1}>
            {values.data.map((dataItem, index) => (
              <Card key={index} variant="outlined">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomTextField
                        label={'Action Name'}
                        name={`data[${index}].actionName`}
                        value={dataItem.actionName || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <CommonActionFields dataItem={dataItem} index={index} />
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelectField
                        label={'Action Type'}
                        name={`data[${index}].actionType`}
                        value={dataItem.actionType || ''}
                        options={actionTypes}
                        onChange={(event) =>
                          handleActionTypeChange(event, index)
                        }
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelectField
                        label={'Override Action'}
                        name={`data[${index}].overrideAct`}
                        value={dataItem.overrideAct || ''}
                        options={standardActions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {dataItem.actionType === 'custom' && (
                      <Grid item xs={12} sm={6} md={3}>
                        <CustomSelectField
                          label={'Route or customFun'}
                          name={`data[${index}].routeOrCustomFun`}
                          value={dataItem.routeOrCustomFun || ''}
                          options={routeOrCustomFun}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                    {dataItem.actionType === 'api' && (
                      <Grid item xs={12} sm={6} md={3}>
                        <CustomSelectField
                          label={'Api Endpoint'}
                          name={`data[${index}].apiEndpoint`}
                          value={dataItem?.apiEndpoint || ''}
                          options={api_endpoint}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                    {dataItem.routeOrCustomFun !== 'customFun' && (
                      <Grid item xs={12} sm={6} md={3}>
                        <CustomSelectField
                          label={'Target Entity'}
                          name={`data[${index}].targetEntity`}
                          value={dataItem?.targetEntity || ''}
                          options={entities}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                    {dataItem.routeOrCustomFun === 'customFun' && (
                      <Grid item xs={12} sm={6} md={3}>
                        <CustomSelectField
                          label={'Select Function'}
                          name={`data[${index}].customFun`}
                          value={dataItem.customFun || ''}
                          options={availableActions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                    {dataItem.routeOrCustomFun === 'route' && (
                      <Grid item xs={12} sm={6} md={3}>
                        <CustomTextField
                          label={'Route Type'}
                          name={`data[${index}].routeType`}
                          value={dataItem.routeType || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ maxWidth: '50%', width: '200px' }}
                        >
                          Button State :
                        </Typography>
                        <CustomRadioField
                          name={`data[${index}].buttonState`}
                          value={dataItem.buttonState || 'show'}
                          row={true}
                          options={[
                            { value: 'show', label: 'Show' },
                            { value: 'hide', label: 'Hide' },
                            { value: 'disabled', label: 'Disabled' }
                          ]}
                          onChange={(event) =>
                            setFieldValue(
                              `data[${index}].buttonState`,
                              event.target.value
                            )
                          } // Update formik value
                          onBlur={handleBlur}
                        />
                      </Stack>
                    </Grid>
                    {(dataItem.buttonState === 'hide' ||
                      dataItem.buttonState === 'disabled') && (
                      <Grid item xs={12}>
                        <ActionCondition
                          conditionArr={dataItem.conditions}
                          path={`data[${index}].conditions`}
                          entity={values.entity}
                          btnName="Add Condition"
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    size="small"
                    onClick={() => remove(index)}
                    color="error"
                  >
                    Remove Action
                  </Button>
                </CardActions>
              </Card>
            ))}

            <Button
              variant="text"
              size="small"
              sx={{ alignSelf: 'flex-start' }}
              color="primary"
              onClick={() =>
                push({
                  customFun: '',
                  actionType: '',
                  routeOrCustomFun: '',
                  overrideAct: '',
                  routeType: '',
                  apiEndpoint: 'by_convert',
                  targetEntity: '',
                  actionLocation: '',
                  actionName: '',
                  icon: '',
                  buttonOrIcon: '',
                  buttonState: 'show',
                  conditions: []
                })
              }
            >
              {values?.data?.length ? 'Add more action' : 'Add action'}
            </Button>
          </Stack>
        </Grid>
      )}
    </FieldArray>
  );
};

export default ActionSetting;
