import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  sections: [],
  status: {
    createSection: false,
    deleteSection: false,
    updateSection: false,
    getSection: true
  }
  // message: { success: null, error: null, for: null, isOptionCreate: false }
};

export const createSectionAsync = createAsyncThunk(
  'sections/createData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.createData(data);
      if (response?.status === 'SUCCESS') {
        return response.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Async thunk to get tasks
export const getSectionAsync = createAsyncThunk(
  'sections/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSectionAsync = createAsyncThunk(
  'sections/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status == 'SUCCESS') {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


// Async thunk to delete a section
export const deleteSectionAsync = createAsyncThunk(
  'sections/deleteData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteData(data);
      console.log(response, 'responsetaskdelete');
      if (response?.status === 'SUCCESS') {
        return data.id; // Returning the deleted task ID
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    cleanMessageLog: (state) => {
      state.message.success = null;
      state.message.error = null;
      state.message.for = null;
      state.message.isOptionCreate = false;
    },
    updateSectionArray: (state,{payload})=> {
      state.sections = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSectionAsync.pending, (state) => {
        state.status.createSection = true;
      })
      .addCase(createSectionAsync.fulfilled, (state, {payload}) => {
        state.status.createSection = false;
        console.log(payload, 'createSection');
        state.sections.push(payload); // Assuming payload contains the created task
      })
      .addCase(createSectionAsync.rejected, (state) => {
        state.status.createSection = false;
      })
      .addCase(getSectionAsync.pending, (state) => {
        state.status.getSection = true;
      })
      .addCase(getSectionAsync.fulfilled, (state, { payload }) => {
        state.status.getSection = false;
        state.sections = payload?.data;
      })

      .addCase(getSectionAsync.rejected, (state) => {
        state.sections = [];
        state.status.getSection = false;
      })
      .addCase(updateSectionAsync.pending, (state) => {
        state.status.updateSection = true;
      })
      .addCase(updateSectionAsync.fulfilled, (state, { payload }) => {
        state.status.updateSection = false;
        state.sections = state.sections?.map((section) =>
          section?.id === payload?.id ? payload : section
        );
      })
      .addCase(updateSectionAsync.rejected, (state) => {
        state.status.updateSection = false;
      })
      .addCase(deleteSectionAsync.pending, (state) => {
        state.status.deleteSection = true;
      })
      .addCase(deleteSectionAsync.fulfilled, (state, {payload}) => {
        state.status.deleteSection = false;
        state.sections = state.sections.filter(
          (section) => section?.id !== payload
        );
      });
  }
});

// selectors
export const { cleanMessageLog, updateSectionArray } = slice.actions;
export const selectSectionData = (state) => state.sectionState;
export default slice.reducer;
