import { useTheme } from '@emotion/react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';

function PageHeader() {
  const { t } = useTranslation();
  const theme = useTheme();
  const loggedUserData = useSelector(selectLoggedUserData);
  const { name, username, avatar } = loggedUserData;

  return (
    <>
      <Box
        display="flex"
        alignItems={{ xs: 'stretch', md: 'center' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Stack direction={'row'} alignItems={'center'}>
          <Avatar
            sx={{
              mr: 2,
              width: theme.spacing(8),
              height: theme.spacing(8)
            }}
            variant="rounded"
            alt={name || username}
            src={avatar}
          />
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              {t('Welcome')}, {name || username}!
            </Typography>
            <Typography variant="subtitle2">
              {t(
                'You can manage and update your workspace settings here, configure essential details.'
              )}
            </Typography>
          </Box>
        </Stack>
        {/* <Box mt={{ xs: 3, md: 0 }}>
        </Box> */}
      </Box>
    </>
  );
}

export default PageHeader;
