import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import React, { useEffect, useState } from 'react';
import CustomPopover from 'src/components/CustomPopover';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import { requestApi } from 'src/mocks/request'; // Assuming you're using this to make the API call
import Scrollbar from 'src/components/Scrollbar';
import { useFormikContext } from 'formik';
import CustomAvatar from 'src/components/CustomAvatar';

const UserSelectBox = ({ user, path, boxType }) => {
  console.log(user, 'user');
  const { setFieldValue } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [userSelectBox, setUserSelectBox] = useState(null);

  const handleUserSelectBox = (event) => {
    setUserSelectBox((prev) => (!prev ? event.currentTarget : null));
  };

  const handleFetchUser = async () => {
    const payLoad = {
      endPoint: 'user',
      query: {
        $or: [
          { name: { $regex: searchTerm, $options: 'i' } },
          { username: { $regex: searchTerm, $options: 'i' } }
        ]
      }
    };
    const response = await requestApi.getData(payLoad);
    if (response?.data?.data?.length) {
      setOptions(response.data.data);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFetchUser();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleUserSelect = (selectedUser) => {
    setFieldValue(path, selectedUser);
    setUserSelectBox(null);
  };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={0.2}>
        <Tooltip title="Select User">
          {user ? (
            <CustomAvatar
              name={user.name || user.username}
              src={user?.avatar}
              onClick={handleUserSelectBox}
              sx={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          ) : (
            <IconButton
              disableRipple
              color="primary"
              onClick={handleUserSelectBox}
            >
              <AccountCircleOutlinedIcon />
            </IconButton>
          )}
        </Tooltip>
        <Typography variant="body1" textTransform={'capitalize'}>
          {user.name || user.username || `No ${boxType}`}
        </Typography>
      </Stack>

      <CustomPopover
        anchorEl={userSelectBox}
        open={Boolean(userSelectBox)}
        onClose={handleUserSelectBox}
      >
        <Stack spacing={2} p={2}>
          <CustomTextField
            label="Search User"
            size="small"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />

          {options.length > 0 ? (
            <Scrollbar maxHeight={250}>
              <List>
                {options.map((userItem) => (
                  <ListItem
                    key={userItem.id}
                    onClick={() => handleUserSelect(userItem)}
                    disablePadding
                  >
                    <ListItemButton selected={user.id === userItem.id}>
                      <ListItemAvatar>
                        <Avatar alt={userItem.name} src={userItem.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={userItem.name || userItem.username}
                        secondary={userItem.email || ''}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Scrollbar>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No users found
            </Typography>
          )}
        </Stack>
      </CustomPopover>
    </>
  );
};

export default UserSelectBox;
