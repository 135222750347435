import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CommonModal from 'src/components/CommonModal.js';
import {
  createDataAsync,
  selectStatus,
  updateDataAsync
} from 'src/slices/dynamicSlice';
import CustomDateField from '../../../CustomFields/CustomDateField';

const GenerateWorkOrder = ({
  open,
  handleModal,
  selectedItem,
  setOpen,
  currentItem,
  formData,
  setFormData,
  setItems
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createData } = useSelector(selectStatus);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'quantity' ? Number(value) : value
    }));
  };

  const handleGenerateWorkOrder = async () => {
    try {
      const payload = {
        product: selectedItem?.product?.id,
        uom: selectedItem?.uom?.id,
        production_plan: currentItem?.id,
        source_warehouse: currentItem?.source_warehouse?.id,
        target_warehouse: currentItem?.target_warehouse?.id,
        ...formData
      };
      const res = await dispatch(
        createDataAsync({ endPoint: 'work_order', data: payload })
      );

      const workOrderId = res?.payload?.id;
      const newDeadline = res?.payload?.dead_line;

      if (!workOrderId || !newDeadline) {
        console.error('Invalid response from work order creation');
        return;
      }

      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.product.id === selectedItem?.product?.id) {
            const updatedWorkOrders = [...item.work_orders, res?.payload];
            return { ...item, work_orders: updatedWorkOrders };
          }
          return item;
        })
      );

      const updateItems = currentItem?.items?.map((item) => {
        if (item.product.id === selectedItem?.product?.id) {
          const work_orders = [...item.work_orders, workOrderId];

          if (work_orders?.length > 0) {
            // Update item only if the new deadline is later than the current deadline

            if (
              item?.dead_line &&
              new Date(item.dead_line) < new Date(newDeadline)
            ) {
              console.log('newDeadline', newDeadline);
              return { ...item, work_orders, dead_line: newDeadline };
            } else {
              return { ...item, work_orders };
            }
          }

          return { ...item, work_orders, dead_line: newDeadline };
        }
        return item;
      });

      await dispatch(
        updateDataAsync({
          endPoint: 'production_plan',
          data: { items: updateItems },
          id: currentItem?.id
        })
      );
      setOpen(false);
    } catch (error) {
      console.error('Failed to generate work order:', error);
    }
  };

  return (
    <>
      <CommonModal
        maxWidth="xs"
        open={open}
        handleModal={handleModal}
        title={t(`Work Order - ${selectedItem?.product?.name}`)}
      >
        <Stack spacing={1} sx={{ mb: 1 }}>
          <InputLabel>
            {t('How many products do you want to manufacture?')}
          </InputLabel>
          <TextField
            name="quantity"
            value={formData?.quantity}
            onChange={handleChange}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: <Typography>{selectedItem?.uom?.name}</Typography>
            }}
          />
          <InputLabel>
            {t('Any tentative deadline for the Work Order?')}
          </InputLabel>
          <CustomDateField
            label="Dead Line"
            value={formData?.dead_line}
            onChange={(value) => {
              console.log('onChange', value);
              setFormData((prev) => ({
                ...prev,
                dead_line: value
              }));
            }}
          />
        </Stack>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            loading={createData}
            disabled={createData}
            onClick={handleGenerateWorkOrder}
          >
            {t('Confirm')}
          </LoadingButton>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </DialogActions>
      </CommonModal>
    </>
  );
};

export default GenerateWorkOrder;
