import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { getFieldType } from 'src/services/commonService';
const getMdSize = (fieldname, schema, size) => {
  const { isStringHasMaxNull, isObject, isArrayWithMultipleProperties } =
    getFieldType(fieldname, schema);
  if (isArrayWithMultipleProperties || isObject || isStringHasMaxNull)
    return 12;
  return size;
};

const NotesAndFiles = ({ fieldName, schema, entitySetting }) => {
  const { values, errors, touched } = useFormikContext();
  const companySchema = schema ? schema?.[fieldName]?.schema?.paths : null;
  let fields = ['notes', 'images', 'files'];

  return (
    <>
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid
            item
            xs={12}
            md={getMdSize(field, companySchema, 4)}
            sm={getMdSize(field, companySchema, 6)}
            key={`${field}_${index}`}
          >
            <GetFieldBasedOnSchema
              name={`company.${field}`}
              fieldName={field}
              entitySetting={entitySetting}
              schema={companySchema}
              value={values?.['company']?.[field] || ''}
              error={errors?.['company']?.[field]}
              touched={touched?.['company']?.[field]}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NotesAndFiles;
