import {
  Box,
  Divider,
  Drawer,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import ReasultBox from './ReasultBox';
import { useFormikContext } from 'formik';
import CustomSwitchField from 'src/components/CustomFields/CustomSwitchField';
import LaunchIcon from '@mui/icons-material/Launch';
import DOMPurify from 'dompurify';
const sanitizeHtml = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return cleanHtml.replace(/<[^>]*>?/gm, ''); // Remove remaining HTML tags
};
const TQTableRow = ({ item, currentItem, path }) => {
  const { setFieldValue } = useFormikContext();
  const [procedureDrawer, setProcedureDrawer] = useState(false);
  const { parameter, is_pass = false } = item;
  const { spec } = currentItem;
  const specifications = useMemo(() => {
    const foundSpec = spec?.specifications?.find(
      (specItem) => specItem?.parameter === parameter
    );
    return foundSpec || {};
  }, [spec, parameter]);

  const { test, exact_value, max_value, min_value, uom } = specifications;
  const specificationsText = () => {
    if (exact_value) {
      return `${exact_value} ${uom?.symbol || ''}`;
    }
    if (min_value && max_value) {
      return `${min_value}-${max_value} ${uom?.symbol || ''}`;
    }
    return '-';
  };
  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{parameter || '-'}</TableCell>
        <TableCell>
          {test?.name || '-'}
          {test?.name && (
            <Tooltip title="View Procedures" arrow>
              <IconButton
                color="primary"
                onClick={() => setProcedureDrawer(true)}
              >
                <LaunchIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Typography>{specificationsText()}</Typography>
        </TableCell>
        <TableCell>
          <ReasultBox item={item} path={path} specifications={specifications} />
        </TableCell>
        <TableCell>
          <CustomSwitchField
            checked={is_pass}
            onChange={(event) =>
              setFieldValue(`${path}.is_pass`, event.target.checked)
            }
          />
        </TableCell>
      </TableRow>
      <Drawer
        transitionDuration={300}
        anchor="right"
        open={procedureDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '95%', sm: 350 }
          }
        }}
        onClose={() => setProcedureDrawer(false)}
      >
        <Box padding={2}>
          <Typography variant="h6" gutterBottom>
            Test Procedures
          </Typography>
          <Divider />
          <Box marginTop={2}>
            <Typography variant="h5" gutterBottom>
              Title : {test?.name}
            </Typography>
            {test?.procedure ? (
              <Typography variant="body2">
                {sanitizeHtml(test?.procedure)}
              </Typography>
            ) : (
              <Typography variant="body2">No procedure</Typography>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default TQTableRow;
