import {
  alpha,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import CustomCardWithSearch from 'src/components/CustomCardWithSearch';
import useFetchData from 'src/hooks/useFetchData';
import useSearch from 'src/hooks/useSearch';

const StockViewTable = ({ currentItem }) => {
  const theme = useTheme();
  const getRowBackgroundColor = (actualQty, systemQty) => {
    if (actualQty === systemQty) {
      return alpha(theme.palette.success.main, 0.2);
    }
    return alpha(theme.palette.warning.main, 0.2);
  };
  const { data, isFetching } = useFetchData({
    endPoint: 'audit_record',
    populate: 'product location',
    query: { audit: currentItem?.id }
  });
  const { searchTerm, handleSearchChange, filteredItems } = useSearch(
    data,
    'product.name'
  );
  if (isFetching) return <Skeleton variant="rounded" height={'30vh'} />;
  return (
    <CustomCardWithSearch
      title={`Stock details by (${currentItem?.split_by})`}
      searchTerm={searchTerm}
      handleSearchChange={handleSearchChange}
    >
      <Table sx={{ minWidth: 700, tableLayout: 'fixed' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>System Qty</TableCell>
            <TableCell>Actual Qty</TableCell>
            <TableCell>Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems?.length ? (
            filteredItems.map((item, index) => (
              <TableRow
                key={item.id}
                sx={{
                  backgroundColor: getRowBackgroundColor(
                    item.actual_quantity,
                    item.system_quantity
                  )
                }}
              >
                <TableCell>{item?.product?.name || '-'}</TableCell>
                <TableCell>{item?.location?.name || '-'}</TableCell>
                <TableCell>{item?.system_quantity || '-'}</TableCell>
                <TableCell>
                  <Stack direction={'row'} alignItems={'center'}>
                    <span>{item?.actual_quantity || '-'}</span>
                    {item?.actual_quantity > item?.system_quantity ? (
                      <NorthIcon sx={{ fontSize: 15 }} />
                    ) : item?.actual_quantity < item?.system_quantity ? (
                      <SouthIcon sx={{ fontSize: 15 }} />
                    ) : null}
                  </Stack>
                </TableCell>
                <TableCell>{item?.reason || '-'}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>No stock adjustments found.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomCardWithSearch>
  );
};

export default StockViewTable;
