import { Button, CircularProgress, Stack } from '@mui/material';
import { Form, Formik } from 'formik';

import OrderInformation from './OrderInformation';
import ItemDetails from './ItemDetails';
import { selectStatus } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useDynamicFormLogic from 'src/hooks/useDynamicFormLogic';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { useState } from 'react';
const GrnFormBody = ({ currentItem = null }) => {
  const [activeTab, setActiveTab] = useState('order');
  const status = useSelector(selectStatus);
  const { initialValues, validationSchema, handleFormSubmit } =
    useDynamicFormLogic({
      currentItem,
      isCustomForm: true
    });
  const handleActiveTab = (tabName, values) => {
    if (tabName === 'product' && !values?.purchase_order) {
      toast.warning(
        'Please select a purchase order before switching to products tab.'
      );
    } else {
      setActiveTab(tabName);
    }
  };
  if (!initialValues || !validationSchema) return <CircularProgress />;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values }) => (
        <>
          <Form>
            <PageTitleWrapper>
              <PageHeader
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
                currentItem={currentItem}
              />
            </PageTitleWrapper>
            {activeTab === 'order' ? <OrderInformation /> : <ItemDetails />}
            <Stack alignItems={'center'} mt={2}>
              {activeTab === 'product' && (
                <Button
                  type="submit"
                  startIcon={
                    currentItem ? (
                      status.updateData ? (
                        <CircularProgress size="1rem" />
                      ) : null
                    ) : status.createData ? (
                      <CircularProgress size="1rem" />
                    ) : null
                  }
                  disabled={currentItem ? status.updateData : status.createData}
                  variant="contained"
                >
                  {currentItem ? 'Upadte GRN' : 'Create Grn'}
                </Button>
              )}
            </Stack>
          </Form>
          <Stack alignItems={'center'}>
            {activeTab === 'order' && (
              <Button
                variant="contained"
                type="button"
                onClick={() => handleActiveTab('product', values)}
              >
                Go To Products
              </Button>
            )}
          </Stack>
        </>
      )}
    </Formik>
  );
};

export default GrnFormBody;
