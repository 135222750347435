import React from 'react';
import MultipleFileUpload from 'src/components/CustomFields/MultipleFileUpload';
import SingleImageUpload from 'src/components/CustomFields/SingleFileUpload';

const FieldOverrideHandler = ({ fieldName, name, value }) => {
  if (['gallery', 'images', 'attachments'].includes(fieldName)) {
    return (
      <MultipleFileUpload fieldName={fieldName} name={name} value={value} />
    );
  } else {
    return (
      <SingleImageUpload fieldName={fieldName} name={name} value={value} />
    );
  }
};

export default FieldOverrideHandler;
