import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack
} from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { getFieldType } from 'src/services/commonService';
import { structureSchema } from 'src/utils/structureSchema';
import AddIcon from '@mui/icons-material/Add';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const getMdSize = (fieldname, schema, size) => {
  const { isStringHasMaxNull, isObject, isArrayWithMultipleProperties } =
    getFieldType(fieldname, schema);
  if (isArrayWithMultipleProperties || isObject || isStringHasMaxNull)
    return 12;
  return size;
};

const BankAccTab = ({ config }) => {
  const { values } = useFormikContext();
  const { dependentKey, depKeySchema } = config || {};

  // Fetching the reference schema for bank accounts
  let bankAccRef =
    depKeySchema['bank_accounts']?.$embeddedSchemaType?.options?.ref ||
    depKeySchema['bank_accounts']?.options?.ref;
  const { schema } = useEntityPageConfig(bankAccRef); // Get the schema using hook

  // Filter out any unwanted fields from the schema
  let bankAccountFields = Object.keys(structureSchema(schema) || {}).filter(
    (key) => !REMOVE_FIELDS.includes(key)
  );

  if (!schema) return null;

  return (
    <FieldArray
      name={`${dependentKey}.bank_accounts`}
      render={({ remove, push }) => (
        <Card variant="outlined">
          <CardHeader title="Bank Accounts" />
          <Divider />
          <CardContent>
            <Stack spacing={2} p={2}>
              {(values?.[dependentKey]?.bank_accounts || []).map(
                (bankAccount, index) => (
                  <Grid
                    key={index}
                    container
                    spacing={2}
                    border={1}
                    p={1}
                    borderColor="divider"
                    borderRadius={1}
                  >
                    {bankAccountFields.map((field) => (
                      <Grid
                        item
                        xs={12}
                        md={getMdSize(field, schema, 4)}
                        sm={getMdSize(field, schema, 6)}
                        key={field}
                      >
                        <GetFieldBasedOnSchema
                          name={`${dependentKey}.bank_accounts[${index}].${field}`}
                          fieldName={field}
                          schema={schema}
                          value={bankAccount?.[field] || ''}
                        />
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        size="small"
                        color="error"
                        onClick={() => remove(index)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                )
              )}
              <Button
                sx={{ alignSelf: 'flex-start' }}
                onClick={() => push({})} // Push a new empty bank account
                size="small"
                startIcon={<AddIcon />}
              >
                {values?.[dependentKey]?.bank_accounts?.length > 0
                  ? 'Add another account'
                  : 'Add account'}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      )}
    />
  );
};

export default BankAccTab;
