import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import Production from './Production';
import SupplyChain from './SupplyChain';
import QualityManagement from './QualityManagement';
import { Card, Container } from '@mui/material';
import { useTheme } from '@mui/material';

function CustomTabPanel(props) {
  const theme = useTheme();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card
          sx={{
            p: 3,
            border:
              value === index
                ? `2px solid ${theme.palette.primary.main}`
                : 'none',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px'
          }}
        >
          {children}
        </Card>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Landing = () => {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Production"
            {...a11yProps(0)}
            sx={{
              backgroundColor:
                value === 0 ? `${theme.palette.primary.main}` : 'none',
              border: `2px solid ${theme.palette.primary.main}`
            }}
            icon={<ViewQuiltOutlinedIcon />}
            iconPosition="start"
          />

          <Tab
            label="Suply Chain"
            {...a11yProps(1)}
            sx={{
              backgroundColor:
                value === 1 ? `${theme.palette.primary.main}` : 'none',
              marginX: '2rem',
              border: `2px solid ${theme.palette.primary.main}`
            }}
            icon={<ViewQuiltOutlinedIcon />}
            iconPosition="start"
          />

          <Tab
            label="Quality Management"
            {...a11yProps(2)}
            sx={{
              backgroundColor:
                value === 2 ? `${theme.palette.primary.main}` : 'none',
              marginX: '2rem',
              border: `2px solid ${theme.palette.primary.main}`
            }}
            icon={<ViewQuiltOutlinedIcon />}
            iconPosition="start"
          />

          <Tab
            label="Human Resources"
            {...a11yProps(2)}
            sx={{
              backgroundColor:
                value === 3 ? `${theme.palette.primary.main}` : 'none',
              marginX: '2rem',
              border: `2px solid ${theme.palette.primary.main}`
            }}
            icon={<ViewQuiltOutlinedIcon />}
            iconPosition="start"
          />

          <Tab
            label="CRM"
            {...a11yProps(2)}
            sx={{
              backgroundColor:
                value === 4 ? `${theme.palette.primary.main}` : 'none',
              marginX: '2rem',
              border: `2px solid ${theme.palette.primary.main}`
            }}
            icon={<ViewQuiltOutlinedIcon />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Production />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SupplyChain />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <QualityManagement />
      </CustomTabPanel>
    </Container>
  );
};

export default Landing;
