import { Box, Stack, Typography } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { useTheme } from '@emotion/react';

const Production = () => {
    const theme = useTheme();
    return (
        <>
            <Stack direction={'row'}>
                <Stack direction={'column'} width={'60%'}>
                    <Box>
                        <Typography marginTop={4}
                            sx={{ backgroundColor: `${theme.palette.primary.main}`, paddingX: 1, paddingY: .5, borderRadius: 10 }}
                            textAlign={'center'}
                            width={200}
                            color={'white'}>
                            Production & Planning
                        </Typography>
                    </Box>

                    <Stack>
                        <Typography marginTop={1} fontSize={35}>
                            Optimize your production process for maximum efficiency, precise scheduling, & manpower allocation and capacity utilisation
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography marginTop={1} >
                            Avoid stock-outs and reduce excess inventory costs.
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={.5}
                            width={80}
                            marginTop={1}
                            sx={{
                                color: `${theme.palette.primary.main}`, // Blue text color
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: '-4px', // Controls the gap between text and underline
                                    height: '2px', // Thickness of the underline
                                    backgroundColor: `${theme.palette.primary.main}`, // Blue underline color
                                }
                            }}
                        >
                            Explore
                            <ArrowRightAltOutlinedIcon fontSize='medium' />
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction={'row'} gap={2} marginTop={4}>
                <Stack direction={'column'} gap={1}>
                    <Typography fontSize={18}>
                        Automated Scheduling
                    </Typography>

                    <Typography fontSize={14}>
                        Automatically manage production schedule, material requirements, and capacity planning. This ensures that all processes are efficiently cordinated, reducing manual effort and errors.
                    </Typography>
                </Stack>

                <Stack direction={'column'} gap={1}>
                    <Typography fontSize={18}>
                        Resource Efficiency
                    </Typography>

                    <Typography fontSize={14}>
                        Optimise the allocation of manpower, machinery and materials to minimise the waste and downtime. Efficient resource utilisation leads to smoother operations and cost savings.
                    </Typography>
                </Stack>

                <Stack direction={'column'} gap={1}>
                    <Typography fontSize={18}>
                        Real-Time Tracking
                    </Typography>

                    <Typography fontSize={14}>
                        Continuously monitor production problems in real-time, allowing for quick adjustments to meet deadlines. This ensures timely deliveries and reduce bottlenecks in the workflow.
                    </Typography>
                </Stack>

            </Stack>
        </>

    )
}

export default Production;