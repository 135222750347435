import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import WorkspaceAdminAuth from 'src/components/WorkspaceAdminAuth';
import EntityVerifier from '../components/EntityVerifier';
import DynamicListPage from 'src/content/management/DynamicListPage';
import DynamicPageWrapper from 'src/components/DynamicPageWrapper';
import { workspaceSettingRoutes } from './workspaceSettingRoutes';
import ViewPage from 'src/content/management/ViewPage';
import DynamicForm from 'src/components/DynamicEntityForm/DynamicForm';
import Test from 'src/Test';
import ProductionPlanForm from 'src/components/DynamicEntityForm/ProductionPlanForm';
import { customRoutesConfig } from './customRoutesConfig';
import WorkOrderForm from 'src/components/DynamicEntityForm/WorkOrderForm';
import ProcessRequest from 'src/content/ProcessRequest';
import MaterialRequestForm from 'src/components/DynamicEntityForm/MaterialRequestForm';

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const Status403 = Loader(lazy(() => import('src/components/Status403')));
const HrManagement = Loader(lazy(() => import('src/content/hrManagement')));
const Users = Loader(lazy(() => import('src/content/management/Users')));
const Profile = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const Setting = Loader(lazy(() => import('src/content/setting')));
const RoleManagement = Loader(lazy(() => import('src/content/roleManagement')));
const Dashboard = Loader(lazy(() => import('src/content/dashboard')));

const ProjectsBoard = Loader(
  lazy(() => import('src/content/applications/ProjectsBoard'))
);

const createCustomRoute = ({ path, endPoint, type, component: Component }) => ({
  path,
  element: (
    <EntityVerifier endPoint={endPoint}>
      <DynamicPageWrapper endPoint={endPoint} type={type}>
        <Component />
      </DynamicPageWrapper>
    </EntityVerifier>
  )
});

// Define routes dynamically based on the configuration
const customRoutes = customRoutesConfig.map(createCustomRoute);
// authenticated and has workspace
export const workspaceRoutes = [
  {
    path: '',
    element: <Navigate to="/dashboard" replace />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  ...customRoutes,
  {
    path: '/:endPoint',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper>
          <DynamicListPage />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },

  {
    path: '/:endPoint/create',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper type={'create'}>
          <DynamicForm />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },

  {
    path: '/:endPoint/edit/:id',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper type={'edit'}>
          <DynamicForm />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },

  {
    path: '/:endPoint/view/:id',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper type={'view'}>
          <ViewPage />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },

  {
    path: '/:endPoint/process-request/:id',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper type={'process-request'}>
          <ProcessRequest />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },
  {
    path: '/production_plan/process/:id',
    element: (
      <EntityVerifier endPoint={'production_plan'}>
        <DynamicPageWrapper type={'process'} endPoint={'production_plan'}>
          <ProductionPlanForm />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },
  {
    path: '/work_orders/process/:id',
    element: (
      <EntityVerifier endPoint={'work_order'}>
        <DynamicPageWrapper type={'process'} endPoint={'work_order'}>
          <WorkOrderForm />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },
  {
    path: '/material_requests/process/:id',
    element: (
      <EntityVerifier endPoint={'material_requests'}>
        <DynamicPageWrapper type={'process'} endPoint={'material_requests'}>
          <MaterialRequestForm />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },

  {
    path: '/roleManagement',
    element: <RoleManagement />
  },
  {
    path: '/projects',
    element: <ProjectsBoard />
  },
  /* {
    path: '/crm',
    element: <CRM/>
  },
  */
  {
    path: '/management/users',
    element: <Users />
  },
  {
    path: '/management/user/profile',
    element: <Profile />
  },

  {
    path: '/setting',
    element: <Setting />
  },
  {
    path: '/hr-management/:id',
    element: <HrManagement />
  },
  {
    path: '/hr-management/:id',
    element: <HrManagement />
  },
  {
    path: '/workspace-setting',
    element: <WorkspaceAdminAuth element={<Outlet />} />,
    children: workspaceSettingRoutes
  },
  {
    path: '/forbidden',
    element: <Status403 />
  },
  {
    path: '/test',
    element: <Test />
  }
];
