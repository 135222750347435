import { InfoOutlined } from '@mui/icons-material';
import {
  Card,
  Stack,
  Typography,
  useTheme,
  Tooltip,
  Avatar,
  Box
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconButtonWrapper } from 'src/components/Styled';
import UserBox from './UserBox';
import { useState } from 'react';

const JobCard = ({ jobCard, options = { isEditable: true }, onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [openPopover, setOpenPopover] = useState(null);
  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    event.stopPropagation();
    setOpenPopover(null);
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        border: !jobCard?.isActive
          ? `2px solid ${theme.palette.primary.main} !important`
          : `1px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        p: 1,
        width: '100%',
        cursor: 'pointer'
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" fontWeight="bold">
          {jobCard?.number}
        </Typography>
        {options?.isEditable && (
          <Tooltip
            arrow
            title={t(
              `We've drafted a schedule. Feel free to edit by clicking on any field.`
            )}
            placement="right"
          >
            <IconButtonWrapper sx={{ ml: 0.5 }}>
              <InfoOutlined fontSize="small" />
            </IconButtonWrapper>
          </Tooltip>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">
          Production Floor: {jobCard?.location}
        </Typography>
        <Typography variant="subtitle2">
          {/* // ! replace with start_time */}
          {moment(jobCard?.scheduled_start).isValid()
            ? moment(jobCard?.scheduled_start).format('h:mm a')
            : ''}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Avatar
            src={jobCard?.operator?.user?.avatar}
            sx={{ width: 20, height: 20 }}
            onClick={handlePopoverOpen}
          />
          <UserBox
            openPopover={openPopover}
            user={jobCard?.operator?.user}
            handlePopoverClose={handlePopoverClose}
          />
          <Typography variant="subtitle1">
            {jobCard?.equipment?.name}
          </Typography>
        </Box>
        <Typography variant="subtitle2">{jobCard?.units} units</Typography>
      </Stack>
    </Card>
  );
};

export default JobCard;
