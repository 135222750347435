import { Box, Stack, Typography } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { useTheme } from '@emotion/react';

const QualityManagement = () => {
    const theme = useTheme();
    return (
        <>
            <Stack direction={'row'}>
                <Stack direction={'column'} width={'60%'}>
                    <Box>
                        <Typography marginTop={4}
                            sx={{ backgroundColor: `${theme.palette.primary.main}`, paddingX: 1, paddingY: .5, borderRadius: 10 }}
                            textAlign={'center'}
                            width={200}
                            color={'white'}>
                            Quality Management
                        </Typography>
                    </Box>

                    <Stack>
                        <Typography marginTop={1} fontSize={35}>
                            Ensure consistant quality, compliance, and contimuous improvement across every stage of production.
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography marginTop={1} >
                            Delivery excellence, ensure compliance, and drive continuous improvement.
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={.5}
                            width={80}
                            marginTop={1}
                            sx={{
                                color: `${theme.palette.primary.main}`, // Blue text color
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: '-4px', // Controls the gap between text and underline
                                    height: '2px', // Thickness of the underline
                                    backgroundColor: `${theme.palette.primary.main}`, // Blue underline color
                                }
                            }}
                        >
                            Explore
                            <ArrowRightAltOutlinedIcon fontSize='medium' />
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction={'row'} gap={2} marginTop={4}>
                <Stack direction={'column'} gap={1}>
                    <Typography fontSize={18}>
                        Predictive Demand Forecasting
                    </Typography>

                    <Typography fontSize={14}>
                        Leverage AI to anticipate demand, optimise inventory levels, and avoid stockouts or overstocking, ensuring smooth operations.
                    </Typography>
                </Stack>

                <Stack direction={'column'} gap={1}>
                    <Typography fontSize={18}>
                        Enhanced Supplier Collaboration
                    </Typography>

                    <Typography fontSize={14}>
                        Improve communication and cordination with suppliers, ensuring timely deliveries, reducing bottlenecks, and strengthening supplier relationships.
                    </Typography>
                </Stack>

                <Stack direction={'column'} gap={1}>
                    <Typography fontSize={18}>
                        Risk Mitigation and Transperancy
                    </Typography>

                    <Typography fontSize={14}>
                        gain real-time visibility into potential risks across the supply chain, allowing for proactive management and quick resolution of issues.
                    </Typography>
                </Stack>

            </Stack>
        </>
    )
}

export default QualityManagement;