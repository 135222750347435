import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Fab, Tooltip, styled } from '@mui/material';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import { useLocation } from 'react-router-dom';
import { requestApi } from 'src/mocks/request';
import { useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';
import { toast } from 'react-toastify';
import BackDropLoader from 'src/components/BackDropLoader';
import CustomPopover from 'src/components/CustomPopover';
import ApprovalActionBtn from './ApprovalActionBtn';

const ManageAppActButton = styled(Box)(
  ({ theme }) => `
          position: fixed;
          z-index: 9999;
          right: ${theme.spacing(4)};
          bottom: ${theme.spacing(4)};
  `
);

const ApprovalActionBox = ({ data }) => {
  const ref = useRef(null);
  const [showApprovalAct, setApprovalAct] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id: userId } = useSelector(selectLoggedUserData);
  const [hasPermission, setHasPermission] = useState(false);
  const location = useLocation();

  const handleShowApprovalAct = (event) => {
    setApprovalAct((prev) => (!prev ? event.currentTarget : null));
  };

  const searchParams = new URLSearchParams(location.search);
  const approvReqId = searchParams.get('approveReqId') || null;
  useEffect(() => {
    const fetchData = async () => {
      if (approvReqId) {
        setIsLoading(true);
        try {
          const payload = {
            endPoint: 'approval_request',
            query: {
              _id: approvReqId,
              approval: data.approval_id.id,
              approvers: { $in: [userId] }
            }
          };
          const response = await requestApi.getData(payload);
          if (response?.data?.data?.length) {
            const approvalReq = response?.data?.data[0];
            if (approvalReq?.isActive) {
              setHasPermission(true);
              ref.current.click();
            } else {
              toast.info(
                'You do not have permission to approve this document.'
              );
            }
          } else {
            toast.info('You do not have permission to approve this document.');
          }
          console.log('Approval request data:', response.data);
        } catch (error) {
          console.error('Error fetching approval request:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [approvReqId, userId]);

  return (
    <>
      <BackDropLoader isShow={isLoading}>
        <CircularProgress color="inherit" />
      </BackDropLoader>
      {hasPermission && (
        <ManageAppActButton>
          <Tooltip arrow title={'Manage Approval Actions'}>
            <Fab ref={ref} color="primary" onClick={handleShowApprovalAct}>
              <ApprovalOutlinedIcon />
            </Fab>
          </Tooltip>
        </ManageAppActButton>
      )}
      <CustomPopover
        anchorEl={showApprovalAct}
        open={Boolean(showApprovalAct)}
        onClose={handleShowApprovalAct}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <ApprovalActionBtn data={data} approvReqId={approvReqId} />
      </CustomPopover>
    </>
  );
};

export default ApprovalActionBox;
