import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

const StockAuditViewLoader = ({ title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageTitleWrapper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} md={4} sm={6}>
            <Skeleton variant="text" sx={{ width: '60%' }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display={'flex'}
            gap={0.5}
            justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}
          >
            <Skeleton variant="rounded" width={130} height={40} />
            <Skeleton variant="rounded" width={40} height={40} />
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={'25vh'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={'45vh'} />
        </Grid>
      </Grid>
    </>
  );
};

export default StockAuditViewLoader;
