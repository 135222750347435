import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Form, Formik } from 'formik';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Button, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import CompanyTabs from './CompanyTabs';
import { selectLoggedUserData } from 'src/slices/authSlice';
import { useSelector } from 'react-redux';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import { requestApi } from 'src/mocks/request';

const WorkspaceProfile = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const loggedUserData = useSelector(selectLoggedUserData);
  const { schema } = useEntityPageConfig('workspace');
  const { workspace } = loggedUserData || {};
  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const { set, unset } = sanitizeFormData(values, schema, ['company']);
      const payload = {
        endPoint: 'workspace',
        data: {
          $set: set,
          $unset: unset
        },
        id: workspace._id || workspace.id
      };
      //   console.log(payload, 'payload');
      await requestApi.updateData(payload);
    } finally {
      setSubmitting(false);
    }
  };
  if (!schema) return null;
  return (
    <>
      <Helmet>
        <title>Workspace Profile</title>
      </Helmet>

      <Formik
        initialValues={{ company: workspace?.company || {} }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <PageTitleWrapper>
              <PageHeader />
            </PageTitleWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CompanyTabs />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={'1rem'} />}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WorkspaceProfile;
