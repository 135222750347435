import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConditionFields from './ConditionFields';

const ActionCondition = ({ conditionArr, path, entity = null }) => {
  const { setFieldValue } = useFormikContext();

  const handleFieldNameChange = (event, conditionIndex) => {
    const value = event.target.value;
    const payload = {
      field_name: value,
      field_operator: '',
      field_value: '',
      field_join: ''
    };
    setFieldValue(`${path}.${conditionIndex}`, payload);
  };

  const renderAddConditionButton = (push) => (
    <Box sx={{ alignSelf: 'start' }}>
      <Button
        size="small"
        onClick={() =>
          push({
            field_name: '',
            field_operator: '',
            field_value: '',
            field_join: ''
          })
        }
        startIcon={<AddIcon fontSize={'small'} />}
      >
        Add Condition
      </Button>
    </Box>
  );

  return (
    <FieldArray name={path}>
      {({ push, remove }) => (
        <Stack rowGap={1}>
          {conditionArr.length === 0 && renderAddConditionButton(push)}

          {conditionArr.map((condition, conditionIndex) => (
            <Grid
              container
              spacing={1.5}
              alignItems={'center'}
              key={conditionIndex}
            >
              <ConditionFields
                conditionPath={path}
                conditionIndex={conditionIndex}
                condition={condition}
                handlFieldNameChange={handleFieldNameChange}
                conditionArr={conditionArr}
                entity={entity}
                gridXs={3}
              />
              <Grid item xs={1}>
                <Tooltip title="Remove Condition">
                  <IconButton onClick={() => remove(conditionIndex)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              {condition.field_join && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {condition.field_join}
                  </Typography>
                </Grid>
              )}

              {conditionIndex === conditionArr.length - 1 &&
                condition.field_join &&
                renderAddConditionButton(push)}
            </Grid>
          ))}
        </Stack>
      )}
    </FieldArray>
  );
};

export default ActionCondition;

// import React from 'react';
// import { FieldArray, useFormikContext } from 'formik';
// import {
//   Box,
//   Button,
//   Grid,
//   IconButton,
//   Stack,
//   Tooltip,
//   Typography
// } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import ConditionFields from './ConditionFields';

// const ActionCondition = ({ conditionArr, path, entity = null }) => {
//   const { setFieldValue } = useFormikContext();

//   const handlFieldNameChange = (event, conditionIndex) => {
//     const value = event.target.value;
//     const payload = {
//       field_name: value,
//       field_operator: '',
//       field_value: '',
//       field_join: ''
//     };
//     setFieldValue(`${path}.${conditionIndex}`, payload);
//   };

//   return (
//     <FieldArray name={path}>
//       {({ push, remove }) => (
//         <Stack rowGap={1}>
//           {conditionArr.length > 0 &&
//             conditionArr.map((condition, conditionIndex) => (
//               <Grid
//                 container
//                 spacing={1.5}
//                 alignItems={'center'}
//                 key={conditionIndex}
//               >
//                 <ConditionFields
//                   conditionPath={path}
//                   conditionIndex={conditionIndex}
//                   condition={condition}
//                   handlFieldNameChange={handlFieldNameChange}
//                   conditionArr={conditionArr}
//                   entity={entity}
//                   gridXs={3}
//                 />
//                 <Grid item xs={1}>
//                   <Tooltip title={'Remove Condition'}>
//                     <IconButton onClick={() => remove(conditionIndex)}>
//                       <DeleteForeverIcon />
//                     </IconButton>
//                   </Tooltip>
//                 </Grid>
//                 {conditionArr[conditionIndex]['field_join'] && (
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">
//                       {conditionArr[conditionIndex]['field_join']}
//                     </Typography>
//                     {conditionIndex === conditionArr.length - 1 && (
//                       <Box sx={{ alignSelf: 'start' }}>
//                         <Button
//                           size="small"
//                           onClick={() =>
//                             push({
//                               field_name: '',
//                               field_operator: '',
//                               field_value: '',
//                               field_join: ''
//                             })
//                           }
//                           startIcon={<AddIcon fontSize={'small'} />}
//                         >
//                           Add Condition
//                         </Button>
//                       </Box>
//                     )}
//                   </Grid>
//                 )}
//               </Grid>
//             ))}
//           {conditionArr.length === 0 && (
//             <Box sx={{ alignSelf: 'start' }}>
//               <Button
//                 size="small"
//                 onClick={() =>
//                   push({
//                     field_name: '',
//                     field_operator: '',
//                     field_value: '',
//                     field_join: ''
//                   })
//                 }
//                 startIcon={<AddIcon fontSize={'small'} />}
//               >
//                 Add Condition
//               </Button>
//             </Box>
//           )}
//         </Stack>
//       )}
//     </FieldArray>
//   );
// };

// export default ActionCondition;
