import { Popover } from '@mui/material';
import React from 'react';

const CustomPopover = ({
  anchorEl,
  open,
  onClose,
  children,
  sx = {},
  id = null,
  slotProps = {},
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'center' }
}) => {
  return (
    <Popover
      disableScrollLock
      id={id}
      sx={{ ...sx }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      slotProps={slotProps}
      onClose={onClose}
      disableRestoreFocus
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;
