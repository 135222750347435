import { requestApi } from 'src/mocks/request';

const cache = new Map();
const inFlightRequests = new Map();

const getValueByPath = (value, path) => {
  const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.');
  return keys.reduce(
    (acc, key) => (acc !== undefined && acc !== null ? acc[key] : null),
    value
  );
};

const getCachedOrFetchValue = async (payload) => {
  const queryKey = JSON.stringify(payload);

  // Check if result is already cached
  if (cache.has(queryKey)) {
    return cache.get(queryKey);
  }

  // Check if the request is already in progress
  if (inFlightRequests.has(queryKey)) {
    return inFlightRequests.get(queryKey);
  }

  // Start the API request and store the promise in the in-flight map
  const requestPromise = requestApi.getData(payload).then((response) => {
    const data = response?.data?.data?.length ? response.data.data[0] : null;

    // Store the result in cache
    cache.set(queryKey, data);

    // Remove the request from in-flight tracking
    inFlightRequests.delete(queryKey);

    return data;
  });

  inFlightRequests.set(queryKey, requestPromise);

  // Return the ongoing promise
  return requestPromise;
};

const getValueByQuery = async (affItem, allValues, idx) => {
  try {
    const { meta, arrayKey: affArrKey, query, path: affPath } = affItem;
    if (!query || !meta) return null;

    const buildQuery = query.reduce((acc, queryItem) => {
      const { path, arrayKey, fieldToSearch } = queryItem;
      let resolvedPath = path;
      if (arrayKey) resolvedPath = `${arrayKey}[${idx}].${path}`;
      const foundValue = getValueByPath(allValues, resolvedPath);
      acc[fieldToSearch] = foundValue;
      return acc;
    }, {});

    const hasNullOrUndefined = Object.values(buildQuery).some(
      (value) => value === null || value === undefined
    );

    if (hasNullOrUndefined) {
      return null;
    }

    const payload = {
      endPoint: meta.entity,
      query: buildQuery
    };

    console.log('Constructed Query Payload:', payload);

    // Fetch data using cache and in-flight request handling
    const data = await getCachedOrFetchValue(payload);

    if (data) {
      let path = affPath;
      if (affArrKey) path = `${affArrKey}[${idx}].${path}`;
      return getValueByPath(data, path);
    }

    return null;
  } catch (error) {
    console.error('Error fetching value by query:', error);
    return null;
  }
};

export const processAutoFillFields = async (
  value,
  allAutoFillFieldName,
  index,
  fieldName,
  itemTableHead,
  setFieldValue,
  allValues
) => {
  for (const item of allAutoFillFieldName) {
    const { fieldName: field, aff, isQuery } = item;
    const path = itemTableHead ? `${fieldName}.${index}.${field}` : field;

    if (isQuery && Array.isArray(aff) && allValues?.[field]?.length) {
      await Promise.all(
        allValues[field].map(async (existingValue, idx) => {
          // Modify existingValue directly since it's an object reference
          for (const affItem of aff) {
            let foundValue = null;

            if (affItem.type === 'populate') {
              let affPath = affItem.path;
              let arrayKey = affItem.arrayKey;
              if (arrayKey) affPath = `${arrayKey}[${idx}].${affPath}`;
              foundValue = getValueByPath(allValues, affPath);
            } else if (affItem.type === 'query') {
              foundValue = await getValueByQuery(affItem, allValues, idx);
            }

            if (foundValue !== null) {
              const key = affItem.subKey; // Assume affItem has subKey defined
              existingValue[key] = foundValue; // Modify existingValue directly
            }
          }
        })
      );

      const filteredPayload = allValues[field].filter(
        (item) => item && Object.keys(item).length > 0
      );

      if (filteredPayload.length) {
        setFieldValue(path, filteredPayload);
      }
    } else if (Array.isArray(aff) && value?.[field]?.length) {
      const payload = await Promise.all(
        Array.from({ length: value[field].length }).map(async (_, idx) => {
          const dataObjResult = {};

          for (const affItem of aff) {
            let foundValue = null;

            if (affItem.type === 'populate') {
              let path = affItem.path;
              let arrayKey = affItem.arrayKey;
              if (arrayKey) path = `${arrayKey}[${idx}].${path}`;
              foundValue = getValueByPath(value, path);
            } else if (affItem.type === 'query') {
              foundValue = await getValueByQuery(affItem, allValues, idx);
            }

            if (foundValue) {
              const key = affItem.subKey; // Assume affItem has subKey defined
              dataObjResult[key] = foundValue;
            }
          }

          return Object.keys(dataObjResult).length > 0 ? dataObjResult : null;
        })
      );

      const filteredPayload = payload.filter((item) => item !== null);
      if (filteredPayload.length) {
        setFieldValue(path, filteredPayload);
      }
    } else {
      let foundValue = null;

      if (aff.type === 'populate') {
        foundValue = getValueByPath(value, aff.path);
      } else if (aff.type === 'query') {
        foundValue = await getValueByQuery(aff, allValues, index);
      }

      if (foundValue !== null) {
        setFieldValue(path, foundValue);
      }
    }
  }
};
