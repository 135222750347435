// todo to confirm (when deep field in fieldshow)
// do we need nested serach
// do we need to give option for create nested option

import {
  Box,
  CircularProgress,
  createFilterOptions,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getAutoCompleteConfig,
  getOption
} from 'src/services/AutoCompleteService';
import useRefMounted from 'src/hooks/useRefMounted';
import { getAllRequiredFields } from 'src/services/commonService';
import { requestApi } from 'src/mocks/request';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { getNestedValue } from 'src/utils/getNestedValue';

const useAutocompleteLogic = ({
  fieldName,
  itemTableHead,
  schema,
  isMultiple,
  value,
  onChange,
  fieldQuery,
  entitySetting,
  onBlur,
  error,
  touched,
  required,
  label,
  name,
  readOnly,
  placeholder
}) => {
  const filter = createFilterOptions();
  const [options, setOptions] = useState(() => {
    if (isMultiple) {
      return value ? value : [];
    } else {
      return value ? [value] : [];
    }
  });
  const [query, setQuery] = useState('');
  const isTyping = useRef(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addOptLoading, setAddOptLoading] = useState(false);
  const selectedInputValue = useRef('');
  const isMounted = useRefMounted();
  const refEntity = isMultiple
    ? schema[itemTableHead || fieldName]?.$embeddedSchemaType?.options?.ref
    : schema[itemTableHead || fieldName]?.options?.ref;
  const { schema: refSchema } = useEntityPageConfig(refEntity);
  const {
    query: defaultQuery,
    fieldShow,
    secondary_text,
    populateString
  } = useMemo(() => {
    if (refSchema) {
      return getAutoCompleteConfig({
        fieldName,
        entitySetting,
        itemTableHead,
        refSchema,
        schema
      });
    } else {
      return {
        query: {},
        fieldShow: null,
        secondary_text: null,
        populateString: ''
      };
    }
  }, [fieldName, itemTableHead, refSchema, entitySetting, schema]);

  const handleFetchOption = useCallback(() => {
    if (!isTyping.current) return;
    if (refEntity && fieldShow) {
      getOption({
        endPoint: refEntity,
        fieldQuery,
        refSchema,
        populateString,
        fieldShow,
        secondary_text,
        defaultQuery,
        value,
        setLoading,
        setOptions,
        isMounted,
        query: query.trim()
      });
    }
  }, [
    refEntity,
    fieldShow,
    fieldQuery,
    secondary_text,
    defaultQuery,
    value,
    refSchema,
    setLoading,
    setOptions,
    isMounted,
    populateString,
    query
  ]);

  useEffect(() => {
    let clearTime = setTimeout(() => {
      handleFetchOption();
    }, 500);
    return () => {
      clearTimeout(clearTime);
    };
  }, [query]);

  const handleFocus = () => {
    isTyping.current = true;
  };

  const handleBlur = (e) => {
    isTyping.current = false;
    onBlur(e);
  };

  const handleQueryChange = (event, value) => {
    setLoading(true);
    setQuery(value);
    const trimmedValue = value.trim().toLowerCase();
    const currentQuery = query.trim().toLowerCase();
    if (trimmedValue === currentQuery) {
      isTyping.current = false;
      setLoading(false);
    } else if (trimmedValue !== currentQuery && !isTyping.current) {
      isTyping.current = true;
    }
  };

  const handOnOpen = () => {
    isTyping.current = true;
    handleFetchOption();
  };

  const handleDrawer = (query = null, event = null) => {
    if (event) {
      event.stopPropagation();
    }
    selectedInputValue.current = query;
    setDrawerOpen(!drawerOpen);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const addOption = async () => {
    setAddOptLoading(true);
    const payLoad = {
      refEntity,
      data: {
        [fieldShow]: query.trim()
      }
    };
    try {
      const response = await requestApi.createData(payLoad);
      if (response.status === 'SUCCESS') {
        setOptions((prevOptions) => [...prevOptions, response.data]);
        onChange(isMultiple ? [...value, response.data] : response.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setAddOptLoading(false);
    }
  };

  const CheckRequiredFields = () => {
    selectedInputValue.current = query.trim();
    let requiredFields = getAllRequiredFields(
      Object.keys(refSchema),
      refSchema
    );
    if (requiredFields.length === 1) {
      if (requiredFields[0] === fieldShow) {
        addOption();
      } else {
        handleModal();
      }
    } else if (requiredFields.length > 1) {
      handleModal();
    } else {
      handleDrawer();
    }
  };
  // const handleFilterOption = (options, params) => {
  //   const filtered = filter(options, params);
  //   console.log(options, filtered, 'filteredfiltered');

  //   const { inputValue } = params;
  //   const isExisting = options.some(
  //     (option) => inputValue === (option[fieldShow] || option[secondary_text])
  //   );
  //   if (inputValue !== '' && !isExisting) {
  //     filtered.push({
  //       inputValue,
  //       isNew: true
  //     });
  //   }

  //   return filtered;
  // };
  const handleFilterOption = (options, params) => {
    const { inputValue } = params;

    // Define a custom filter logic
    const filtered = options.filter((option) => {
      const primaryMatch = getNestedValue(option, fieldShow)
        ?.toLowerCase()
        .includes(inputValue.toLowerCase());
      const secondaryMatch = secondary_text
        ? getNestedValue(option, secondary_text)
            ?.toLowerCase()
            .includes(inputValue.toLowerCase())
        : false;
      return primaryMatch || secondaryMatch;
    });

    // // Check if the input value matches any existing options based on fieldShow or secondary_text
    // const isExisting = filtered.some(
    //   (option) =>
    //     inputValue.toLowerCase() === (option[fieldShow]?.toLowerCase() || '') ||
    //     inputValue.toLowerCase() ===
    //       (option[secondary_text]?.toLowerCase() || '')
    // );
    // Check if the input value matches any existing options based on fieldShow or secondary_text
    const isExisting = filtered.some(
      (option) =>
        inputValue.toLowerCase() ===
          (getNestedValue(option, fieldShow)?.toLowerCase() || '') ||
        (secondary_text &&
          inputValue.toLowerCase() ===
            getNestedValue(option, secondary_text)?.toLowerCase()) ||
        ''
    );

    // If the input value does not match any of the existing options, add it as a new option
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        isNew: true
      });
    }

    return filtered;
  };

  const handleChange = (event, newValue) => {
    isTyping.current = false;
    setLoading(false);
    if (isMultiple && newValue.some((item) => item && item.inputValue)) {
      CheckRequiredFields();
    } else if (newValue?.inputValue) {
      // Handle case when newValue is a new single object with inputValue
      CheckRequiredFields();
    } else {
      // Handle case when newValue is a single existing option
      onChange(newValue);
    }
  };

  const handleRenderOption = (props, option, { index }) => {
    if (loading && index !== 0) return null;

    return (
      <li {...props}>
        {loading && 'Loading options...'}
        {!loading &&
          (option.isNew ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AddIcon fontSize="small" />
                <span>{`Add "${option.inputValue}"`}</span>
              </Stack>
              <Tooltip title={`Fill all ${refEntity} details`}>
                <IconButton
                  size="small"
                  onClick={(event) => handleDrawer(query, event)}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <Box>
              <Typography variant="h6">
                {getNestedValue(option, fieldShow)}
              </Typography>
              {secondary_text && (
                <Typography variant="caption">
                  {getNestedValue(option, secondary_text)}
                </Typography>
              )}
            </Box>
          ))}
      </li>
    );
  };

  const handleRenderInput = (params) => (
    <TextField
      sx={{ p: 0 }}
      {...params}
      label={label}
      placeholder={placeholder ? placeholder : 'Search...'}
      inputProps={{ ...params.inputProps, name, readOnly }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      required={required}
      error={Boolean(touched && error?.id)}
      helperText={(touched && error?.id) || ''}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {addOptLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : (
              params.InputProps.endAdornment
            )}
          </>
        ),
        style: {
          paddingRight: addOptLoading ? 10 : 60
        }
      }}
    />
  );

  return {
    options,
    query,
    drawerOpen,
    openModal,
    fieldShow,
    secondary_text,
    refEntity,
    selectedInputValue,
    handleDrawer,
    handleModal,
    setOptions,
    handleQueryChange,
    handOnOpen,
    handleFilterOption,
    handleChange,
    handleRenderOption,
    handleRenderInput
  };
};

export default useAutocompleteLogic;
