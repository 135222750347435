import { Button, Skeleton, Stack, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { useState, useEffect } from 'react';
import { requestApi } from 'src/mocks/request';
import ApprovalConfirmDialog from './ApprovalConfirmDialog';
import useRefMounted from 'src/hooks/useRefMounted';
import HistoryIcon from '@mui/icons-material/History';
import CommonModal from 'src/components/CommonModal.js';
import RenderTimelineItem from './RenderTimelineItem';

const ApprovalActionBtn = ({ data, approvReqId }) => {
  const { approval_id: approval } = data;
  const [historyModal, setHistoryModal] = useState(false);
  const [approvalRequests, setApprovalRequests] = useState([]);
  const [isFetching, setFetching] = useState(true);
  const [dialogType, setDialogType] = useState(null);
  const isMounted = useRefMounted();

  const handleDialogOpen = (type) => setDialogType(type);
  const handleDialogClose = () => setDialogType(null);

  useEffect(() => {
    const fetchApprovalRequests = async () => {
      setFetching(true);
      try {
        const payload = {
          endPoint: 'approval_request',
          populate: 'approval_by',
          query: {
            approval: approval?.id,
            $or: [
              { stage: { $lt: approval?.current_stage } },
              {
                stage: approval?.current_stage,
                // approval_by: { $ne: null }
                isActive: false
              }
            ]
          }
        };

        const response = await requestApi.getData(payload);
        if (response?.data?.data?.length && isMounted.current) {
          setApprovalRequests(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching approval requests:', error);
      } finally {
        if (isMounted.current) setFetching(false);
      }
    };

    fetchApprovalRequests();
  }, [approval?.id, approval?.current_stage, isMounted]);

  if (isFetching)
    return (
      <Stack spacing={1} p={2}>
        <Skeleton variant="rounded" width={150} height={40} />
        <Skeleton variant="rounded" width={150} height={40} />
        <Skeleton variant="rounded" width={150} height={40} />
      </Stack>
    );

  return (
    <>
      <Stack spacing={1} p={2}>
        <Button
          variant="outlined"
          color="success"
          startIcon={<DoneTwoToneIcon />}
          onClick={() => handleDialogOpen('APPROVED')}
        >
          Approve
        </Button>
        <Button
          variant="outlined"
          color="error"
          startIcon={<ClearTwoToneIcon />}
          onClick={() => handleDialogOpen('REJECTED')}
        >
          Reject
        </Button>
        <Button
          variant="outlined"
          color="warning"
          startIcon={<HistoryIcon />}
          onClick={() => setHistoryModal(true)}
        >
          History
        </Button>
      </Stack>
      <ApprovalConfirmDialog
        open={dialogType}
        approvReqId={approvReqId}
        handleClose={handleDialogClose}
      />
      <CommonModal
        open={historyModal}
        handleModal={() => setHistoryModal(false)}
        title={'Approval History'}
      >
        {approvalRequests.length > 0 ? (
          <Timeline>
            {approvalRequests?.map((approval) => (
              <RenderTimelineItem
                key={approval.approval_by.id}
                approval={approval}
              />
            ))}
          </Timeline>
        ) : (
          <Typography variant="body2">No approval comments found.</Typography>
        )}
      </CommonModal>
    </>
  );
};

export default ApprovalActionBtn;
