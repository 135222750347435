import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { Form, Formik } from 'formik';
import AuditSplit from './AuditSplit';
import AuditDetails from './AuditDetails';
import StockAuditLoader from '../StockAuditLoader';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
import { useNavigate } from 'react-router';

const StockAudit = () => {
  const navigate = useNavigate();
  const { schema } = useEntityPageConfig('stock_audit');
  const { currentItem, isFetching } = useDyFetchDataById();
  const [submitting, setSubmitting] = useState(false);
  const isMounted = useRefMounted();
  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const { createData } = sanitizeFormData(
        values,
        schema,
        Object.keys(values)
      );
      const payload = {
        endPoint: 'stock_audit',
        data: createData
      };
      const response = await requestApi.createData(payload);
      if (response.data) {
        navigate('/stock_audit');
      }
    } finally {
      if (isMounted.current) setSubmitting(true);
    }
  };
  if (isFetching) return <StockAuditLoader />;
  return (
    <>
      <Helmet>
        <title>Stock Audit</title>
      </Helmet>

      <Formik
        initialValues={{
          warehouse: currentItem || '',
          splits: [],
          split_by: '',
          scheduled_on: '',
          audit_type: 'stock_audit',
          scope: '',
          instructions: `
    <p>Verify that the physical count of stock items matches the recorded quantities in the inventory system.</p>
    <p>Ensure that stock items are stored in their designated locations according to the inventory management system.</p>
    <p>Evaluate the condition of stock items to identify any damage, obsolescence, or spoilage.</p>
    <p>For perishable items, check the expiration dates and ensure that they are within the acceptable range.</p>
    <p>Assess the measures in place to prevent stock theft and unauthorized access.</p>
  `
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <PageTitleWrapper>
              <PageHeader submitting={submitting} />
            </PageTitleWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AuditDetails />
              </Grid>
              <Grid item xs={12}>
                <AuditSplit currentItem={currentItem} />
              </Grid>
              <Grid item xs={12}>
                <QuillEditorField
                  label={'Audit Criteria &  Instructions'}
                  value={values.instructions || ''}
                  onChange={(value) => setFieldValue('instructions', value)}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default StockAudit;
