import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';

const CustomDateField = ({
  label,
  size,
  value,
  onChange,
  onBlur,
  error,
  touched
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size={size}
            onBlur={onBlur}
            error={Boolean(touched && error?.id)}
            helperText={(touched && error?.id) || ''}
          />
        )}
        inputFormat="DD-MM-YYYY"
      />
    </LocalizationProvider>
  );
};

export default CustomDateField;
