import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Scrollbar from 'src/components/Scrollbar';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import UserSelectBox from './UserSelectBox';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import { requestApi } from 'src/mocks/request';
import useRefMounted from 'src/hooks/useRefMounted';
import TableLoader from 'src/components/TableLoader';

const AuditSplit = ({ currentItem }) => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { warehouse, split_by, scope, splits } = values;
  console.log(splits, 'splits');
  const isMounted = useRefMounted();
  const [isFetchingLoc, setFetchingLoc] = useState(false);
  const { schema } = useEntityPageConfig('stock_audit');

  const fetchLocation = async () => {
    setFetchingLoc(true);
    try {
      const payload = {
        endPoint: 'warehouse_location',
        query: {
          warehouse: warehouse?.id,
          other_parents: { $in: scope?.id ? [scope?.id] : [] },
          location_type: split_by
        }
      };
      const response = await requestApi.getData(payload);
      if (response?.data?.data?.length) {
        const splitsArr = response.data.data.map((location) => ({
          location,
          performed_by: '',
          verified_by: ''
        }));
        setFieldValue('splits', splitsArr);
      } else {
        setFieldValue('splits', []);
      }
    } finally {
      if (isMounted.current) setFetchingLoc(false);
    }
  };

  useEffect(() => {
    if (warehouse && split_by && scope) {
      fetchLocation();
    }
  }, [warehouse, split_by, scope]);

  if (!schema) return <Skeleton variant="rounded" height={'30vh'} />;

  return (
    <Card variant="outlined">
      <CardHeader
        action={
          <Box width={{ xs: 150, sm: 250 }}>
            <CustomSelectField
              name="split_by"
              label="Split By"
              size="small"
              value={split_by || ''}
              options={schema?.split_by?.enumValues || []}
              onChange={handleChange}
            />
          </Box>
        }
        title="Audit Split"
      />
      <Divider />
      <CardContent>
        <Scrollbar maxHeight="auto">
          <Table sx={{ minWidth: 700, tableLayout: 'fixed' }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Appoint Verifier</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetchingLoc ? (
                <TableLoader rows={3} cols={3} />
              ) : splits.length ? (
                splits.map((split, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {split?.location?.full_code ||
                        split?.location?.code ||
                        ''}
                    </TableCell>
                    <TableCell>
                      <UserSelectBox
                        boxType={'assignee'}
                        user={split['performed_by']}
                        path={`splits[${index}].performed_by`}
                      />
                    </TableCell>
                    <TableCell>
                      <UserSelectBox
                        boxType={'verifier'}
                        user={split['verified_by']}
                        path={`splits[${index}].verified_by`}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No Locations Found</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </CardContent>
    </Card>
  );
};

export default AuditSplit;
