import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import ApprovalStatusDetails from './ApprovalStatusDetails';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch } from 'react-redux';
import useRefMounted from 'src/hooks/useRefMounted';
import { selectDynamicPageConfig, setData } from 'src/slices/dynamicSlice';
import { requestApi } from 'src/mocks/request';
import { useSelector } from 'react-redux';

const getBadgeColor = (status) => {
  switch (status) {
    case 'PENDING':
      return 'warning';
    case 'APPROVED':
      return 'success';
    case 'REJECTED':
      return 'error';
    default:
      return 'secondary';
  }
};
const CombinedStatusApproval = ({ rowData, fieldname }) => {
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const { entity } = useSelector(selectDynamicPageConfig);
  const isMounted = useRefMounted();
  const { approval_id: approval, approval_status } = rowData;
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const sendApproval = useCallback(async () => {
    setSending(true);
    try {
      const payload = {
        endPoint: 'approval',
        data: {
          entity,
          document_id: rowData.id
        }
      };
      const response = await requestApi.createData(payload);
      if (response?.data)
        dispatch(
          setData({
            ...rowData,
            approval_id: response.data,
            approval_status: 'PENDING'
          })
        );
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted.current) setSending(false);
    }
  }, [dispatch, entity, rowData]);
  if (approval_status === 'NA') return rowData[fieldname];
  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <span style={{ whiteSpace: 'nowrap' }}>{rowData[fieldname]}</span>
        {approval_status === 'NOT SUBMITTED' ? (
          <Tooltip title={'Send Approval'} arrow>
            <IconButton
              disableFocusRipple
              disabled={sending}
              color="primary"
              sx={{ p: 0, ml: 0.5 }}
              onClick={sendApproval}
            >
              {sending ? (
                <CircularProgress size={12} />
              ) : (
                <SendIcon sx={{ fontSize: 12 }} />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={approval_status} arrow>
            <Badge
              sx={{
                ml: 1,
                cursor: 'pointer',
                '& .MuiBadge-dot': {
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  transition: 'width 0.3s, height 0.3s',
                  '&:hover': {
                    width: 12,
                    height: 12
                  }
                }
              }}
              color={getBadgeColor(approval_status)}
              variant="dot"
              onClick={handleModal}
            />
          </Tooltip>
        )}
      </Stack>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        btnName={'Close'}
        title={'Approval Status'}
      >
        <ApprovalStatusDetails approval={approval} />
      </CommonModal>
    </>
  );
};

export default CombinedStatusApproval;
