import * as Yup from 'yup';

// Get the validation schema for the provided schema and field names
export const getValidationSchema = (schema, fieldNames) => {
  if (!schema || !fieldNames) return null;

  const validationSchema = fieldNames.reduce((accumulate, item) => {
    accumulate[item] = getFieldValidation(schema, item); // Get validation for each field
    return accumulate;
  }, {});

  return Yup.object().shape(validationSchema); // Return the overall schema
};

// Function to handle validation for each field type
const getFieldValidation = (schema, item) => {
  switch (schema[item]?.instance) {
    case 'String':
      return schema[item]?.options?.required
        ? Yup.string().required(`${item} is required`)
        : Yup.string();

    case 'Number':
      return schema[item]?.options?.required
        ? Yup.number().required(`${item} is required`)
        : Yup.number();

    case 'Boolean':
      return schema[item]?.options?.required
        ? Yup.boolean().required(`${item} is required`)
        : Yup.boolean();

    case 'Date':
      return schema[item]?.options?.required
        ? Yup.date().required(`${item} is required`)
        : Yup.date().nullable(true);

    case 'ObjectID':
      return schema[item]?.options?.required
        ? Yup.object({
            id: Yup.string().required(`${item} is required`)
          })
        : Yup.object({
            id: Yup.string()
          }).nullable();

    case 'Array': {
      const embeddedSchemaType = schema[item]?.$embeddedSchemaType;

      // Handle ObjectID in arrays
      if (embeddedSchemaType?.instance === 'ObjectID') {
        return embeddedSchemaType.options?.required
          ? Yup.array().of(Yup.string().required(`${item} is required`))
          : Yup.array();
      }

      // Handle nested object schemas within arrays
      if (embeddedSchemaType?.schema?.paths) {
        const paths = embeddedSchemaType.schema.paths;
        const nestedValidationSchema = Object.keys(paths).reduce(
          (nestedAccumulate, key) => {
            nestedAccumulate[key] = getFieldValidation(paths, key);
            return nestedAccumulate;
          },
          {}
        );

        return Yup.array().of(Yup.object().shape(nestedValidationSchema));
      }

      // Default array validation
      return schema[item]?.$embeddedSchemaType?.options?.required
        ? Yup.array().min(1, `${item} must have at least one element`)
        : Yup.array();
    }

    default:
      return Yup.mixed(); // Default case for unsupported types
  }
};

export const getInitialValues = (
  schema,
  fieldNames,
  currentItem,
  inputValue = null,
  fieldShow = null
) => {
  if (!schema || !fieldNames) return null;

  const initialValues = fieldNames.reduce((accumulate, item) => {
    const propertyValue = Boolean(
      currentItem && currentItem[item] !== undefined
    );

    switch (schema[item]?.instance) {
      case 'String':
        accumulate[item] = propertyValue
          ? currentItem[item]
          : inputValue && fieldShow && item === fieldShow
          ? inputValue
          : schema[item]?.options?.default
          ? schema[item]?.options?.default
          : '';
        break;

      case 'Boolean':
        accumulate[item] = propertyValue
          ? currentItem[item]
          : schema[item]?.options?.default !== undefined
          ? schema[item]?.options?.default
          : '';
        break;

      case 'Date':
        accumulate[item] = propertyValue
          ? currentItem[item]
          : schema[item]?.options?.default !== undefined
          ? new Date(schema[item].options.default)
          : new Date();
        break;

      case 'ObjectID':
        accumulate[item] = propertyValue ? currentItem[item] : '';
        break;

      case 'Number':
        accumulate[item] = propertyValue
          ? currentItem[item]
          : schema[item]?.options?.default !== undefined
          ? schema[item]?.options?.default
          : '';
        break;

      case 'Array': {
        accumulate[item] = propertyValue ? currentItem[item] : [];
        break;
      }
      //   case 'Embedded':
      case 'Object': {
        accumulate[item] = propertyValue ? currentItem[item] : {};
        break;
      }

      default:
        accumulate[item] = propertyValue ? currentItem[item] : '';
    }

    return accumulate;
  }, {});

  return initialValues;
};
