import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom';
import { capitalizeAndRemoveChar } from 'src/services/commonService';
import { selectDynamicPageConfig, selectTitle } from 'src/slices/dynamicSlice';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader({ handleActiveTab, activeTab, currentItem }) {
  const { values, setFieldValue } = useFormikContext();
  const title = useSelector(selectTitle);
  const { schema } = useSelector(selectDynamicPageConfig);
  const navigate = useNavigate();
  const formattedTitle = capitalizeAndRemoveChar(title);
  const statusOption = schema?.status?.enumValues || [];
  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    const currentIndex = statusOption.indexOf(currentItem?.status);
    const selectedIndex = statusOption.indexOf(selectedStatus);

    if (selectedIndex < currentIndex) {
      toast.info(
        `You cannot change the document status from ${statusOption[currentIndex]} to an earlier status ${statusOption[selectedIndex]}.`
      );
    } else {
      setFieldValue('status', selectedStatus);
    }
  };

  return (
    <Stack direction={{ md: 'row' }} gap={1}>
      <Box>
        <Typography variant="h3" component="h3" gutterBottom>
          {currentItem ? 'Update' : 'Add New'} {formattedTitle}
        </Typography>
        <Typography variant="body1">
          Fill in the fields below to {currentItem ? 'Update' : 'create a new'}{' '}
          {formattedTitle} to the site
        </Typography>
      </Box>
      <Stack
        direction={'row'}
        gap={1}
        flexGrow={1}
        justifyContent={{ md: 'flex-end' }}
        flexWrap={'wrap'}
      >
        <CustomSelectField
          label={'Status'}
          name="status"
          size="small"
          sx={{ maxWidth: 200 }}
          onChange={handleStatusChange}
          value={values?.status || ''}
          options={statusOption}
        />
        <Button
          size="small"
          variant={activeTab === 'order' ? 'contained' : 'outlined'}
          endIcon={<ReceiptLongOutlinedIcon />}
          onClick={() => handleActiveTab('order', values)}
          sx={{ whiteSpace: 'nowrap', height: 40 }}
        >
          Order Information
        </Button>

        <Button
          size="small"
          variant={activeTab === 'product' ? 'contained' : 'outlined'}
          endIcon={<Inventory2OutlinedIcon />}
          onClick={() => handleActiveTab('product', values)}
          sx={{ whiteSpace: 'nowrap', height: 40 }}
        >
          Product Details
        </Button>
        <Tooltip title={`Go back`}>
          <IconButtonWrapper
            sx={{ height: 40 }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackTwoToneIcon fontSize="small" />
          </IconButtonWrapper>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

export default PageHeader;
