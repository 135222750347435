import React, { useEffect, useState } from 'react';
import { requestApi } from 'src/mocks/request';
import RenameFields from './RenameFields';
import CompanyEntitySettings from './CompanyEntitySettings';
import CompanyGeneralSettings from './CompanyGeneralSettings';
import useRefMounted from 'src/hooks/useRefMounted';
import PopulateField from './PopulateField';
import { useSelector } from 'react-redux';
import DesignSetting from './DesignSetting';
import { getFieldType, transformSchema } from 'src/services/commonService';
import FieldSelector from './FieldSelector';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import ActionSetting from './ActionSetting';
import ViewTemplateSettings from './ViewTemplateSettings';

const SettingDynamicField = ({
  setting_type,
  endPoint,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue
}) => {
  const isMountedRef = useRefMounted();
  const [fieldNames, setFieldNames] = useState([]);
  const [schema, setSchema] = useState(null);
  const { userTypes: userTypesObj, platform: platformTypesObj } =
    useSelector(selectAppConfigData);
  const userTypes = Object.entries(userTypesObj);
  const platformTypes = Object.entries(platformTypesObj);
  const getFieldsWithObjectIDs = (schema, key) => {
    const {
      isObjectID,
      isArrayWithObjectID,
      isArrayWithMultipleProperties,
      isObject
    } = getFieldType(key, schema);

    if (isObjectID || isArrayWithObjectID) {
      return [key];
    }

    if (isObject || isArrayWithMultipleProperties) {
      const nestedSchema = isObject
        ? schema[key]?.schema
        : schema[key]?.$embeddedSchemaType?.schema?.paths;
      const matchingFields = [];

      for (const pathKey in nestedSchema) {
        if (pathKey !== '_id') {
          const {
            isObjectID: isNestedObjectID,
            isArrayWithObjectID: isNestedArrayWithObjectID
          } = getFieldType(pathKey, nestedSchema);

          if (isNestedObjectID || isNestedArrayWithObjectID) {
            matchingFields.push(`${key}.${pathKey}`);
          }
        }
      }

      return matchingFields;
    }

    return [];
  };
  useEffect(() => {
    if (endPoint !== 'COMPANY') {
      async function fetchSchema() {
        try {
          const response = await requestApi.getSchema({ endPoint });

          if (isMountedRef.current) {
            if (response?.statusText === 'OK') {
              const transformedSchema = transformSchema(response?.data);
              setSchema(transformedSchema);
              const keys =
                setting_type === 'populate_fields'
                  ? Object.keys(transformedSchema).flatMap((key) =>
                      getFieldsWithObjectIDs(transformedSchema, key)
                    )
                  : Object.keys(transformedSchema);
              setFieldNames(keys);
            } else {
              console.log(response?.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
      fetchSchema();
    }
  }, [endPoint]);

  useEffect(() => {
    if (setting_type === 'rename_fields') {
      if (values.data.length === 0) {
        setFieldValue('data', [...values.data, { old_name: '', new_name: '' }]);
      }
    }
    if (setting_type === 'populate_fields') {
      if (values.data.length === 0) {
        setFieldValue('data', [
          ...values.data,
          { populate_field: '', field_show: '' }
        ]);
      }
    }
    if (setting_type === 'company_entity_settings') {
      if (values.data.length === 0) {
        const allUserValues = userTypes.map(
          ([propertyName, propertyValue]) => propertyValue
        );
        const allPlatformValues = platformTypes.map(
          ([propertyName, propertyValue]) => propertyValue
        );
        setFieldValue('data', [
          ...values.data,
          {
            entity_name: '',
            // show: false,
            new_name: '',
            icon: '',
            allowed_user_types: allUserValues,
            allowed_platform_types: allPlatformValues
          }
        ]);
      }
    }
    if (setting_type === 'company_general_settings') {
      function isObjectBlank(obj) {
        return obj && Object.keys(obj).every((key) => !obj[key]);
      }
      const blankObject = {
        companyName: '',
        companyLogo: '',
        layouts: '',
        theme: '',
        palette: {
          primaryColor: '#FF0000',
          secondaryColor: '#0000ff',
          reset: 'false'
        },
        fontFamily: ''
      };

      if (isObjectBlank(values.companyGeneralSettings)) {
        setFieldValue('companyGeneralSettings', blankObject);
      }
    }
    if (setting_type === 'view_template_settings') {
      if (values.data.length === 0) {
        setFieldValue('data', [...values.data, { default: '' }]);
      }
    }
  }, [setting_type]);
  return (
    <>
      {(setting_type === 'show_fields' ||
        setting_type === 'search_fields' ||
        setting_type === 'edit_fields' ||
        setting_type === 'create_fields') && (
        <FieldSelector
          schema={schema}
          fieldNames={fieldNames}
          setting_type={setting_type}
        />
      )}
      {setting_type === 'populate_fields' && schema && (
        <PopulateField schema={schema} fieldNames={fieldNames} />
      )}
      {setting_type === 'actions' && schema && (
        <>
          <ActionSetting schema={schema} />
          {/* <ActionsFields
          schema={schema}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
        /> */}
        </>
      )}
      {setting_type === 'rename_fields' && (
        <RenameFields
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          fieldNames={fieldNames}
        />
      )}
      {setting_type === 'design_settings' && schema && (
        <DesignSetting
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          fieldNames={fieldNames}
          schema={schema}
        />
      )}

      {setting_type === 'company_entity_settings' && (
        <CompanyEntitySettings
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}

      {setting_type === 'company_general_settings' && (
        <CompanyGeneralSettings
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}

      {setting_type === 'view_template_settings' && (
        <ViewTemplateSettings
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  );
};

export default SettingDynamicField;
