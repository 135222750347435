import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import {
  Box,
  Card as MuiCard,
  Chip as MuiChip,
  CardContent,
  Container as MuiContainer,
  Paper as MuiPaper,
  Typography,
  styled,
  CardMedia,
  CardHeader,
  ImageList as MuiImageList,
  ImageListItem,
  Avatar,
  Alert,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Divider,
  Stack,
  useTheme
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { ExpandMore } from '@mui/icons-material';

import randomId from 'src/utils/randomId';
import ViewAction from '../ViewAction';

const PaperRoot = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '1.5rem'
  }
}));

const componentMap = {
  Container: (props) => (
    <MuiContainer {...props} disableGutters>
      {props?.children}
    </MuiContainer>
  ),
  Text: (props) => {
    const { value, layout } = props;
    console.log('layout', layout);
    return (
      <Typography
        {...props}
        textAlign={layout?.horizontalAlign}
        alignItems={layout?.verticalAlign}
      >
        {value}
      </Typography>
    );
  },
  Spacer: (props) => (
    <Box
      {...props}
      sx={{
        height: props?.layout?.height ?? '100%',
        minHeight: '60px',
        ...props?.sx
      }}
    />
  ),
  PageRow: (props) => {
    const theme = useTheme();

    // Extract children from props
    const { children } = props;

    const gridTemplateColumns = React.Children.map(children, (child) => {
      const columnSize = child?.props?.layout?.columnSize || 1;
      return `minmax(0, ${columnSize}fr)`;
    }).join(' ');

    return (
      <Box
        sx={{
          width: '100%',
          gap: '.5rem',
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns,
          [theme.breakpoints.down('sm')]: {
            gridAutoFlow: 'row',
            gridTemplateColumns: '1fr',
            gap: '1rem'
          }
        }}
        {...props}
      >
        {props?.children}
      </Box>
    );
  },
  PageColumn: (props) => {
    const theme = useTheme();
    return (
      <Box
        sx={{
          gap: props?.gap,
          display: 'grid',
          gridAutoFlow: 'row',
          gridAutoRows: 'fit-content',
          width: '100%',
          gridAutoColumns: '100%',
          [theme.breakpoints.down('sm')]: {
            // if screen is small, then align items to left including all the nested children
            alignItems: 'flex-start !important',
            justifyContent: 'flex-start !important',
            textAlign: 'left !important',
            '-webkit-justify-content': 'flex-start !important',
            '-webkit-align-items': 'flex-start !important'
          }
        }}
        {...props}
      >
        {props?.children}
      </Box>
    );
  },
  DataGrid: (props) => (
    <DataGrid
      {...props}
      getRowHeight={() => 'auto'}
      autoHeight
      autoPageSize
      getRowId={(row) => randomId()}
      // sx={{
      //   '& .MuiDataGrid-cell': {}
      // }}
    />
  ),
  Paper: (props) => <PaperRoot {...props}>{props?.children}</PaperRoot>,
  Image: (props) => <Box component="img" {...props} />,
  'codeComponent.Avatar': (props) => {
    const { src, alt, variant, sx, children } = props;
    return (
      <Avatar
        src={src || ''}
        alt={alt}
        variant={variant}
        sx={{ ...sx }}
        {...props}
      >
        {children}
      </Avatar>
    );
  },
  'codeComponent.Alert': (props) => {
    const { severity, title, message, icon, sx } = props;
    return (
      <Alert
        {...(!icon && { icon: false })}
        severity={severity}
        sx={{ width: '100%', ...sx }}
        {...props}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    );
  },
  'codeComponent.Accordion': (props) => {
    const { title, content, expanded, titleSx, contentSx, sx } = props;
    // todo: fix this (useState cannot be used in here)
    // const [isExpanded, setIsExpanded] = useState(expanded);

    // const handleChange = () => {
    //   setIsExpanded(!isExpanded);
    // };
    return (
      <Accordion
        expanded={expanded}
        // onChange={handleChange}
        sx={{ width: '100%', ...sx }}
        slotProps={{ transition: { timeout: 400 } }}
        {...props}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography sx={titleSx}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={contentSx}>{content}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  },
  'codeComponent.Badge': (props) => {
    const {
      badgeContent,
      color,
      variant,
      overlap,
      anchorOriginVertical,
      anchorOriginHorizontal,
      showZero,
      sx,
      children
    } = props;
    return (
      <Badge
        badgeContent={badgeContent}
        color={color}
        variant={variant}
        overlap={overlap}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal
        }}
        showZero={showZero}
        sx={{ ...sx }}
        {...props}
      >
        {children}
      </Badge>
    );
  },
  'codeComponent.Card': (props) => {
    const {
      children,
      sx,
      variant,
      title,
      subheader,
      avatarSrc,
      mediaUrl,
      mediaHeight,
      mediaAlt,
      titleVariant,
      titleSx,
      subheaderVariant,
      subheaderSx
    } = props;
    return (
      <MuiCard sx={sx} {...props} variant={variant}>
        {(title || subheader || avatarSrc) && (
          <CardHeader
            avatar={avatarSrc && <Avatar src={avatarSrc}></Avatar>}
            title={
              <Typography variant={titleVariant} sx={titleSx}>
                {title}
              </Typography>
            }
            subheader={
              <Typography variant={subheaderVariant} sx={subheaderSx}>
                {subheader}
              </Typography>
            }
          />
        )}
        {mediaUrl && (
          <CardMedia
            component="img"
            height={mediaHeight}
            image={mediaUrl}
            alt={mediaAlt}
          />
        )}
        <CardContent>{children}</CardContent>
      </MuiCard>
    );
  },
  'codeComponent.Chip': (props) => {
    const { label, variant, color, size, avatarSrc, sx } = props;
    return (
      <MuiChip
        label={label}
        variant={variant}
        color={color}
        size={size}
        avatar={avatarSrc ? <Avatar src={avatarSrc} /> : undefined}
        sx={sx}
        {...props}
      />
    );
  },
  'codeComponent.ColorPreview': (props) => {
    const { color, sx } = props;
    return (
      <Box
        sx={{
          flex: 1,
          background: color,
          width: 30,
          height: 30,
          ...sx
        }}
      ></Box>
    );
  },
  'codeComponent.Divider': (props) => {
    const { orientation, variant, sx } = props;
    return (
      <Divider
        orientation={orientation}
        variant={variant}
        sx={{
          flexGrow: 1,
          ...sx
        }}
        {...props}
      />
    );
  },
  'codeComponent.ImageList': (props) => {
    const { images, cols, gap, variant, sx } = props;
    return (
      <MuiImageList cols={cols} gap={gap} variant={variant} sx={sx} {...props}>
        {images?.map((image, index) => (
          <ImageListItem key={index}>
            <img src={image?.img} alt={image?.title} loading="lazy" />
          </ImageListItem>
        ))}
      </MuiImageList>
    );
  },
  'codeComponent.QRCodeCustom': (props) => {
    const { level, size, style, containerStyle, ...rest } = props;
    return (
      <Box sx={{ ...containerStyle }}>
        <QRCode
          size={size}
          ecLevel={level}
          logoWidth={size * 0.2}
          logoHeight={size * 0.2}
          {...rest}
          style={{ height: 'auto', maxWidth: '100%', width: '100%', ...style }}
        />
      </Box>
    );
  }
};

const safeEval = (expression, context) => {
  try {
    // Ensure the expression is wrapped in a function to execute it safely
    const func = new Function(
      'context',
      `with(context) { return ${expression}; }`
    );

    return func(context);
  } catch (e) {
    console.error('Error evaluating expression:', e);
    return '';
  }
};

const processProps = (props, context) => {
  const processedProps = { ...props };
  for (const key in processedProps) {
    if (
      processedProps[key] &&
      typeof processedProps[key] === 'object' &&
      processedProps[key]?.$$jsExpression
    ) {
      processedProps[key] = safeEval(
        processedProps[key].$$jsExpression,
        context
      );
    }
  }
  return processedProps;
};

const processStyles = (layout) => {
  const styles = {};
  if (layout) {
    if (layout.horizontalAlign) {
      styles.textAlign = layout.horizontalAlign;
    }
    if (layout.verticalAlign) {
      styles.verticalAlign = layout.verticalAlign;
    }
    if (layout.columnSize) {
      styles.flex = layout.columnSize;
    }
  }
  return styles;
};

const renderComponent = (theme, componentConfig, index, context) => {
  const { component, props, children, layout } = componentConfig;

  const Component = componentMap[component];

  if (!Component) {
    return null;
  }

  const processedProps = processProps(props, context);
  const styles = processStyles(layout);
  console.log('styles', styles);

  const childElements = children
    ? children.map((child, idx) => renderComponent(theme, child, idx, context))
    : null;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: layout?.verticalAlign,
        justifyContent: layout?.horizontalAlign,
        [theme.breakpoints.down('sm')]: {
          // if screen is small, then align items to left including all the nested children as well
          alignItems: 'flex-start !important',
          justifyContent: 'flex-start !important',
          textAlign: 'left !important',
          '-webkit-justify-content': 'flex-start !important',
          '-webkit-align-items': 'flex-start !important'
        },
        '&:empty': {
          display: 'none'
        }
      }}
    >
      <Component key={index} {...processedProps}>
        {childElements}
      </Component>
    </Box>
  );
};

const TemplateViewPage = ({ getData, template }) => {
  const theme = useTheme();
  const { schema } = useSelector(selectDynamicPageConfig);
  const componentRef = useRef(null);
  const containerMaxWidth = template?.content?.spec?.maxWidth;

  console.log('template', template);

  return (
    <>
      {/* <MuiContainer maxWidth={template?.content?.spec?.maxWidth}>
        <ViewAction componentRef={componentRef} />
        <Box ref={componentRef} mt={0.5}>
          {template?.content?.spec?.content?.map((content, index) =>
            renderComponent(theme, content, index, {
              getschema: schema,
              getData: getData
            })
          )}
        </Box>
      </MuiContainer> */}
      <MuiContainer
        maxWidth={containerMaxWidth}
        sx={{
          [theme.breakpoints.down('sm')]: {
            padding: '0'
          }
        }}
      >
        <ViewAction componentRef={componentRef} />
        <Stack
          direction="column"
          sx={{ my: 2, gap: 1 }}
          ref={componentRef}
          // {...props}
        >
          {template?.content?.spec?.content?.map((content, index) =>
            renderComponent(theme, content, index, {
              getschema: schema,
              getData: getData
            })
          )}
        </Stack>
      </MuiContainer>
    </>
  );
};

export default TemplateViewPage;
