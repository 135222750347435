import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography
} from '@mui/material';

const OverviewCard = ({ currentItem }) => {
  const { warehouse, scope, split_by } = currentItem;

  return (
    <Card variant="outlined">
      <CardHeader title="Audit Overview" />
      <Divider />
      <CardContent>
        <Stack
          direction={'row'}
          flexWrap="wrap"
          justifyContent={'space-between'}
          gap={4}
        >
          <Stack>
            <Typography variant="subtitle1">Warehouse</Typography>
            <Typography variant="body1">{warehouse?.name}</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1">Audit Coverage</Typography>
            <Typography variant="body1">{scope?.name}</Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1">Audit Split By</Typography>
            <Typography variant="body1">{split_by}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OverviewCard;
