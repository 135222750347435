import { AddRounded, CloseRounded } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  useTheme
} from '@mui/material';
import Typography from '@mui/material/Typography';
import PhoneTextField from '../PhoneTextField';

const StringArrayField = ({ fieldName, value, name, setFieldValue }) => {
  const theme = useTheme();

  console.log('value ', value);

  const handleAddItem = () => {
    const updatedValues = [...value, null];
    setFieldValue(name, updatedValues);
  };

  const handleDeleteItem = (delIndex) => {
    const updatedValues = value.filter((_, index) => index !== delIndex);
    setFieldValue(name, updatedValues);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ pb: 0 }}>
          <CardHeader
            sx={{ background: theme.colors.alpha.black[5] }}
            title={
              <Typography variant="h5" textTransform={'capitalize'}>
                {fieldName}
              </Typography>
            }
            action={
              <IconButton size="small" variant="text" onClick={handleAddItem}>
                <AddRounded />
              </IconButton>
            }
          />
          <Divider />
          <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
            <Stack spacing={1} p={1}>
              {value?.map((item, index) => (
                <Stack key={`${item}_${index}`} direction="row" spacing={1}>
                  <PhoneTextField
                    value={item}
                    name={index}
                    fieldValue={value}
                    fieldName={fieldName}
                    setFieldValue={setFieldValue}
                    isArray={true}
                  />
                  <IconButton
                    size="small"
                    variant="text"
                    color="error"
                    onClick={() => handleDeleteItem(index)}
                  >
                    <CloseRounded />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default StringArrayField;
