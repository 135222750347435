import * as Yup from 'yup';

const getValidationSchema = (schema, item) => {
  switch (schema[item]?.instance) {
    case 'String':
      return schema[item]?.options?.required
        ? Yup.string().required(`${item} is required`)
        : Yup.string();

    case 'Number':
      return schema[item]?.options?.required
        ? Yup.number().required(`${item} is required`)
        : Yup.number();

    case 'Boolean':
      return schema[item]?.options?.required
        ? Yup.boolean().required(`${item} is required`)
        : Yup.boolean();

    case 'Date':
      return schema[item]?.options?.required
        ? Yup.date().required(`${item} is required`)
        : Yup.date().nullable(true);

    case 'ObjectID':
      return schema[item]?.options?.required
        ? Yup.object({
            id: Yup.string().required(`${item} is required`)
          })
        : Yup.object({
            id: Yup.string()
          }).nullable();
    case 'Array':
      const embeddedSchemaType = schema[item]?.$embeddedSchemaType;
      if (embeddedSchemaType.instance === 'ObjectID') {
        embeddedSchemaType.options?.required
          ? Yup.array().of(Yup.string().required(`${item} is required`))
          : Yup.array();
      } else {
        return schema[item]?.$embeddedSchemaType?.options?.required
          ? Yup.array().min(1, `${item} must have at least one element`)
          : Yup.array();
      }

    default:
      return Yup.mixed();
  }
};

export const initDynamicFormValues = ({
  schema,
  fieldNames,
  currentItem,
  inputValue = null,
  fieldShow = null
}) => {
  // Return null if schema or fieldNames are missing
  if (!schema || !fieldNames) {
    return { initialValues: null, validationSchema: null };
  }

  const { initialValues, validationSchema } = fieldNames.reduce(
    (accumulate, item) => {
      const propertyValue = Boolean(
        currentItem && currentItem[item] !== undefined
      );

      switch (schema[item]?.instance) {
        case 'String':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : schema[item]?.enumValues.length > 0
            ? schema[item]?.options?.default || ''
            : inputValue && fieldShow && item === fieldShow
            ? inputValue
            : '';
          accumulate.validationSchema[item] = getValidationSchema(schema, item);
          break;

        case 'Boolean':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : schema[item]?.options?.default !== undefined
            ? schema[item]?.options?.default
            : '';
          accumulate.validationSchema[item] = getValidationSchema(schema, item);
          break;

        case 'Date':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : schema[item]?.options?.default !== undefined
            ? new Date(schema[item].options.default)
            : new Date();
          accumulate.validationSchema[item] = getValidationSchema(schema, item);
          break;

        case 'Mixed':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : '';
          break;

        case 'ObjectID':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : '';
          accumulate.validationSchema[item] = getValidationSchema(schema, item);
          break;

        case 'Number':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : schema[item]?.options?.default !== undefined
            ? schema[item]?.options?.default
            : '';
          accumulate.validationSchema[item] = getValidationSchema(schema, item);
          break;

        case 'Array':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : [];
          const embeddedSchemaType = schema[item]?.$embeddedSchemaType;

          // Handle ObjectID embedded schema type
          if (embeddedSchemaType.instance === 'ObjectID') {
            accumulate.validationSchema[item] = embeddedSchemaType.options
              ?.required
              ? Yup.array().of(Yup.string().required(`${item} is required`))
              : Yup.array();
          }
          // Handle nested schemas with paths
          else if (
            embeddedSchemaType.schema?.paths &&
            Object.keys(embeddedSchemaType.schema.paths).length > 0
          ) {
            const paths = embeddedSchemaType.schema.paths;
            const nestedValidationSchema = Object.keys(paths).reduce(
              (nestedAccumulate, key) => {
                nestedAccumulate[key] = getValidationSchema(paths, key);
                return nestedAccumulate;
              },
              {}
            );

            accumulate.validationSchema[item] = Yup.array().of(
              Yup.object().shape(nestedValidationSchema)
            );
          } else {
            accumulate.validationSchema[item] = embeddedSchemaType?.options
              ?.required
              ? Yup.array().min(1, `${item} must have at least one element`)
              : Yup.array();
          }
          break;

        case 'Object':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : {};
          const nestedSchema = schema[item]?.schema;
          const nestedValidationSchema = Object.keys(nestedSchema).reduce(
            (nestedAccumulate, key) => {
              nestedAccumulate[key] = getValidationSchema(nestedSchema, key);
              return nestedAccumulate;
            },
            {}
          );
          accumulate.validationSchema[item] = Yup.object().shape(
            nestedValidationSchema
          );
          break;

        case 'Embedded':
          accumulate.initialValues[item] = propertyValue
            ? currentItem[item]
            : {};
          const embeddedSchema = schema[item]?.schema?.paths;
          // const embeddedValidationSchema = Object.keys(embeddedSchema).reduce(
          //   (embeddedAccumulate, key) => {
          //     embeddedAccumulate[key] = getValidationSchema(
          //       embeddedSchema,
          //       key
          //     );
          //     return embeddedAccumulate;
          //   },
          //   {}
          // );
          // accumulate.validationSchema[item] = Yup.object().shape(
          //   embeddedValidationSchema
          // );
          break;

        default:
          break;
      }

      return accumulate;
    },
    { initialValues: {}, validationSchema: {} }
  );

  return {
    initialValues,
    validationSchema: Yup.object().shape(validationSchema)
  };
};

// export const initDynamicFormValues = ({
//   schema,
//   fieldNames,
//   currentItem,
//   inputValue = null,
//   fieldShow = null
// }) => {
//   if (!schema || !fieldNames) {
//     return { initialValues: null, validationSchema: null };
//   }
//   const { initialValues, validationSchema } = fieldNames.reduce(
//     (accumulate, item) => {
//       let { initialValues, validationSchema } = accumulate;
//       const propertyValue = Boolean(
//         currentItem && currentItem[item] !== undefined
//       );

//       switch (schema[item]?.instance) {
//         case 'String':
//           initialValues[item] = propertyValue
//             ? currentItem[item]
//             : schema[item]?.enumValues.length > 0
//             ? schema[item]?.options?.default || ''
//             : inputValue && fieldShow && item === fieldShow
//             ? inputValue
//             : '';
//           validationSchema[item] = getValidationSchema(schema, item);
//           break;
//         case 'Boolean':
//           initialValues[item] = propertyValue
//             ? currentItem[item]
//             : schema[item]?.options?.default !== undefined
//             ? schema[item]?.options?.default
//             : '';
//           validationSchema[item] = getValidationSchema(schema, item);
//           break;
//         case 'Date':
//           initialValues[item] = propertyValue
//             ? currentItem[item]
//             : schema[item]?.options?.default !== undefined
//             ? new Date(schema[item].options.default)
//             : new Date();
//           validationSchema[item] = getValidationSchema(schema, item);
//           break;

//         case 'Mixed':
//           initialValues[item] = propertyValue ? currentItem[item] : '';
//           break;

//         case 'ObjectID':
//           initialValues[item] = propertyValue ? currentItem[item] : '';
//           validationSchema[item] = getValidationSchema(schema, item);

//           break;
//         case 'Number':
//           initialValues[item] = propertyValue
//             ? currentItem[item]
//             : schema[item]?.options?.default !== undefined
//             ? schema[item]?.options?.default
//             : '';
//           validationSchema[item] = getValidationSchema(schema, item);
//           break;
//         case 'Array':
//           initialValues[item] = propertyValue ? currentItem[item] : [];
//           const embeddedSchemaType = schema[item]?.$embeddedSchemaType;

//           // Handle ObjectID embedded schema type
//           if (embeddedSchemaType.instance === 'ObjectID') {
//             validationSchema[item] = embeddedSchemaType.options?.required
//               ? Yup.array().of(Yup.string().required(`${item} is required`))
//               : Yup.array();
//           }
//           // Handle nested schemas with paths
//           else if (
//             embeddedSchemaType.schema?.paths &&
//             Object.keys(embeddedSchemaType.schema.paths).length > 0
//           ) {
//             const paths = embeddedSchemaType.schema.paths;
//             const nestedValidationSchema = Object.keys(paths).reduce(
//               (accumulate, key) => {
//                 accumulate[key] = getValidationSchema(paths, key);
//                 return accumulate;
//               },
//               {}
//             );

//             validationSchema[item] = Yup.array().of(
//               Yup.object().shape(nestedValidationSchema)
//             );
//           } else {
//             validationSchema[item] = embeddedSchemaType?.options?.required
//               ? Yup.array().min(1, `${item} must have at least one element`)
//               : Yup.array();
//           }
//           break;
//         case 'Object':
//           initialValues[item] = propertyValue ? currentItem[item] : {};
//           const nestedschema = schema[item]?.schema;
//           const nestedValidationSchema = Object.keys(nestedschema).reduce(
//             (accumulate, key) => {
//               accumulate[key] = getValidationSchema(nestedschema, key);
//               return accumulate;
//             },
//             {}
//           );
//           validationSchema[item] = Yup.object().shape(nestedValidationSchema);
//           break;
//         case 'Embedded':
//           initialValues[item] = propertyValue ? currentItem[item] : {};
//           const embeddedSchma = schema[item]?.schema?.paths;
//           const embeddedValidationSchema = Object.keys(embeddedSchma).reduce(
//             (accumulate, key) => {
//               accumulate[key] = getValidationSchema(nestedschema, key);
//               return accumulate;
//             },
//             {}
//           );
//           validationSchema[item] = Yup.object().shape(embeddedValidationSchema);
//           break;

//         default:
//           break;
//       }
//     },
//     { initialValues: {}, validationSchema: {} }
//   );
//   // fieldNames.forEach((item) => {
//   //   const propertyValue = Boolean(
//   //     currentItem && currentItem[item] !== undefined
//   //   );

//   //   switch (schema[item]?.instance) {
//   //     case 'String':
//   //       initialValues[item] = propertyValue
//   //         ? currentItem[item]
//   //         : schema[item]?.enumValues.length > 0
//   //         ? schema[item]?.options?.default || ''
//   //         : inputValue && fieldShow && item === fieldShow
//   //         ? inputValue
//   //         : '';
//   //       validationSchema[item] = getValidationSchema(schema, item);
//   //       break;
//   //     case 'Boolean':
//   //       initialValues[item] = propertyValue
//   //         ? currentItem[item]
//   //         : schema[item]?.options?.default !== undefined
//   //         ? schema[item]?.options?.default
//   //         : '';
//   //       validationSchema[item] = getValidationSchema(schema, item);
//   //       break;
//   //     case 'Date':
//   //       initialValues[item] = propertyValue
//   //         ? currentItem[item]
//   //         : schema[item]?.options?.default !== undefined
//   //         ? new Date(schema[item].options.default)
//   //         : new Date();
//   //       validationSchema[item] = getValidationSchema(schema, item);
//   //       break;

//   //     case 'Mixed':
//   //       initialValues[item] = propertyValue ? currentItem[item] : '';
//   //       break;

//   //     case 'ObjectID':
//   //       initialValues[item] = propertyValue ? currentItem[item] : '';
//   //       validationSchema[item] = getValidationSchema(schema, item);

//   //       break;
//   //     case 'Number':
//   //       initialValues[item] = propertyValue
//   //         ? currentItem[item]
//   //         : schema[item]?.options?.default !== undefined
//   //         ? schema[item]?.options?.default
//   //         : '';
//   //       validationSchema[item] = getValidationSchema(schema, item);
//   //       break;
//   //     case 'Array':
//   //       initialValues[item] = propertyValue ? currentItem[item] : [];
//   //       const embeddedSchemaType = schema[item]?.$embeddedSchemaType;

//   //       // Handle ObjectID embedded schema type
//   //       if (embeddedSchemaType.instance === 'ObjectID') {
//   //         validationSchema[item] = embeddedSchemaType.options?.required
//   //           ? Yup.array().of(Yup.string().required(`${item} is required`))
//   //           : Yup.array();
//   //       }
//   //       // Handle nested schemas with paths
//   //       else if (
//   //         embeddedSchemaType.schema?.paths &&
//   //         Object.keys(embeddedSchemaType.schema.paths).length > 0
//   //       ) {
//   //         const paths = embeddedSchemaType.schema.paths;
//   //         const nestedValidationSchema = Object.keys(paths).reduce(
//   //           (accumulate, key) => {
//   //             accumulate[key] = getValidationSchema(paths, key);
//   //             return accumulate;
//   //           },
//   //           {}
//   //         );

//   //         validationSchema[item] = Yup.array().of(
//   //           Yup.object().shape(nestedValidationSchema)
//   //         );
//   //       } else {
//   //         validationSchema[item] = embeddedSchemaType?.options?.required
//   //           ? Yup.array().min(1, `${item} must have at least one element`)
//   //           : Yup.array();
//   //       }
//   //       break;
//   //     case 'Object':
//   //       initialValues[item] = propertyValue ? currentItem[item] : {};
//   //       const nestedschema = schema[item]?.schema;
//   //       const nestedValidationSchema = Object.keys(nestedschema).reduce(
//   //         (accumulate, key) => {
//   //           accumulate[key] = getValidationSchema(nestedschema, key);
//   //           return accumulate;
//   //         },
//   //         {}
//   //       );
//   //       validationSchema[item] = Yup.object().shape(nestedValidationSchema);
//   //       break;
//   //     case 'Embedded':
//   //       initialValues[item] = propertyValue ? currentItem[item] : {};
//   //       const embeddedSchma = schema[item]?.schema?.paths;
//   //       const embeddedValidationSchema = Object.keys(embeddedSchma).reduce(
//   //         (accumulate, key) => {
//   //           accumulate[key] = getValidationSchema(nestedschema, key);
//   //           return accumulate;
//   //         },
//   //         {}
//   //       );
//   //       validationSchema[item] = Yup.object().shape(embeddedValidationSchema);
//   //       break;

//   //     default:
//   //       break;
//   //   }
//   // });

//   return {
//     initialValues,
//     validationSchema: Yup.object().shape(validationSchema)
//   };
// };

// import moment from 'moment';
// import { getFieldType } from 'src/services/commonService';
// import * as Yup from 'yup';
// export const initDynamicFormValues = ({
//   schema,
//   fieldNames,
//   currentItem,
//   inputValue = null,
//   fieldShow = null
// }) => {
//   if (!schema || !fieldNames) {
//     return { initialValues: null, validationSchema: null };
//   }

//   const initialValues = {};
//   const validationSchema = {};
//   fieldNames.forEach((item) => {
//     const propertyValue = Boolean(
//       currentItem && currentItem[item] !== undefined
//     );

//     switch (schema[item]?.instance) {
//       case 'String':
//         initialValues[item] = propertyValue
//           ? currentItem[item]
//           : schema[item]?.enumValues.length > 0
//           ? schema[item]?.options?.default || ''
//           : inputValue && fieldShow && item === fieldShow
//           ? inputValue
//           : '';
//         validationSchema[item] = schema[item]?.options?.required
//           ? Yup.string().required(`${item} is required`)
//           : Yup.string();

//         break;

//       case 'Array':
//         initialValues[item] = propertyValue ? currentItem[item] : [];
//         const isArray = schema[item]?.instance === 'Array';
//         const isArrayWithObjectID =
//           isArray && schema[item]?.$embeddedSchemaType?.instance === 'ObjectID';
//         const isArrayWithMultipleProperties =
//           !isArrayWithObjectID &&
//           schema[item]?.$embeddedSchemaType?.schema &&
//           Object.keys(schema[item]?.$embeddedSchemaType?.schema?.paths)
//             ?.length > 0;

//         if (isArrayWithObjectID) {
//           const requireArrayOption =
//             schema[item]?.$embeddedSchemaType?.options?.require;
//           validationSchema[item] = requireArrayOption
//             ? Yup.array().of(Yup.string().required(`${item} is required`))
//             : Yup.array();
//         } else if (isArrayWithMultipleProperties) {
//           const paths = schema[item]?.$embeddedSchemaType?.schema?.paths;
//           // console.log(paths, 'paths');
//           const validationRules = {};

//           // Iterate over each property and construct validation rules
//           Object.keys(paths).forEach((path) => {
//             const instance = paths[path]?.instance;
//             const requireOption = paths[path]?.options?.required;
//             const isArrayWithObjectID =
//               instance === 'Array' &&
//               paths[path]?.$embeddedSchemaType?.instance === 'ObjectID';
//             const requireArrayOption =
//               isArrayWithObjectID &&
//               paths[path]?.$embeddedSchemaType?.options?.required;

//             if (isArrayWithObjectID && requireArrayOption) {
//               validationRules[path] = Yup.array().of(
//                 Yup.string().required(`${path} is required`)
//               );
//             } else if (requireOption) {
//               switch (instance) {
//                 case 'String':
//                   validationRules[path] = Yup.string().required(
//                     `${path} is required`
//                   );
//                   break;
//                 case 'Number':
//                   validationRules[path] = Yup.number().required(
//                     `${path} is required`
//                   );
//                   break;
//                 case 'Date':
//                   validationRules[path] = Yup.date().required(
//                     `${path} is required`
//                   );
//                   break;
//                 case 'ObjectID':
//                   validationRules[path] = Yup.object({
//                     id: Yup.string().required(`${path} is required`)
//                   });
//                   break;
//                 default:
//                   console.log('Unhandled instance type:', instance);
//               }
//             }
//           });

//           // Create a Yup object schema with the constructed validation rules for each property
//           validationSchema[item] = Yup.array().of(
//             Yup.object().shape(validationRules)
//           );
//         } else {
//           validationSchema[item] = schema[item]?.$embeddedSchemaType?.options
//             ?.required
//             ? Yup.array().min(1, `${item} must have at least one element`)
//             : Yup.array();
//         }

//         break;
//       case 'Object':
//         const nestedFields = schema[item]?.schema;
//         const nestedInitialValues = {};
//         const nestedValidationSchema = {};

//         Object.keys(nestedFields).forEach((nestedField) => {
//           const { isArray } = getFieldType(nestedField, nestedFields);

//           if (propertyValue) {
//             // Check if the field value is present
//             if (currentItem?.[item]?.[nestedField]) {
//               nestedInitialValues[nestedField] = currentItem[item][nestedField];
//             } else {
//               // If the field value is not present and it is an array, set it to an empty array
//               nestedInitialValues[nestedField] = isArray ? [] : '';
//             }

//             // nestedValidationSchema[nestedField] = nestedFields[nestedField]
//             //   ?.options?.required
//             //   ? Yup.string().required(`${nestedField} is required`)
//             //   : Yup.string();
//           } else {
//             // If propertyValue is not true, set initial values to blank strings or empty arrays
//             nestedInitialValues[nestedField] = isArray ? [] : '';

//             // nestedValidationSchema[nestedField] = nestedFields[nestedField]
//             //   ?.options?.required
//             //   ? Yup.string().required(`${nestedField} is required`)
//             //   : Yup.string();
//           }
//         });

//         initialValues[item] = nestedInitialValues;
//         validationSchema[item] = Yup.object().shape(nestedValidationSchema);
//         break;

//       case 'Boolean':
//         initialValues[item] = propertyValue
//           ? currentItem[item]
//           : schema[item]?.options?.default !== undefined
//           ? schema[item]?.options?.default
//           : '';
//         validationSchema[item] = schema[item]?.options?.required
//           ? Yup.boolean().required(`${item} is required`)
//           : Yup.boolean();
//         break;
//       case 'Date':
//         initialValues[item] = propertyValue
//           ? currentItem[item]
//           : schema[item]?.options?.default !== undefined
//           ? new Date(schema[item].options.default)
//           : new Date();
//         validationSchema[item] = schema[item]?.options?.required
//           ? Yup.date().required(`${item} is required`)
//           : Yup.date().nullable(true);
//         break;

//       case 'Mixed':
//         initialValues[item] = propertyValue ? currentItem[item] : '';
//         break;

//       case 'ObjectID':
//         initialValues[item] = propertyValue ? currentItem[item] : '';
//         // validationSchema[item] = schema[item]?.options?.required
//         //   ? Yup.object({
//         //       id: Yup.string().required(`${item} is required`)
//         //     })
//         //   : Yup.object({
//         //       id: Yup.string()
//         //     });
//         validationSchema[item] = schema[item]?.options?.required
//           ? Yup.object({
//               id: Yup.string().required(`${item} is required`)
//             })
//           : Yup.object({
//               id: Yup.string()
//             }).nullable();

//         break;
//       case 'Number': // Add a case for Number fields
//         initialValues[item] = propertyValue
//           ? currentItem[item]
//           : schema[item]?.options?.default !== undefined
//           ? schema[item]?.options?.default
//           : '';
//         validationSchema[item] = schema[item]?.options?.required
//           ? Yup.number().required(`${item} is required`)
//           : Yup.number();
//         break;
//       default:
//         break;
//     }
//   });

//   return {
//     initialValues,
//     validationSchema: Yup.object().shape(validationSchema)
//   };
// };
