import { useState } from 'react';

const getNestedValue = (object, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], object);
};

const useSearch = (initialItems, searchField = 'name') => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = !searchTerm
    ? initialItems
    : initialItems.filter((item) => {
        const value = getNestedValue(item, searchField);
        if (typeof value === 'string')
          return value.toLowerCase().includes(searchTerm.toLowerCase());

        if (typeof value === 'number')
          return value.toString().includes(searchTerm);

        return false;
      });

  return { searchTerm, handleSearchChange, filteredItems };
};

export default useSearch;
