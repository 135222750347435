import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate } from 'react-router-dom';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';

const RootWrapper = styled(Box)(
  () => `
            flex: 1;
        `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader({ currentItem, submitting }) {
  const navigate = useNavigate();
  const [isUpdating, setUpdating] = useState(false);
  const isMounted = useRefMounted();
  const handleStartAudit = async () => {
    setUpdating(true);
    try {
      const payload = {
        endPoint: 'stock_audit',
        id: currentItem.id,
        data: {
          status: 'in-progress'
        }
      };
      const response = await requestApi.updateData(payload);
      if (response.data) {
        navigate(`/stock_audit/perform-audit/${currentItem.id}`);
      }
    } finally {
      if (isMounted.current) setUpdating(false);
    }
  };

  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h3" component="h3" gutterBottom>
                Stock Audit & Reconcilliation
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={0.5}>
            <Button
              variant="outlined"
              size="small"
              type="submit"
              disabled={submitting}
              startIcon={
                submitting ? (
                  <CircularProgress size={'1rem'} />
                ) : (
                  <CalendarTodayOutlinedIcon size={'1rem'} />
                )
              }
            >
              Reschedule Reconciliation
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleStartAudit}
              disabled={isUpdating || submitting}
              startIcon={
                isUpdating ? (
                  <CircularProgress size={'1rem'} />
                ) : (
                  <FlagOutlinedIcon size={'1rem'} />
                )
              }
            >
              Start Audit
            </Button>
            <Tooltip title={`Go back`}>
              <IconButtonWrapper
                disabled={submitting}
                onClick={() => navigate(-1)}
              >
                <ArrowBackTwoToneIcon />
              </IconButtonWrapper>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
