import { Button, Stack, Skeleton, CircularProgress } from '@mui/material';
import { Form, Formik, FieldArray } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import useFetchData from 'src/hooks/useFetchData';
import ProductFields from './ProductFields';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';

const AddStock = ({ handleModal, location, currentItem }) => {
  const { schema } = useEntityPageConfig('audit_record');
  const [isLoading, setLoading] = useState(false);
  const isMounted = useRefMounted();
  const { data, isFetching } = useFetchData({
    endPoint: 'audit_record',
    populate: 'product location',
    query: { audit: currentItem?.id, location: location?.id }
  });
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const sanitizedData = values.products.map((product) => {
        const { createData } = sanitizeFormData(
          product,
          schema,
          Object.keys(product)
        );

        return createData;
      });

      const payload = {
        endPoint: 'audit_record',
        data: {
          data: sanitizedData.map((product) => ({
            ...product,
            audit: currentItem.id,
            warehouse: currentItem?.warehouse?.id
          }))
        }
      };
      const response = await requestApi.createBulkData(payload);
      if (response?.status) {
        handleModal();
      }
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };
  if (!schema || isFetching) {
    return <Skeleton variant="rounded" height={100} />;
  }
  return (
    <Formik initialValues={{ products: data }} onSubmit={handleSubmit}>
      {({ values: { products } }) => (
        <Form>
          <FieldArray name="products">
            {({ push, remove }) => (
              <Stack spacing={1}>
                {products.map((product, proIdx) => (
                  <ProductFields
                    key={`product_${proIdx}`}
                    product={product}
                    proIdx={proIdx}
                    currentItem={currentItem}
                    location={location}
                    remove={remove}
                  />
                ))}
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                  <Button
                    startIcon={<AddIcon fontSize="small" />}
                    size="small"
                    sx={{ alignSelf: 'flex-start' }}
                    onClick={() =>
                      push({
                        product: '',
                        actual_quantity: '',
                        system_quantity: '',
                        reason: '',
                        location: ''
                      })
                    }
                  >
                    {products.length ? 'Add More Products' : 'Add Products'}
                  </Button>
                  {products.length > 0 && (
                    <Button
                      disabled={isLoading}
                      startIcon={
                        isLoading && <CircularProgress size={'1rem'} />
                      }
                      size="small"
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </Button>
                  )}
                </Stack>
              </Stack>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

export default AddStock;
