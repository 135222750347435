import {
  Grid,
  CardHeader,
  CardContent,
  Card,
  Divider,
  Button
} from '@mui/material';
import { useFormikContext } from 'formik';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const OrderDetailsCard = ({ currentItem }) => {
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const { values, errors, touched } = useFormikContext();

  const fieldNames = [
    'based_on',
    `${values?.based_on}`,
    'start_date',
    'due_date',
    'source_warehouse',
    'target_warehouse'
  ];

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title="Order Details"
          action={
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              Save
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {fieldNames.map((fieldName) => (
              <Grid item xs={12} md={6} key={fieldName}>
                <GetFieldBasedOnSchema
                  name={fieldName}
                  fieldName={fieldName}
                  entitySetting={entitySetting}
                  schema={schema}
                  value={values[fieldName]}
                  error={errors[fieldName]}
                  touched={touched[fieldName]}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default OrderDetailsCard;
