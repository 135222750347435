import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { Form, Formik } from 'formik';
import AuditSplit from './AuditSplit';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
import { useNavigate } from 'react-router';
import StockAuditEditLoader from '../StockAuditEdit/StockAuditEditLoader';
import OverviewCard from './OverviewCard';

const PerformAudit = () => {
  const navigate = useNavigate();
  const { schema } = useEntityPageConfig('stock_audit');
  const { currentItem, isFetching } = useDyFetchDataById();
  const [submitting, setSubmitting] = useState(false);
  const isMounted = useRefMounted();
  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const { set, unset } = sanitizeFormData(
        values,
        schema,
        Object.keys(values)
      );
      const payload = {
        endPoint: 'stock_audit',
        data: {
          $set: set,
          $unset: unset
        },
        id: currentItem.id
      };
      const response = await requestApi.updateData(payload);
      if (response.data) {
        navigate('/stock_audit');
      }
    } finally {
      if (isMounted.current) setSubmitting(true);
    }
  };
  if (isFetching) return <StockAuditEditLoader />;
  return (
    <>
      <Helmet>
        <title>Stock Audit</title>
      </Helmet>

      <Formik
        initialValues={{
          split_by: currentItem.split_by || '',
          splits: currentItem.splits || []
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <PageTitleWrapper>
              <PageHeader currentItem={currentItem} submitting={submitting} />
            </PageTitleWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OverviewCard currentItem={currentItem} />
              </Grid>
              <Grid item xs={12}>
                <AuditSplit currentItem={currentItem} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PerformAudit;
