import React, { useEffect, useState, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from 'react-redux';
import { selectCustomSettingData } from 'src/slices/CustomThemeSettingSlice';

export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {
  const { mode: selectCustomMode, color: selectCustomColor } = useSelector(
    selectCustomSettingData
  );
  const curThemeName = selectCustomMode || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);

  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName); // Assuming _setThemeName is properly defined
  };
  const theme = useMemo(
    () => themeCreator(themeName, selectCustomColor),
    [themeName, selectCustomColor]
  );
  useEffect(() => {
    localStorage.setItem('appTheme', curThemeName);
    _setThemeName(curThemeName);
  }, [curThemeName, setThemeName, themeName]);

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
