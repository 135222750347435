import { requestApi } from 'src/mocks/request';
import { escapeRegexSpecialChars, getPopulateString } from './commonService';

export const getOption = async ({
  endPoint,
  refSchema,
  fieldQuery,
  defaultQuery,
  populateString,
  fieldShow,
  secondary_text,
  setLoading,
  setOptions,
  isMounted,
  value,
  query
}) => {
  try {
    setLoading(true);
    let queryObject = { ...defaultQuery, ...fieldQuery };
    let orConditions = [];

    if (refSchema[fieldShow]?.instance === 'String') {
      orConditions.push({
        [fieldShow]: { $regex: escapeRegexSpecialChars(query), $options: 'i' }
      });
    } else {
      orConditions.push({
        [fieldShow]: query
      });
    }

    if (secondary_text) {
      if (refSchema[secondary_text]?.instance === 'String') {
        orConditions.push({
          [secondary_text]: {
            $regex: escapeRegexSpecialChars(query),
            $options: 'i'
          }
        });
      } else {
        orConditions.push({
          [secondary_text]: query
        });
      }
    }

    if (orConditions.length > 0) {
      queryObject.$or = orConditions;
    }

    const response = await requestApi.getData({
      endPoint,
      query: queryObject,
      populate: populateString,
      pagination: true
    });

    if (isMounted.current) {
      if (response?.status === 'SUCCESS') {
        let newOptions = response?.data?.data || [];
        if (value && Array.isArray(value)) {
          let filterOption = newOptions.filter(
            (newOption) =>
              !value.some(
                (existingOption) => existingOption.id === newOption.id
              )
          );
          newOptions = [...filterOption, ...value];
        }
        setOptions(newOptions);
      } else {
        if (isMounted.current) {
          setOptions(value && Array.isArray(value) ? value : []);
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (isMounted.current) {
      setLoading(false);
    }
  }
};

export const getAutoCompleteConfig = ({
  fieldName,
  entitySetting,
  itemTableHead = null,
  refSchema,
  schema
}) => {
  const foundData = entitySetting?.populate_fields?.find(
    (populateFieldItem) => {
      return populateFieldItem?.populate_field?.includes('.')
        ? populateFieldItem?.populate_field === `${fieldName}.${itemTableHead}`
        : populateFieldItem?.populate_field === fieldName;
    }
  );

  //find defaulQuery
  const query = schema?.[itemTableHead || fieldName]?.options?.query || {};
  // Extract schema keys
  const schemaKeys = Object.keys(refSchema);
  const populateString = getPopulateString(refSchema, schemaKeys);

  // If foundData has field_show, return it immediately
  if (foundData?.primary_text) {
    const primaryFieldShow = [
      foundData?.primary_text?.show_field,
      foundData?.primary_text?.deep
    ]
      .filter(Boolean)
      .join('.');
    const secondaryFieldShow = [
      foundData?.secondary_text?.show_field,
      foundData?.secondary_text?.deep
    ]
      .filter(Boolean)
      .join('.');

    return {
      query,
      populateString,
      fieldShow: primaryFieldShow || 'id',
      secondary_text: secondaryFieldShow
    };
  }

  // Determine fieldShow based on schema keys
  const foundFieldShow = schemaKeys.includes('name')
    ? 'name'
    : schemaKeys.includes('title')
    ? 'title'
    : 'id';

  // Return the configuration
  return {
    query,
    fieldShow: foundFieldShow,
    populateString
  };
};
