import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createDataAsync, updateDataAsync } from 'src/slices/dynamicSlice';

const MaterialItem = ({ item, quantityMultiplier, isLastItem }) => (
  <>
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Typography variant="h5" gutterBottom>
          {item?.product?.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {item?.product?.is_purchased && 'purchased'}
          {item?.product?.is_manufactured && 'in-house'}
        </Typography>
      </Box>
      <Typography variant="subtitle1" gutterBottom>
        {item?.quantity * quantityMultiplier} {item?.uom?.name}
      </Typography>
    </Stack>
    {!isLastItem && <Divider />}
  </>
);

const MaterialSection = ({ title, materials, actionLabel, onActionClick }) => {
  const theme = useTheme();

  return (
    <>
      {materials?.length > 0 && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography>{title}</Typography>
            <Button size="small" variant="outlined" onClick={onActionClick}>
              {actionLabel}
            </Button>
          </Stack>
          <Stack
            spacing={1}
            sx={{
              background: theme.colors.alpha.black[10],
              borderRadius: theme.general.borderRadius,
              p: 2,
              mb: 2
            }}
          >
            {materials.map((item, index) => (
              <MaterialItem
                key={index}
                item={item}
                quantityMultiplier={item?.quantity}
                isLastItem={index === materials.length - 1}
              />
            ))}
          </Stack>
        </>
      )}
    </>
  );
};

const DialogStepContent = ({
  activeStep,
  currentItem,
  onRequestMaterial,
  onReplenishClick,
  handleModal
}) => {
  const theme = useTheme();
  const inHouseMaterials = currentItem?.materials?.filter(
    ({ product }) => product?.is_manufactured
  );
  const purchasedMaterials = currentItem?.materials?.filter(
    ({ product }) => product?.is_purchased
  );

  return activeStep === 0 ? (
    <Fragment>
      <DialogContent>
        <Stack
          spacing={1}
          sx={{
            background: `${theme.colors.alpha.black[10]}`,
            borderRadius: theme.general.borderRadius,
            p: 2,
            mb: 2
          }}
        >
          {currentItem?.materials?.map((item, index) => (
            <MaterialItem
              key={index}
              item={item}
              quantityMultiplier={currentItem?.quantity}
              isLastItem={index === currentItem?.materials.length - 1}
            />
          ))}
        </Stack>
        {/* // todo: remove this */}
        {/* <Typography variant="body1" gutterBottom sx={{ fontSize: 13 }}>
          Some Material Requests cannot be fulfilled. Do you want to replenish?{' '}
          <Link onClick={onReplenishClick}>Replenish Items</Link>
        </Typography> */}
        <Typography variant="body1" gutterBottom sx={{ fontSize: 13 }}>
          Material Request for all the materials will be made, once approved all
          the material will be reserved for production.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button size="small" variant="contained" onClick={onRequestMaterial}>
          Confirm & Request
        </Button>
        <Button size="small" onClick={handleModal} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Fragment>
  ) : (
    <Fragment>
      <DialogContent>
        <MaterialSection
          title="In-House Material"
          materials={inHouseMaterials}
          actionLabel="Generate WO"
          onActionClick={() => console.log('Generate WO')}
        />
        <MaterialSection
          title="Purchased Material"
          materials={purchasedMaterials}
          actionLabel="Generate PR"
          onActionClick={() => console.log('Generate PR')}
        />
        <Typography variant="body1" gutterBottom sx={{ fontSize: 13 }}>
          Purchase requests and Work Orders will be generated for the items, and
          once available, they will be reserved for production.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button size="small" variant="contained">
          Done
        </Button>
        <Button size="small" onClick={onReplenishClick} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Fragment>
  );
};

const RequestMaterial = ({ open, handleModal, currentItem }) => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);

  const handleRequestMaterial = async () => {
    const data = {
      items: currentItem?.materials?.map((item) => ({
        product: item?.product?.id,
        qty: item?.quantity * currentItem?.quantity,
        uom: item?.uom?.id
      })),
      warehouse: currentItem?.source_warehouse?.id
    };
    const res = await dispatch(
      createDataAsync({ endPoint: 'material_request', data })
    );

    const materialRequestId = res?.payload?.id;

    if (!materialRequestId) {
      console.error('Invalid response from material request creation');
      return;
    }

    await dispatch(
      updateDataAsync({
        endPoint: 'work_order',
        data: { material_request: materialRequestId },
        id: currentItem?.id
      })
    );
  };

  return (
    <Dialog fullWidth open={open} onClose={handleModal}>
      <Box sx={{ minHeight: 350 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle>
            <Typography variant="h4">Request Raw Materials</Typography>
          </DialogTitle>
        </Stack>
        <DialogStepContent
          activeStep={activeStep}
          currentItem={currentItem}
          onRequestMaterial={handleRequestMaterial}
          // onReplenishClick={() => setActiveStep(1)}
          handleModal={handleModal}
        />
      </Box>
    </Dialog>
  );
};

export default RequestMaterial;
