import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  refreshDynamicSliceState,
  selectDynamicPageConfig,
  setCurrentTab,
  setDefaultQuery,
  setDynamicPageConfig,
  setTitle
} from 'src/slices/dynamicSlice';
import {
  capitalizeAndRemoveChar,
  getAllRequiredFields,
  getPopulateString
} from 'src/services/commonService';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import useEntity from 'src/hooks/useEntity';
import { requestApi } from 'src/mocks/request';
import TablePageLoader from 'src/components/SkeltonLoaders/TablePageLoader';
import ViewPageLoader from 'src/components/SkeltonLoaders/ViewPageLoader';
import CreateEditPageLoader from 'src/components/SkeltonLoaders/CreateEditPageLoader';

const DynamicPageWrapper = ({
  children,
  endPoint: propEndPoint,
  type: propType
}) => {
  const params = useParams();
  let endPoint = propEndPoint ?? params.endPoint;
  let type = propType ?? params.type;

  const dispatch = useDispatch();
  const { menuItems, companyEntitySettings } = useSelector(selectAppConfigData);
  const dynamicPageConfig = useSelector(selectDynamicPageConfig);
  const { entity } = useEntity(endPoint);
  const { schema, entitySetting } = useEntityPageConfig(entity);

  useEffect(() => {
    return () => {
      dispatch(refreshDynamicSliceState());
    };
  }, [entity]);

  useEffect(() => {
    const formattedTitle = capitalizeAndRemoveChar(entity, '_');

    if (companyEntitySettings && companyEntitySettings.length > 0) {
      const data = companyEntitySettings.find(
        (entitySetting) => entitySetting.entity_name === entity
      );

      const title = data && data.new_name ? data.new_name : formattedTitle;
      dispatch(setTitle(title));
    } else {
      dispatch(setTitle(formattedTitle));
    }
  }, [companyEntitySettings, entity, endPoint, dispatch]);

  useEffect(() => {
    if (schema) {
      const fieldNames = Object.keys(schema);
      let filterFieldNames = fieldNames.filter(
        (fieldName) => !REMOVE_FIELDS.includes(fieldName)
      );
      let requiredFieldNames = getAllRequiredFields(filterFieldNames, schema);
      const populateString = getPopulateString(schema, fieldNames);
      dispatch(
        setDynamicPageConfig({
          fieldNames: fieldNames.filter(
            (fieldName) =>
              !['approval_status', 'approval_id'].includes(fieldName)
          ),
          populateString,
          requiredFieldNames,
          entity,
          schema,
          entitySetting
        })
      );
    }
  }, [schema, entitySetting, dispatch]);

  useEffect(() => {
    if (!menuItems.length || !entity) return;

    // Find the relevant menu item based on the endpoint
    const foundMenuItem = menuItems
      .flatMap((menuItem) => menuItem.items)
      .find((item) => item.link === endPoint);

    // Handle tabs within the found menu item
    const firstTab = foundMenuItem?.tabs?.[0];

    // Determine the default query to use
    const defaultQuery = firstTab?.query || foundMenuItem?.query || null;

    // Dispatch the default query once
    if (defaultQuery) dispatch(setDefaultQuery(defaultQuery));

    // Set the first tab as the current tab if it exists
    if (firstTab) {
      dispatch(setCurrentTab(firstTab.name));
    }
  }, [menuItems, entity, endPoint, dispatch]);

  if (!dynamicPageConfig || entity !== dynamicPageConfig?.entity) {
    return type === 'view' ? (
      <ViewPageLoader />
    ) : type === 'edit' || type === 'create' ? (
      <CreateEditPageLoader />
    ) : (
      <TablePageLoader />
    );
  }
  return children;
};

export default DynamicPageWrapper;
