import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  docNumConfigs: [],
  paginator: null,
  status: {
    updateDocNumConfig: false,
    getDocNumConfig: false,
    createDocNumConfig: false
  }
};

export const createDocNumConfig = createAsyncThunk(
  'docNumConfig/createData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.createData(data);
      if (response?.status === 'SUCCESS') {
        return data.localUpdate;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllDocNumConfig = createAsyncThunk(
  'docNumConfig/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDocNumConfig = createAsyncThunk(
  'docNumConfig/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status === 'SUCCESS') {
        return data.localUpdate;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const slice = createSlice({
  name: 'docNumConfig',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllDocNumConfig.pending, (state) => {
        state.status.getDocNumConfig = true;
      })
      .addCase(getAllDocNumConfig.fulfilled, (state, { payload }) => {
        state.status.getDocNumConfig = false;
        const docNumConfigs = payload?.data || [];

        // Create a map to keep track of the best document for each entity
        const entityMap = new Map();

        docNumConfigs.forEach((doc) => {
          const entity = doc.entity;

          // Check if there is already a document with this entity
          if (entityMap.has(entity)) {
            // If the current document has a workspace, it takes priority over the existing one
            if (doc.workspace) {
              entityMap.set(entity, doc);
            }
          } else {
            // Add the first document for each entity
            entityMap.set(entity, doc);
          }
        });

        // Convert the map values back to an array
        state.docNumConfigs = Array.from(entityMap.values());
        state.paginator = payload?.paginator || null;
      })
      .addCase(getAllDocNumConfig.rejected, (state) => {
        state.docNumConfigs = [];
        state.status.getDocNumConfig = false;
      })
      .addCase(createDocNumConfig.pending, (state) => {
        state.status.createDocNumConfig = true;
      })
      .addCase(createDocNumConfig.fulfilled, (state, { payload }) => {
        state.status.createDocNumConfig = false;
      })
      .addCase(createDocNumConfig.rejected, (state) => {
        state.status.createDocNumConfig = false;
      })
      .addCase(updateDocNumConfig.pending, (state) => {
        state.status.updateDocNumConfig = true;
      })
      .addCase(updateDocNumConfig.fulfilled, (state, { payload }) => {
        state.status.updateDocNumConfig = false;
        state.docNumConfigs = state.docNumConfigs.map((docNumConfig) =>
          docNumConfig.id === payload.id ? payload : docNumConfig
        );
      })
      .addCase(updateDocNumConfig.rejected, (state) => {
        state.status.updateDocNumConfig = false;
      });
  }
});

export const selectDocNumConfig = (state) => state.docNumConfig;
export default slice.reducer;
