import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const HiglightsIndex = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container sx={{ py: 3 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Box
            display={'flex'}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            marginBottom={4}
          >
            <Box width={isSmallScreen ? '100%' : '60%'}>
              <Box
                component="img"
                alt="Highlights Page"
                width={'100%'}
                src={'/Screenshot (239).png'}
              />
            </Box>
            <Box
              width={isSmallScreen ? '100%' : '40%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              paddingX={3}
              gap={1}
            >
              <SettingsSuggestIcon fontSize="medium" sx={{ fill: '#e8bf47' }} />
              <Typography variant="h3">Automate Workflows</Typography>
              <Typography variant="body2" fontSize={14}>
                Automate repetitive tasks and processes with ERPZ, allowing your
                team to focus on what truly matters.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            display={'flex'}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            marginBottom={4}
          >
            <Box width={isSmallScreen ? '100%' : '60%'}>
              <Box
                component="img"
                alt="Highlights Page"
                width={'100%'}
                src={'/Screenshot (239).png'}
              />
            </Box>
            <Box
              width={isSmallScreen ? '100%' : '40%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              paddingX={3}
              gap={1}
            >
              <ThumbUpIcon fontSize="medium" sx={{ fill: '#e8bf47' }} />
              <Typography variant="h3">Real-Time Analytics</Typography>
              <Typography variant="body2" fontSize={14}>
                Make informed decisions with real-time data and insights.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            display={'flex'}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            marginBottom={4}
          >
            <Box width={isSmallScreen ? '100%' : '60%'}>
              <Box
                component="img"
                alt="Highlights Page"
                width={'100%'}
                src={'/Screenshot (239).png'}
              />
            </Box>
            <Box
              width={isSmallScreen ? '100%' : '40%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              paddingX={3}
              gap={1}
            >
              <AutoFixHighIcon fontSize="medium" sx={{ fill: '#e8bf47' }} />
              <Typography variant="h3">Customizable</Typography>
              <Typography variant="body2" fontSize={14}>
                Tailor ERPZ to fit your unique business requirements.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HiglightsIndex;
