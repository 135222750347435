import { Helmet } from 'react-helmet-async';

import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import PurchaseReqTable from './ProcessReqTable';
import { ProcessReqProvider } from 'src/contexts/ProcessReqContext';
import TablePageLoader from 'src/components/SkeltonLoaders/TablePageLoader';
import { useSelector } from 'react-redux';
import { selectData, selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import useGetItemById from 'src/hooks/useGetItemById';

function ProcessRequest() {
  const { id } = useParams();
  const data = useSelector(selectData);
  const { populateString, entity } = useSelector(selectDynamicPageConfig);
  const { currentItem, isFetching } = useGetItemById(
    id,
    {
      endPoint: entity,
      populate: populateString,
      query: { _id: id }
    },
    data
  );
  if (isFetching) return <TablePageLoader />;
  return (
    <>
      <Helmet>
        <title>Process Req Details</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <ProcessReqProvider>
            <PurchaseReqTable selectedRowData={currentItem} />
          </ProcessReqProvider>
        </Grid>
      </Grid>
    </>
  );
}

export default ProcessRequest;
