import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { requestApi } from 'src/mocks/request';

const CreateJobCards = ({ open, handleModal, currentItem }) => {
  const handleCreate = async () => {
    const res = await requestApi.custom({
      method: 'put',
      url: `/work_order/createjobs/${currentItem?.id}`
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={handleModal} maxWidth="xs">
      <Box sx={{ p: 3 }}>
        <Typography variant="h2" fontSize={14} textAlign={'center'} mb={2}>
          we'll generate job cards for you. do you want to proceed?
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <Button size="small" variant="outlined" onClick={handleModal}>
            No
          </Button>
          <Button size="small" variant="contained" onClick={handleCreate}>
            Yes
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default CreateJobCards;
