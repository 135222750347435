import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import JobCard from './JobCard';
import { useDispatch } from 'react-redux';
import { updateBulkDataAsync } from 'src/slices/dynamicSlice';

const JobCardSuggestions = ({
  open,
  handleModal,
  selectedOperation,
  selectedOperationJobCards,
  currentItem
}) => {
  const dispatch = useDispatch();

  const handleActiveJobCards = () => {
    const payload = {
      data: { isActive: true },
      filter: {
        _id: {
          $in: [...selectedOperationJobCards.map((jobCard) => jobCard.id)]
        }
      }
    };
    if (!currentItem?.start_date) return;
    dispatch(updateBulkDataAsync({ endPoint: 'job_card', data: payload }));
  };

  return (
    <Dialog fullWidth open={open} onClose={handleModal}>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          Confirm Suggestions for ‘{selectedOperation?.operation?.name}’
          Operation
        </Typography>
      </DialogTitle>
      <Grid container spacing={2} px={3}>
        <Grid item container spacing={1}>
          {selectedOperationJobCards?.map((jobCard, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <JobCard
                jobCard={jobCard}
                options={{
                  isEditable: false
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontSize={14} gutterBottom>
            Once you confirm, we'll activate the job cards for your operation.
            If you need any changes, feel free to go back.
          </Typography>
        </Grid>
      </Grid>
      <DialogActions sx={{ marginX: 'auto', marginY: 2 }}>
        <Button size="small" variant="outlined" onClick={handleModal}>
          Go Back
        </Button>
        <Button size="small" variant="contained" onClick={handleActiveJobCards}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobCardSuggestions;
