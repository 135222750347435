import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
import { getFieldType } from 'src/services/commonService';
const getMdSize = (fieldname, schema, size) => {
  const { isStringHasMaxNull, isObject, isArrayWithMultipleProperties } =
    getFieldType(fieldname, schema);
  if (isArrayWithMultipleProperties || isObject || isStringHasMaxNull)
    return 12;
  return size;
};

const Identity = ({ schema, fieldName, entitySetting }) => {
  const { values, setFieldValue } = useFormikContext();
  const isMounted = useRefMounted();
  const [isFetching, setFetching] = useState(false);
  const companySchema = schema ? schema?.['company']?.schema?.paths : null;
  const country = values?.[fieldName]?.country;
  const identities = values?.[fieldName]?.identities || [];
  const identitiesSchema = companySchema?.identities?.schema?.paths;
  const fetchIdentityType = useCallback(async () => {
    if (!country || identities?.length > 0) return;
    setFetching(true);
    try {
      const payload = {
        endPoint: 'identity_type',
        query: {
          country
        }
      };
      const response = await requestApi.getData(payload);
      const resData = response?.data?.data || [];
      if (resData?.length) {
        const identities = resData.map((item) => {
          return {
            name: item.name,
            number: '',
            valid_from: '',
            validity_till: '',
            attachment: ''
          };
        });
        setFieldValue(`${fieldName}.identities`, identities);
      }
    } finally {
      if (isMounted.current) setFetching(false);
    }
  }, [country]);
  useEffect(() => fetchIdentityType(), []);
  if (isFetching) return <Skeleton width={'100%'} height={20} />;
  return (
    <Grid container spacing={2}>
      {identities.map((identity, index) => (
        <Grid item xs={12} key={index}>
          <Card variant="outlined">
            <CardHeader title={identity?.name} />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                {['number', 'valid_from', 'validity_till', 'attachment'].map(
                  (field) => (
                    <Grid
                      item
                      xs={12}
                      md={getMdSize(field, identitiesSchema, 4)}
                      sm={getMdSize(field, identitiesSchema, 6)}
                      key={`${field}_${index}`}
                    >
                      <GetFieldBasedOnSchema
                        name={`${fieldName}.identities[${index}].${field}`}
                        fieldName={field}
                        schema={identitiesSchema}
                        value={identity?.[field] || ''}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Identity;
