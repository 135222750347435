import { DatePicker } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { IconButtonWrapper } from 'src/components/Styled';
import JobScheduler from './Scheduler';
import DTAutocomplete from 'src/components/GetFieldBasedOnSchema/DTAutocomplete';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import { requestApi } from 'src/mocks/request';
import Scrollbar from 'src/components/Scrollbar';
import { updateDataAsync } from 'src/slices/dynamicSlice';
import { useDispatch } from 'react-redux';

const EditJobCard = ({
  open,
  handleModal,
  selectedJobCard,
  setSelectedJobCard,
  setCurrentItem,
  currentItem
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [jobCards, setJobCards] = useState([]);
  const [formData, setFormData] = useState({});
  const { schema, entitySetting } = useEntityPageConfig('job_card');

  const handleFormChange = (fieldName, value) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const jobCardDate = selectedJobCard?.scheduled_start;

  const fetchJobCards = async () => {
    try {
      const query = {
        shop_floor: formData?.shop_floor?.id,
        equipment: formData?.equipment?.id,
        operator: formData?.operator?.id,
        scheduled_start: {
          $gte: moment(selectedDate).startOf('day'),
          $lte: moment(selectedDate).endOf('day')
        }
      };
      const response = await requestApi.getData({
        endPoint: 'job_card',
        query
      });

      const jobs = response?.data?.data || [];
      setJobCards(jobs);
    } catch (error) {
      console.error('Error fetching job cards:', error);
    }
  };

  useEffect(() => {
    if (jobCardDate) {
      const initialDate = moment(jobCardDate);
      setSelectedDate(initialDate);
      updateDaysInMonth(initialDate);
      setSelectedDay(initialDate.date());
    }
  }, [jobCardDate]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      shop_floor: selectedJobCard?.shop_floor,
      equipment: selectedJobCard?.equipment,
      operator: selectedJobCard?.operator
    }));
  }, [selectedJobCard]);

  useEffect(() => {
    fetchJobCards();
  }, [formData, selectedDate]);

  const handleMonthChange = (newDate) => {
    if (newDate) {
      const formattedDate = moment(newDate).startOf('month');
      updateDaysInMonth(formattedDate);

      // If the selected day is valid for the new month, use it; otherwise default to the 1st day
      const newSelectedDay =
        selectedDay <= formattedDate.daysInMonth() ? selectedDay : 1;

      // Update selectedDate and selectedDay
      const updatedDate = formattedDate.clone().date(newSelectedDay);
      setSelectedDate(updatedDate);
      setSelectedDay(newSelectedDay);
    }
  };

  const handleDayChange = (day) => {
    setSelectedDay(day);
    const updatedDate = selectedDate.clone().date(day);
    setSelectedDate(updatedDate);
    setSelectedJobCard((prev) => ({ ...prev, scheduled_start: updatedDate }));
  };

  const updateDaysInMonth = (date) => {
    const daysInMonth = moment(date).daysInMonth();
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    setDaysInSelectedMonth(daysArray);
  };

  const handleSetJobCardActive = () => {
    if (!currentItem?.start_date) return;
    dispatch(
      updateDataAsync({
        endPoint: 'job_card',
        id: selectedJobCard?.id,
        data: { isActive: !selectedJobCard?.isActive }
      })
    );
    setSelectedJobCard((prev) => ({ ...prev, isActive: !prev.isActive }));
    setCurrentItem((prev) => {
      const updatedItem = prev;
      updatedItem?.operations?.forEach((op) => {
        op?.job_cards?.forEach((jobCard) => {
          if (jobCard?.id === selectedJobCard?.id) {
            jobCard.isActive = !jobCard.isActive;
          }
        });
      });
      return updatedItem;
    });
  };

  const renderDayButtons = useMemo(() => {
    return daysInSelectedMonth?.map((day) => (
      <IconButtonWrapper
        key={day}
        size="small"
        onClick={() => handleDayChange(day)}
        sx={{
          borderRadius: '2px',
          width: '30px',
          backgroundColor:
            day === selectedDay
              ? theme.colors.primary.main
              : theme.colors.primary.lighter,
          color:
            day === selectedDay
              ? theme.palette.getContrastText(theme.colors.primary.main)
              : theme.colors.primary.main,
          transition: `${theme.transitions.create(['all'])}`,
          '&:hover': {
            backgroundColor: `${theme.colors.primary.main}`,
            color: `${theme.palette.getContrastText(theme.colors.primary.main)}`
          }
        }}
      >
        {day}
      </IconButtonWrapper>
    ));
  }, [daysInSelectedMonth, selectedDay]);

  return (
    <Dialog fullWidth open={open} onClose={handleModal} maxWidth="md">
      <Box sx={{ height: '800px' }}>
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Edit Job Card | {selectedJobCard?.number}
            </Typography>

            <Stack direction="row" spacing={1}>
              <DatePicker
                views={['year', 'month']}
                label="Select Month"
                minDate={moment().startOf('year').toDate()}
                maxDate={moment().add(10, 'years').endOf('year').toDate()}
                value={selectedDate}
                onChange={handleMonthChange}
                renderInput={(params) => (
                  <TextField size="small" {...params} fullWidth />
                )}
              />
              {schema && entitySetting && (
                <DTAutocomplete
                  size="small"
                  label="Shop Floor"
                  fieldName={'shop_floor'}
                  value={formData?.shop_floor || ''}
                  schema={schema}
                  onChange={(value) => handleFormChange('shop_floor', value)}
                  entitySetting={entitySetting}
                />
              )}
              {schema && entitySetting && (
                <DTAutocomplete
                  size="small"
                  label="Equipment"
                  fieldName={'equipment'}
                  value={formData?.equipment || ''}
                  schema={schema}
                  onChange={(value) => handleFormChange('equipment', value)}
                  entitySetting={entitySetting}
                />
              )}
              {schema && entitySetting && (
                <DTAutocomplete
                  size="small"
                  label="Operator"
                  fieldName={'operator'}
                  value={formData?.operator || ''}
                  schema={schema}
                  onChange={(value) => handleFormChange('operator', value)}
                  entitySetting={entitySetting}
                />
              )}
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              overflow={'auto'}
              sx={{
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
            >
              {renderDayButtons}
            </Stack>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                background: `${theme.colors.alpha.black[10]}`,
                borderRadius: '8px',
                border: `2px solid ${theme.colors.alpha.black[20]}`
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Stack direction="row" spacing={4} alignItems="center">
                  <Typography variant="h5">
                    {selectedDate?.format('D MMM YYYY')}
                  </Typography>
                  <Box>
                    <Typography
                      fontWeight="bold"
                      variant="caption"
                      color="primary"
                    >
                      Activate
                    </Typography>
                    <Switch
                      edge="end"
                      color="primary"
                      checked={selectedJobCard?.isActive}
                      onChange={handleSetJobCardActive}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '2px',
                      background: theme.palette.primary.main
                    }}
                  ></Box>
                  <Typography variant="h6">Your Job</Typography>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '2px',
                      background: theme.palette.secondary.light
                    }}
                  ></Box>
                  <Typography variant="h6">Scheduled</Typography>
                </Stack>
              </Stack>

              <JobScheduler
                jobCards={jobCards}
                selectedJobCard={selectedJobCard}
                setCurrentItem={setCurrentItem}
              />

              <Box>
                <Typography variant="h6" gutterBottom>
                  Some Recommended slots for you
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Button size="small" variant="outlined">
                    11:15 am - 11:30 am
                  </Button>
                  <Button size="small" variant="outlined">
                    7:00 pm - 7:30 pm
                  </Button>
                </Stack>
              </Box>
            </Card>
          </Stack>
        </Scrollbar>
      </Box>
    </Dialog>
  );
};

export default EditJobCard;
