import { Box, styled, Tooltip, tooltipClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectWorkspace } from 'src/slices/publicSlice';

const LogoWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo() {
  const { t } = useTranslation();
  const workspace = useSelector(selectWorkspace);

  return (
    <TooltipWrapper title={t(workspace?.name)} arrow>
      <LogoWrapper>
        {workspace && workspace?.logo ? (
          <img
            src={workspace?.logo}
            alt={workspace?.name}
            width={62}
            height={48}
          />
        ) : (
          <img
            src={
              'https://erptp.s3.ap-south-1.amazonaws.com/website-assets/erpz-logo.png'
            }
            alt="erpz-logo"
            width={100}
            height={100}
          />
        )}
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
