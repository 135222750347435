import React from 'react';
import { getFieldType } from 'src/services/commonService';
import CustomSelectFieldWithLabel from '../CustomFields/CustomSelectFieldWithLabel';
import CustomTextField from '../CustomFields/CustomTextField';
import FlatArrayField from '../CustomFields/FlatArrayField';
import CustomMultiSelectField from '../CustomFields/CustomMultiSelectField';
import { useFormikContext } from 'formik';
import CustomSelectField from '../CustomFields/CustomSelectField';

const FieldByTypeOp = ({
  name,
  label,
  value,
  operator,
  fieldName,
  schema,
  touched,
  error
}) => {
  const { setFieldValue, handleChange } = useFormikContext();
  const {
    isBoolean,
    isNumber,
    isString,
    isDate,
    isObjectID,
    isStringEnum,
    isNumberEnum,
    isArray,
    isArrayEnum,
    enumValues
  } = getFieldType(fieldName, schema);

  const commonProps = {
    name,
    label: label || fieldName,
    value,
    onChange: handleChange,
    touched,
    error
  };

  const renderTextField = (type) => (
    <CustomTextField
      {...commonProps}
      fullWidth={true}
      inputProps={{ type }}
      InputLabelProps={isDate ? { shrink: true } : {}}
    />
  );

  const renderSelectField = () => (
    <CustomSelectField {...commonProps} options={enumValues} />
  );

  const renderMultiSelectField = () => (
    <CustomMultiSelectField
      {...commonProps}
      options={enumValues}
      onChange={(e) => setFieldValue(name, e.target.value)}
    />
  );

  const renderFlatArrayField = () => (
    <FlatArrayField
      value={value || []}
      error={error}
      touched={touched}
      name={name}
      onChange={(value) => setFieldValue(name, value)}
      label={label || fieldName}
    />
  );

  switch (operator) {
    case 'equal':
    case 'is not equal':
      if (isBoolean) {
        return (
          <CustomSelectFieldWithLabel
            {...commonProps}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
          />
        );
      } else if (
        (isString && !isStringEnum) ||
        (isNumber && !isNumberEnum) ||
        isDate ||
        isObjectID
      ) {
        return renderTextField(isDate ? 'date' : isNumber ? 'number' : 'text');
      } else if (isStringEnum || isNumberEnum) {
        return renderSelectField();
      }
      break;

    case 'contains':
      if ((isString && !isStringEnum) || isObjectID) {
        return renderTextField('text');
      } else if (isStringEnum) {
        return renderSelectField();
      } else if (isArrayEnum) {
        return renderMultiSelectField();
      } else if (isArray) {
        return renderFlatArrayField();
      }
      break;

    case 'is any of':
      if (isStringEnum || isArrayEnum) {
        return renderMultiSelectField();
      } else if (isArray || isString || isObjectID) {
        return renderFlatArrayField();
      }
      break;

    case 'greater than':
    case 'in between':
    case 'less than':
      if ((isNumber && !isNumberEnum) || isDate) {
        return renderTextField(isDate ? 'date' : 'number');
      } else if (isNumberEnum) {
        return renderSelectField();
      }
      break;

    case 'ends with':
    case 'starts with':
      if ((isString && !isStringEnum) || isObjectID) {
        return renderTextField('text');
      } else if (isStringEnum) {
        return renderSelectField();
      }
      break;

    default:
      return null;
  }
};

export default FieldByTypeOp;
