import React from 'react';
import { Grid } from '@mui/material';
import GrnFormBody from './GrnFormBody';
import CreateEditPageLoader from 'src/components/SkeltonLoaders/CreateEditPageLoader';
import useDyFetchDataById from 'src/hooks/useDyFetchDataById';
import { Helmet } from 'react-helmet-async';

const GrnForm = () => {
  const { currentItem, isFetching } = useDyFetchDataById();

  if (isFetching) return <CreateEditPageLoader />;
  return (
    <>
      <Helmet>
        <title>GRN</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <GrnFormBody currentItem={currentItem} />
        </Grid>
      </Grid>
    </>
  );
};

export default GrnForm;
