import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import ASTableRow from './ASTableRow';

const AuditSplit = ({ currentItem }) => {
  const { splits } = currentItem;
  return (
    <Card variant="outlined">
      <CardHeader title="Audit Split" />
      <Divider />
      <CardContent>
        <Scrollbar maxHeight="auto">
          <Table sx={{ minWidth: 700, tableLayout: 'fixed' }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Appoint Verifier</TableCell>
                <TableCell>Operations</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {splits.length ? (
                splits.map((split, index) => (
                  <ASTableRow
                    key={index}
                    currentItem={currentItem}
                    split={split}
                    path={`splits[${index}]`}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell>No data Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </CardContent>
    </Card>
  );
};

export default AuditSplit;
