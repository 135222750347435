import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Field } from 'formik';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const EntityDropdown = ({ entity, name, label }) => {
  const { schema } = useEntityPageConfig(entity);
  let entityFieldNames = schema ? Object.keys(schema) : [];
  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Field
        name={name}
        as={Select}
        labelId={`${name}-label`}
        label={label}
        fullWidth
      >
        {entityFieldNames.map((fieldName, index) => (
          <MenuItem value={fieldName} key={index}>
            {fieldName}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export default EntityDropdown;
