import React from 'react';
import { Typography, styled, Grid, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormikContext } from 'formik';
import useMultiFileUpload from 'src/hooks/useMultiFIleUpload';
import Scrollbar from 'src/components/Scrollbar';
import ImageGrid from './ImageGrid';
import FileGrid from './FileGrid';

const BoxUploadWrapper = styled(Grid)(
  ({ theme }) => `
  border-radius: ${theme.general.borderRadius};
  padding: ${theme.spacing(3)};
  background: ${theme.colors.alpha.black[5]};
  border: 1px dashed ${theme.colors.alpha.black[30]};
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: ${theme.transitions.create(['border', 'background'])};
  height: 100%;

  &:hover {
    background: ${theme.colors.alpha.white[100]};
    border-color: ${theme.colors.primary.main};
  }
`
);

const FileUpload = ({ value, path, isImage }) => {
  const { setFieldValue } = useFormikContext();
  const handleFileChange = (value) => setFieldValue(path, value);
  const {
    files,
    getRootProps,
    getInputProps,
    isDragActive,
    handleFileDelete,
    loading
  } = useMultiFileUpload(value, handleFileChange, isImage);
  return (
    <>
      {files.length > 0 && (
        <Scrollbar maxHeight={'auto'}>
          {isImage ? (
            <ImageGrid
              files={files}
              handleFileDelete={handleFileDelete}
              loading={loading}
            />
          ) : (
            <Stack direction={'row'} spacing={0.5}>
              {files.map((file, index) => (
                <FileGrid
                  key={index}
                  file={file}
                  handleFileDelete={handleFileDelete}
                  loading={loading}
                  index={index}
                />
              ))}
            </Stack>
          )}
        </Scrollbar>
      )}
      <BoxUploadWrapper {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 60, mb: 2 }} />
        <Typography variant="body1" color="textSecondary" textAlign="center">
          {isDragActive
            ? `Drop the ${isImage ? 'image' : 'file'} here...`
            : `Drag 'n' drop ${isImage ? 'a image' : 'an file'} here,`}
        </Typography>
        {!isDragActive && (
          <Typography variant="body1" color="textSecondary" textAlign="center">
            or{' '}
            <Typography
              component="span"
              variant="body1"
              color="primary"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              click to select one
            </Typography>
          </Typography>
        )}
      </BoxUploadWrapper>
    </>
  );
};

export default FileUpload;
