import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEntityPageSchemaAsync,
  getEntityPageSettingAsync,
  selectAppConfigData
} from 'src/slices/appConfigSlice';

const useEntityPageConfig = (entity) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { entityPageConfigs } = useSelector(selectAppConfigData);
  const { schema, entitySetting } = useMemo(() => {
    const schema = entityPageConfigs?.[entity]?.schema || null;
    const entitySetting = entityPageConfigs?.[entity]?.setting || null;
    return { schema, entitySetting };
  }, [entityPageConfigs, entity]);

  useEffect(() => {
    if (!entity || schema) return;
    const fetchEntityConfig = async () => {
      setLoading(true);
      try {
        await Promise.all([
          dispatch(
            getEntityPageSchemaAsync({ storekey: entity, endPoint: entity })
          ),
          dispatch(
            getEntityPageSettingAsync({
              storekey: entity,
              endPoint: 'setting',
              query: { entity }
            })
          )
        ]);
      } finally {
        setLoading(false);
      }
    };
    fetchEntityConfig();
  }, [dispatch, entity, schema]);

  return { schema, entitySetting, loading };
};

export default useEntityPageConfig;
